import { IconButton } from "@mui/material"
import Link from "next/link"
import { useContext } from "react"
import { BiArrowBack, BiLinkAlt, BiX } from "react-icons/bi"
import { CgArrowsExpandRight } from "react-icons/cg"
import { classNames } from "../../../helpers/classNames"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { CopyToClipboard } from "../../CopyToClipboard"
import { infoSnack } from "../../Notistack/ThemedSnackbars"

type DrawerHeaderProps = {
  href?: string
  handleClose: () => void
}

export const DrawerHeader = ({ href, handleClose }: DrawerHeaderProps) => {
  const { clearAll, stack } = useContext(DrawerContext)

  return (
    <div className={classNames("flex items-center justify-center py-1 px-3", "md:border-b md:justify-between")}>
      <IconButton size="large" className="hidden md:block" onClick={handleClose}>
        <BiArrowBack className={classNames("w-5 h-5", (stack?.length || 0) > 1 ? "text-gray-800" : "text-gray-400")} />
      </IconButton>

      <div className={classNames("flex gap-1 items-start", "md:justify-end md:py-3 md:px-6")}>
        {href && (
          <>
            <CopyToClipboard
              className={classNames(
                "hidden h-12 w-12 -mr-4 md:mr-0 items-center justify-center flex-none rounded-full transition-colors p-2",
                "md:h-12 md:w-12 md:flex",
                "hover:bg-gray-50"
              )}
              onCopy={() => infoSnack("Copied link to clipboard")}
              text={href}
              title="Copy link to clipboard"
            >
              <BiLinkAlt className="w-6 h-6" />
            </CopyToClipboard>
            <Link
              href={href}
              className={classNames(
                "hidden h-12 w-12 -mr-4 md:mr-0 items-center justify-center flex-none rounded-full transition-colors p-2",
                "md:h-12 md:w-12 md:flex",
                "hover:bg-gray-50"
              )}
              title="Open in full view"
            >
              <CgArrowsExpandRight className="w-5 h-5" />
            </Link>
          </>
        )}
        <IconButton size="large" onClick={clearAll} className="hidden md:block" title="Close drawer">
          <BiX className={classNames("w-7 h-7", "md:w-6 md:h-6")} />
        </IconButton>
        <div className={classNames("w-full flex justify-center pt-3", "md:hidden")}>
          <div className="h-1.5 w-12 bg-gray-200 rounded-full" />
        </div>
      </div>
    </div>
  )
}
