import { FC } from "react"
import { Table } from "../../Table/Table"
import { Row } from "../../Table/Row"
import { SkeletonIcon } from "../../Skeletons/SkeletonIcon"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { SkeletonH2 } from "../../Skeletons/SkeletonH2"
import { SkeletonTextSmall } from "../../Skeletons/SkeletonTextSmall"
import { SkeletonElement } from "../../Skeletons/SkeletonElement"
import { ColumnTitles } from "../../Table/ColumnTitles"
import { classNames } from "../../../helpers/classNames"

const SkeletonRow: FC = () => {
  return (
    <Row
      className={classNames(
        "grid auto-rows-min grid-cols-12 grid-flow-col gap-y-6",
        "md:grid-flow-row md:grid-cols-12 md:grid-rows-1 md:items-center md:gap-x-6"
      )}
    >
      <div className="col-span-10 md:col-span-3 flex gap-x-4 items-center">
        <SkeletonElement className={"h-10 w-10"} />
        <div className={"flex flex-col gap-y-3"}>
          <SkeletonElement className={"h-4 w-36"} />
          <SkeletonTextSmall className={"w-40"} />
        </div>
      </div>
      <div className="col-span-10 md:col-span-5">
        <div className={"flex flex-col gap-y-3"}>
          <SkeletonElement className={"h-3 w-16 md:hidden"} />
          <SkeletonElement className={"h-4 w-40"} />
        </div>
      </div>
      <div className="col-span-10 md:col-span-3">
        <div className={"flex flex-col gap-y-3"}>
          <SkeletonElement className={"h-3 w-32 md:hidden"} />
          <SkeletonElement className={"h-4 w-52"} />
        </div>
      </div>
      <div className={"row-span-3 col-span-2 justify-self-end md:row-auto md:col-span-1"}>
        <SkeletonIcon />
      </div>
    </Row>
  )
}

export const OrganizationsSkeleton: FC = () => {
  return (
    <SkeletonContainer>
      <div className="flex justify-between items-center mb-6">
        <SkeletonH2 />
        <SkeletonIcon />
      </div>
      <div className="flex mb-10 gap-x-6">
        <SkeletonElement className={"h-6 w-16"} />
        <SkeletonElement className={"h-6 w-28"} />
      </div>
      <ColumnTitles>
        <SkeletonElement className={"col-span-3 h-6 w-28"} />
        <SkeletonElement className={"col-span-5 h-6 w-16 hidden md:block"} />
        <SkeletonElement className={"col-span-4 h-6 w-32 hidden md:block"} />
      </ColumnTitles>
      <Table>
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
      </Table>
      <div className="flex justify-center pt-8">
        <SkeletonTextSmall />
      </div>
    </SkeletonContainer>
  )
}
