import React, { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"
import { classNames } from "../../helpers/classNames"

type Props = {
  children: ReactNode
  className?: string
}

export const P: FC<Props> = ({ children, className = "", ...props }) => (
  <p {...props} className={overrideTailwindClasses(classNames("mt-2 mb-4 text-gray-800", className))}>
    {children}
  </p>
)
