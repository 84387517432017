import Link from "next/link"
import { FC } from "react"
import { AiFillHome } from "react-icons/ai"
import { SkeletonTextSmall } from "./Skeletons/SkeletonTextSmall"

type Props = {
  countSegments: number
}

export const BreadcrumbsSkeleton: FC<Props> = ({ countSegments }) => {
  return (
    <ol className="flex gap-1 md:gap-2 items-center text-gray-400">
      <li className={"cursor-pointer flex gap-x-1 md:gap-x-2"}>
        <Link href="/" className="flex items-center justify-center leading-[0]">
          <AiFillHome className="h-4 w-4" />
        </Link>
        <span className="text-gray-400">/</span>
      </li>
      {[...Array(countSegments - 1)].map((_e, i) => (
        <li key={i} className={"animate-pulse flex gap-x-1 md:gap-x-2 text-gray-400 capitalize items-center"}>
          <SkeletonTextSmall />
          {i < countSegments - 1 && <span className="text-gray-400">/</span>}
        </li>
      ))}
      <li className={"animate-pulse text-gray-800 max-w-[4em] md:max-w-[10em] truncate"}>
        <SkeletonTextSmall />
      </li>
    </ol>
  )
}
