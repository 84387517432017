import Head from "next/head"
import { FC, ReactNode, useState } from "react"
import { OrganizationBillingStatus } from "../../graphql/generated/graphcache"
import { classNames } from "../../helpers/classNames"
import { useOrganizationBillingStatus } from "../../hooks/useOrganizationBillingStatus"
import { PageTitle } from "../PageTitle"
import { BillingPastDueBanner } from "./Elements/BillingPastDueBanner"
import Header from "./Header/Header"
import Navbar from "./Navbar/Navbar"

type Props = {
  children: ReactNode
  title?: string
  useDataGridStyles?: boolean
}

const Layout: FC<Props> = ({ children, title = "Admin", useDataGridStyles = false }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { organizationBillingStatus } = useOrganizationBillingStatus()

  return (
    <>
      <PageTitle title={title} />
      <Head>
        {process.env.NEXT_PUBLIC_IS_PRODUCTION !== "true" && (
          <link rel="shortcut icon" href="/favicon-development.ico" />
        )}
      </Head>

      {organizationBillingStatus !== OrganizationBillingStatus.DisabledDueToNonPayment && (
        <div className="min-h-screen">
          <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div className="xl:pl-64 flex flex-col">
            <Header setSidebarOpen={setSidebarOpen} />
            {/* z-0 here prevents this main section from overlapping header elements */}
            <main className="flex-1 z-0">
              <div className={classNames("bg-white", useDataGridStyles ? "pt-8" : "py-8")}>
                <div
                  className={classNames(
                    "max-w-7xl mx-auto px-4 sm:px-6 md:px-8",
                    useDataGridStyles ? "" : "mb-12 md:mb-24"
                  )}
                >
                  {children}
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
      <BillingPastDueBanner />
    </>
  )
}

export default Layout
