import { DeprecatedTabGroup, Tab } from "../../components/deprecated/TabGroup"
import { organizationTabsTestSelectors } from "../../cypress/support/testSelectors"

const tabs: Tab[] = [
  {
    label: "Details",
    href: "/organization/details",
    testSelector: organizationTabsTestSelectors.details,
  },
  {
    label: "Admins",
    href: "/organization/admins",
    testSelector: organizationTabsTestSelectors.admins,
    requiredPermission: "fe-organizationAdmins:read",
  },
  {
    label: "Roles",
    href: "/organization/roles",
    testSelector: organizationTabsTestSelectors.roles,
    requiredPermission: "fe-organizationRoles:read",
  },
  {
    label: "Settings",
    href: "/organization/settings",
    testSelector: organizationTabsTestSelectors.settings,
    requiredPermission: "organization:update",
  },
]

const Tabs = () => {
  return <DeprecatedTabGroup tabs={tabs} />
}
export default Tabs
