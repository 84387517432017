import { StyledEngineProvider, ThemeProvider } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LicenseInfo } from "@mui/x-license-pro"
import { NextComponentType, NextPage } from "next"
import { AppProps } from "next/app"
import Script from "next/script"
import { SnackbarProvider } from "notistack"
import { FC, ReactNode, useContext, useEffect, useState } from "react"
import { Provider as UrQLProvider } from "urql"
import { SnackbarProviderActions, ThemedSnackbars } from "../components/Notistack/ThemedSnackbars"
import { urqlClient } from "../lib/urql"
import {
  DevelopmentFeatureFlagContext,
  DevelopmentFeatureFlagProvider,
} from "../providers/DevelopmentFeatureFlagProvider"
import { ModalProvider } from "../providers/ModalProvider"
import { NotificationsProvider } from "../providers/NotificationsProvider"
import { SessionContext, SessionProvider } from "../providers/SessionProvider"
import "../styles/globals.css"
import { muiTheme } from "../styles/mui-theme"
import Router from "./_router"

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_SECRET_FOR_UI_LIBRARY_1 || "")

const ClientOnly: FC<{ children: ReactNode }> = ({ children, ...delegated }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return <div {...delegated}>{children}</div>
}

const App: NextPage<AppProps<{}>> = ({ Component, pageProps }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <ClientOnly>
          {process.env.NEXT_PUBLIC_IS_PRODUCTION === "true" && (
            <>
              <Script id="google-tag-manager" strategy="afterInteractive">
                {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P2KRB67');
        `}
              </Script>
              <noscript>
                <iframe
                  src="https://www.googletagmanager.com/ns.html?id=GTM-P2KRB67"
                  height="0"
                  width="0"
                  style={{ display: "none", visibility: "hidden" }}
                />
              </noscript>
            </>
          )}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SessionProvider>
              <DevelopmentFeatureFlagProvider>
                <Authenticated Component={Component} pageProps={pageProps} />
              </DevelopmentFeatureFlagProvider>
            </SessionProvider>
          </LocalizationProvider>
        </ClientOnly>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

function Authenticated({ Component, pageProps }: { Component: NextComponentType; pageProps: any }) {
  const session = useContext(SessionContext)
  const featureFlagContext = useContext(DevelopmentFeatureFlagContext)
  return (
    <UrQLProvider value={urqlClient(session, featureFlagContext)}>
      <ModalProvider>
        <NotificationsProvider>
          <SnackbarProvider
            Components={ThemedSnackbars}
            action={(snackbarId) => <SnackbarProviderActions snackbarId={snackbarId} />}
            autoHideDuration={5000}
            maxSnack={3}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <Router Component={Component} pageProps={pageProps} />
          </SnackbarProvider>
        </NotificationsProvider>
      </ModalProvider>
    </UrQLProvider>
  )
}

export default App
