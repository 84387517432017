import { NextComponentType } from "next"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { FC, useContext } from "react"
import { LoadingIndicator } from "../components/Loading/LoadingIndicator"
import { BreadcrumbsProvider } from "../providers/BreadcrumbsProvider"
import { DrawerProvider } from "../providers/DrawerProvider"
import { OrganizationSettingsProvider } from "../providers/OrganizationSettingsProvider"
import { PermissionsProvider } from "../providers/PermissionsProvider/PermissionsProvider"
import { CurrentUserProvider } from "../providers/PermissionsProvider/currentUserProvider"
import { SessionContext } from "../providers/SessionProvider"

// We don't need this as part of the server rendered page
const PageLoadingProgress = dynamic(
  () => import("../components/PageLoadingProgress").then((mod) => mod.PageLoadingProgress),
  {
    ssr: false,
  }
)

const publicUrls = [
  "/404",
  "/auth/email-login",
  "/auth/login",
  "/auth/reset-password",
  "/auth/verify",
  "/auth/error",
  "/policies",
  "/unsubscribe",
] as string[]

type Props = {
  Component: NextComponentType
  pageProps: any
}

const Router: FC<Props> = ({ Component, pageProps }) => {
  const router = useRouter()
  const { status } = useContext(SessionContext)

  if (status === "unauthenticated") {
    if (publicUrls.includes(router.pathname)) {
      return <Component {...pageProps} />
    }

    router.push(`/auth/login`)
  } else if (status === "loading") {
    return (
      <div className={"flex min-h-screen w-screen items-center justify-center"}>
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <>
      <CurrentUserProvider>
        <PermissionsProvider>
          <OrganizationSettingsProvider>
            <BreadcrumbsProvider>
              <PageLoadingProgress />
              <DrawerProvider>
                <Component {...pageProps} />
              </DrawerProvider>
            </BreadcrumbsProvider>
          </OrganizationSettingsProvider>
        </PermissionsProvider>
      </CurrentUserProvider>
    </>
  )
}

export default Router
