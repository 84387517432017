import Script from "next/script"
import { BiDiamond } from "react-icons/bi"

export const WhatsNewNavItem = () => {
  return (
    <>
      <Script strategy="lazyOnload" type="text/javascript" src="https://app.olvy.co/scriptV2.js" />
      <Script id="load-olvy">{`
      var OlvyConfig = { workspaceAlias: "crewview" };
      `}</Script>

      <button
        className="w-full text-gray-700 hover:bg-gray-100 hover:text-gray-800 group flex items-center px-2 py-2 font-bold rounded-md transition-all text-base"
        id="olvy-whats-new"
        type="button"
      >
        <BiDiamond className="mr-3 flex-shrink-0 h-6 w-6" />
        What&apos;s New
      </button>
    </>
  )
}
