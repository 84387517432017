import { GraphQLError } from "graphql"

export const EXPIRED_TOKEN = "EXPIRED_TOKEN"
export const INVALID_SESSION = "INVALID_SESSION"
export const FORBIDDEN = "FORBIDDEN"
export class ForbiddenError extends GraphQLError {
  constructor(msg?: string) {
    super(msg ? `Forbidden: ${msg}` : "Forbidden")
    this.extensions.code = FORBIDDEN
  }
}

// There are 2 types of unauthorized errors.
// 1. ExpiredTokenError - Logged in and needs to refresh token.
// 2. InvalidSessionError - Logged out and needs to be redirected to sign in page
export class ExpiredTokenError extends GraphQLError {
  constructor(msg?: string) {
    super(msg ? `Unauthenticated: ${msg}` : "Unauthorized: Token has expired")
    this.extensions.code = EXPIRED_TOKEN
  }
}

export class InvalidSessionError extends GraphQLError {
  constructor(msg?: string) {
    super(msg ? `Unauthenticated: ${msg}` : "Unauthorized: User is logged out")
    this.extensions.code = INVALID_SESSION
  }
}

export class UserInputError extends GraphQLError {
  constructor(msg: string) {
    super(msg)
    this.extensions.code = "BAD_USER_INPUT"
  }
}

export class ConflictError extends GraphQLError {
  constructor(msg: string) {
    super(msg)
  }
}
export class JobsError extends GraphQLError {
  constructor(msg: string) {
    super(msg)
  }
}
