var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"40e19bfd396b2c120940a1e0ee126cbae5cb0d9c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { BrowserTracing } from "@sentry/browser"
import * as Sentry from "@sentry/nextjs"

const isProd = process.env.NODE_ENV === "production"
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const debug = !!process.env.SENTRY_DEBUG

const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT

if (SENTRY_DSN) {
  const tracesSampleRate = process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || 1.0 // Defaulted high to support initial performance investigations

  Sentry.init({
    dsn: SENTRY_DSN || "",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: isProd ? tracesSampleRate : 1.0,
    integrations: [new BrowserTracing()],

    environment: SENTRY_ENVIRONMENT,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    debug,
  })
}
