import { Divider, FormControlLabel, SwipeableDrawer, ToggleButtonGroup, Tooltip } from "@mui/material"
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from "react"
import { classNames } from "../helpers/classNames"

import { FormGroup, Switch } from "@mui/material"
import MuiToggleButton from "@mui/material/ToggleButton"
import { styled } from "@mui/material/styles"
import { BsFlagFill } from "react-icons/bs"
import { colors } from "../helpers/colors"
import { SessionContext } from "./SessionProvider"

const ToggleButton = styled(MuiToggleButton)({
  "&.MuiToggleButton-root": {
    "&.Mui-selected": {
      color: "white",
      backgroundColor: colors.blue[600],
    },
    "&.Mui-selected:hover": {
      color: "white",
      backgroundColor: colors.blue[700],
    },
  },
})

export type FeatureFlagContextValue = {
  flagIsEnabled: (name: string) => boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const DEV_FEATURE_FLAGS_LOCALSTORAGE_KEY = "dev_feature_flags"
export const DEV_FEATURE_FLAGS_LOCALSTORAGE_OVERRIDE_KEY = "dev_feature_flags_override"

export const useFlags = () => useContext(DevelopmentFeatureFlagContext)

export const DevelopmentFeatureFlagContext = createContext<FeatureFlagContextValue>({} as FeatureFlagContextValue)

export const DevelopmentFeatureFlagProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const {
    flagIsEnabled,
    featureFlags,
    setFeatureFlagOverrides,
    resetFeatureFlags,
    overridesEnabled,
    setOverridesEnabled,
  } = useContext(SessionContext)

  const [isOpen, setIsOpen] = useState(false)

  const handleKeypress = (event: KeyboardEvent) => {
    if (event.repeat) return
    //Keypress to toggle the feature flag drawer is Shift + Ctrl + Cmd + f
    const isRightKeyCombination = event.shiftKey && event.ctrlKey && event.metaKey && event.key === "F"
    if (isRightKeyCombination) setIsOpen((previous) => !previous)
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeypress)
    return () => document.removeEventListener("keydown", handleKeypress)
  })

  const toggleOne = (name: string, enabled: boolean) => {
    setFeatureFlagOverrides((current) =>
      current.map((flag) => {
        if (flag.name === name) flag.enabled = enabled
        return flag
      })
    )
  }

  const toggleAll = (enabled: boolean) => {
    setFeatureFlagOverrides((current) => current.map((flag) => ({ ...flag, enabled })))
  }

  return (
    <DevelopmentFeatureFlagContext.Provider value={{ flagIsEnabled, setIsOpen }}>
      {children}
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        PaperProps={{ classes: { root: classNames("w-full max-w-lg") } }}
      >
        <div className="flex items-start justify-between p-8">
          <header className="font-bold text-3xl">Feature Flag Overrides</header>
        </div>
        <div className="grid gap-12 p-10">
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={overridesEnabled} onChange={(_e, checked) => setOverridesEnabled(checked)} />}
              label="Override Feature Toggles"
            />

            <Divider />
            {featureFlags.map(({ name, enabled, setByToggleService }, i) => {
              return (
                <Tooltip
                  key={i}
                  title="Set by Toggle Service"
                  arrow
                  placement="left"
                  disableHoverListener={!setByToggleService}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={!overridesEnabled}
                        checked={enabled}
                        onChange={(_e, checked) => toggleOne(name, checked)}
                      />
                    }
                    label={
                      <div className="flex gap-3">
                        <BsFlagFill
                          color={colors.purple[600]}
                          className={classNames(setByToggleService ? "block" : "hidden")}
                        />
                        {name}
                      </div>
                    }
                  />
                </Tooltip>
              )
            })}

            <ToggleButtonGroup
              disabled={!overridesEnabled}
              className="mt-5"
              color="primary"
              value={null}
              exclusive
              onChange={(_e, enabled) => {
                if (enabled === "reset") resetFeatureFlags()
                else toggleAll(enabled)
              }}
            >
              <ToggleButton value={false}>Off</ToggleButton>
              <ToggleButton value={true}>On</ToggleButton>
              <ToggleButton value="reset">Reset</ToggleButton>
            </ToggleButtonGroup>
          </FormGroup>
        </div>
      </SwipeableDrawer>
    </DevelopmentFeatureFlagContext.Provider>
  )
}
