import { BiAbacus, BiBarChartSquare, BiCar, BiGroup, BiHomeAlt, BiLayer, BiStoreAlt } from "react-icons/bi"
import { navigationTestSelectors } from "../../../../cypress/support/testSelectors"
import { UserNotificationModelType } from "../../../../graphql/generated/client-types-and-hooks"
import { NavItemData } from "../NavItem"

export const navigation: NavItemData[] = [
  {
    name: "Home",
    icon: BiHomeAlt,
    href: "/",
    testSelector: navigationTestSelectors.dashboard,
  },
  {
    name: "Projects",
    icon: BiLayer,
    href: "/projects",
    requiredPermission: "project:list",
    testSelector: navigationTestSelectors.projects,
  },
  {
    name: "People",
    icon: BiGroup,
    href: "/team",
    requiredPermission: "user:list",
    testSelector: navigationTestSelectors.team,
    getApplicableNotificationsCount: (notifications) =>
      notifications.filter((n) => n.modelType === UserNotificationModelType.User).length,
  },
  {
    name: "Assets",
    icon: BiCar,
    href: "/assets",
    requiredPermission: "asset:list",
    testSelector: navigationTestSelectors.assets,
    getApplicableNotificationsCount: (notifications) =>
      notifications.filter((n) => n.modelType === UserNotificationModelType.Asset).length,
  },
  {
    name: "Reports",
    icon: BiBarChartSquare,
    href: "/reports",
    requiredPermission: "timeEntry:export",
    testSelector: navigationTestSelectors.reports,
  },
  {
    name: "Kitchen Sink",
    icon: BiAbacus,
    href: "/kitchen-sink",
    requiredDevelopmentFeatureFlag: "Kitchen Sink",
    testSelector: navigationTestSelectors.kitchenSink,
    bottom: true,
  },
  {
    name: "Organizations",
    icon: BiStoreAlt,
    href: "/admin/organizations",
    requiredPermission: "blackthornEmployee:*",
    testSelector: navigationTestSelectors.organizations,
    bottom: true,
  },
  // {
  //   name: 'Time',
  //   icon: BiTime,
  //   href: '/time',
  // },
  // {
  //   name: "Equipment",
  //   icon: BiWrench,
  //   href: "/equipment",
  // },
  // {
  //   name: "Customers",
  //   icon: BiBuildings,
  //   href: "/customers",
  // },
]
