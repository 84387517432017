import { Transition } from "@headlessui/react"
import { useRouter } from "next/router"
import { FC, useContext } from "react"
import { DevelopmentFeatureFlagName } from "../../helpers/api/developmentFeatureFlags"
import { classNames } from "../../helpers/classNames"
import { DevelopmentFeatureFlagContext } from "../../providers/DevelopmentFeatureFlagProvider"
import { PermissionsContext } from "../../providers/PermissionsProvider/PermissionsProvider"
import { useCurrentUser } from "../../providers/PermissionsProvider/currentUserProvider"
import { CheckedPermission } from "../../types/Permission"

export type Tab = {
  label: string
  href: string
  testSelector?: string
  requiredPermission?: CheckedPermission | CheckedPermission[]
  requiredFeatureFlag?: DevelopmentFeatureFlagName
}

type Props = {
  tabs: Tab[]
}

const Tab: FC<Tab> = ({ label, href, testSelector }) => {
  const router = useRouter()

  const isSelected = router.pathname === href

  return (
    <button
      className={classNames("relative my-2 md:my-8 pb-2 font-bold uppercase", !isSelected && "text-gray-400")}
      onClick={() => {
        router.replace(
          {
            pathname: href,
            query: router.query,
          },
          undefined,
          { scroll: false }
        )
      }}
      data-test={testSelector}
    >
      {label}
      <Transition
        appear={true}
        show={true}
        enter="transition ease-in-out duration-150 transform"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
      >
        <div
          className={classNames(
            "w-full relative top-1 h-1 transition-all rounded-sm",
            isSelected ? "bg-blue-600" : "bg-transparent"
          )}
        />
      </Transition>
    </button>
  )
}

export const DeprecatedTabGroup: FC<Props> = ({ tabs }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)
  const { organizationId } = useCurrentUser()

  return tabs.length > 0 ? (
    <div className="relative">
      <div className={"flex gap-6 overflow-x-auto"}>
        {tabs
          .filter((tab) =>
            !!tab.requiredPermission
              ? hasPermissionTo(tab.requiredPermission, {
                  organizationId,
                })
              : true
          )
          .filter((tab) => (!!tab.requiredFeatureFlag ? flagIsEnabled(tab.requiredFeatureFlag) : true))
          .map((tab) => {
            return <Tab key={tab.href} {...tab} />
          })}
      </div>
    </div>
  ) : null
}
