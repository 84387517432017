import { Button, ButtonProps } from "@mui/material"
import React, { ButtonHTMLAttributes, FC, ReactNode } from "react"
import { classNames } from "../../helpers/classNames"

type Props = {
  children: ReactNode
  className?: string
  color?: ButtonProps["color"]
  disabled?: HTMLInputElement["disabled"]
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  type: ButtonHTMLAttributes<HTMLButtonElement>["type"]
}

export const ButtonHollow: FC<Props> = ({ children, className = "", disabled = false, type, ...props }) => {
  return (
    <Button {...props} variant="outlined" type={type} className={classNames(className)} disabled={disabled}>
      {children}
    </Button>
  )
}
