import jwt from "jsonwebtoken"
import { useRouter } from "next/router"
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useCallback, useEffect, useState } from "react"
import { warningSnack } from "../components/Notistack/ThemedSnackbars"
import { fetchSession, getFeatureFlags, login, logout as logoutSession, refreshSession } from "../data/api"
import { DevelopmentFeatureFlag } from "../helpers/api/developmentFeatureFlags"
import { expiresIn, sessionKeys } from "../lib/jwtHelpers"
import { SessionUser, StandardClaimsResponse } from "../services/auth"
import {
  DEV_FEATURE_FLAGS_LOCALSTORAGE_KEY,
  DEV_FEATURE_FLAGS_LOCALSTORAGE_OVERRIDE_KEY,
} from "./DevelopmentFeatureFlagProvider"
import { PERMISSIONS_EXPLORER_LOCALSTORAGE_KEY } from "./PermissionsProvider/PermissionsExplorer"

type SessionContextProviderProps = {
  children: ReactNode
}

export type SessionContextValue = {
  status: "unauthenticated" | "loading" | "authenticated"
  data?: { user: SessionUser | undefined; featureFlags?: DevelopmentFeatureFlag[] } | null
  update: () => void
  logout: (options?: { allSessions: boolean }) => void
  flagIsEnabled: (name: string, _passedInFlags?: DevelopmentFeatureFlag[]) => boolean
  featureFlags: DevelopmentFeatureFlag[]
  setFeatureFlagOverrides: Dispatch<SetStateAction<DevelopmentFeatureFlag[]>>
  fetchFeatureFlags: (email?: string) => Promise<DevelopmentFeatureFlag[]>
  resetFeatureFlags: () => void
  overridesEnabled: boolean
  hasLoginToken: boolean
  setOverridesEnabled: Dispatch<SetStateAction<boolean>>
  claims?: StandardClaimsResponse | undefined
}

export const SessionContext = createContext<SessionContextValue>({
  status: "loading",
  update: () => null,
  logout: () => null,
  flagIsEnabled: (_name, _) => false,
  featureFlags: [],
  setFeatureFlagOverrides: () => null,
  fetchFeatureFlags: () => new Promise((resolve) => resolve([])),
  resetFeatureFlags: () => null,
  overridesEnabled: false,
  hasLoginToken: false,
  setOverridesEnabled: () => null,
})

const deepClone = (data: any) => JSON.parse(JSON.stringify(data))

const mapFlagsFromLocalStorage = (flags: DevelopmentFeatureFlag[]): DevelopmentFeatureFlag[] => {
  const initialLocalStorageState = localStorage.getItem(DEV_FEATURE_FLAGS_LOCALSTORAGE_KEY)
  const deepCopy = deepClone(flags)
  if (!initialLocalStorageState) return deepCopy

  const storedFlags = JSON.parse(initialLocalStorageState) as DevelopmentFeatureFlag[]
  return deepCopy.map((flag: any) => {
    const storedFlag = storedFlags.find(({ name }) => name === flag.name)
    return storedFlag || flag
  })
}

function stringToBoolean(string: string | null | undefined) {
  if (string === "true") return true
  return false
}

export const SessionProvider: FC<SessionContextProviderProps> = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false)

  const router = useRouter()
  const [status, setStatus] = useState<SessionContextValue["status"]>("loading")
  const [data, setData] = useState<{ user: SessionUser | undefined }>()
  const [overridesEnabled, setOverridesEnabled] = useState<boolean>(
    stringToBoolean(localStorage.getItem(DEV_FEATURE_FLAGS_LOCALSTORAGE_OVERRIDE_KEY))
  )
  const [claims, setClaims] = useState<StandardClaimsResponse | undefined>()

  const token = (router.query.login_token || "") as string
  const decoded = jwt.decode(token) as StandardClaimsResponse
  const hasLoginToken = decoded && decoded.scope === "login"

  // featureFlags are evaluated on the backend and are immutable by the client
  const [originalFeatureFlags, setOriginalFeatureFlags] = useState<DevelopmentFeatureFlag[]>([])
  // clientFeatureFlags are cloned from featureFlags and mutable with the feature-flag-UI
  const [featureFlagOverrides, setFeatureFlagOverrides] = useState<DevelopmentFeatureFlag[]>([])

  const featureFlags = overridesEnabled ? featureFlagOverrides : originalFeatureFlags

  useEffect(() => {
    // Store the client override is local storage
    localStorage.setItem(DEV_FEATURE_FLAGS_LOCALSTORAGE_OVERRIDE_KEY, overridesEnabled.toString())
  }, [overridesEnabled])

  useEffect(() => {
    // Store the client version of the feature flags in local storage
    if (featureFlagOverrides.length === 0) return
    else {
      const strippedFlags = featureFlagOverrides.map(({ name, enabled }) => ({ name, enabled }))
      localStorage.setItem(DEV_FEATURE_FLAGS_LOCALSTORAGE_KEY, JSON.stringify(strippedFlags))
    }
  }, [featureFlagOverrides])

  // Always use the client version of the feature flags for evaluation
  const flagIsEnabled = useCallback(
    (name: string, passedInFlags?: DevelopmentFeatureFlag[]) => {
      const flags = passedInFlags || featureFlags
      if (!flags) return false
      return !!flags.find((flag) => flag.name === name)?.enabled
    },
    [featureFlags]
  )

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (hasMounted) {
      getSession()
      fetchFeatureFlags()
    }
    // eslint-disable-next-line
  }, [hasMounted])

  async function fetchFeatureFlags(email?: string) {
    const { featureFlags: flags } = await getFeatureFlags(email)
    const flagsFromLocalStorage = mapFlagsFromLocalStorage(flags)
    setOriginalFeatureFlags(flags)
    setFeatureFlagOverrides(flagsFromLocalStorage)
    return overridesEnabled ? flagsFromLocalStorage : flags
  }

  function resetFeatureFlags() {
    if (!originalFeatureFlags) warningSnack("ORIGINAL FEATURE FLAGS NOT FOUND")
    const strippedFlags = originalFeatureFlags.map(({ name, enabled }) => ({ name, enabled }))
    setFeatureFlagOverrides(deepClone(strippedFlags))
  }

  async function getSession() {
    try {
      if (data?.user) return
      // The following order matters
      if (hasLoginToken) await login({ token })

      // 1. First check the refresh token
      const refreshToken = localStorage.getItem(sessionKeys.refreshToken)

      const NewAuthSignedOut = !refreshToken

      if (NewAuthSignedOut) return setStatus("unauthenticated")

      // 2. Then check the access token
      const accessToken = localStorage.getItem(sessionKeys.accessToken)

      // 3. Refresh
      if (!accessToken) {
        const response = await refreshSession(refreshToken)
        if (response.status === "unauthenticated") return setStatus("unauthenticated")
      } else {
        const decoded = jwt.decode(accessToken) as StandardClaimsResponse
        const expiresInSeconds = expiresIn(decoded.exp)
        setClaims(decoded)
        // If regular session, always refresh
        // If masqueraded session refresh only if expired. The refresh token still belongs to the masquerader so on expiration the session will revert which is the expected behavior
        if (!decoded.masqueraderId || expiresInSeconds < 1) {
          const response = await refreshSession(refreshToken)
          if (response.status === "unauthenticated") return setStatus("unauthenticated")
        }
      }

      // 4. Then get the user session
      const session = await fetchSession()
      setData({ ...session })
      if (session.user.id) setStatus("authenticated")
    } catch (error) {
      console.error("ERROR", error)
      setStatus("unauthenticated")
    }
  }

  async function logout(options = {}) {
    try {
      await logoutSession(options)
      setStatus("unauthenticated")
      setData(undefined)
      router.push("/auth/login")
    } catch (error) {
      window.location.href = "/"
    } finally {
      localStorage.removeItem(PERMISSIONS_EXPLORER_LOCALSTORAGE_KEY)
      localStorage.removeItem(DEV_FEATURE_FLAGS_LOCALSTORAGE_KEY)
      localStorage.removeItem(DEV_FEATURE_FLAGS_LOCALSTORAGE_OVERRIDE_KEY)
      localStorage.removeItem(sessionKeys.accessToken)
      localStorage.removeItem(sessionKeys.refreshToken)
    }
  }

  const additionalExports = {
    logout,
    flagIsEnabled,
    featureFlags,
    setFeatureFlagOverrides,
    fetchFeatureFlags,
    resetFeatureFlags,
    overridesEnabled,
    setOverridesEnabled,
    claims,
    hasLoginToken,
  }
  const masquerader = data?.user?.masquerader

  const value: SessionContextValue = { status, data, update: getSession, ...additionalExports }

  return (
    <SessionContext.Provider value={value}>
      {masquerader ? <div className="border-x-8 border-red-500">{children}</div> : children}
    </SessionContext.Provider>
  )
}
