import { FC } from "react"
import { classNames } from "../../helpers/classNames"

type Props = {
  className?: string
}

export const SkeletonTextSmall: FC<Props> = ({ className }) => (
  <div className={classNames("h-3 w-32 bg-gray-200 rounded-full", className)} />
)
