import { differenceInHours, differenceInMinutes, differenceInSeconds, sub } from "date-fns"

type Duration = {
  hours: number
  minutes: number
  seconds: number
}

export function getDuration(startDate: Date, endDate: Date) {
  const duration: Duration = { hours: 0, minutes: 0, seconds: 0 }
  duration.hours = differenceInHours(startDate, endDate)

  const remainingMinutes = sub(startDate, { hours: duration.hours })
  duration.minutes = differenceInMinutes(remainingMinutes, endDate)

  const remainingSeconds = sub(remainingMinutes, { minutes: duration.minutes })
  duration.seconds = differenceInSeconds(remainingSeconds, endDate)

  Object.keys(duration).forEach((key) => (duration[key as keyof Duration] = Math.abs(duration[key as keyof Duration])))

  return duration
}

export function secondsToDuration(seconds: number) {
  const startDate = new Date(0)
  const endDate = new Date(seconds * 1000)
  return getDuration(startDate, endDate)
}

export function formatDuration(duration: Duration) {
  const formatted = { hours: "", minutes: "", seconds: "" }
  formatted.hours = `${duration.hours} ${duration.hours === 1 ? "hr" : "hrs"}`
  formatted.minutes = `${duration.minutes} min`
  formatted.seconds = `${duration.seconds} sec`
  return formatted
}

export function secondsToFormattedString(seconds: number) {
  const duration = secondsToDuration(seconds)
  const time = formatDuration(duration)
  let str = ""
  if (duration.hours) str += time.hours
  if (duration.minutes) str += " " + time.minutes
  return str || time.seconds
}

export const sortByTime = (time1: string, time2: string) => {
  const [aHours, aMinutes] = time1.split(":")
  const [bHours, bMinutes] = time2.split(":")

  const aDate = new Date(new Date().setHours(+aHours, +aMinutes))
  const bDate = new Date(new Date().setHours(+bHours, +bMinutes))

  return aDate.getTime() - bDate.getTime()
}
