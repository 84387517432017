import { styled } from "@mui/material"
import jwt from "jsonwebtoken"
import { NextRouter, useRouter } from "next/router"
import { FC, useContext, useEffect, useRef, useState } from "react"
import {
  BiAlarm,
  BiBell,
  BiBug,
  BiCog,
  BiExit,
  BiFile,
  BiGroup,
  BiMenu,
  BiQuestionMark,
  BiSolidMask,
  BiUser,
  BiUserCircle,
} from "react-icons/bi"
import { headerTestSelectors } from "../../../cypress/support/testSelectors"
import { refreshSession } from "../../../data/api"
import {
  Organization,
  useEndAllMasqueradeSessionsForUserMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../helpers/classNames"
import { secondsToFormattedString } from "../../../helpers/time-utility"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { expiresIn, sessionKeys } from "../../../lib/jwtHelpers"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { NotificationsContext } from "../../../providers/NotificationsProvider"
import { useCurrentUser } from "../../../providers/PermissionsProvider/currentUserProvider"
import { SessionContext } from "../../../providers/SessionProvider"
import { StandardClaimsResponse } from "../../../services/auth"
import { URLs } from "../../../urls.config"
import { Breadcrumbs } from "../../Breadcrumbs"
import { DevelopmentFeatureFlag } from "../../DevelopmentFeatureFlag"
import { Monogram } from "../../Monogram"
import { NotificationsDrawer } from "../../Partials/Drawer/NotificationsDrawer"
import { MenuItem, MenuItem as MenuItemMui, QuickMenuMui } from "../../QuickMenuMui"

const PulseAnimation = styled("div")({
  "@keyframes custom-pulse": {
    from: { boxShadow: "0 0 0 0 rgba(229, 62, 62, 1)" },
    to: { boxShadow: "0 0 0 10px rgba(229, 62, 62, 0)" },
  },
  animation: "custom-pulse 2s infinite",
})

type HeaderProps = {
  setSidebarOpen: (value: boolean) => void
}

export const buildOrgLinks = (organizationId: Organization["id"], router: NextRouter) => {
  const orgLinks: MenuItem[][] = [
    [
      {
        value: "Company Details",
        onClick: () => router.push(URLs.companyDetails),
        Icon: BiFile,
        testId: headerTestSelectors.orgMenuItems.companyDetails,
      },
      {
        value: "Admins",
        onClick: () => router.push(URLs.admins),
        Icon: BiUserCircle,
        testId: headerTestSelectors.orgMenuItems.admins,
        requiredPermission: "fe-organizationAdmins:read",
      },
      {
        value: "Roles",
        onClick: () => router.push(URLs.roles),
        Icon: BiGroup,
        testId: headerTestSelectors.orgMenuItems.roles,
        requiredPermission: "fe-organizationRoles:read",
      },
      {
        value: "Settings",
        onClick: () => router.push(URLs.settings),
        Icon: BiCog,
        testId: headerTestSelectors.orgMenuItems.settings,
        requiredPermission: "organization:update",
        requiredPermissionContext: { organizationId },
      },
    ],
  ]

  return orgLinks
}

const Header: FC<HeaderProps> = ({ setSidebarOpen }) => {
  const { logout, data, update: updateSession, claims } = useContext(SessionContext)
  const [sessionExpiresIn, setSessionExpiresIn] = useState<number | undefined>()
  const router = useRouter()
  const currentUser = useCurrentUser()
  const headerContainerRef = useRef<HTMLDivElement>(null)
  const hamburgerButtonRef = useRef<HTMLButtonElement>(null)
  const profileButtonRef = useRef<HTMLDivElement>(null)
  const masqueradeButtonRef = useRef<HTMLDivElement>(null)
  const breadcrumbsRef = useRef<HTMLDivElement>(null)
  const { width: windowWidth } = useWindowSize()
  const masquerader = data?.user?.masquerader
  const [, endAllMasqueradeSessionsForUser] = useEndAllMasqueradeSessionsForUserMutation()
  const { push } = useContext(DrawerContext)
  const { notifications } = useContext(NotificationsContext)
  const unreadNotifications = notifications?.filter((notification) => !notification.markedReadAt)
  const hasUnreadNotifications = unreadNotifications.length > 0

  async function endMasquerade() {
    const refreshToken = localStorage.getItem(sessionKeys.refreshToken)
    const accessToken = localStorage.getItem(sessionKeys.accessToken)

    if (accessToken) {
      const decoded = jwt.decode(accessToken) as StandardClaimsResponse
      await endAllMasqueradeSessionsForUser({ subjectUserId: decoded.sub })
    }

    await refreshSession(refreshToken)
    await updateSession()

    window.location.href = "/"
  }

  const profileActions: MenuItemMui[][] = [
    [
      {
        value: "Profile",
        onClick: () => router.push(URLs.profile + currentUser.id),
        Icon: customUserCircle,
      },
      {
        value: "Help Center",
        onClick: () => window.open(URLs.helpCenter),
        Icon: customQuestionMark,
      },
      {
        value: "Settings",
        onClick: () => router.push("/settings"),
        Icon: BiCog,
      },
      {
        value: "Report a Bug",
        href: URLs.reportBug,
        target: "_blank",
        Icon: customBug,
      },
    ],
    [
      {
        value: "Log out",
        onClick: () => logout(),
        Icon: BiExit,
        testId: headerTestSelectors.logout,
      },
    ],
  ]

  useEffect(() => {
    if (!windowWidth) return
    if (!breadcrumbsRef.current) return

    const breadCrumbsWidth =
      (headerContainerRef?.current?.clientWidth || 0) -
      (hamburgerButtonRef.current?.clientWidth || 0) -
      (profileButtonRef.current?.clientWidth || 0) -
      (masqueradeButtonRef.current?.clientWidth || 0) -
      20
    breadcrumbsRef.current.style.width = `${breadCrumbsWidth}px`
  }, [windowWidth])

  useEffect(() => {
    if (sessionExpiresIn && masquerader && sessionExpiresIn < 0) endMasquerade()
    // eslint-disable-next-line
  }, [sessionExpiresIn])

  useEffect(() => {
    const interval = setInterval(async () => {
      setSessionExpiresIn(expiresIn(claims?.exp || 0))
    }, 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [])

  const masqueraderTimeRemaining = sessionExpiresIn || 0 > 0 ? secondsToFormattedString(sessionExpiresIn || 0) : 0

  const masqueradeMenu: MenuItem[][] = [
    [
      {
        value: `${masquerader?.firstName} ${masquerader?.lastName}`,
        Icon: BiUser,
        isDisabled: true,
      },
      {
        value: `Time remaining: ${typeof sessionExpiresIn === "number" ? masqueraderTimeRemaining : ". . ."}`,
        Icon: BiAlarm,
        isDisabled: true,
      },
    ],
    [
      {
        value: "End Session",
        onClick: endMasquerade,
        Icon: BiExit,
      },
    ],
  ]

  return (
    <>
      <div
        ref={headerContainerRef}
        className={"w-full sticky top-0 z-10 flex items-center justify-between h-16 border-b bg-white"}
      >
        <button
          type="button"
          className={classNames("px-4 focus:outline-none xl:hidden", masquerader ? "text-white" : "text-gray-600")}
          onClick={() => setSidebarOpen(true)}
          data-test={headerTestSelectors.hamburgerMenu}
          ref={hamburgerButtonRef}
        >
          <span className="sr-only">Open sidebar</span>
          <BiMenu className="h-8 w-8" aria-hidden="true" />
        </button>

        <div className="flex justify-between items-center md:pl-4">
          <div ref={breadcrumbsRef}>
            <Breadcrumbs />
          </div>
          <div ref={masqueradeButtonRef} className="w-14">
            {masquerader && (
              <QuickMenuMui items={masqueradeMenu} noHover buttonShape="round" className="hover:bg-white">
                <PulseAnimation className="h-10 w-10 bg-red-100 rounded-full flex justify-center items-center animation-pulse border-2 border-red-600 hover:bg-red-200">
                  <BiSolidMask className="h-8 w-8 text-red-600" />
                </PulseAnimation>
              </QuickMenuMui>
            )}
          </div>
          <div className="px-4 flex justify-end items-center shrink" ref={profileButtonRef}>
            <div className="sm:ml-4 flex items-center xl:ml-6 gap-x-3" data-test={headerTestSelectors.open}>
              <DevelopmentFeatureFlag name="Notifications">
                <button className="relative" onClick={() => push(<NotificationsDrawer />)}>
                  {hasUnreadNotifications && (
                    <div className="absolute top-0 right-0 bg-white w-3 h-3 rounded-full flex justify-center items-center">
                      <span className="bg-red-500 h-2 w-2 block rounded-full">
                        <span className="sr-only">You have {unreadNotifications.length} unread notifications</span>
                      </span>
                    </div>
                  )}
                  <BiBell className="h-6 w-6 text-gray-800" />
                </button>
              </DevelopmentFeatureFlag>
              <QuickMenuMui items={profileActions} noHover buttonShape="round">
                <span className="sr-only">Open user menu</span>
                <Monogram user={currentUser} className="h-10 w-10" />
              </QuickMenuMui>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const customQuestionMark = () => {
  return (
    <div className="w-4.5 h-4.5 rounded-xl border-[#808080] border-2">
      <BiQuestionMark color={"#808080"} />
    </div>
  )
}
const customUserCircle = () => {
  return <BiUserCircle className="w-6 h-6 relative right-0.5" />
}

const customBug = () => {
  return <BiBug color={"#808080"} className="w-6 h-6 relative right-0.5 mr-3" />
}

export default Header
