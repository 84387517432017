import { Cache, Entity, Link } from "@urql/exchange-graphcache"
import { gql } from "urql"
import { UserAssignment } from "../../graphql/generated/graphcache"

export const updateImageCache = async (url: string) => {
  try {
    await fetch(url, { cache: "reload" })
    document.body.querySelectorAll(`img[src='${url}']`).forEach((img) => ((img as HTMLImageElement).src = url))
  } catch (e) {
    // In some cases, this fetch might fail but since this is a "best effort" sort of thing, we are comfortable handling/ignoring the error
    console.error(e)
  }
}

const orgClockedInCountUpdateDocument = gql`
  query OrgClockedInCountUpdateDocument {
    myOrganization {
      id
      clockedInUserCount
    }
  }
`

export const updateOrganizationClockedInCount = (cache: Cache, countChange: number) => {
  cache.updateQuery({ query: orgClockedInCountUpdateDocument }, (data) => {
    if (data?.myOrganization) {
      data.myOrganization.clockedInUserCount += countChange
    }
    return data
  })
}

export const linkUserAssignment = (
  cache: Cache,
  newUserAssignment: UserAssignment,
  userKey: string | null,
  projectKey: string | null
) => {
  let userAssignments = cache.resolve(userKey, "assignments") as UserAssignment[] | null
  let projectAssignments = cache.resolve(projectKey, "assignees") as UserAssignment[] | null

  // If we don't have it, it means the query hasn't yet been populated and it doesn't need to be updated
  if (Array.isArray(userAssignments)) {
    // Add the result
    userAssignments.push(newUserAssignment)
    cache.link(userKey, "assignments", userAssignments as Link<Entity>)
  }
  // If we don't have it, it means the query hasn't yet been populated and it doesn't need to be updated
  if (Array.isArray(projectAssignments)) {
    // Add the result
    projectAssignments.push(newUserAssignment)
    cache.link(projectKey, "assignees", projectAssignments as Link<Entity>)
  }
}
