// THIS FILE IS GENERATED by `npm run generate:graphql`, DO NOT EDIT MANUALLY!
import {
  OptimisticMutationResolver as GraphCacheOptimisticMutationResolver,
  Resolver as GraphCacheResolver,
  UpdateResolver as GraphCacheUpdateResolver,
  offlineExchange,
} from "@urql/exchange-graphcache"

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: Date | string
  JSON: any
}

export type Address = {
  __typename?: "Address"
  addressComponents: Array<AddressComponent>
  formattedAddress?: Maybe<Scalars["String"]>
}

export type AddressComponent = {
  __typename?: "AddressComponent"
  longName: Scalars["String"]
  shortName: Scalars["String"]
  types: Array<Scalars["String"]>
}

export type AddressComponentInput = {
  longName?: InputMaybe<Scalars["String"]>
  shortName?: InputMaybe<Scalars["String"]>
  types?: InputMaybe<Array<Scalars["String"]>>
}

export type AddressInput = {
  addressComponents?: InputMaybe<Array<AddressComponentInput>>
  formattedAddress?: InputMaybe<Scalars["String"]>
}

export type Asset = {
  __typename?: "Asset"
  active: Scalars["Boolean"]
  activeChangedAt: Scalars["DateTime"]
  assetChildCount: Scalars["Int"]
  assetGroupId?: Maybe<Scalars["String"]>
  assetGroupMemberCount: Scalars["Int"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  assignedAsset?: Maybe<Asset>
  assignedAssetId?: Maybe<Scalars["String"]>
  assignedTask?: Maybe<Task>
  assignedTaskId?: Maybe<Scalars["String"]>
  assignedUser?: Maybe<User>
  assignedUserId?: Maybe<Scalars["String"]>
  assignmentsCount: Scalars["Int"]
  category: AssetCategory
  childAssetGroups?: Maybe<Array<AssetGroup>>
  childAssets?: Maybe<Array<Asset>>
  companyAssetNumber?: Maybe<Scalars["String"]>
  compositeKey: Scalars["String"]
  createdAt: Scalars["DateTime"]
  deletedAt?: Maybe<Scalars["DateTime"]>
  description?: Maybe<Scalars["String"]>
  files: Array<File>
  groupQuantity?: Maybe<Scalars["Int"]>
  id: Scalars["String"]
  imageUrl?: Maybe<Scalars["String"]>
  inferredOwner?: Maybe<User>
  inferredProjectId?: Maybe<Scalars["String"]>
  inspectionTemplatesAssignments: Array<AssetInspectionTemplateAssignments>
  inventoryRequirements?: Maybe<AssetInventoryRequirements>
  isArchived?: Maybe<Scalars["Boolean"]>
  isAssetGroup: Scalars["Boolean"]
  lastInventoriedAt?: Maybe<Scalars["DateTime"]>
  manufacturer?: Maybe<AssetManufacturer>
  name: Scalars["String"]
  organization: Organization
  organizationId: Scalars["String"]
  ownershipType: Scalars["String"]
  photo: File
  photoId?: Maybe<Scalars["String"]>
  purchaseDetails?: Maybe<AssetPurchaseDetails>
  rentalAgreement?: Maybe<AssetRentalAgreement>
  reportCount: Scalars["Int"]
  /** @deprecated Use inspectionTemplatesAssignments instead, since the table is no longer directly associated */
  reportTemplates: Array<AssetReportTemplate>
  reports: Array<AssetReport>
  status: AssetStatus
  updatedAt: Scalars["DateTime"]
  vendorContact?: Maybe<AssetVendorContact>
}

export enum AssetAssignableType {
  Asset = "Asset",
  Task = "Task",
  User = "User",
}

export enum AssetCategory {
  Electronics = "Electronics",
  HeavyEquipment = "HeavyEquipment",
  Other = "Other",
  SmallEquipment = "SmallEquipment",
  Tools = "Tools",
  Trailers = "Trailers",
  Vehicles = "Vehicles",
}

export type AssetFilter = {
  assetGroupId?: InputMaybe<Scalars["String"]>
  assetId?: InputMaybe<Scalars["String"]>
  assignableId?: InputMaybe<Scalars["String"]>
  assignableType?: InputMaybe<AssetAssignableType>
  categories?: InputMaybe<Array<AssetCategory>>
  includeGroupedAssets?: InputMaybe<Scalars["Boolean"]>
  isArchived?: InputMaybe<Scalars["Boolean"]>
  projectId?: InputMaybe<Scalars["String"]>
  searchText?: InputMaybe<Scalars["String"]>
  statuses?: InputMaybe<Array<AssetStatus>>
  taskGroupId?: InputMaybe<Scalars["String"]>
  taskId?: InputMaybe<Scalars["String"]>
  userId?: InputMaybe<Scalars["String"]>
}

export type AssetGroup = {
  __typename?: "AssetGroup"
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  assignedAsset?: Maybe<Asset>
  assignedAssetId?: Maybe<Scalars["String"]>
  assignedTask?: Maybe<Task>
  assignedTaskId?: Maybe<Scalars["String"]>
  assignedUser?: Maybe<User>
  assignedUserId?: Maybe<Scalars["String"]>
  compositeKey: Scalars["String"]
  count: Scalars["Int"]
  groupParent: Asset
  status: AssetStatus
}

export type AssetGroupFilterSpecification = {
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: Scalars["String"]
  count: Scalars["Int"]
  status: AssetStatus
}

export type AssetGroupReassignmentSpecification = {
  filter: AssetGroupFilterSpecification
  ids: Array<Scalars["String"]>
}

/** AssetInspectionRequirements */
export type AssetInspectionRequirements = {
  __typename?: "AssetInspectionRequirements"
  intervalInSeconds?: Maybe<Scalars["Int"]>
  startDate?: Maybe<Scalars["DateTime"]>
}

export type AssetInspectionRequirementsInput = {
  assetReportTemplateId?: InputMaybe<Scalars["String"]>
  intervalInSeconds?: InputMaybe<Scalars["Int"]>
  startDate?: InputMaybe<Scalars["DateTime"]>
}

export type AssetInspectionTemplateAssignments = {
  __typename?: "AssetInspectionTemplateAssignments"
  asset: Asset
  assetId: Scalars["String"]
  assetReportTemplateId: Scalars["String"]
  /** helper method to make it simple on the client side */
  id: Scalars["ID"]
  inspectionTemplate: AssetReportTemplate
  intervalInSeconds?: Maybe<Scalars["Int"]>
  intervalRequired: Scalars["Boolean"]
  lastReportedAt?: Maybe<Scalars["DateTime"]>
  organizationId: Scalars["String"]
  startDate?: Maybe<Scalars["DateTime"]>
}

/** AssetInventoryRequirements */
export type AssetInventoryRequirements = {
  __typename?: "AssetInventoryRequirements"
  intervalInSeconds?: Maybe<Scalars["Int"]>
  photoRequired?: Maybe<Scalars["Boolean"]>
}

export type AssetInventoryRequirementsInput = {
  intervalInSeconds?: InputMaybe<Scalars["Int"]>
  photoRequired?: InputMaybe<Scalars["Boolean"]>
}

/** AssetManufacturer */
export type AssetManufacturer = {
  __typename?: "AssetManufacturer"
  id?: Maybe<Scalars["String"]>
  make?: Maybe<Scalars["String"]>
  model?: Maybe<Scalars["String"]>
  name?: Maybe<Scalars["String"]>
  year?: Maybe<Scalars["Int"]>
}

export type AssetManufacturerInput = {
  id?: InputMaybe<Scalars["String"]>
  make?: InputMaybe<Scalars["String"]>
  model?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  year?: InputMaybe<Scalars["Int"]>
}

/** AssetPurchaseDetails */
export type AssetPurchaseDetails = {
  __typename?: "AssetPurchaseDetails"
  date?: Maybe<Scalars["String"]>
  price?: Maybe<Scalars["Float"]>
}

export type AssetPurchaseDetailsInput = {
  date?: InputMaybe<Scalars["String"]>
  price?: InputMaybe<Scalars["Float"]>
}

/** AssetRentalAgreement */
export type AssetRentalAgreement = {
  __typename?: "AssetRentalAgreement"
  endOn?: Maybe<Scalars["String"]>
  rate?: Maybe<AssetRentalAgreementRate>
  startOn?: Maybe<Scalars["String"]>
}

export type AssetRentalAgreementInput = {
  endOn?: InputMaybe<Scalars["String"]>
  rate?: InputMaybe<AssetRentalAgreementRateInput>
  startOn?: InputMaybe<Scalars["String"]>
}

/** AssetRentalAgreementRate */
export type AssetRentalAgreementRate = {
  __typename?: "AssetRentalAgreementRate"
  daily: Scalars["Float"]
  monthly: Scalars["Float"]
  weekly: Scalars["Float"]
}

export type AssetRentalAgreementRateInput = {
  daily?: InputMaybe<Scalars["Float"]>
  monthly?: InputMaybe<Scalars["Float"]>
  weekly?: InputMaybe<Scalars["Float"]>
}

export type AssetReport = {
  __typename?: "AssetReport"
  asset: Asset
  assetId: Scalars["String"]
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  inspectionReport?: Maybe<Array<AssetReportInspectionSubmission>>
  inspectionReportAggregations?: Maybe<InspectionReportAggregations>
  inventoryReport?: Maybe<AssetReportInventoryReport>
  notes?: Maybe<Scalars["String"]>
  organization: Organization
  organizationId: Scalars["String"]
  quantityReported?: Maybe<Scalars["Int"]>
  reporter: User
  reporterId: Scalars["String"]
  statusChange?: Maybe<AssetStatusChange>
  transferReport?: Maybe<AssetReportTransferReport>
  type: AssetReportType
  updatedAt: Scalars["DateTime"]
}

export type AssetReportInput = {
  assetGroupId?: InputMaybe<Scalars["String"]>
  assetId: Scalars["String"]
  assetReportTemplateId?: InputMaybe<Scalars["String"]>
  inspectionReport?: InputMaybe<Array<AssetReportInspectionSubmissionInput>>
  inventoryReport?: InputMaybe<AssetReportInventoryReportType>
  notes?: InputMaybe<Scalars["String"]>
  quantityReported?: InputMaybe<Scalars["Int"]>
  statusChange?: InputMaybe<AssetStatusChangeInput>
  type: AssetReportType
}

/** AssetReportInspectionSubmission */
export type AssetReportInspectionSubmission = {
  __typename?: "AssetReportInspectionSubmission"
  failedStatus?: Maybe<Scalars["String"]>
  fileIds?: Maybe<Array<Scalars["String"]>>
  id?: Maybe<Scalars["String"]>
  input?: Maybe<Scalars["String"]>
  label?: Maybe<Scalars["String"]>
  photoLabel?: Maybe<Scalars["String"]>
  photoRequired?: Maybe<Scalars["Boolean"]>
  photoUrls?: Maybe<Array<Scalars["String"]>>
  required?: Maybe<Scalars["Boolean"]>
  rule?: Maybe<Scalars["String"]>
  type?: Maybe<Scalars["String"]>
}

/** AssetReportInspectionSubmission */
export type AssetReportInspectionSubmissionInput = {
  failedStatus?: InputMaybe<Scalars["String"]>
  fileIds?: InputMaybe<Array<Scalars["String"]>>
  id?: InputMaybe<Scalars["String"]>
  input?: InputMaybe<Scalars["String"]>
  label?: InputMaybe<Scalars["String"]>
  photoLabel?: InputMaybe<Scalars["String"]>
  photoRequired?: InputMaybe<Scalars["Boolean"]>
  photoUrls?: InputMaybe<Array<Scalars["String"]>>
  photos?: InputMaybe<Array<Scalars["String"]>>
  required?: InputMaybe<Scalars["Boolean"]>
  rule?: InputMaybe<Scalars["String"]>
  type?: InputMaybe<Scalars["String"]>
}

/** AssetReportInventoryReport */
export type AssetReportInventoryReport = {
  __typename?: "AssetReportInventoryReport"
  fileIds?: Maybe<Array<Scalars["String"]>>
  note?: Maybe<Scalars["String"]>
  photoUrls?: Maybe<Array<Scalars["String"]>>
}

export type AssetReportInventoryReportType = {
  fileIds?: InputMaybe<Array<Scalars["String"]>>
  note?: InputMaybe<Scalars["String"]>
  photoUrls?: InputMaybe<Array<Scalars["String"]>>
  photos?: InputMaybe<Array<Scalars["String"]>>
}

export type AssetReportTemplate = {
  __typename?: "AssetReportTemplate"
  /** @deprecated Use assetAssignments in the future */
  assets: Array<Asset>
  assetsCount: Scalars["Int"]
  createdAt: Scalars["DateTime"]
  deletedAt?: Maybe<Scalars["DateTime"]>
  fields: Array<AssetReportTemplateField>
  id: Scalars["String"]
  name: Scalars["String"]
  organization: Organization
  organizationId?: Maybe<Scalars["String"]>
  reusable: Scalars["Boolean"]
  universal: Scalars["Boolean"]
  updatedAt: Scalars["DateTime"]
}

/** AssetReportInput */
export type AssetReportTemplateField = {
  __typename?: "AssetReportTemplateField"
  failedStatus?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  label: Scalars["String"]
  photoLabel?: Maybe<Scalars["String"]>
  photoRequired: Scalars["Boolean"]
  required: Scalars["Boolean"]
  rule?: Maybe<Scalars["String"]>
  type: Scalars["String"]
}

/** AssetReportTemplateFieldInput */
export type AssetReportTemplateFieldInput = {
  failedStatus?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  label: Scalars["String"]
  photoLabel?: InputMaybe<Scalars["String"]>
  photoRequired: Scalars["Boolean"]
  required: Scalars["Boolean"]
  rule?: InputMaybe<Scalars["String"]>
  type: Scalars["String"]
}

/** AssetReportTransferAssignmentInput */
export type AssetReportTransferAssignment = {
  __typename?: "AssetReportTransferAssignment"
  asset?: Maybe<Asset>
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  task?: Maybe<Task>
  user?: Maybe<User>
}

/** AssetReportTransferAssignmentInput */
export type AssetReportTransferAssignmentInput = {
  assignableId?: InputMaybe<Scalars["String"]>
  assignableType?: InputMaybe<AssetAssignableType>
}

/** AssetReportTransferReport */
export type AssetReportTransferReport = {
  __typename?: "AssetReportTransferReport"
  from: AssetReportTransferAssignment
  to: AssetReportTransferAssignment
}

/** AssetReportTransferReport */
export type AssetReportTransferReportInput = {
  from?: InputMaybe<AssetReportTransferAssignmentInput>
  to?: InputMaybe<AssetReportTransferAssignmentInput>
}

export enum AssetReportType {
  Inspection = "Inspection",
  Inventory = "Inventory",
  StatusChange = "StatusChange",
  Transfer = "Transfer",
}

export type AssetSort = {
  by?: AssetSortByInput
  direction?: AssetSortDirectionInput
}

export enum AssetSortByInput {
  Name = "name",
}

export enum AssetSortDirectionInput {
  Asc = "asc",
  Desc = "desc",
}

export enum AssetStatus {
  Available = "Available",
  BeingRepaired = "BeingRepaired",
  InUse = "InUse",
  Missing = "Missing",
  NeedsRepair = "NeedsRepair",
  OutOfService = "OutOfService",
  Sold = "Sold",
  Transferred = "Transferred",
}

/** AssetStatusChange */
export type AssetStatusChange = {
  __typename?: "AssetStatusChange"
  active: Scalars["Boolean"]
  missingCount?: Maybe<Scalars["Int"]>
  status: AssetStatus
}

/** AssetStatusChange */
export type AssetStatusChangeInput = {
  active?: InputMaybe<Scalars["Boolean"]>
  missingCount?: InputMaybe<Scalars["Int"]>
  status?: InputMaybe<AssetStatus>
}

/** AssetVendorContact */
export type AssetVendorContact = {
  __typename?: "AssetVendorContact"
  email?: Maybe<Scalars["String"]>
  name?: Maybe<Scalars["String"]>
  phone?: Maybe<Scalars["String"]>
}

export type AssetVendorContactInput = {
  email?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  phone?: InputMaybe<Scalars["String"]>
}

export type AssignedAsset = {
  __typename?: "AssignedAsset"
  active: Scalars["Boolean"]
  assetChildCount: Scalars["Int"]
  assetGroupId?: Maybe<Scalars["String"]>
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  assignedAsset?: Maybe<Asset>
  assignedAssetId?: Maybe<Scalars["String"]>
  assignedTask?: Maybe<Task>
  assignedTaskId?: Maybe<Scalars["String"]>
  assignedUser?: Maybe<User>
  assignedUserId?: Maybe<Scalars["String"]>
  assignmentsCount: Scalars["Int"]
  category: AssetCategory
  companyAssetNumber?: Maybe<Scalars["String"]>
  compositeKey: Scalars["String"]
  deletedAt?: Maybe<Scalars["DateTime"]>
  depth?: Maybe<Scalars["Int"]>
  groupParentAsset?: Maybe<Asset>
  id: Scalars["String"]
  imageUrl?: Maybe<Scalars["String"]>
  inferredOwner?: Maybe<User>
  inventoryRequirements?: Maybe<AssetInventoryRequirements>
  isAssetGroup: Scalars["Boolean"]
  lastInventoriedAt?: Maybe<Scalars["DateTime"]>
  name: Scalars["String"]
  ownershipType: Scalars["String"]
  quantizedAssetIds?: Maybe<Array<Scalars["String"]>>
  status: AssetStatus
}

export type AssignedAssetFilter = {
  assignableId?: InputMaybe<Scalars["String"]>
  isArchived?: InputMaybe<Scalars["Boolean"]>
  projectId?: InputMaybe<Scalars["String"]>
  searchText?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<AssetStatus>
  taskId?: InputMaybe<Scalars["String"]>
  userId?: InputMaybe<Scalars["String"]>
}

export type BulkClockInCandidate = {
  projectId: Scalars["String"]
  startEvidence?: InputMaybe<StartEvidenceInput>
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type BulkClockOutCandidate = {
  endEvidence?: InputMaybe<EndEvidenceInput>
  userId: Scalars["String"]
}

/** BulkTimeEntryResponse */
export type BulkTimeEntryResponse = {
  __typename?: "BulkTimeEntryResponse"
  errors: Array<TimeEntryError>
  success: Scalars["Int"]
}

export type BulkUpdateTaskSortOrderInput = {
  id: Scalars["String"]
  sortOrder: Scalars["Int"]
  taskGroupId?: InputMaybe<Scalars["String"]>
  /** Either a task or taskGroup */
  type: Scalars["String"]
}

export type ChildAncestorNode = {
  __typename?: "ChildAncestorNode"
  depth: Scalars["Int"]
  userId: Scalars["String"]
}

export type ClockInData = {
  __typename?: "ClockInData"
  imagePath?: Maybe<Scalars["String"]>
  task: Task
  taskId: Scalars["String"]
  user: User
  userId: Scalars["String"]
}

export type ClockInDataInput = {
  imagePath?: InputMaybe<Scalars["String"]>
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type ClockOutData = {
  __typename?: "ClockOutData"
  imagePath?: Maybe<Scalars["String"]>
  signaturePath?: Maybe<Scalars["String"]>
  task?: Maybe<Task>
  taskId: Scalars["String"]
  user?: Maybe<User>
  userId: Scalars["String"]
}

export type ClockOutDataInput = {
  imagePath?: InputMaybe<Scalars["String"]>
  signaturePath?: InputMaybe<Scalars["String"]>
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type Contract = {
  __typename?: "Contract"
  createdAt: Scalars["DateTime"]
  customerId?: Maybe<Scalars["String"]>
  deliverableUnits: Array<ContractDeliverableUnit>
  description?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  name: Scalars["String"]
  number?: Maybe<Scalars["String"]>
  organization: Organization
  organizationId: Scalars["String"]
  updatedAt: Scalars["DateTime"]
}

export type ContractDeliverableUnit = {
  __typename?: "ContractDeliverableUnit"
  contract: Contract
  contractId: Scalars["String"]
  createdAt: Scalars["DateTime"]
  customerDescription?: Maybe<Scalars["String"]>
  deliverableUnit: DeliverableUnit
  deliverableUnitId: Scalars["String"]
  id: Scalars["String"]
  rate?: Maybe<Scalars["Float"]>
  updatedAt: Scalars["DateTime"]
}

export type ContractDeliverableUnitInput = {
  customerDescription?: InputMaybe<Scalars["String"]>
  deliverableUnitId: Scalars["String"]
  rate?: InputMaybe<Scalars["Float"]>
}

export type CreateManyOfflineEventsResponsePayload = {
  __typename?: "CreateManyOfflineEventsResponsePayload"
  createdEvents: Array<OfflineEvent>
  failureCount: Scalars["Int"]
  successCount: Scalars["Int"]
}

export type Customer = {
  __typename?: "Customer"
  activeProjectCount: Scalars["Int"]
  addressLine1?: Maybe<Scalars["String"]>
  addressLine2?: Maybe<Scalars["String"]>
  city?: Maybe<Scalars["String"]>
  contracts: Array<Contract>
  country?: Maybe<Scalars["String"]>
  createdAt: Scalars["DateTime"]
  customerId?: Maybe<Scalars["String"]>
  email?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  image?: Maybe<Scalars["String"]>
  imageUrl?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  notes?: Maybe<Scalars["String"]>
  phoneNumber?: Maybe<Scalars["String"]>
  phoneNumberExt?: Maybe<Scalars["String"]>
  state?: Maybe<Scalars["String"]>
  updatedAt: Scalars["DateTime"]
  zip?: Maybe<Scalars["String"]>
}

export type DeliverableUnit = {
  __typename?: "DeliverableUnit"
  archivedAt?: Maybe<Scalars["DateTime"]>
  color: Scalars["String"]
  createdAt: Scalars["DateTime"]
  deletedAt?: Maybe<Scalars["DateTime"]>
  description: Scalars["String"]
  id: Scalars["ID"]
  referenceNumber?: Maybe<Scalars["String"]>
  unitOfMeasure: Scalars["String"]
}

export type EditHistoryEvent = {
  __typename?: "EditHistoryEvent"
  createdAt: Scalars["DateTime"]
  data: Scalars["JSON"]
  id: Scalars["ID"]
  modelId: Scalars["String"]
  modelType: Scalars["String"]
  organizationId: Scalars["ID"]
  project: Project
  projectId?: Maybe<Scalars["String"]>
  task?: Maybe<Task>
  taskId?: Maybe<Scalars["String"]>
  type: Scalars["String"]
  updatedAt: Scalars["DateTime"]
  user: User
  userId: Scalars["String"]
}

export type EndEvidenceInput = {
  imagePath?: InputMaybe<Scalars["String"]>
  injured?: InputMaybe<Scalars["Boolean"]>
  injuryNotes?: InputMaybe<Scalars["String"]>
  location?: InputMaybe<GeolocationPositionInput>
  locationError?: InputMaybe<Scalars["String"]>
  signaturePath?: InputMaybe<Scalars["String"]>
}

export enum FeatureToggleSegments {
  DeveloperBeta = "DEVELOPER_BETA",
  PublicBeta = "PUBLIC_BETA",
}

export type File = {
  __typename?: "File"
  assetId?: Maybe<Scalars["String"]>
  contentType: Scalars["String"]
  createdAt: Scalars["DateTime"]
  deletedAt?: Maybe<Scalars["DateTime"]>
  documentUrl?: Maybe<Scalars["String"]>
  expiresAt?: Maybe<Scalars["DateTime"]>
  fileName: Scalars["String"]
  id: Scalars["String"]
  key: Scalars["String"]
  objectVersion: Scalars["Int"]
  organizationId: Scalars["String"]
  projectId?: Maybe<Scalars["String"]>
  status?: Maybe<Scalars["String"]>
  userId?: Maybe<Scalars["String"]>
}

export type GeolocationCoordinates = {
  __typename?: "GeolocationCoordinates"
  accuracy: Scalars["Float"]
  altitude?: Maybe<Scalars["Float"]>
  altitudeAccuracy?: Maybe<Scalars["Float"]>
  heading?: Maybe<Scalars["Float"]>
  latitude: Scalars["Float"]
  longitude: Scalars["Float"]
  speed?: Maybe<Scalars["Float"]>
}

export type GeolocationCoordinatesInput = {
  accuracy: Scalars["Float"]
  altitude?: InputMaybe<Scalars["Float"]>
  altitudeAccuracy?: InputMaybe<Scalars["Float"]>
  heading?: InputMaybe<Scalars["Float"]>
  latitude: Scalars["Float"]
  longitude: Scalars["Float"]
  speed?: InputMaybe<Scalars["Float"]>
}

export type GeolocationPosition = {
  __typename?: "GeolocationPosition"
  coords: GeolocationCoordinates
  timestamp: Scalars["DateTime"]
}

export type GeolocationPositionInput = {
  coords: GeolocationCoordinatesInput
  timestamp: Scalars["Float"]
}

export type GroupInput = {
  description?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  sortOrder?: InputMaybe<Scalars["Int"]>
  tasks?: InputMaybe<Array<TaskInput>>
}

export type InjuryReport = {
  __typename?: "InjuryReport"
  createdAt: Scalars["DateTime"]
  id: Scalars["ID"]
  notes?: Maybe<Scalars["String"]>
  reporter: User
  reporterId: Scalars["String"]
  updatedAt: Scalars["DateTime"]
  user: User
  userId: Scalars["String"]
}

export type InspectionReportAggregations = {
  __typename?: "InspectionReportAggregations"
  falseCount: Scalars["Int"]
  trueCount: Scalars["Int"]
}

export type LastExport = {
  __typename?: "LastExport"
  exportedAt: Scalars["String"]
  userId: Scalars["String"]
  userName: Scalars["String"]
}

export type LastExportInput = {
  exportedAt: Scalars["String"]
  userId: Scalars["String"]
  userName: Scalars["String"]
}

export enum ListVisibilityFilter {
  /** Return all records */
  All = "all",
  /** Return records directly pertinent to the user making the request */
  My = "my",
}

export type LocationWithRadius = {
  __typename?: "LocationWithRadius"
  /** @deprecated Use latitude instead */
  lat: Scalars["Float"]
  latitude: Scalars["Float"]
  /** @deprecated Use longitude instead */
  lng: Scalars["Float"]
  longitude: Scalars["Float"]
  /** @deprecated Use radiusInMeters instead */
  radius?: Maybe<Scalars["Float"]>
  radiusInMeters?: Maybe<Scalars["Float"]>
}

export type MetadataNote = {
  __typename?: "MetadataNote"
  content: Scalars["String"]
  highlight?: Maybe<Scalars["Boolean"]>
  label: Scalars["String"]
}

export type MetadataNoteInput = {
  content: Scalars["String"]
  highlight?: InputMaybe<Scalars["Boolean"]>
  label: Scalars["String"]
}

export type Mutation = {
  __typename?: "Mutation"
  activateOrganization: Organization
  activateProject: Project
  addOrUpdateNonWorkDay: ScheduleTemplate
  addQuantitiesToGroup?: Maybe<Asset>
  archiveOrganization: Organization
  archiveProject: Project
  archiveQuantities: Array<Asset>
  archiveWorkersCompCode?: Maybe<WorkersCompCode>
  /** Assign a user as a child of another user */
  assignChildToParent: UserToUser
  /** Clock-in users in bulk */
  bulkClockIn: BulkTimeEntryResponse
  /** Clock-out users in bulk */
  bulkClockOut: BulkTimeEntryResponse
  bulkUpdateTaskSortOrder: Array<UpdateSortOrderPayload>
  bulkUpdateUserAssignments?: Maybe<UserAssignmentBatchResponse>
  /** Clock-in a user and create a new time entry */
  clockIn: TimeEntry
  /** Clock-out a user to close their time entry */
  clockOut: TimeEntry
  /** Clock-out a user to close their time entry */
  clockOutUser: TimeEntry
  completeProject: Project
  createCustomer?: Maybe<Customer>
  createDeliverableUnit: DeliverableUnit
  /** Creates a new injury report */
  createInjuryReport: InjuryReport
  createManyTaskReports?: Maybe<Array<Task>>
  createOfflineEvent: OfflineEvent
  createOfflineEvents: CreateManyOfflineEventsResponsePayload
  createOneContract: Contract
  createScheduleTemplate: ScheduleTemplate
  createScheduledBreak: ScheduledBreak
  createUnitGoal?: Maybe<UnitGoal>
  createUnitOfMeasure: UnitOfMeasure
  createUser?: Maybe<User>
  createUserAssignment?: Maybe<UserAssignment>
  /** Allow users to create notifications for themselves */
  createUserNotification?: Maybe<UserNotification>
  createWorkersCompCode?: Maybe<WorkersCompCode>
  deleteCustomer: Customer
  deleteDeliverableUnit?: Maybe<DeliverableUnit>
  deleteNonWorkDay: ScheduleTemplate
  deleteOneAsset?: Maybe<Asset>
  deleteOneContract: Contract
  /** 'Soft delete' file metadata */
  deleteOneFile: File
  deleteOneTask?: Maybe<Task>
  /** Deletes a Task Group */
  deleteOneTaskGroup?: Maybe<TaskGroup>
  /** Delete a time entry */
  deleteOneTimeEntry: TimeEntry
  deleteOneUser: User
  /** Delete a user to user relationship */
  deleteParentChildRelationship: UserToUser
  deleteReportTemplate: AssetReportTemplate
  deleteScheduledBreak: ScheduledBreak
  deleteUnitGoal?: Maybe<UnitGoal>
  deleteUnitOfMeasure: UnitOfMeasure
  deleteUserAssignment: UserAssignment
  /** Delete a user device session */
  deleteUserDeviceSession: Scalars["Boolean"]
  /** Duplicate a project */
  duplicateProject: Project
  duplicateReportTemplate: AssetReportTemplate
  duplicateScheduleTemplate: ScheduleTemplate
  editDeliverableUnit: DeliverableUnit
  editOrganization: Organization
  editScheduledBreak: ScheduledBreak
  editUnitGoal?: Maybe<UnitGoal>
  editUnitOfMeasure: UnitOfMeasure
  /** Deletes all masquerade sessions for a user */
  endAllMasqueradeSessionsForUser: Scalars["Boolean"]
  insertManyAssetReports: Array<AssetReport>
  insertOneAsset?: Maybe<Asset>
  /** Create a new project */
  insertOneProject: Project
  insertOneTask: Task
  /** Creates a new Task Group */
  insertOneTaskGroup: TaskGroup
  /** Create a new time entry */
  insertOneTimeEntry: TimeEntry
  insertReportTemplate: AssetReportTemplate
  markAllNotificationsRead?: Maybe<Array<UserNotification>>
  markNotificationRead?: Maybe<UserNotification>
  markNotificationsReadById?: Maybe<Array<UserNotification>>
  markOrganizationNotPastDue: Organization
  markOrganizationPastDue: Organization
  markTaskCompletion?: Maybe<Task>
  /** Masquerade as a user */
  masqueradeUser: MasqueradeCredentials
  reassignUser: User
  reassignUsers: Array<User>
  reportTaskProgress?: Maybe<Task>
  resendUserInvites?: Maybe<Scalars["Boolean"]>
  restoreOneAsset?: Maybe<Asset>
  restoreOneUser: User
  returnQuantityToInventory: Array<Asset>
  transferAssets: Array<Asset>
  unarchiveQuantities: Array<Asset>
  updateCustomer?: Maybe<Customer>
  /** Updates an injury report */
  updateInjuryReport: InjuryReport
  updateLastExport?: Maybe<LastExport>
  updateOneAsset?: Maybe<Asset>
  updateOneContract: Contract
  /** Updates file metadata */
  updateOneFile: File
  /** Edit a project */
  updateOneProject: Project
  updateOneTask: Task
  /** Updates a Task Group */
  updateOneTaskGroup: TaskGroup
  /** Update a time entry */
  updateOneTimeEntry: TimeEntry
  updateOneUser: User
  /** Updates a user's password */
  updatePassword: Scalars["Boolean"]
  updateReportTemplate: AssetReportTemplate
  updateScheduleTemplate: ScheduleTemplate
  updateTaskSortOrder?: Maybe<UpdateSortOrderPayload>
  updateUserAssignment?: Maybe<UserAssignment>
}

export type MutationActivateOrganizationArgs = {
  id: Scalars["String"]
}

export type MutationActivateProjectArgs = {
  id: Scalars["String"]
}

export type MutationAddOrUpdateNonWorkDayArgs = {
  nonWorkDay: ScheduleTemplateNonWorkDaysInput
}

export type MutationAddQuantitiesToGroupArgs = {
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  quantityToAdd: Scalars["Int"]
}

export type MutationArchiveOrganizationArgs = {
  id: Scalars["String"]
}

export type MutationArchiveProjectArgs = {
  id: Scalars["String"]
}

export type MutationArchiveQuantitiesArgs = {
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  quantityToArchive: Scalars["Int"]
  status: AssetStatus
}

export type MutationArchiveWorkersCompCodeArgs = {
  id: Scalars["String"]
}

export type MutationAssignChildToParentArgs = {
  childId: Scalars["String"]
  parentId: Scalars["String"]
}

export type MutationBulkClockInArgs = {
  candidates: Array<BulkClockInCandidate>
  location?: InputMaybe<GeolocationPositionInput>
  startAt?: InputMaybe<Scalars["DateTime"]>
}

export type MutationBulkClockOutArgs = {
  candidates: Array<BulkClockOutCandidate>
  endAt?: InputMaybe<Scalars["DateTime"]>
}

export type MutationBulkUpdateTaskSortOrderArgs = {
  updates: Array<BulkUpdateTaskSortOrderInput>
}

export type MutationBulkUpdateUserAssignmentsArgs = {
  assignmentsToCreate?: InputMaybe<Array<UserAssignmentCreateInput>>
  assignmentsToDelete?: InputMaybe<Array<Scalars["String"]>>
}

export type MutationClockInArgs = {
  id?: InputMaybe<Scalars["String"]>
  startEvidence?: InputMaybe<StartEvidenceInput>
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type MutationClockOutArgs = {
  endEvidence?: InputMaybe<EndEvidenceInput>
  id: Scalars["String"]
}

export type MutationClockOutUserArgs = {
  endEvidence?: InputMaybe<EndEvidenceInput>
  userId: Scalars["String"]
}

export type MutationCompleteProjectArgs = {
  id: Scalars["String"]
}

export type MutationCreateCustomerArgs = {
  addressLine1?: InputMaybe<Scalars["String"]>
  addressLine2?: InputMaybe<Scalars["String"]>
  city?: InputMaybe<Scalars["String"]>
  country?: InputMaybe<Scalars["String"]>
  customerId?: InputMaybe<Scalars["String"]>
  email?: InputMaybe<Scalars["String"]>
  image?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  notes?: InputMaybe<Scalars["String"]>
  phoneNumber?: InputMaybe<Scalars["String"]>
  phoneNumberExt?: InputMaybe<Scalars["String"]>
  state?: InputMaybe<Scalars["String"]>
  zip?: InputMaybe<Scalars["String"]>
}

export type MutationCreateDeliverableUnitArgs = {
  color: Scalars["String"]
  description: Scalars["String"]
  referenceNumber?: InputMaybe<Scalars["String"]>
  unitOfMeasure: Scalars["String"]
}

export type MutationCreateInjuryReportArgs = {
  notes?: InputMaybe<Scalars["String"]>
  userId: Scalars["String"]
}

export type MutationCreateManyTaskReportsArgs = {
  projectId: Scalars["String"]
  taskReports: Array<TaskReportInput>
}

export type MutationCreateOfflineEventArgs = {
  clientCreatedAt: Scalars["DateTime"]
  clockInData?: InputMaybe<ClockInDataInput>
  clockOutData?: InputMaybe<ClockOutDataInput>
  location: GeolocationCoordinatesInput
  type: OfflineEventType
}

export type MutationCreateOfflineEventsArgs = {
  events: Array<OfflineEventInput>
}

export type MutationCreateOneContractArgs = {
  customerId: Scalars["String"]
  deliverableUnits: Array<ContractDeliverableUnitInput>
  description?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  number?: InputMaybe<Scalars["String"]>
}

export type MutationCreateScheduleTemplateArgs = {
  isDefault?: InputMaybe<Scalars["Boolean"]>
  nonWorkDays?: InputMaybe<Array<ScheduleTemplateNonWorkDaysInput>>
  workDays?: InputMaybe<Array<ScheduleTemplateWorkDaysInput>>
  workHours?: InputMaybe<ScheduleTemplateWorkHoursInput>
}

export type MutationCreateScheduledBreakArgs = {
  breakTaskId: Scalars["String"]
  durationInMinutes: Scalars["Int"]
  localizedStartTime: Scalars["String"]
  name: Scalars["String"]
  projectId?: InputMaybe<Scalars["String"]>
}

export type MutationCreateUnitGoalArgs = {
  goal: UnitGoalInput
  taskId: Scalars["String"]
}

export type MutationCreateUnitOfMeasureArgs = {
  name: Scalars["String"]
  symbol: Scalars["String"]
}

export type MutationCreateUserArgs = {
  sendInvite?: Scalars["Boolean"]
  user: NewUser
}

export type MutationCreateUserAssignmentArgs = {
  projectId: Scalars["String"]
  taskId?: InputMaybe<Scalars["String"]>
  userId: Scalars["String"]
}

export type MutationCreateUserNotificationArgs = {
  assetId?: InputMaybe<Scalars["String"]>
  modelType: UserNotificationModelType
  type: UserNotificationType
  userId?: InputMaybe<Scalars["String"]>
}

export type MutationCreateWorkersCompCodeArgs = {
  name: Scalars["String"]
  ncciCode: Scalars["String"]
  ncciDescription: Scalars["String"]
  override: Scalars["Boolean"]
  rate: Scalars["Float"]
}

export type MutationDeleteCustomerArgs = {
  id: Scalars["String"]
}

export type MutationDeleteDeliverableUnitArgs = {
  id: Scalars["String"]
}

export type MutationDeleteNonWorkDayArgs = {
  id: Scalars["String"]
}

export type MutationDeleteOneAssetArgs = {
  id: Scalars["String"]
  status: AssetStatus
}

export type MutationDeleteOneContractArgs = {
  id: Scalars["String"]
}

export type MutationDeleteOneFileArgs = {
  id: Scalars["String"]
}

export type MutationDeleteOneTaskArgs = {
  id: Scalars["String"]
}

export type MutationDeleteOneTaskGroupArgs = {
  id: Scalars["String"]
}

export type MutationDeleteOneTimeEntryArgs = {
  id: Scalars["String"]
}

export type MutationDeleteOneUserArgs = {
  id: Scalars["String"]
}

export type MutationDeleteParentChildRelationshipArgs = {
  childId: Scalars["String"]
  parentId: Scalars["String"]
}

export type MutationDeleteReportTemplateArgs = {
  id: Scalars["String"]
}

export type MutationDeleteScheduledBreakArgs = {
  id: Scalars["String"]
}

export type MutationDeleteUnitGoalArgs = {
  id: Scalars["String"]
}

export type MutationDeleteUnitOfMeasureArgs = {
  id: Scalars["Int"]
}

export type MutationDeleteUserAssignmentArgs = {
  id: Scalars["String"]
}

export type MutationDeleteUserDeviceSessionArgs = {
  deviceSessionId: Scalars["String"]
}

export type MutationDuplicateProjectArgs = {
  includeAdditionalUnits?: InputMaybe<Scalars["Boolean"]>
  includeReportingUnits?: InputMaybe<Scalars["Boolean"]>
  includeTasks?: InputMaybe<Scalars["Boolean"]>
  projectId: Scalars["String"]
  projectName: Scalars["String"]
}

export type MutationDuplicateReportTemplateArgs = {
  id: Scalars["String"]
}

export type MutationDuplicateScheduleTemplateArgs = {
  id: Scalars["String"]
}

export type MutationEditDeliverableUnitArgs = {
  color: Scalars["String"]
  description: Scalars["String"]
  id: Scalars["String"]
  referenceNumber?: InputMaybe<Scalars["String"]>
  unitOfMeasure: Scalars["String"]
}

export type MutationEditOrganizationArgs = {
  featureToggleSegments?: InputMaybe<Array<FeatureToggleSegments>>
  id: Scalars["String"]
  image?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  notificationSettings?: InputMaybe<Scalars["JSON"]>
  overtimeType?: InputMaybe<OvertimeTypeEnum>
  requireTimeEntryEvidence?: InputMaybe<Scalars["Boolean"]>
  stripeCustomerId?: InputMaybe<Scalars["String"]>
  timezone?: InputMaybe<Scalars["String"]>
}

export type MutationEditScheduledBreakArgs = {
  breakTaskId?: InputMaybe<Scalars["String"]>
  durationInMinutes?: InputMaybe<Scalars["Int"]>
  id: Scalars["String"]
  isActive?: InputMaybe<Scalars["Boolean"]>
  localizedStartTime?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  projectId?: InputMaybe<Scalars["String"]>
}

export type MutationEditUnitGoalArgs = {
  goal: UnitGoalInput
  id: Scalars["String"]
}

export type MutationEditUnitOfMeasureArgs = {
  id: Scalars["Int"]
  name: Scalars["String"]
  symbol: Scalars["String"]
}

export type MutationEndAllMasqueradeSessionsForUserArgs = {
  subjectUserId: Scalars["String"]
}

export type MutationInsertManyAssetReportsArgs = {
  reports: Array<AssetReportInput>
}

export type MutationInsertOneAssetArgs = {
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  category?: InputMaybe<AssetCategory>
  companyAssetNumber?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  groupQuantity?: InputMaybe<Scalars["Int"]>
  inspectionRequirements?: InputMaybe<Array<AssetInspectionRequirementsInput>>
  inventoryRequirements?: InputMaybe<AssetInventoryRequirementsInput>
  isAssetGroup?: InputMaybe<Scalars["Boolean"]>
  manufacturer?: InputMaybe<AssetManufacturerInput>
  name: Scalars["String"]
  ownershipType: Scalars["String"]
  photoId?: InputMaybe<Scalars["String"]>
  purchaseDetails?: InputMaybe<AssetPurchaseDetailsInput>
  rentalAgreement?: InputMaybe<AssetRentalAgreementInput>
  reportTemplateIds?: InputMaybe<Array<Scalars["String"]>>
  vendorContact?: InputMaybe<AssetVendorContactInput>
}

export type MutationInsertOneProjectArgs = {
  address?: InputMaybe<AddressInput>
  code?: InputMaybe<Scalars["String"]>
  customerId?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  location?: InputMaybe<Scalars["JSON"]>
  locationNotes?: InputMaybe<Scalars["String"]>
  metadata?: InputMaybe<Array<MetadataNoteInput>>
  name: Scalars["String"]
  schedule?: InputMaybe<ScheduleTemplateInput>
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>
  startDate?: InputMaybe<Scalars["DateTime"]>
  taskGroups?: InputMaybe<Array<GroupInput>>
  tasks?: InputMaybe<Array<TaskInput>>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
}

export type MutationInsertOneTaskArgs = {
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  estimatedHours?: InputMaybe<Scalars["Float"]>
  groupId?: InputMaybe<Scalars["String"]>
  metadata?: InputMaybe<Array<MetadataNoteInput>>
  name: Scalars["String"]
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId: Scalars["String"]
  schedule?: InputMaybe<ScheduleTemplateInput>
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>
  startDate?: InputMaybe<Scalars["DateTime"]>
  unitGoals?: InputMaybe<Array<UnitGoalInput>>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
}

export type MutationInsertOneTaskGroupArgs = {
  description?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  notes?: InputMaybe<Scalars["String"]>
  projectId: Scalars["String"]
}

export type MutationInsertOneTimeEntryArgs = {
  endAt?: InputMaybe<Scalars["DateTime"]>
  startAt: Scalars["DateTime"]
  startEvidence?: InputMaybe<StartEvidenceInput>
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type MutationInsertReportTemplateArgs = {
  fields: Array<AssetReportTemplateFieldInput>
  name: Scalars["String"]
  reusable: Scalars["Boolean"]
  universal?: InputMaybe<Scalars["Boolean"]>
}

export type MutationMarkAllNotificationsReadArgs = {
  id?: InputMaybe<Scalars["String"]>
}

export type MutationMarkNotificationReadArgs = {
  id?: InputMaybe<Scalars["String"]>
}

export type MutationMarkNotificationsReadByIdArgs = {
  ids?: InputMaybe<Array<Scalars["String"]>>
}

export type MutationMarkOrganizationNotPastDueArgs = {
  id: Scalars["String"]
}

export type MutationMarkOrganizationPastDueArgs = {
  id: Scalars["String"]
}

export type MutationMarkTaskCompletionArgs = {
  completed: Scalars["Boolean"]
  id: Scalars["String"]
}

export type MutationMasqueradeUserArgs = {
  subjectUserId: Scalars["String"]
}

export type MutationReassignUserArgs = {
  projectId: Scalars["String"]
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type MutationReassignUsersArgs = {
  assignments: Array<UserAssignmentAssignment>
}

export type MutationReportTaskProgressArgs = {
  fileIds?: InputMaybe<Array<Scalars["String"]>>
  note?: InputMaybe<Scalars["String"]>
  projectId: Scalars["String"]
  taskId: Scalars["String"]
  unitGoalProgress?: InputMaybe<Array<UnitGoalProgressInput>>
}

export type MutationResendUserInvitesArgs = {
  userIds: Array<Scalars["String"]>
}

export type MutationRestoreOneAssetArgs = {
  id: Scalars["String"]
}

export type MutationRestoreOneUserArgs = {
  id: Scalars["String"]
}

export type MutationReturnQuantityToInventoryArgs = {
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  quantityToReturn: Scalars["Int"]
  status: AssetStatus
}

export type MutationTransferAssetsArgs = {
  assetGroupReassignments?: InputMaybe<Array<AssetGroupReassignmentSpecification>>
  assetIds: Array<Scalars["String"]>
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  projectIdIfTask?: InputMaybe<Scalars["String"]>
}

export type MutationUnarchiveQuantitiesArgs = {
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  quantityToUnarchive: Scalars["Int"]
  status: AssetStatus
}

export type MutationUpdateCustomerArgs = {
  addressLine1?: InputMaybe<Scalars["String"]>
  addressLine2?: InputMaybe<Scalars["String"]>
  city?: InputMaybe<Scalars["String"]>
  country?: InputMaybe<Scalars["String"]>
  customerId?: InputMaybe<Scalars["String"]>
  email?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  image?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  notes?: InputMaybe<Scalars["String"]>
  phoneNumber?: InputMaybe<Scalars["String"]>
  phoneNumberExt?: InputMaybe<Scalars["String"]>
  state?: InputMaybe<Scalars["String"]>
  zip?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateInjuryReportArgs = {
  id: Scalars["String"]
  notes?: InputMaybe<Scalars["String"]>
  userId?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateLastExportArgs = {
  entityId?: InputMaybe<Scalars["String"]>
  entityType?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateOneAssetArgs = {
  active?: InputMaybe<Scalars["Boolean"]>
  assigneeId?: InputMaybe<Scalars["String"]>
  category?: InputMaybe<AssetCategory>
  companyAssetNumber?: InputMaybe<Scalars["String"]>
  deletedAt?: InputMaybe<Scalars["DateTime"]>
  description?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  inspectionRequirements?: InputMaybe<Array<AssetInspectionRequirementsInput>>
  inventoryRequirements?: InputMaybe<AssetInventoryRequirementsInput>
  manufacturer?: InputMaybe<AssetManufacturerInput>
  name?: InputMaybe<Scalars["String"]>
  ownershipType?: InputMaybe<Scalars["String"]>
  photoId?: InputMaybe<Scalars["String"]>
  purchaseDetails?: InputMaybe<AssetPurchaseDetailsInput>
  rentalAgreement?: InputMaybe<AssetRentalAgreementInput>
  reportTemplateIds?: InputMaybe<Array<Scalars["String"]>>
  status?: InputMaybe<AssetStatus>
  vendorContact?: InputMaybe<AssetVendorContactInput>
}

export type MutationUpdateOneContractArgs = {
  description?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  name?: InputMaybe<Scalars["String"]>
  number?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateOneFileArgs = {
  expiresAt?: InputMaybe<Scalars["DateTime"]>
  fileName?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  status?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateOneProjectArgs = {
  address?: InputMaybe<AddressInput>
  code?: InputMaybe<Scalars["String"]>
  customerId?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  googlePlace?: InputMaybe<Scalars["JSON"]>
  id: Scalars["String"]
  image?: InputMaybe<Scalars["String"]>
  isArchived?: InputMaybe<Scalars["DateTime"]>
  isComplete?: InputMaybe<Scalars["DateTime"]>
  location?: InputMaybe<Scalars["JSON"]>
  locationNotes?: InputMaybe<Scalars["String"]>
  metadata?: InputMaybe<Array<MetadataNoteInput>>
  name?: InputMaybe<Scalars["String"]>
  schedule?: InputMaybe<ScheduleTemplateInput>
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>
  startDate?: InputMaybe<Scalars["DateTime"]>
  unassignedTaskId?: InputMaybe<Scalars["String"]>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateOneTaskArgs = {
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  estimatedHours?: InputMaybe<Scalars["Float"]>
  groupId?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  isComplete?: InputMaybe<Scalars["DateTime"]>
  isSubtask?: InputMaybe<Scalars["Boolean"]>
  metadata?: InputMaybe<Array<MetadataNoteInput>>
  name: Scalars["String"]
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId: Scalars["String"]
  schedule?: InputMaybe<ScheduleTemplateInput>
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>
  startDate?: InputMaybe<Scalars["DateTime"]>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateOneTaskGroupArgs = {
  description?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  name?: InputMaybe<Scalars["String"]>
  notes?: InputMaybe<Scalars["String"]>
  projectId?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateOneTimeEntryArgs = {
  endAt?: InputMaybe<Scalars["DateTime"]>
  id: Scalars["String"]
  startAt?: InputMaybe<Scalars["DateTime"]>
  taskId: Scalars["String"]
}

export type MutationUpdateOneUserArgs = {
  companyUserId?: InputMaybe<Scalars["String"]>
  firstName?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  image?: InputMaybe<Scalars["String"]>
  jobTitle?: InputMaybe<Scalars["String"]>
  lastName?: InputMaybe<Scalars["String"]>
  payRate?: InputMaybe<Scalars["String"]>
  payType?: InputMaybe<PayType>
  phoneNumber?: InputMaybe<Scalars["String"]>
  phoneNumberExt?: InputMaybe<Scalars["String"]>
  roles?: InputMaybe<Array<Scalars["String"]>>
  task?: InputMaybe<Scalars["String"]>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
}

export type MutationUpdatePasswordArgs = {
  currentPassword: Scalars["String"]
  newPassword: Scalars["String"]
}

export type MutationUpdateReportTemplateArgs = {
  fields?: InputMaybe<Array<AssetReportTemplateFieldInput>>
  id: Scalars["String"]
  name?: InputMaybe<Scalars["String"]>
  reusable?: InputMaybe<Scalars["Boolean"]>
}

export type MutationUpdateScheduleTemplateArgs = {
  id: Scalars["String"]
  isDefault?: InputMaybe<Scalars["Boolean"]>
  nonWorkDays?: InputMaybe<Array<ScheduleTemplateNonWorkDaysInput>>
  workDays?: InputMaybe<Array<ScheduleTemplateWorkDaysInput>>
  workHours?: InputMaybe<ScheduleTemplateWorkHoursInput>
}

export type MutationUpdateTaskSortOrderArgs = {
  sortOrder: Scalars["Int"]
  taskId: Scalars["String"]
  type: Scalars["String"]
}

export type MutationUpdateUserAssignmentArgs = {
  id: Scalars["String"]
  projectId: Scalars["String"]
  taskId?: InputMaybe<Scalars["String"]>
}

export type NewUser = {
  companyUserId?: InputMaybe<Scalars["String"]>
  email: Scalars["String"]
  firstName: Scalars["String"]
  jobTitle: Scalars["String"]
  lastName: Scalars["String"]
  organizationId?: InputMaybe<Scalars["String"]>
  parentId?: InputMaybe<Scalars["String"]>
  payRate?: InputMaybe<Scalars["String"]>
  payType?: InputMaybe<PayType>
  phoneNumber: Scalars["String"]
  phoneNumberExt?: InputMaybe<Scalars["String"]>
  roles: Array<Scalars["String"]>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
}

export type OfflineEvent = {
  __typename?: "OfflineEvent"
  assetReportData?: Maybe<Scalars["JSON"]>
  clientCanceledAt?: Maybe<Scalars["DateTime"]>
  clientCreatedAt: Scalars["DateTime"]
  clientUpdatedAt?: Maybe<Scalars["DateTime"]>
  clockInData?: Maybe<ClockInData>
  clockOutData?: Maybe<ClockOutData>
  createdAt: Scalars["DateTime"]
  deviceInformation?: Maybe<Scalars["JSON"]>
  id: Scalars["ID"]
  issues?: Maybe<Scalars["String"]>
  location?: Maybe<GeolocationPosition>
  mobileApplicationIsVerified: Scalars["Boolean"]
  organizationId: Scalars["ID"]
  processedAt?: Maybe<Scalars["DateTime"]>
  receivedAt: Scalars["DateTime"]
  reconciledAt?: Maybe<Scalars["DateTime"]>
  reporterId: Scalars["ID"]
  requestInformation?: Maybe<Scalars["JSON"]>
  taskProgressData?: Maybe<Scalars["JSON"]>
  type: OfflineEventType
  updatedAt: Scalars["DateTime"]
}

export type OfflineEventInput = {
  clientCreatedAt: Scalars["DateTime"]
  clockInData?: InputMaybe<ClockInDataInput>
  clockOutData?: InputMaybe<ClockOutDataInput>
  id: Scalars["String"]
  location: GeolocationPositionInput
  type: OfflineEventType
}

export enum OfflineEventType {
  AssetReportEvent = "AssetReportEvent",
  ClockInEvent = "ClockInEvent",
  ClockOutEvent = "ClockOutEvent",
  TaskProgressEvent = "TaskProgressEvent",
}

export type Organization = {
  __typename?: "Organization"
  activeProjectsCount: Scalars["Int"]
  activeUsersForDateRangeCount: Scalars["Int"]
  admins: Array<User>
  archived: Scalars["Boolean"]
  billingPastDueAt?: Maybe<Scalars["DateTime"]>
  billingStatus: OrganizationBillingStatus
  clockedInUserCount: Scalars["Int"]
  dateCreated: Scalars["DateTime"]
  featureToggleSegments: Array<Scalars["String"]>
  id: Scalars["String"]
  image?: Maybe<Scalars["String"]>
  imageUrl?: Maybe<Scalars["String"]>
  lastStripeInvoice?: Maybe<StripeInvoice>
  name?: Maybe<Scalars["String"]>
  notificationSettings: Scalars["JSON"]
  overtimeType: OvertimeTypeEnum
  projectsCount: Scalars["Int"]
  requireTimeEntryEvidence: Scalars["Boolean"]
  roles: Array<Role>
  scheduledBreaks: Array<ScheduledBreak>
  secondsClockedForCurrentWeek: Scalars["Int"]
  stripeCustomerId?: Maybe<Scalars["String"]>
  stripeInvoices: Array<StripeInvoice>
  timezone: Scalars["String"]
  unassignedProjectId: Scalars["String"]
  unitsOfMeasure: Array<UnitOfMeasure>
  userCount: Scalars["Int"]
}

export type OrganizationActiveUsersForDateRangeCountArgs = {
  rangeEnd: Scalars["DateTime"]
  rangeStart: Scalars["DateTime"]
}

export enum OrganizationBillingStatus {
  Active = "ACTIVE",
  DisabledDueToNonPayment = "DISABLED_DUE_TO_NON_PAYMENT",
  PastDue = "PAST_DUE",
}

export enum OvertimeTypeEnum {
  DailyOvertime = "DAILY_OVERTIME",
  StandardOvertime = "STANDARD_OVERTIME",
}

export type PageInfo = {
  __typename?: "PageInfo"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  hasPreviousPage: Scalars["Boolean"]
  startCursor?: Maybe<Scalars["String"]>
}

export enum PayType {
  Hourly = "HOURLY",
  Salary = "SALARY",
}

export type Project = {
  __typename?: "Project"
  activeTasksCount: Scalars["Int"]
  address?: Maybe<Address>
  assets: Array<Asset>
  assetsCount: Scalars["Int"]
  assignees: Array<UserAssignment>
  code?: Maybe<Scalars["String"]>
  customer?: Maybe<Customer>
  customerId?: Maybe<Scalars["String"]>
  dateCreated: Scalars["DateTime"]
  /** @deprecated Please use unassignedTask instead as they are redundant */
  defaultTask: Task
  deliveredPrimaryGoalUnits: Scalars["Float"]
  description?: Maybe<Scalars["String"]>
  documents: Array<File>
  editHistoryEvents: Array<EditHistoryEvent>
  editHistoryEventsCount: Scalars["Float"]
  endDate?: Maybe<Scalars["DateTime"]>
  estimatedHours: Scalars["Float"]
  /** @deprecated Not pertinent or used anymore */
  googlePlace?: Maybe<Scalars["JSON"]>
  hasReportableUnit: Scalars["Boolean"]
  hoursDataByDateRangeType: Scalars["JSON"]
  id: Scalars["String"]
  imageUrl?: Maybe<Scalars["String"]>
  injuryReports: Array<InjuryReport>
  isArchived?: Maybe<Scalars["DateTime"]>
  isComplete?: Maybe<Scalars["DateTime"]>
  isDefault: Scalars["Boolean"]
  lastExport?: Maybe<Scalars["JSON"]>
  location?: Maybe<LocationWithRadius>
  locationNotes?: Maybe<Scalars["String"]>
  /** @deprecated This field is untyped and replaced by location */
  locationUntyped?: Maybe<Scalars["JSON"]>
  /** @deprecated Please use metadataNotes instead as they are typed */
  metadata?: Maybe<Scalars["JSON"]>
  metadataNotes?: Maybe<Array<MetadataNote>>
  name: Scalars["String"]
  organization: Organization
  organizationId: Scalars["String"]
  primaryGoalTargetQuantity: Scalars["Float"]
  productionRate: Scalars["Float"]
  progressSummary: ProjectProgressSummary
  scheduleTemplate?: Maybe<ScheduleTemplate>
  scheduledBreaks?: Maybe<Array<ScheduledBreak>>
  startDate?: Maybe<Scalars["DateTime"]>
  taskGroups: Array<TaskGroup>
  taskProgressEventsWithUnitReports: Array<TaskProgressEvent>
  tasks: Array<Task>
  tasksCount: Scalars["Int"]
  timeEntries: Array<TimeEntry>
  timeEntriesSumDurationInSeconds: Scalars["Float"]
  unassignedTask: Task
  unassignedTaskId?: Maybe<Scalars["String"]>
  unitGoals: Array<UnitGoal>
  userCount: Scalars["Int"]
  users: Array<User>
  workersCompCode?: Maybe<WorkersCompCode>
  workersCompCodeId?: Maybe<Scalars["String"]>
}

export type ProjectAssetsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectAssetsCountArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectDeliveredPrimaryGoalUnitsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectHoursDataByDateRangeTypeArgs = {
  dateRangeType: Scalars["String"]
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectProductionRateArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectTaskProgressEventsWithUnitReportsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectTasksArgs = {
  includeArchived?: InputMaybe<Scalars["Boolean"]>
  includeCompleted?: InputMaybe<Scalars["Boolean"]>
  includeSubtasks?: InputMaybe<Scalars["Boolean"]>
}

export type ProjectTimeEntriesArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectTimeEntriesSumDurationInSecondsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectUsersArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectFilter = {
  searchText?: InputMaybe<Scalars["String"]>
  status?: ProjectStatus
}

export type ProjectProgressSummary = {
  __typename?: "ProjectProgressSummary"
  completedHours: Scalars["Float"]
  completedUnits: Scalars["Float"]
  completionPercentage: Scalars["Float"]
  noHours?: Maybe<Scalars["Boolean"]>
  targetHours: Scalars["Float"]
  targetUnits: Scalars["Float"]
}

export type ProjectSort = {
  by?: ProjectSortByInput
  direction?: SortDirectionInput
}

export enum ProjectSortByInput {
  Name = "name",
}

export enum ProjectStatus {
  Active = "active",
  Archived = "archived",
  Completed = "completed",
}

export type Query = {
  __typename?: "Query"
  activeProjectCount: Scalars["Int"]
  activeProjects: Array<Project>
  activeProjectsPaginated: QueryActiveProjectsPaginatedConnection
  archivedAssetGroups: Array<AssetGroup>
  archivedAssets: QueryArchivedAssetsConnection
  asset: Asset
  assetGroups: Array<AssetGroup>
  assetReport: AssetReport
  assetReportTemplate: AssetReportTemplate
  assetReports: Array<AssetReport>
  assets: Array<Asset>
  assets_2: QueryAssets_2Connection
  assignedAssets: Array<AssignedAsset>
  breakTasks: Array<Task>
  contract: Contract
  contracts: Array<Contract>
  currentUser: User
  customer: Customer
  customers: Array<Customer>
  deliverableUnit: DeliverableUnit
  deliverableUnits: Array<DeliverableUnit>
  editHistoryEvent: EditHistoryEvent
  editHistoryEvents: Array<EditHistoryEvent>
  getJobTitles: Array<Scalars["String"]>
  myNotifications: Array<UserNotification>
  myOrganization: Organization
  nestedAssets: Array<Asset>
  nestedAssetsForInventory: Array<AssignedAsset>
  offlineEvent: OfflineEvent
  offlineEvents: Array<OfflineEvent>
  organization: Organization
  organizations: Array<Organization>
  project?: Maybe<Project>
  projects: Array<Project>
  projectsByStatus: Array<Project>
  projectsPaginated: QueryProjectsPaginatedConnection
  reusableAssetReportTemplates: Array<AssetReportTemplate>
  roles: Array<Role>
  scheduleTemplates: Array<ScheduleTemplate>
  scheduledBreaks: Array<ScheduledBreak>
  subtasks: Array<Task>
  subtasksPaginated: QuerySubtasksPaginatedConnection
  task: Task
  taskGroup?: Maybe<TaskGroup>
  taskGroups: Array<TaskGroup>
  taskList: Array<TaskListItem>
  taskProgressEvent: TaskProgressEvent
  taskProgressEvents: Array<TaskProgressEvent>
  tasks: Array<Task>
  tasksForGroupId: Array<Task>
  tasksPaginated: QueryTasksPaginatedConnection
  unitGoal?: Maybe<UnitGoal>
  unitGoals: Array<UnitGoal>
  user: User
  users: QueryUsersConnection
  usersForSummarySection: Array<User>
  usersList: Array<User>
  workersCompCodes: Array<WorkersCompCode>
}

export type QueryActiveProjectsPaginatedArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
}

export type QueryArchivedAssetGroupsArgs = {
  assetId: Scalars["String"]
}

export type QueryArchivedAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  searchText?: InputMaybe<Scalars["String"]>
  sort?: AssetSort
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryAssetArgs = {
  id: Scalars["String"]
}

export type QueryAssetGroupsArgs = {
  assetGroupId?: InputMaybe<Scalars["String"]>
  assetId?: InputMaybe<Scalars["String"]>
  assetIds?: InputMaybe<Array<Scalars["String"]>>
  projectId?: InputMaybe<Scalars["String"]>
  taskId?: InputMaybe<Scalars["String"]>
  userId?: InputMaybe<Scalars["String"]>
}

export type QueryAssetReportArgs = {
  reportId: Scalars["String"]
}

export type QueryAssetReportTemplateArgs = {
  id: Scalars["String"]
}

export type QueryAssetReportsArgs = {
  assetId: Scalars["String"]
}

export type QueryAssetsArgs = {
  assetGroupId?: InputMaybe<Scalars["String"]>
  assetGroupIds?: InputMaybe<Array<Scalars["String"]>>
  assetId?: InputMaybe<Scalars["String"]>
  assetIds?: InputMaybe<Array<Scalars["String"]>>
  assignableId?: InputMaybe<Scalars["String"]>
  assignableType?: InputMaybe<AssetAssignableType>
  category?: InputMaybe<AssetCategory>
  deleted?: InputMaybe<Scalars["Boolean"]>
  includeGroupedAssets?: InputMaybe<Scalars["Boolean"]>
  projectId?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<AssetStatus>
  taskId?: InputMaybe<Scalars["String"]>
  userId?: InputMaybe<Scalars["String"]>
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryAssets_2Args = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  filter?: AssetFilter
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  sort?: AssetSort
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryAssignedAssetsArgs = {
  filter?: AssignedAssetFilter
  sort?: AssetSort
}

export type QueryContractArgs = {
  id: Scalars["String"]
}

export type QueryContractsArgs = {
  customerId?: InputMaybe<Scalars["String"]>
}

export type QueryCustomerArgs = {
  id: Scalars["String"]
}

export type QueryDeliverableUnitArgs = {
  id: Scalars["String"]
}

export type QueryEditHistoryEventArgs = {
  id: Scalars["String"]
}

export type QueryEditHistoryEventsArgs = {
  taskId: Scalars["String"]
}

export type QueryMyNotificationsArgs = {
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryNestedAssetsArgs = {
  id: Scalars["String"]
}

export type QueryNestedAssetsForInventoryArgs = {
  id: Scalars["String"]
}

export type QueryOfflineEventArgs = {
  id: Scalars["String"]
}

export type QueryOfflineEventsArgs = {
  processed?: InputMaybe<Scalars["Boolean"]>
  reporterId?: InputMaybe<Scalars["String"]>
}

export type QueryOrganizationArgs = {
  id: Scalars["String"]
}

export type QueryOrganizationsArgs = {
  archived?: InputMaybe<Scalars["Boolean"]>
}

export type QueryProjectArgs = {
  id: Scalars["String"]
}

export type QueryProjectsArgs = {
  filter?: ProjectFilter
  sort?: ProjectSort
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryProjectsByStatusArgs = {
  includeDefault?: InputMaybe<Scalars["Boolean"]>
  limit?: InputMaybe<Scalars["Int"]>
  status: ProjectStatus
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryProjectsPaginatedArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
}

export type QueryScheduleTemplatesArgs = {
  isDefault?: InputMaybe<Scalars["Boolean"]>
  projectId?: InputMaybe<Scalars["String"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type QueryScheduledBreaksArgs = {
  includeOrgBreaks?: InputMaybe<Scalars["Boolean"]>
  projectId?: InputMaybe<Scalars["String"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type QuerySubtasksArgs = {
  onlyActive?: InputMaybe<Scalars["Boolean"]>
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId?: InputMaybe<Scalars["String"]>
}

export type QuerySubtasksPaginatedArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  onlyActive?: InputMaybe<Scalars["Boolean"]>
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId?: InputMaybe<Scalars["String"]>
}

export type QueryTaskArgs = {
  id: Scalars["String"]
}

export type QueryTaskGroupArgs = {
  id: Scalars["String"]
}

export type QueryTaskGroupsArgs = {
  projectId?: InputMaybe<Scalars["String"]>
}

export type QueryTaskListArgs = {
  archived: Scalars["Boolean"]
  projectId: Scalars["String"]
  searchText?: InputMaybe<Scalars["String"]>
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryTaskProgressEventArgs = {
  id: Scalars["String"]
}

export type QueryTaskProgressEventsArgs = {
  filter?: InputMaybe<TaskProgressEventFilter>
  projectId: Scalars["String"]
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type QueryTasksArgs = {
  includeSubtasks?: InputMaybe<Scalars["Boolean"]>
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId?: InputMaybe<Scalars["String"]>
  sorted?: InputMaybe<Scalars["Boolean"]>
  status?: InputMaybe<TaskStatus>
}

export type QueryTasksForGroupIdArgs = {
  groupId: Scalars["String"]
}

export type QueryTasksPaginatedArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  onlyActive?: InputMaybe<Scalars["Boolean"]>
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId?: InputMaybe<Scalars["String"]>
}

export type QueryUnitGoalArgs = {
  id: Scalars["String"]
}

export type QueryUnitGoalsArgs = {
  projectId: Scalars["String"]
  taskId?: InputMaybe<Scalars["String"]>
}

export type QueryUserArgs = {
  id: Scalars["String"]
}

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  filter?: UserFilter
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  sort?: UserSort
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryUsersForSummarySectionArgs = {
  projectId: Scalars["String"]
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type QueryUsersListArgs = {
  projectId?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<Scalars["String"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type QueryActiveProjectsPaginatedConnection = {
  __typename?: "QueryActiveProjectsPaginatedConnection"
  edges: Array<Maybe<QueryActiveProjectsPaginatedConnectionEdge>>
  pageInfo: PageInfo
}

export type QueryActiveProjectsPaginatedConnectionEdge = {
  __typename?: "QueryActiveProjectsPaginatedConnectionEdge"
  cursor: Scalars["String"]
  node: Project
}

export type QueryArchivedAssetsConnection = {
  __typename?: "QueryArchivedAssetsConnection"
  edges: Array<Maybe<QueryArchivedAssetsConnectionEdge>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type QueryArchivedAssetsConnectionEdge = {
  __typename?: "QueryArchivedAssetsConnectionEdge"
  cursor: Scalars["String"]
  node: Asset
}

export type QueryAssets_2Connection = {
  __typename?: "QueryAssets_2Connection"
  edges: Array<Maybe<QueryAssets_2ConnectionEdge>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type QueryAssets_2ConnectionEdge = {
  __typename?: "QueryAssets_2ConnectionEdge"
  cursor: Scalars["String"]
  node: Asset
}

export type QueryProjectsPaginatedConnection = {
  __typename?: "QueryProjectsPaginatedConnection"
  edges: Array<Maybe<QueryProjectsPaginatedConnectionEdge>>
  pageInfo: PageInfo
}

export type QueryProjectsPaginatedConnectionEdge = {
  __typename?: "QueryProjectsPaginatedConnectionEdge"
  cursor: Scalars["String"]
  node: Project
}

export type QuerySubtasksPaginatedConnection = {
  __typename?: "QuerySubtasksPaginatedConnection"
  edges: Array<Maybe<QuerySubtasksPaginatedConnectionEdge>>
  pageInfo: PageInfo
}

export type QuerySubtasksPaginatedConnectionEdge = {
  __typename?: "QuerySubtasksPaginatedConnectionEdge"
  cursor: Scalars["String"]
  node: Task
}

export type QueryTasksPaginatedConnection = {
  __typename?: "QueryTasksPaginatedConnection"
  edges: Array<Maybe<QueryTasksPaginatedConnectionEdge>>
  pageInfo: PageInfo
}

export type QueryTasksPaginatedConnectionEdge = {
  __typename?: "QueryTasksPaginatedConnectionEdge"
  cursor: Scalars["String"]
  node: Task
}

export type QueryUsersConnection = {
  __typename?: "QueryUsersConnection"
  edges: Array<Maybe<QueryUsersConnectionEdge>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type QueryUsersConnectionEdge = {
  __typename?: "QueryUsersConnectionEdge"
  cursor: Scalars["String"]
  node: User
}

export type RequestInfo = {
  __typename?: "RequestInfo"
  browser?: Maybe<Scalars["String"]>
  city?: Maybe<Scalars["String"]>
  country?: Maybe<Scalars["String"]>
  isBot?: Maybe<Scalars["Boolean"]>
  isCurl?: Maybe<Scalars["Boolean"]>
  isDesktop?: Maybe<Scalars["Boolean"]>
  isMobile?: Maybe<Scalars["Boolean"]>
  isTablet?: Maybe<Scalars["Boolean"]>
  os?: Maybe<Scalars["String"]>
  platform?: Maybe<Scalars["String"]>
  region?: Maybe<Scalars["String"]>
}

export type Role = {
  __typename?: "Role"
  dateCreated: Scalars["DateTime"]
  description?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  name?: Maybe<Scalars["String"]>
  order?: Maybe<Scalars["Int"]>
  organization: Organization
  organizationId: Scalars["String"]
  permissions: Scalars["JSON"]
  widgets: Scalars["JSON"]
}

export type ScheduleTemplate = {
  __typename?: "ScheduleTemplate"
  id: Scalars["String"]
  isDefault?: Maybe<Scalars["Boolean"]>
  nonWorkDays?: Maybe<Array<ScheduleTemplateNonWorkDays>>
  workDays?: Maybe<Array<ScheduleTemplateWorkDay>>
  workHours?: Maybe<ScheduleTemplateWorkHours>
}

export type ScheduleTemplateInput = {
  id?: InputMaybe<Scalars["String"]>
  isDefault?: InputMaybe<Scalars["Boolean"]>
  nonWorkDays?: InputMaybe<Array<ScheduleTemplateNonWorkDaysInput>>
  workDays?: InputMaybe<Array<ScheduleTemplateWorkDaysInput>>
  workHours?: InputMaybe<ScheduleTemplateWorkHoursInput>
}

/** ScheduleTemplateNonWorkDays */
export type ScheduleTemplateNonWorkDays = {
  __typename?: "ScheduleTemplateNonWorkDays"
  active: Scalars["Boolean"]
  dateRange: Array<Scalars["DateTime"]>
  id: Scalars["String"]
  name: Scalars["String"]
}

/** ScheduleTemplateNonWorkDaysInput */
export type ScheduleTemplateNonWorkDaysInput = {
  active: Scalars["Boolean"]
  dateRange: Array<Scalars["DateTime"]>
  id?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
}

/** ScheduleTemplateWorkDay */
export type ScheduleTemplateWorkDay = {
  __typename?: "ScheduleTemplateWorkDay"
  active: Scalars["Boolean"]
  label: Scalars["String"]
}

/** ScheduleTemplateWorkDaysInput */
export type ScheduleTemplateWorkDaysInput = {
  active: Scalars["Boolean"]
  label: Scalars["String"]
}

/** ScheduleTemplateWorkHours */
export type ScheduleTemplateWorkHours = {
  __typename?: "ScheduleTemplateWorkHours"
  endTime?: Maybe<Scalars["String"]>
  hours?: Maybe<Scalars["Int"]>
  startTime?: Maybe<Scalars["String"]>
}

/** ScheduleTemplateWorkHoursInput */
export type ScheduleTemplateWorkHoursInput = {
  endTime?: InputMaybe<Scalars["String"]>
  hours?: InputMaybe<Scalars["Int"]>
  startTime?: InputMaybe<Scalars["String"]>
}

export type ScheduledBreak = {
  __typename?: "ScheduledBreak"
  breakTask: Task
  breakTaskId: Scalars["String"]
  durationInMinutes: Scalars["Int"]
  id: Scalars["String"]
  isActive: Scalars["Boolean"]
  localizedStartTime: Scalars["String"]
  name: Scalars["String"]
  projectId?: Maybe<Scalars["String"]>
}

export type ScheduledBreakInput = {
  breakTaskId: Scalars["String"]
  durationInMinutes: Scalars["Int"]
  id?: InputMaybe<Scalars["String"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  localizedStartTime: Scalars["String"]
  name: Scalars["String"]
}

export type SecurityActivity = {
  __typename?: "SecurityActivity"
  createdAt: Scalars["DateTime"]
  event: Scalars["String"]
  ip?: Maybe<Scalars["String"]>
  userAgent?: Maybe<Scalars["String"]>
}

export enum SortByInput {
  FirstName = "firstName",
  ProjectName = "projectName",
}

export enum SortDirectionInput {
  Asc = "asc",
  Desc = "desc",
}

export type StartEvidenceInput = {
  imagePath?: InputMaybe<Scalars["String"]>
  location?: InputMaybe<GeolocationPositionInput>
  locationError?: InputMaybe<Scalars["String"]>
}

/** Invoices created with Stripe */
export type StripeInvoice = {
  __typename?: "StripeInvoice"
  accountName?: Maybe<Scalars["String"]>
  amountDue: Scalars["Int"]
  amountPaid: Scalars["Int"]
  amountRemaining: Scalars["Int"]
  attemptCount: Scalars["Int"]
  attempted: Scalars["Boolean"]
  autoAdvance?: Maybe<Scalars["Boolean"]>
  billingReason?: Maybe<Scalars["String"]>
  collectionMethod: Scalars["String"]
  created?: Maybe<Scalars["DateTime"]>
  currency: Scalars["String"]
  customerEmail?: Maybe<Scalars["String"]>
  customerName?: Maybe<Scalars["String"]>
  customerPhone?: Maybe<Scalars["String"]>
  description?: Maybe<Scalars["String"]>
  dueDate?: Maybe<Scalars["DateTime"]>
  hostedInvoiceUrl?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  invoicePdf?: Maybe<Scalars["String"]>
  livemode: Scalars["Boolean"]
  object: Scalars["String"]
  paid: Scalars["Boolean"]
  status?: Maybe<Scalars["String"]>
  subtotal: Scalars["Int"]
  subtotalExcludingTax?: Maybe<Scalars["Int"]>
  tax?: Maybe<Scalars["Int"]>
  total: Scalars["Int"]
}

export type Task = {
  __typename?: "Task"
  archived: Scalars["Boolean"]
  assetCount: Scalars["Int"]
  assignees: Array<UserAssignment>
  clockedInUserCount: Scalars["Int"]
  clockedOutUserCount: Scalars["Int"]
  completedHours: Scalars["Float"]
  dateCreated: Scalars["DateTime"]
  description?: Maybe<Scalars["String"]>
  endDate?: Maybe<Scalars["DateTime"]>
  estimatedHours?: Maybe<Scalars["Float"]>
  group?: Maybe<TaskGroup>
  groupId?: Maybe<Scalars["String"]>
  hasReportableUnit: Scalars["Boolean"]
  hoursDataByDateRangeType: Scalars["JSON"]
  id: Scalars["String"]
  injuryReports: Array<InjuryReport>
  isComplete?: Maybe<Scalars["DateTime"]>
  isDefault: Scalars["Boolean"]
  isParentTask: Scalars["Boolean"]
  isUnpaid: Scalars["Boolean"]
  lastExport?: Maybe<Scalars["JSON"]>
  /** @deprecated Untyped; use `metadataNotes` instead */
  metadata?: Maybe<Scalars["JSON"]>
  metadataNotes?: Maybe<Array<MetadataNote>>
  name: Scalars["String"]
  organizationId: Scalars["String"]
  parentTask?: Maybe<Task>
  parentTaskId?: Maybe<Scalars["String"]>
  primaryUnitGoals: Array<UnitGoal>
  productionRate: Scalars["Float"]
  project: Project
  projectId: Scalars["String"]
  scheduleTemplate?: Maybe<ScheduleTemplate>
  scheduledBreaks?: Maybe<Array<ScheduledBreak>>
  sortOrder?: Maybe<Scalars["Int"]>
  startDate?: Maybe<Scalars["DateTime"]>
  subtaskCount: Scalars["Int"]
  subtasks: Array<Task>
  sumOfDeliveredUnits: Scalars["Float"]
  taskProgressEvents: Array<TaskProgressEvent>
  taskProgressEventsWithUnitReports: Array<TaskProgressEvent>
  timeEntries: Array<TimeEntry>
  timeEntriesSumDurationInSeconds: Scalars["Float"]
  unitGoals: Array<UnitGoal>
  unitGoalsSumProgress: Scalars["Float"]
  unitGoalsSumTargetQuantity: Scalars["Float"]
  userCount: Scalars["Int"]
  users: Array<User>
  usersWhoClockedOrReportedCount: Scalars["Int"]
  visibilityLevel: Scalars["String"]
  workersCompCode?: Maybe<WorkersCompCode>
  workersCompCodeId?: Maybe<Scalars["String"]>
}

export type TaskAssetCountArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskCompletedHoursArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskHoursDataByDateRangeTypeArgs = {
  dateRangeType: Scalars["String"]
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskProductionRateArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskSumOfDeliveredUnitsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskTaskProgressEventsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskTaskProgressEventsWithUnitReportsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskTimeEntriesArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskTimeEntriesSumDurationInSecondsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskUsersArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskUsersWhoClockedOrReportedCountArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskGroup = {
  __typename?: "TaskGroup"
  assetCount: Scalars["Int"]
  completedHours: Scalars["Float"]
  completedTaskCount: Scalars["Int"]
  createdAt: Scalars["DateTime"]
  description?: Maybe<Scalars["String"]>
  endDate?: Maybe<Scalars["DateTime"]>
  estimatedHours: Scalars["Float"]
  id: Scalars["ID"]
  isComplete: Scalars["Boolean"]
  name: Scalars["String"]
  notes?: Maybe<Scalars["String"]>
  organizationId: Scalars["ID"]
  productionRate: Scalars["Float"]
  projectId: Scalars["String"]
  sortOrder?: Maybe<Scalars["Int"]>
  startDate?: Maybe<Scalars["DateTime"]>
  taskCount: Scalars["Int"]
  tasks: Array<Task>
  unitProgress: Scalars["Int"]
  unitTargetGoal: Scalars["Int"]
  updatedAt: Scalars["DateTime"]
  userCount: Scalars["Int"]
}

export type TaskGroupAssetCountArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskGroupProductionRateArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskGroupUserCountArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskInput = {
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  estimatedHours?: InputMaybe<Scalars["Int"]>
  isDefault?: InputMaybe<Scalars["Boolean"]>
  name: Scalars["String"]
  sortOrder?: InputMaybe<Scalars["Int"]>
  startDate?: InputMaybe<Scalars["DateTime"]>
  unitGoals?: InputMaybe<Array<UnitGoalInput>>
  workDays?: InputMaybe<Scalars["Int"]>
}

export type TaskListItem = {
  __typename?: "TaskListItem"
  assetCount: Scalars["Int"]
  completedHours?: Maybe<Scalars["Float"]>
  completedTaskCount: Scalars["Int"]
  createdAt: Scalars["DateTime"]
  /** endDate */
  endDate?: Maybe<Scalars["DateTime"]>
  estimatedHours?: Maybe<Scalars["Float"]>
  groupId?: Maybe<Scalars["String"]>
  isComplete: Scalars["Boolean"]
  isDefault: Scalars["Boolean"]
  name: Scalars["String"]
  sortOrder?: Maybe<Scalars["Int"]>
  /** startDate */
  startDate?: Maybe<Scalars["DateTime"]>
  taskCount: Scalars["Int"]
  taskGroupId?: Maybe<Scalars["String"]>
  taskId?: Maybe<Scalars["String"]>
  unitProgress: Scalars["Int"]
  unitTargetGoal: Scalars["Int"]
  userCount: Scalars["Int"]
}

export type TaskProgressEvent = {
  __typename?: "TaskProgressEvent"
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  imageUrls: Array<Maybe<Scalars["String"]>>
  note?: Maybe<Scalars["String"]>
  reporter: User
  reporterId: Scalars["String"]
  task: Task
  taskId: Scalars["String"]
  unitGoalProgressCount: Scalars["Int"]
  unitGoalProgressReports: Array<UnitGoalProgress>
  updatedAt: Scalars["DateTime"]
}

export enum TaskProgressEventFilter {
  Images = "IMAGES",
  Notes = "NOTES",
}

export type TaskReportInput = {
  fileIds?: InputMaybe<Array<Scalars["String"]>>
  note?: InputMaybe<Scalars["String"]>
  taskId: Scalars["String"]
  unitGoalProgress?: InputMaybe<Array<UnitGoalProgressInput>>
}

export enum TaskStatus {
  Active = "active",
  Archived = "archived",
  Completed = "completed",
}

export type TimeEntry = {
  __typename?: "TimeEntry"
  createdAt: Scalars["DateTime"]
  durationInSeconds: Scalars["Float"]
  editedById: Scalars["String"]
  editor: User
  endAt?: Maybe<Scalars["DateTime"]>
  evidence: Scalars["JSON"]
  id: Scalars["String"]
  /** Tells us if this was a break or not */
  isBreak: Scalars["Boolean"]
  organization: Organization
  organizationId: Scalars["String"]
  project: Project
  projectId: Scalars["String"]
  signInPhotoUrl?: Maybe<Scalars["String"]>
  signOutPhotoUrl?: Maybe<Scalars["String"]>
  startAt: Scalars["DateTime"]
  task: Task
  taskId: Scalars["String"]
  updatedAt: Scalars["DateTime"]
  user: User
  userId: Scalars["String"]
}

/** Time Entry error response */
export type TimeEntryError = {
  __typename?: "TimeEntryError"
  reason: TimeEntryErrorReason
  userId: Scalars["String"]
}

export enum TimeEntryErrorReason {
  AlreadyClockedIn = "ALREADY_CLOCKED_IN",
  AlreadyClockedOut = "ALREADY_CLOCKED_OUT",
  AttemptedDoubleBooking = "ATTEMPTED_DOUBLE_BOOKING",
  EndCannotBeBeforeStart = "END_CANNOT_BE_BEFORE_START",
}

export type UnitGoal = {
  __typename?: "UnitGoal"
  createdAt: Scalars["DateTime"]
  deliverableUnit: DeliverableUnit
  deliverableUnitId: Scalars["ID"]
  id: Scalars["ID"]
  isPrimary: Scalars["Boolean"]
  organizationId: Scalars["ID"]
  previousProgress: Scalars["Float"]
  progressInDateRange: Scalars["Float"]
  project: Project
  projectId: Scalars["ID"]
  targetQuantity?: Maybe<Scalars["Float"]>
  task: Task
  taskId: Scalars["ID"]
  totalProgress: Scalars["Float"]
  unitGoalProgress: Array<UnitGoalProgress>
  updatedAt: Scalars["DateTime"]
}

export type UnitGoalPreviousProgressArgs = {
  rangeStart: Scalars["DateTime"]
}

export type UnitGoalProgressInDateRangeArgs = {
  rangeEnd: Scalars["DateTime"]
  rangeStart: Scalars["DateTime"]
}

export type UnitGoalInput = {
  deliverableUnitId: Scalars["String"]
  isPrimary: Scalars["Boolean"]
  targetQuantity?: InputMaybe<Scalars["Float"]>
}

export type UnitGoalProgress = {
  __typename?: "UnitGoalProgress"
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  progress: Scalars["Float"]
  unitGoal: UnitGoal
  unitGoalId: Scalars["String"]
  updatedAt: Scalars["DateTime"]
}

export type UnitGoalProgressInput = {
  progress: Scalars["Float"]
  unitGoalId: Scalars["String"]
}

export type UnitOfMeasure = {
  __typename?: "UnitOfMeasure"
  id?: Maybe<Scalars["Int"]>
  name: Scalars["String"]
  organizationId?: Maybe<Scalars["String"]>
  symbol: Scalars["String"]
}

/** Response from update sort order */
export type UpdateSortOrderPayload = {
  __typename?: "UpdateSortOrderPayload"
  id: Scalars["String"]
  sortOrder: Scalars["Int"]
}

export type User = {
  __typename?: "User"
  archived: Scalars["Boolean"]
  assignedProjectIds: Array<Scalars["String"]>
  assignedTaskIds: Array<Scalars["String"]>
  assignments: Array<UserAssignment>
  childAncestorNodes: Array<ChildAncestorNode>
  children: Array<User>
  companyUserId?: Maybe<Scalars["String"]>
  currentProject: Project
  currentProjectId: Scalars["String"]
  currentTask: Task
  currentTaskId: Scalars["String"]
  deviceSessions: Array<UserDeviceSession>
  editedTimeEntries: Array<TimeEntry>
  email: Scalars["String"]
  emailVerifiedAt?: Maybe<Scalars["DateTime"]>
  firstName: Scalars["String"]
  fullName: Scalars["String"]
  id: Scalars["String"]
  imageUrl?: Maybe<Scalars["String"]>
  injuryReports: Array<InjuryReport>
  invitationPending: Scalars["Boolean"]
  isBlackthornEmployee: Scalars["Boolean"]
  isClockedIn: Scalars["Boolean"]
  /** This field is a convenience helper to encapsulate logic which tells us if a user is on a scheduled break or not.  Someday this might look different but this is adequate today */
  isOnBreak: Scalars["Boolean"]
  jobTitle: Scalars["String"]
  lastName: Scalars["String"]
  latestTimeEntry?: Maybe<TimeEntry>
  myFiles: Array<File>
  offlineEvents: Array<OfflineEvent>
  organization: Organization
  organizationId: Scalars["String"]
  parents: Array<User>
  passwordUpdatedAt?: Maybe<Scalars["DateTime"]>
  payRate?: Maybe<Scalars["String"]>
  payType?: Maybe<PayType>
  phoneNumber: Scalars["String"]
  phoneNumberExt?: Maybe<Scalars["String"]>
  project: Project
  /** @deprecated Use currentProjectId */
  projectId: Scalars["String"]
  reportedUnitsInDateRangeByProjectOrTask: Array<TaskProgressEvent>
  roles: Array<Role>
  secondsClockedInDateRangeOnProjectOrTask: Scalars["Int"]
  secondsClockedSinceOrgMidnight: Scalars["Int"]
  secondsClockedThisWeek: Scalars["Int"]
  securityActivity: Array<SecurityActivity>
  sortName: Scalars["String"]
  task: Task
  /** @deprecated Use currentTaskId */
  taskId?: Maybe<Scalars["String"]>
  timeEntries?: Maybe<Array<TimeEntry>>
  workersCompCode?: Maybe<WorkersCompCode>
  workersCompCodeId?: Maybe<Scalars["String"]>
}

export type UserReportedUnitsInDateRangeByProjectOrTaskArgs = {
  projectId?: InputMaybe<Scalars["String"]>
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type UserSecondsClockedInDateRangeOnProjectOrTaskArgs = {
  projectId?: InputMaybe<Scalars["String"]>
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type UserSecurityActivityArgs = {
  skip?: Scalars["Int"]
  take?: Scalars["Int"]
}

export type UserTimeEntriesArgs = {
  projectId?: InputMaybe<Scalars["String"]>
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type UserAssignment = {
  __typename?: "UserAssignment"
  active: Scalars["Boolean"]
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  isCurrentAssignment?: Maybe<Scalars["Boolean"]>
  organizationId: Scalars["String"]
  project: Project
  projectId: Scalars["String"]
  task?: Maybe<Task>
  taskId?: Maybe<Scalars["String"]>
  updatedAt: Scalars["DateTime"]
  user: User
  userId?: Maybe<Scalars["String"]>
}

export type UserAssignmentAssignment = {
  projectId: Scalars["String"]
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type UserAssignmentBatchResponse = {
  __typename?: "UserAssignmentBatchResponse"
  count: Scalars["Int"]
}

export type UserAssignmentCreateInput = {
  projectId: Scalars["String"]
  taskId?: InputMaybe<Scalars["String"]>
  userId: Scalars["String"]
}

export type UserDeviceSession = {
  __typename?: "UserDeviceSession"
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  ip?: Maybe<Scalars["String"]>
  isSingleUse?: Maybe<Scalars["Boolean"]>
  requestInfo: RequestInfo
  updatedAt: Scalars["DateTime"]
  userAgent?: Maybe<Scalars["String"]>
  userId: Scalars["String"]
}

export type UserFilter = {
  archived?: InputMaybe<Scalars["Boolean"]>
  invitationPending?: InputMaybe<Scalars["Boolean"]>
  isClockedIn?: InputMaybe<Scalars["Boolean"]>
  projectId?: InputMaybe<Scalars["String"]>
  searchText?: InputMaybe<Scalars["String"]>
  taskId?: InputMaybe<Scalars["String"]>
  userIds?: InputMaybe<Array<Scalars["String"]>>
}

export type UserNotification = {
  __typename?: "UserNotification"
  asset?: Maybe<Asset>
  assignedUser: User
  assignedUserId: Scalars["String"]
  createdAt: Scalars["DateTime"]
  id: Scalars["ID"]
  markedReadAt?: Maybe<Scalars["DateTime"]>
  modelType: UserNotificationModelType
  organizationId: Scalars["String"]
  type: UserNotificationType
  user?: Maybe<User>
}

export enum UserNotificationModelType {
  Asset = "Asset",
  User = "User",
}

export enum UserNotificationType {
  AssetInspectionFailed = "ASSET_INSPECTION_FAILED",
  InjuryReportCreated = "INJURY_REPORT_CREATED",
}

export type UserSort = {
  by?: SortByInput
  direction?: SortDirectionInput
}

export type UserToUser = {
  __typename?: "UserToUser"
  child: User
  childId: Scalars["String"]
  createdAt: Scalars["DateTime"]
  parent: User
  parentId: Scalars["String"]
  updatedAt: Scalars["DateTime"]
}

export type WorkersCompCode = {
  __typename?: "WorkersCompCode"
  active: Scalars["Boolean"]
  createdAt: Scalars["DateTime"]
  id: Scalars["ID"]
  name: Scalars["String"]
  ncciCode: Scalars["String"]
  ncciDescription: Scalars["String"]
  organizationId: Scalars["ID"]
  override: Scalars["Boolean"]
  rate: Scalars["Float"]
  updatedAt: Scalars["DateTime"]
}

export type MasqueradeCredentials = {
  __typename?: "masqueradeCredentials"
  accessToken: Scalars["String"]
}

export type WithTypename<T extends { __typename?: any }> = Partial<T> & { __typename: NonNullable<T["__typename"]> }

export type GraphCacheKeysConfig = {
  Address?: (data: WithTypename<Address>) => null | string
  AddressComponent?: (data: WithTypename<AddressComponent>) => null | string
  Asset?: (data: WithTypename<Asset>) => null | string
  AssetGroup?: (data: WithTypename<AssetGroup>) => null | string
  AssetInspectionRequirements?: (data: WithTypename<AssetInspectionRequirements>) => null | string
  AssetInspectionTemplateAssignments?: (data: WithTypename<AssetInspectionTemplateAssignments>) => null | string
  AssetInventoryRequirements?: (data: WithTypename<AssetInventoryRequirements>) => null | string
  AssetManufacturer?: (data: WithTypename<AssetManufacturer>) => null | string
  AssetPurchaseDetails?: (data: WithTypename<AssetPurchaseDetails>) => null | string
  AssetRentalAgreement?: (data: WithTypename<AssetRentalAgreement>) => null | string
  AssetRentalAgreementRate?: (data: WithTypename<AssetRentalAgreementRate>) => null | string
  AssetReport?: (data: WithTypename<AssetReport>) => null | string
  AssetReportInspectionSubmission?: (data: WithTypename<AssetReportInspectionSubmission>) => null | string
  AssetReportInventoryReport?: (data: WithTypename<AssetReportInventoryReport>) => null | string
  AssetReportTemplate?: (data: WithTypename<AssetReportTemplate>) => null | string
  AssetReportTemplateField?: (data: WithTypename<AssetReportTemplateField>) => null | string
  AssetReportTransferAssignment?: (data: WithTypename<AssetReportTransferAssignment>) => null | string
  AssetReportTransferReport?: (data: WithTypename<AssetReportTransferReport>) => null | string
  AssetStatusChange?: (data: WithTypename<AssetStatusChange>) => null | string
  AssetVendorContact?: (data: WithTypename<AssetVendorContact>) => null | string
  AssignedAsset?: (data: WithTypename<AssignedAsset>) => null | string
  BulkTimeEntryResponse?: (data: WithTypename<BulkTimeEntryResponse>) => null | string
  ChildAncestorNode?: (data: WithTypename<ChildAncestorNode>) => null | string
  ClockInData?: (data: WithTypename<ClockInData>) => null | string
  ClockOutData?: (data: WithTypename<ClockOutData>) => null | string
  Contract?: (data: WithTypename<Contract>) => null | string
  ContractDeliverableUnit?: (data: WithTypename<ContractDeliverableUnit>) => null | string
  CreateManyOfflineEventsResponsePayload?: (data: WithTypename<CreateManyOfflineEventsResponsePayload>) => null | string
  Customer?: (data: WithTypename<Customer>) => null | string
  DeliverableUnit?: (data: WithTypename<DeliverableUnit>) => null | string
  EditHistoryEvent?: (data: WithTypename<EditHistoryEvent>) => null | string
  File?: (data: WithTypename<File>) => null | string
  GeolocationCoordinates?: (data: WithTypename<GeolocationCoordinates>) => null | string
  GeolocationPosition?: (data: WithTypename<GeolocationPosition>) => null | string
  InjuryReport?: (data: WithTypename<InjuryReport>) => null | string
  InspectionReportAggregations?: (data: WithTypename<InspectionReportAggregations>) => null | string
  LastExport?: (data: WithTypename<LastExport>) => null | string
  LocationWithRadius?: (data: WithTypename<LocationWithRadius>) => null | string
  MetadataNote?: (data: WithTypename<MetadataNote>) => null | string
  OfflineEvent?: (data: WithTypename<OfflineEvent>) => null | string
  Organization?: (data: WithTypename<Organization>) => null | string
  PageInfo?: (data: WithTypename<PageInfo>) => null | string
  Project?: (data: WithTypename<Project>) => null | string
  ProjectProgressSummary?: (data: WithTypename<ProjectProgressSummary>) => null | string
  QueryActiveProjectsPaginatedConnection?: (data: WithTypename<QueryActiveProjectsPaginatedConnection>) => null | string
  QueryActiveProjectsPaginatedConnectionEdge?: (
    data: WithTypename<QueryActiveProjectsPaginatedConnectionEdge>
  ) => null | string
  QueryArchivedAssetsConnection?: (data: WithTypename<QueryArchivedAssetsConnection>) => null | string
  QueryArchivedAssetsConnectionEdge?: (data: WithTypename<QueryArchivedAssetsConnectionEdge>) => null | string
  QueryAssets_2Connection?: (data: WithTypename<QueryAssets_2Connection>) => null | string
  QueryAssets_2ConnectionEdge?: (data: WithTypename<QueryAssets_2ConnectionEdge>) => null | string
  QueryProjectsPaginatedConnection?: (data: WithTypename<QueryProjectsPaginatedConnection>) => null | string
  QueryProjectsPaginatedConnectionEdge?: (data: WithTypename<QueryProjectsPaginatedConnectionEdge>) => null | string
  QuerySubtasksPaginatedConnection?: (data: WithTypename<QuerySubtasksPaginatedConnection>) => null | string
  QuerySubtasksPaginatedConnectionEdge?: (data: WithTypename<QuerySubtasksPaginatedConnectionEdge>) => null | string
  QueryTasksPaginatedConnection?: (data: WithTypename<QueryTasksPaginatedConnection>) => null | string
  QueryTasksPaginatedConnectionEdge?: (data: WithTypename<QueryTasksPaginatedConnectionEdge>) => null | string
  QueryUsersConnection?: (data: WithTypename<QueryUsersConnection>) => null | string
  QueryUsersConnectionEdge?: (data: WithTypename<QueryUsersConnectionEdge>) => null | string
  RequestInfo?: (data: WithTypename<RequestInfo>) => null | string
  Role?: (data: WithTypename<Role>) => null | string
  ScheduleTemplate?: (data: WithTypename<ScheduleTemplate>) => null | string
  ScheduleTemplateNonWorkDays?: (data: WithTypename<ScheduleTemplateNonWorkDays>) => null | string
  ScheduleTemplateWorkDay?: (data: WithTypename<ScheduleTemplateWorkDay>) => null | string
  ScheduleTemplateWorkHours?: (data: WithTypename<ScheduleTemplateWorkHours>) => null | string
  ScheduledBreak?: (data: WithTypename<ScheduledBreak>) => null | string
  SecurityActivity?: (data: WithTypename<SecurityActivity>) => null | string
  StripeInvoice?: (data: WithTypename<StripeInvoice>) => null | string
  Task?: (data: WithTypename<Task>) => null | string
  TaskGroup?: (data: WithTypename<TaskGroup>) => null | string
  TaskListItem?: (data: WithTypename<TaskListItem>) => null | string
  TaskProgressEvent?: (data: WithTypename<TaskProgressEvent>) => null | string
  TimeEntry?: (data: WithTypename<TimeEntry>) => null | string
  TimeEntryError?: (data: WithTypename<TimeEntryError>) => null | string
  UnitGoal?: (data: WithTypename<UnitGoal>) => null | string
  UnitGoalProgress?: (data: WithTypename<UnitGoalProgress>) => null | string
  UnitOfMeasure?: (data: WithTypename<UnitOfMeasure>) => null | string
  UpdateSortOrderPayload?: (data: WithTypename<UpdateSortOrderPayload>) => null | string
  User?: (data: WithTypename<User>) => null | string
  UserAssignment?: (data: WithTypename<UserAssignment>) => null | string
  UserAssignmentBatchResponse?: (data: WithTypename<UserAssignmentBatchResponse>) => null | string
  UserDeviceSession?: (data: WithTypename<UserDeviceSession>) => null | string
  UserNotification?: (data: WithTypename<UserNotification>) => null | string
  UserToUser?: (data: WithTypename<UserToUser>) => null | string
  WorkersCompCode?: (data: WithTypename<WorkersCompCode>) => null | string
  masqueradeCredentials?: (data: WithTypename<MasqueradeCredentials>) => null | string
}

export type GraphCacheResolvers = {
  Query?: {
    activeProjectCount?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Scalars["Int"] | string>
    activeProjects?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<Project> | string>
    >
    activeProjectsPaginated?: GraphCacheResolver<
      WithTypename<Query>,
      QueryActiveProjectsPaginatedArgs,
      WithTypename<QueryActiveProjectsPaginatedConnection> | string
    >
    archivedAssetGroups?: GraphCacheResolver<
      WithTypename<Query>,
      QueryArchivedAssetGroupsArgs,
      Array<WithTypename<AssetGroup> | string>
    >
    archivedAssets?: GraphCacheResolver<
      WithTypename<Query>,
      QueryArchivedAssetsArgs,
      WithTypename<QueryArchivedAssetsConnection> | string
    >
    asset?: GraphCacheResolver<WithTypename<Query>, QueryAssetArgs, WithTypename<Asset> | string>
    assetGroups?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAssetGroupsArgs,
      Array<WithTypename<AssetGroup> | string>
    >
    assetReport?: GraphCacheResolver<WithTypename<Query>, QueryAssetReportArgs, WithTypename<AssetReport> | string>
    assetReportTemplate?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAssetReportTemplateArgs,
      WithTypename<AssetReportTemplate> | string
    >
    assetReports?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAssetReportsArgs,
      Array<WithTypename<AssetReport> | string>
    >
    assets?: GraphCacheResolver<WithTypename<Query>, QueryAssetsArgs, Array<WithTypename<Asset> | string>>
    assets_2?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAssets_2Args,
      WithTypename<QueryAssets_2Connection> | string
    >
    assignedAssets?: GraphCacheResolver<
      WithTypename<Query>,
      QueryAssignedAssetsArgs,
      Array<WithTypename<AssignedAsset> | string>
    >
    breakTasks?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<Task> | string>>
    contract?: GraphCacheResolver<WithTypename<Query>, QueryContractArgs, WithTypename<Contract> | string>
    contracts?: GraphCacheResolver<WithTypename<Query>, QueryContractsArgs, Array<WithTypename<Contract> | string>>
    currentUser?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<User> | string>
    customer?: GraphCacheResolver<WithTypename<Query>, QueryCustomerArgs, WithTypename<Customer> | string>
    customers?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<Customer> | string>>
    deliverableUnit?: GraphCacheResolver<
      WithTypename<Query>,
      QueryDeliverableUnitArgs,
      WithTypename<DeliverableUnit> | string
    >
    deliverableUnits?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<DeliverableUnit> | string>
    >
    editHistoryEvent?: GraphCacheResolver<
      WithTypename<Query>,
      QueryEditHistoryEventArgs,
      WithTypename<EditHistoryEvent> | string
    >
    editHistoryEvents?: GraphCacheResolver<
      WithTypename<Query>,
      QueryEditHistoryEventsArgs,
      Array<WithTypename<EditHistoryEvent> | string>
    >
    getJobTitles?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<Scalars["String"] | string>>
    myNotifications?: GraphCacheResolver<
      WithTypename<Query>,
      QueryMyNotificationsArgs,
      Array<WithTypename<UserNotification> | string>
    >
    myOrganization?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<Organization> | string>
    nestedAssets?: GraphCacheResolver<WithTypename<Query>, QueryNestedAssetsArgs, Array<WithTypename<Asset> | string>>
    nestedAssetsForInventory?: GraphCacheResolver<
      WithTypename<Query>,
      QueryNestedAssetsForInventoryArgs,
      Array<WithTypename<AssignedAsset> | string>
    >
    offlineEvent?: GraphCacheResolver<WithTypename<Query>, QueryOfflineEventArgs, WithTypename<OfflineEvent> | string>
    offlineEvents?: GraphCacheResolver<
      WithTypename<Query>,
      QueryOfflineEventsArgs,
      Array<WithTypename<OfflineEvent> | string>
    >
    organization?: GraphCacheResolver<WithTypename<Query>, QueryOrganizationArgs, WithTypename<Organization> | string>
    organizations?: GraphCacheResolver<
      WithTypename<Query>,
      QueryOrganizationsArgs,
      Array<WithTypename<Organization> | string>
    >
    project?: GraphCacheResolver<WithTypename<Query>, QueryProjectArgs, WithTypename<Project> | string>
    projects?: GraphCacheResolver<WithTypename<Query>, QueryProjectsArgs, Array<WithTypename<Project> | string>>
    projectsByStatus?: GraphCacheResolver<
      WithTypename<Query>,
      QueryProjectsByStatusArgs,
      Array<WithTypename<Project> | string>
    >
    projectsPaginated?: GraphCacheResolver<
      WithTypename<Query>,
      QueryProjectsPaginatedArgs,
      WithTypename<QueryProjectsPaginatedConnection> | string
    >
    reusableAssetReportTemplates?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<AssetReportTemplate> | string>
    >
    roles?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<Role> | string>>
    scheduleTemplates?: GraphCacheResolver<
      WithTypename<Query>,
      QueryScheduleTemplatesArgs,
      Array<WithTypename<ScheduleTemplate> | string>
    >
    scheduledBreaks?: GraphCacheResolver<
      WithTypename<Query>,
      QueryScheduledBreaksArgs,
      Array<WithTypename<ScheduledBreak> | string>
    >
    subtasks?: GraphCacheResolver<WithTypename<Query>, QuerySubtasksArgs, Array<WithTypename<Task> | string>>
    subtasksPaginated?: GraphCacheResolver<
      WithTypename<Query>,
      QuerySubtasksPaginatedArgs,
      WithTypename<QuerySubtasksPaginatedConnection> | string
    >
    task?: GraphCacheResolver<WithTypename<Query>, QueryTaskArgs, WithTypename<Task> | string>
    taskGroup?: GraphCacheResolver<WithTypename<Query>, QueryTaskGroupArgs, WithTypename<TaskGroup> | string>
    taskGroups?: GraphCacheResolver<WithTypename<Query>, QueryTaskGroupsArgs, Array<WithTypename<TaskGroup> | string>>
    taskList?: GraphCacheResolver<WithTypename<Query>, QueryTaskListArgs, Array<WithTypename<TaskListItem> | string>>
    taskProgressEvent?: GraphCacheResolver<
      WithTypename<Query>,
      QueryTaskProgressEventArgs,
      WithTypename<TaskProgressEvent> | string
    >
    taskProgressEvents?: GraphCacheResolver<
      WithTypename<Query>,
      QueryTaskProgressEventsArgs,
      Array<WithTypename<TaskProgressEvent> | string>
    >
    tasks?: GraphCacheResolver<WithTypename<Query>, QueryTasksArgs, Array<WithTypename<Task> | string>>
    tasksForGroupId?: GraphCacheResolver<
      WithTypename<Query>,
      QueryTasksForGroupIdArgs,
      Array<WithTypename<Task> | string>
    >
    tasksPaginated?: GraphCacheResolver<
      WithTypename<Query>,
      QueryTasksPaginatedArgs,
      WithTypename<QueryTasksPaginatedConnection> | string
    >
    unitGoal?: GraphCacheResolver<WithTypename<Query>, QueryUnitGoalArgs, WithTypename<UnitGoal> | string>
    unitGoals?: GraphCacheResolver<WithTypename<Query>, QueryUnitGoalsArgs, Array<WithTypename<UnitGoal> | string>>
    user?: GraphCacheResolver<WithTypename<Query>, QueryUserArgs, WithTypename<User> | string>
    users?: GraphCacheResolver<WithTypename<Query>, QueryUsersArgs, WithTypename<QueryUsersConnection> | string>
    usersForSummarySection?: GraphCacheResolver<
      WithTypename<Query>,
      QueryUsersForSummarySectionArgs,
      Array<WithTypename<User> | string>
    >
    usersList?: GraphCacheResolver<WithTypename<Query>, QueryUsersListArgs, Array<WithTypename<User> | string>>
    workersCompCodes?: GraphCacheResolver<
      WithTypename<Query>,
      Record<string, never>,
      Array<WithTypename<WorkersCompCode> | string>
    >
  }
  Address?: {
    addressComponents?: GraphCacheResolver<
      WithTypename<Address>,
      Record<string, never>,
      Array<WithTypename<AddressComponent> | string>
    >
    formattedAddress?: GraphCacheResolver<WithTypename<Address>, Record<string, never>, Scalars["String"] | string>
  }
  AddressComponent?: {
    longName?: GraphCacheResolver<WithTypename<AddressComponent>, Record<string, never>, Scalars["String"] | string>
    shortName?: GraphCacheResolver<WithTypename<AddressComponent>, Record<string, never>, Scalars["String"] | string>
    types?: GraphCacheResolver<WithTypename<AddressComponent>, Record<string, never>, Array<Scalars["String"] | string>>
  }
  Asset?: {
    active?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["Boolean"] | string>
    activeChangedAt?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["DateTime"] | string>
    assetChildCount?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["Int"] | string>
    assetGroupId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    assetGroupMemberCount?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["Int"] | string>
    assignableId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    assignableType?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, AssetAssignableType | string>
    assignedAsset?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<Asset> | string>
    assignedAssetId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    assignedTask?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<Task> | string>
    assignedTaskId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    assignedUser?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<User> | string>
    assignedUserId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    assignmentsCount?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["Int"] | string>
    category?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, AssetCategory | string>
    childAssetGroups?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Array<WithTypename<AssetGroup> | string>
    >
    childAssets?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Array<WithTypename<Asset> | string>>
    companyAssetNumber?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    compositeKey?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    createdAt?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["DateTime"] | string>
    deletedAt?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["DateTime"] | string>
    description?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    files?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Array<WithTypename<File> | string>>
    groupQuantity?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["Int"] | string>
    id?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    imageUrl?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    inferredOwner?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<User> | string>
    inferredProjectId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    inspectionTemplatesAssignments?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Array<WithTypename<AssetInspectionTemplateAssignments> | string>
    >
    inventoryRequirements?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      WithTypename<AssetInventoryRequirements> | string
    >
    isArchived?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["Boolean"] | string>
    isAssetGroup?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["Boolean"] | string>
    lastInventoriedAt?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["DateTime"] | string>
    manufacturer?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      WithTypename<AssetManufacturer> | string
    >
    name?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    organization?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<Organization> | string>
    organizationId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    ownershipType?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    photo?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<File> | string>
    photoId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["String"] | string>
    purchaseDetails?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      WithTypename<AssetPurchaseDetails> | string
    >
    rentalAgreement?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      WithTypename<AssetRentalAgreement> | string
    >
    reportCount?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["Int"] | string>
    reportTemplates?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      Array<WithTypename<AssetReportTemplate> | string>
    >
    reports?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Array<WithTypename<AssetReport> | string>>
    status?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, AssetStatus | string>
    updatedAt?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars["DateTime"] | string>
    vendorContact?: GraphCacheResolver<
      WithTypename<Asset>,
      Record<string, never>,
      WithTypename<AssetVendorContact> | string
    >
  }
  AssetGroup?: {
    assetGroupId?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars["String"] | string>
    assignableId?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars["String"] | string>
    assignableType?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, AssetAssignableType | string>
    assignedAsset?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, WithTypename<Asset> | string>
    assignedAssetId?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars["String"] | string>
    assignedTask?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, WithTypename<Task> | string>
    assignedTaskId?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars["String"] | string>
    assignedUser?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, WithTypename<User> | string>
    assignedUserId?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars["String"] | string>
    compositeKey?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars["String"] | string>
    count?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars["Int"] | string>
    groupParent?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, WithTypename<Asset> | string>
    status?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, AssetStatus | string>
  }
  AssetInspectionRequirements?: {
    intervalInSeconds?: GraphCacheResolver<
      WithTypename<AssetInspectionRequirements>,
      Record<string, never>,
      Scalars["Int"] | string
    >
    startDate?: GraphCacheResolver<
      WithTypename<AssetInspectionRequirements>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
  }
  AssetInspectionTemplateAssignments?: {
    asset?: GraphCacheResolver<
      WithTypename<AssetInspectionTemplateAssignments>,
      Record<string, never>,
      WithTypename<Asset> | string
    >
    assetId?: GraphCacheResolver<
      WithTypename<AssetInspectionTemplateAssignments>,
      Record<string, never>,
      Scalars["String"] | string
    >
    assetReportTemplateId?: GraphCacheResolver<
      WithTypename<AssetInspectionTemplateAssignments>,
      Record<string, never>,
      Scalars["String"] | string
    >
    id?: GraphCacheResolver<
      WithTypename<AssetInspectionTemplateAssignments>,
      Record<string, never>,
      Scalars["ID"] | string
    >
    inspectionTemplate?: GraphCacheResolver<
      WithTypename<AssetInspectionTemplateAssignments>,
      Record<string, never>,
      WithTypename<AssetReportTemplate> | string
    >
    intervalInSeconds?: GraphCacheResolver<
      WithTypename<AssetInspectionTemplateAssignments>,
      Record<string, never>,
      Scalars["Int"] | string
    >
    intervalRequired?: GraphCacheResolver<
      WithTypename<AssetInspectionTemplateAssignments>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
    lastReportedAt?: GraphCacheResolver<
      WithTypename<AssetInspectionTemplateAssignments>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
    organizationId?: GraphCacheResolver<
      WithTypename<AssetInspectionTemplateAssignments>,
      Record<string, never>,
      Scalars["String"] | string
    >
    startDate?: GraphCacheResolver<
      WithTypename<AssetInspectionTemplateAssignments>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
  }
  AssetInventoryRequirements?: {
    intervalInSeconds?: GraphCacheResolver<
      WithTypename<AssetInventoryRequirements>,
      Record<string, never>,
      Scalars["Int"] | string
    >
    photoRequired?: GraphCacheResolver<
      WithTypename<AssetInventoryRequirements>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
  }
  AssetManufacturer?: {
    id?: GraphCacheResolver<WithTypename<AssetManufacturer>, Record<string, never>, Scalars["String"] | string>
    make?: GraphCacheResolver<WithTypename<AssetManufacturer>, Record<string, never>, Scalars["String"] | string>
    model?: GraphCacheResolver<WithTypename<AssetManufacturer>, Record<string, never>, Scalars["String"] | string>
    name?: GraphCacheResolver<WithTypename<AssetManufacturer>, Record<string, never>, Scalars["String"] | string>
    year?: GraphCacheResolver<WithTypename<AssetManufacturer>, Record<string, never>, Scalars["Int"] | string>
  }
  AssetPurchaseDetails?: {
    date?: GraphCacheResolver<WithTypename<AssetPurchaseDetails>, Record<string, never>, Scalars["String"] | string>
    price?: GraphCacheResolver<WithTypename<AssetPurchaseDetails>, Record<string, never>, Scalars["Float"] | string>
  }
  AssetRentalAgreement?: {
    endOn?: GraphCacheResolver<WithTypename<AssetRentalAgreement>, Record<string, never>, Scalars["String"] | string>
    rate?: GraphCacheResolver<
      WithTypename<AssetRentalAgreement>,
      Record<string, never>,
      WithTypename<AssetRentalAgreementRate> | string
    >
    startOn?: GraphCacheResolver<WithTypename<AssetRentalAgreement>, Record<string, never>, Scalars["String"] | string>
  }
  AssetRentalAgreementRate?: {
    daily?: GraphCacheResolver<WithTypename<AssetRentalAgreementRate>, Record<string, never>, Scalars["Float"] | string>
    monthly?: GraphCacheResolver<
      WithTypename<AssetRentalAgreementRate>,
      Record<string, never>,
      Scalars["Float"] | string
    >
    weekly?: GraphCacheResolver<
      WithTypename<AssetRentalAgreementRate>,
      Record<string, never>,
      Scalars["Float"] | string
    >
  }
  AssetReport?: {
    asset?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, WithTypename<Asset> | string>
    assetId?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars["String"] | string>
    createdAt?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars["DateTime"] | string>
    id?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars["String"] | string>
    inspectionReport?: GraphCacheResolver<
      WithTypename<AssetReport>,
      Record<string, never>,
      Array<WithTypename<AssetReportInspectionSubmission> | string>
    >
    inspectionReportAggregations?: GraphCacheResolver<
      WithTypename<AssetReport>,
      Record<string, never>,
      WithTypename<InspectionReportAggregations> | string
    >
    inventoryReport?: GraphCacheResolver<
      WithTypename<AssetReport>,
      Record<string, never>,
      WithTypename<AssetReportInventoryReport> | string
    >
    notes?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars["String"] | string>
    organization?: GraphCacheResolver<
      WithTypename<AssetReport>,
      Record<string, never>,
      WithTypename<Organization> | string
    >
    organizationId?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars["String"] | string>
    quantityReported?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars["Int"] | string>
    reporter?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, WithTypename<User> | string>
    reporterId?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars["String"] | string>
    statusChange?: GraphCacheResolver<
      WithTypename<AssetReport>,
      Record<string, never>,
      WithTypename<AssetStatusChange> | string
    >
    transferReport?: GraphCacheResolver<
      WithTypename<AssetReport>,
      Record<string, never>,
      WithTypename<AssetReportTransferReport> | string
    >
    type?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, AssetReportType | string>
    updatedAt?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars["DateTime"] | string>
  }
  AssetReportInspectionSubmission?: {
    failedStatus?: GraphCacheResolver<
      WithTypename<AssetReportInspectionSubmission>,
      Record<string, never>,
      Scalars["String"] | string
    >
    fileIds?: GraphCacheResolver<
      WithTypename<AssetReportInspectionSubmission>,
      Record<string, never>,
      Array<Scalars["String"] | string>
    >
    id?: GraphCacheResolver<
      WithTypename<AssetReportInspectionSubmission>,
      Record<string, never>,
      Scalars["String"] | string
    >
    input?: GraphCacheResolver<
      WithTypename<AssetReportInspectionSubmission>,
      Record<string, never>,
      Scalars["String"] | string
    >
    label?: GraphCacheResolver<
      WithTypename<AssetReportInspectionSubmission>,
      Record<string, never>,
      Scalars["String"] | string
    >
    photoLabel?: GraphCacheResolver<
      WithTypename<AssetReportInspectionSubmission>,
      Record<string, never>,
      Scalars["String"] | string
    >
    photoRequired?: GraphCacheResolver<
      WithTypename<AssetReportInspectionSubmission>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
    photoUrls?: GraphCacheResolver<
      WithTypename<AssetReportInspectionSubmission>,
      Record<string, never>,
      Array<Scalars["String"] | string>
    >
    required?: GraphCacheResolver<
      WithTypename<AssetReportInspectionSubmission>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
    rule?: GraphCacheResolver<
      WithTypename<AssetReportInspectionSubmission>,
      Record<string, never>,
      Scalars["String"] | string
    >
    type?: GraphCacheResolver<
      WithTypename<AssetReportInspectionSubmission>,
      Record<string, never>,
      Scalars["String"] | string
    >
  }
  AssetReportInventoryReport?: {
    fileIds?: GraphCacheResolver<
      WithTypename<AssetReportInventoryReport>,
      Record<string, never>,
      Array<Scalars["String"] | string>
    >
    note?: GraphCacheResolver<
      WithTypename<AssetReportInventoryReport>,
      Record<string, never>,
      Scalars["String"] | string
    >
    photoUrls?: GraphCacheResolver<
      WithTypename<AssetReportInventoryReport>,
      Record<string, never>,
      Array<Scalars["String"] | string>
    >
  }
  AssetReportTemplate?: {
    assets?: GraphCacheResolver<
      WithTypename<AssetReportTemplate>,
      Record<string, never>,
      Array<WithTypename<Asset> | string>
    >
    assetsCount?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars["Int"] | string>
    createdAt?: GraphCacheResolver<
      WithTypename<AssetReportTemplate>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
    deletedAt?: GraphCacheResolver<
      WithTypename<AssetReportTemplate>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
    fields?: GraphCacheResolver<
      WithTypename<AssetReportTemplate>,
      Record<string, never>,
      Array<WithTypename<AssetReportTemplateField> | string>
    >
    id?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars["String"] | string>
    name?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars["String"] | string>
    organization?: GraphCacheResolver<
      WithTypename<AssetReportTemplate>,
      Record<string, never>,
      WithTypename<Organization> | string
    >
    organizationId?: GraphCacheResolver<
      WithTypename<AssetReportTemplate>,
      Record<string, never>,
      Scalars["String"] | string
    >
    reusable?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars["Boolean"] | string>
    universal?: GraphCacheResolver<
      WithTypename<AssetReportTemplate>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
    updatedAt?: GraphCacheResolver<
      WithTypename<AssetReportTemplate>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
  }
  AssetReportTemplateField?: {
    failedStatus?: GraphCacheResolver<
      WithTypename<AssetReportTemplateField>,
      Record<string, never>,
      Scalars["String"] | string
    >
    id?: GraphCacheResolver<WithTypename<AssetReportTemplateField>, Record<string, never>, Scalars["String"] | string>
    label?: GraphCacheResolver<
      WithTypename<AssetReportTemplateField>,
      Record<string, never>,
      Scalars["String"] | string
    >
    photoLabel?: GraphCacheResolver<
      WithTypename<AssetReportTemplateField>,
      Record<string, never>,
      Scalars["String"] | string
    >
    photoRequired?: GraphCacheResolver<
      WithTypename<AssetReportTemplateField>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
    required?: GraphCacheResolver<
      WithTypename<AssetReportTemplateField>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
    rule?: GraphCacheResolver<WithTypename<AssetReportTemplateField>, Record<string, never>, Scalars["String"] | string>
    type?: GraphCacheResolver<WithTypename<AssetReportTemplateField>, Record<string, never>, Scalars["String"] | string>
  }
  AssetReportTransferAssignment?: {
    asset?: GraphCacheResolver<
      WithTypename<AssetReportTransferAssignment>,
      Record<string, never>,
      WithTypename<Asset> | string
    >
    assignableId?: GraphCacheResolver<
      WithTypename<AssetReportTransferAssignment>,
      Record<string, never>,
      Scalars["String"] | string
    >
    assignableType?: GraphCacheResolver<
      WithTypename<AssetReportTransferAssignment>,
      Record<string, never>,
      AssetAssignableType | string
    >
    task?: GraphCacheResolver<
      WithTypename<AssetReportTransferAssignment>,
      Record<string, never>,
      WithTypename<Task> | string
    >
    user?: GraphCacheResolver<
      WithTypename<AssetReportTransferAssignment>,
      Record<string, never>,
      WithTypename<User> | string
    >
  }
  AssetReportTransferReport?: {
    from?: GraphCacheResolver<
      WithTypename<AssetReportTransferReport>,
      Record<string, never>,
      WithTypename<AssetReportTransferAssignment> | string
    >
    to?: GraphCacheResolver<
      WithTypename<AssetReportTransferReport>,
      Record<string, never>,
      WithTypename<AssetReportTransferAssignment> | string
    >
  }
  AssetStatusChange?: {
    active?: GraphCacheResolver<WithTypename<AssetStatusChange>, Record<string, never>, Scalars["Boolean"] | string>
    missingCount?: GraphCacheResolver<WithTypename<AssetStatusChange>, Record<string, never>, Scalars["Int"] | string>
    status?: GraphCacheResolver<WithTypename<AssetStatusChange>, Record<string, never>, AssetStatus | string>
  }
  AssetVendorContact?: {
    email?: GraphCacheResolver<WithTypename<AssetVendorContact>, Record<string, never>, Scalars["String"] | string>
    name?: GraphCacheResolver<WithTypename<AssetVendorContact>, Record<string, never>, Scalars["String"] | string>
    phone?: GraphCacheResolver<WithTypename<AssetVendorContact>, Record<string, never>, Scalars["String"] | string>
  }
  AssignedAsset?: {
    active?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["Boolean"] | string>
    assetChildCount?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["Int"] | string>
    assetGroupId?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["String"] | string>
    assignableId?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["String"] | string>
    assignableType?: GraphCacheResolver<
      WithTypename<AssignedAsset>,
      Record<string, never>,
      AssetAssignableType | string
    >
    assignedAsset?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, WithTypename<Asset> | string>
    assignedAssetId?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["String"] | string>
    assignedTask?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, WithTypename<Task> | string>
    assignedTaskId?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["String"] | string>
    assignedUser?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, WithTypename<User> | string>
    assignedUserId?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["String"] | string>
    assignmentsCount?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["Int"] | string>
    category?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, AssetCategory | string>
    companyAssetNumber?: GraphCacheResolver<
      WithTypename<AssignedAsset>,
      Record<string, never>,
      Scalars["String"] | string
    >
    compositeKey?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["String"] | string>
    deletedAt?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["DateTime"] | string>
    depth?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["Int"] | string>
    groupParentAsset?: GraphCacheResolver<
      WithTypename<AssignedAsset>,
      Record<string, never>,
      WithTypename<Asset> | string
    >
    id?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["String"] | string>
    imageUrl?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["String"] | string>
    inferredOwner?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, WithTypename<User> | string>
    inventoryRequirements?: GraphCacheResolver<
      WithTypename<AssignedAsset>,
      Record<string, never>,
      WithTypename<AssetInventoryRequirements> | string
    >
    isAssetGroup?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["Boolean"] | string>
    lastInventoriedAt?: GraphCacheResolver<
      WithTypename<AssignedAsset>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
    name?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["String"] | string>
    ownershipType?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars["String"] | string>
    quantizedAssetIds?: GraphCacheResolver<
      WithTypename<AssignedAsset>,
      Record<string, never>,
      Array<Scalars["String"] | string>
    >
    status?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, AssetStatus | string>
  }
  BulkTimeEntryResponse?: {
    errors?: GraphCacheResolver<
      WithTypename<BulkTimeEntryResponse>,
      Record<string, never>,
      Array<WithTypename<TimeEntryError> | string>
    >
    success?: GraphCacheResolver<WithTypename<BulkTimeEntryResponse>, Record<string, never>, Scalars["Int"] | string>
  }
  ChildAncestorNode?: {
    depth?: GraphCacheResolver<WithTypename<ChildAncestorNode>, Record<string, never>, Scalars["Int"] | string>
    userId?: GraphCacheResolver<WithTypename<ChildAncestorNode>, Record<string, never>, Scalars["String"] | string>
  }
  ClockInData?: {
    imagePath?: GraphCacheResolver<WithTypename<ClockInData>, Record<string, never>, Scalars["String"] | string>
    task?: GraphCacheResolver<WithTypename<ClockInData>, Record<string, never>, WithTypename<Task> | string>
    taskId?: GraphCacheResolver<WithTypename<ClockInData>, Record<string, never>, Scalars["String"] | string>
    user?: GraphCacheResolver<WithTypename<ClockInData>, Record<string, never>, WithTypename<User> | string>
    userId?: GraphCacheResolver<WithTypename<ClockInData>, Record<string, never>, Scalars["String"] | string>
  }
  ClockOutData?: {
    imagePath?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, Scalars["String"] | string>
    signaturePath?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, Scalars["String"] | string>
    task?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, WithTypename<Task> | string>
    taskId?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, Scalars["String"] | string>
    user?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, WithTypename<User> | string>
    userId?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, Scalars["String"] | string>
  }
  Contract?: {
    createdAt?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars["DateTime"] | string>
    customerId?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars["String"] | string>
    deliverableUnits?: GraphCacheResolver<
      WithTypename<Contract>,
      Record<string, never>,
      Array<WithTypename<ContractDeliverableUnit> | string>
    >
    description?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars["String"] | string>
    id?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars["String"] | string>
    name?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars["String"] | string>
    number?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars["String"] | string>
    organization?: GraphCacheResolver<
      WithTypename<Contract>,
      Record<string, never>,
      WithTypename<Organization> | string
    >
    organizationId?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars["String"] | string>
    updatedAt?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars["DateTime"] | string>
  }
  ContractDeliverableUnit?: {
    contract?: GraphCacheResolver<
      WithTypename<ContractDeliverableUnit>,
      Record<string, never>,
      WithTypename<Contract> | string
    >
    contractId?: GraphCacheResolver<
      WithTypename<ContractDeliverableUnit>,
      Record<string, never>,
      Scalars["String"] | string
    >
    createdAt?: GraphCacheResolver<
      WithTypename<ContractDeliverableUnit>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
    customerDescription?: GraphCacheResolver<
      WithTypename<ContractDeliverableUnit>,
      Record<string, never>,
      Scalars["String"] | string
    >
    deliverableUnit?: GraphCacheResolver<
      WithTypename<ContractDeliverableUnit>,
      Record<string, never>,
      WithTypename<DeliverableUnit> | string
    >
    deliverableUnitId?: GraphCacheResolver<
      WithTypename<ContractDeliverableUnit>,
      Record<string, never>,
      Scalars["String"] | string
    >
    id?: GraphCacheResolver<WithTypename<ContractDeliverableUnit>, Record<string, never>, Scalars["String"] | string>
    rate?: GraphCacheResolver<WithTypename<ContractDeliverableUnit>, Record<string, never>, Scalars["Float"] | string>
    updatedAt?: GraphCacheResolver<
      WithTypename<ContractDeliverableUnit>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
  }
  CreateManyOfflineEventsResponsePayload?: {
    createdEvents?: GraphCacheResolver<
      WithTypename<CreateManyOfflineEventsResponsePayload>,
      Record<string, never>,
      Array<WithTypename<OfflineEvent> | string>
    >
    failureCount?: GraphCacheResolver<
      WithTypename<CreateManyOfflineEventsResponsePayload>,
      Record<string, never>,
      Scalars["Int"] | string
    >
    successCount?: GraphCacheResolver<
      WithTypename<CreateManyOfflineEventsResponsePayload>,
      Record<string, never>,
      Scalars["Int"] | string
    >
  }
  Customer?: {
    activeProjectCount?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["Int"] | string>
    addressLine1?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    addressLine2?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    city?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    contracts?: GraphCacheResolver<
      WithTypename<Customer>,
      Record<string, never>,
      Array<WithTypename<Contract> | string>
    >
    country?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    createdAt?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["DateTime"] | string>
    customerId?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    email?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    id?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    image?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    imageUrl?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    name?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    notes?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    phoneNumber?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    phoneNumberExt?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    state?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
    updatedAt?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["DateTime"] | string>
    zip?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars["String"] | string>
  }
  DeliverableUnit?: {
    archivedAt?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars["DateTime"] | string>
    color?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars["String"] | string>
    createdAt?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars["DateTime"] | string>
    deletedAt?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars["DateTime"] | string>
    description?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars["String"] | string>
    id?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars["ID"] | string>
    referenceNumber?: GraphCacheResolver<
      WithTypename<DeliverableUnit>,
      Record<string, never>,
      Scalars["String"] | string
    >
    unitOfMeasure?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars["String"] | string>
  }
  EditHistoryEvent?: {
    createdAt?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars["DateTime"] | string>
    data?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars["JSON"] | string>
    id?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars["ID"] | string>
    modelId?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars["String"] | string>
    modelType?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars["String"] | string>
    organizationId?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars["ID"] | string>
    project?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, WithTypename<Project> | string>
    projectId?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars["String"] | string>
    task?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, WithTypename<Task> | string>
    taskId?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars["String"] | string>
    type?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars["String"] | string>
    updatedAt?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars["DateTime"] | string>
    user?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, WithTypename<User> | string>
    userId?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars["String"] | string>
  }
  File?: {
    assetId?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["String"] | string>
    contentType?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["String"] | string>
    createdAt?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["DateTime"] | string>
    deletedAt?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["DateTime"] | string>
    documentUrl?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["String"] | string>
    expiresAt?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["DateTime"] | string>
    fileName?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["String"] | string>
    id?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["String"] | string>
    key?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["String"] | string>
    objectVersion?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["Int"] | string>
    organizationId?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["String"] | string>
    projectId?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["String"] | string>
    status?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["String"] | string>
    userId?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars["String"] | string>
  }
  GeolocationCoordinates?: {
    accuracy?: GraphCacheResolver<
      WithTypename<GeolocationCoordinates>,
      Record<string, never>,
      Scalars["Float"] | string
    >
    altitude?: GraphCacheResolver<
      WithTypename<GeolocationCoordinates>,
      Record<string, never>,
      Scalars["Float"] | string
    >
    altitudeAccuracy?: GraphCacheResolver<
      WithTypename<GeolocationCoordinates>,
      Record<string, never>,
      Scalars["Float"] | string
    >
    heading?: GraphCacheResolver<WithTypename<GeolocationCoordinates>, Record<string, never>, Scalars["Float"] | string>
    latitude?: GraphCacheResolver<
      WithTypename<GeolocationCoordinates>,
      Record<string, never>,
      Scalars["Float"] | string
    >
    longitude?: GraphCacheResolver<
      WithTypename<GeolocationCoordinates>,
      Record<string, never>,
      Scalars["Float"] | string
    >
    speed?: GraphCacheResolver<WithTypename<GeolocationCoordinates>, Record<string, never>, Scalars["Float"] | string>
  }
  GeolocationPosition?: {
    coords?: GraphCacheResolver<
      WithTypename<GeolocationPosition>,
      Record<string, never>,
      WithTypename<GeolocationCoordinates> | string
    >
    timestamp?: GraphCacheResolver<
      WithTypename<GeolocationPosition>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
  }
  InjuryReport?: {
    createdAt?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars["DateTime"] | string>
    id?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars["ID"] | string>
    notes?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars["String"] | string>
    reporter?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, WithTypename<User> | string>
    reporterId?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars["String"] | string>
    updatedAt?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars["DateTime"] | string>
    user?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, WithTypename<User> | string>
    userId?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars["String"] | string>
  }
  InspectionReportAggregations?: {
    falseCount?: GraphCacheResolver<
      WithTypename<InspectionReportAggregations>,
      Record<string, never>,
      Scalars["Int"] | string
    >
    trueCount?: GraphCacheResolver<
      WithTypename<InspectionReportAggregations>,
      Record<string, never>,
      Scalars["Int"] | string
    >
  }
  LastExport?: {
    exportedAt?: GraphCacheResolver<WithTypename<LastExport>, Record<string, never>, Scalars["String"] | string>
    userId?: GraphCacheResolver<WithTypename<LastExport>, Record<string, never>, Scalars["String"] | string>
    userName?: GraphCacheResolver<WithTypename<LastExport>, Record<string, never>, Scalars["String"] | string>
  }
  LocationWithRadius?: {
    lat?: GraphCacheResolver<WithTypename<LocationWithRadius>, Record<string, never>, Scalars["Float"] | string>
    latitude?: GraphCacheResolver<WithTypename<LocationWithRadius>, Record<string, never>, Scalars["Float"] | string>
    lng?: GraphCacheResolver<WithTypename<LocationWithRadius>, Record<string, never>, Scalars["Float"] | string>
    longitude?: GraphCacheResolver<WithTypename<LocationWithRadius>, Record<string, never>, Scalars["Float"] | string>
    radius?: GraphCacheResolver<WithTypename<LocationWithRadius>, Record<string, never>, Scalars["Float"] | string>
    radiusInMeters?: GraphCacheResolver<
      WithTypename<LocationWithRadius>,
      Record<string, never>,
      Scalars["Float"] | string
    >
  }
  MetadataNote?: {
    content?: GraphCacheResolver<WithTypename<MetadataNote>, Record<string, never>, Scalars["String"] | string>
    highlight?: GraphCacheResolver<WithTypename<MetadataNote>, Record<string, never>, Scalars["Boolean"] | string>
    label?: GraphCacheResolver<WithTypename<MetadataNote>, Record<string, never>, Scalars["String"] | string>
  }
  OfflineEvent?: {
    assetReportData?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars["JSON"] | string>
    clientCanceledAt?: GraphCacheResolver<
      WithTypename<OfflineEvent>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
    clientCreatedAt?: GraphCacheResolver<
      WithTypename<OfflineEvent>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
    clientUpdatedAt?: GraphCacheResolver<
      WithTypename<OfflineEvent>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
    clockInData?: GraphCacheResolver<
      WithTypename<OfflineEvent>,
      Record<string, never>,
      WithTypename<ClockInData> | string
    >
    clockOutData?: GraphCacheResolver<
      WithTypename<OfflineEvent>,
      Record<string, never>,
      WithTypename<ClockOutData> | string
    >
    createdAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars["DateTime"] | string>
    deviceInformation?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars["JSON"] | string>
    id?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars["ID"] | string>
    issues?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars["String"] | string>
    location?: GraphCacheResolver<
      WithTypename<OfflineEvent>,
      Record<string, never>,
      WithTypename<GeolocationPosition> | string
    >
    mobileApplicationIsVerified?: GraphCacheResolver<
      WithTypename<OfflineEvent>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
    organizationId?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars["ID"] | string>
    processedAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars["DateTime"] | string>
    receivedAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars["DateTime"] | string>
    reconciledAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars["DateTime"] | string>
    reporterId?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars["ID"] | string>
    requestInformation?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars["JSON"] | string>
    taskProgressData?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars["JSON"] | string>
    type?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, OfflineEventType | string>
    updatedAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars["DateTime"] | string>
  }
  Organization?: {
    activeProjectsCount?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars["Int"] | string>
    activeUsersForDateRangeCount?: GraphCacheResolver<
      WithTypename<Organization>,
      OrganizationActiveUsersForDateRangeCountArgs,
      Scalars["Int"] | string
    >
    admins?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Array<WithTypename<User> | string>>
    archived?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars["Boolean"] | string>
    billingPastDueAt?: GraphCacheResolver<
      WithTypename<Organization>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
    billingStatus?: GraphCacheResolver<
      WithTypename<Organization>,
      Record<string, never>,
      OrganizationBillingStatus | string
    >
    clockedInUserCount?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars["Int"] | string>
    dateCreated?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars["DateTime"] | string>
    featureToggleSegments?: GraphCacheResolver<
      WithTypename<Organization>,
      Record<string, never>,
      Array<Scalars["String"] | string>
    >
    id?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars["String"] | string>
    image?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars["String"] | string>
    imageUrl?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars["String"] | string>
    lastStripeInvoice?: GraphCacheResolver<
      WithTypename<Organization>,
      Record<string, never>,
      WithTypename<StripeInvoice> | string
    >
    name?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars["String"] | string>
    notificationSettings?: GraphCacheResolver<
      WithTypename<Organization>,
      Record<string, never>,
      Scalars["JSON"] | string
    >
    overtimeType?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, OvertimeTypeEnum | string>
    projectsCount?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars["Int"] | string>
    requireTimeEntryEvidence?: GraphCacheResolver<
      WithTypename<Organization>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
    roles?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Array<WithTypename<Role> | string>>
    scheduledBreaks?: GraphCacheResolver<
      WithTypename<Organization>,
      Record<string, never>,
      Array<WithTypename<ScheduledBreak> | string>
    >
    secondsClockedForCurrentWeek?: GraphCacheResolver<
      WithTypename<Organization>,
      Record<string, never>,
      Scalars["Int"] | string
    >
    stripeCustomerId?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars["String"] | string>
    stripeInvoices?: GraphCacheResolver<
      WithTypename<Organization>,
      Record<string, never>,
      Array<WithTypename<StripeInvoice> | string>
    >
    timezone?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars["String"] | string>
    unassignedProjectId?: GraphCacheResolver<
      WithTypename<Organization>,
      Record<string, never>,
      Scalars["String"] | string
    >
    unitsOfMeasure?: GraphCacheResolver<
      WithTypename<Organization>,
      Record<string, never>,
      Array<WithTypename<UnitOfMeasure> | string>
    >
    userCount?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars["Int"] | string>
  }
  PageInfo?: {
    endCursor?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars["String"] | string>
    hasNextPage?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars["Boolean"] | string>
    hasPreviousPage?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars["Boolean"] | string>
    startCursor?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars["String"] | string>
  }
  Project?: {
    activeTasksCount?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["Int"] | string>
    address?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<Address> | string>
    assets?: GraphCacheResolver<WithTypename<Project>, ProjectAssetsArgs, Array<WithTypename<Asset> | string>>
    assetsCount?: GraphCacheResolver<WithTypename<Project>, ProjectAssetsCountArgs, Scalars["Int"] | string>
    assignees?: GraphCacheResolver<
      WithTypename<Project>,
      Record<string, never>,
      Array<WithTypename<UserAssignment> | string>
    >
    code?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["String"] | string>
    customer?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<Customer> | string>
    customerId?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["String"] | string>
    dateCreated?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["DateTime"] | string>
    defaultTask?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<Task> | string>
    deliveredPrimaryGoalUnits?: GraphCacheResolver<
      WithTypename<Project>,
      ProjectDeliveredPrimaryGoalUnitsArgs,
      Scalars["Float"] | string
    >
    description?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["String"] | string>
    documents?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<File> | string>>
    editHistoryEvents?: GraphCacheResolver<
      WithTypename<Project>,
      Record<string, never>,
      Array<WithTypename<EditHistoryEvent> | string>
    >
    editHistoryEventsCount?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["Float"] | string>
    endDate?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["DateTime"] | string>
    estimatedHours?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["Float"] | string>
    googlePlace?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["JSON"] | string>
    hasReportableUnit?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["Boolean"] | string>
    hoursDataByDateRangeType?: GraphCacheResolver<
      WithTypename<Project>,
      ProjectHoursDataByDateRangeTypeArgs,
      Scalars["JSON"] | string
    >
    id?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["String"] | string>
    imageUrl?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["String"] | string>
    injuryReports?: GraphCacheResolver<
      WithTypename<Project>,
      Record<string, never>,
      Array<WithTypename<InjuryReport> | string>
    >
    isArchived?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["DateTime"] | string>
    isComplete?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["DateTime"] | string>
    isDefault?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["Boolean"] | string>
    lastExport?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["JSON"] | string>
    location?: GraphCacheResolver<
      WithTypename<Project>,
      Record<string, never>,
      WithTypename<LocationWithRadius> | string
    >
    locationNotes?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["String"] | string>
    locationUntyped?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["JSON"] | string>
    metadata?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["JSON"] | string>
    metadataNotes?: GraphCacheResolver<
      WithTypename<Project>,
      Record<string, never>,
      Array<WithTypename<MetadataNote> | string>
    >
    name?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["String"] | string>
    organization?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<Organization> | string>
    organizationId?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["String"] | string>
    primaryGoalTargetQuantity?: GraphCacheResolver<
      WithTypename<Project>,
      Record<string, never>,
      Scalars["Float"] | string
    >
    productionRate?: GraphCacheResolver<WithTypename<Project>, ProjectProductionRateArgs, Scalars["Float"] | string>
    progressSummary?: GraphCacheResolver<
      WithTypename<Project>,
      Record<string, never>,
      WithTypename<ProjectProgressSummary> | string
    >
    scheduleTemplate?: GraphCacheResolver<
      WithTypename<Project>,
      Record<string, never>,
      WithTypename<ScheduleTemplate> | string
    >
    scheduledBreaks?: GraphCacheResolver<
      WithTypename<Project>,
      Record<string, never>,
      Array<WithTypename<ScheduledBreak> | string>
    >
    startDate?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["DateTime"] | string>
    taskGroups?: GraphCacheResolver<
      WithTypename<Project>,
      Record<string, never>,
      Array<WithTypename<TaskGroup> | string>
    >
    taskProgressEventsWithUnitReports?: GraphCacheResolver<
      WithTypename<Project>,
      ProjectTaskProgressEventsWithUnitReportsArgs,
      Array<WithTypename<TaskProgressEvent> | string>
    >
    tasks?: GraphCacheResolver<WithTypename<Project>, ProjectTasksArgs, Array<WithTypename<Task> | string>>
    tasksCount?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["Int"] | string>
    timeEntries?: GraphCacheResolver<
      WithTypename<Project>,
      ProjectTimeEntriesArgs,
      Array<WithTypename<TimeEntry> | string>
    >
    timeEntriesSumDurationInSeconds?: GraphCacheResolver<
      WithTypename<Project>,
      ProjectTimeEntriesSumDurationInSecondsArgs,
      Scalars["Float"] | string
    >
    unassignedTask?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<Task> | string>
    unassignedTaskId?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["String"] | string>
    unitGoals?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<UnitGoal> | string>>
    userCount?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["Int"] | string>
    users?: GraphCacheResolver<WithTypename<Project>, ProjectUsersArgs, Array<WithTypename<User> | string>>
    workersCompCode?: GraphCacheResolver<
      WithTypename<Project>,
      Record<string, never>,
      WithTypename<WorkersCompCode> | string
    >
    workersCompCodeId?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars["String"] | string>
  }
  ProjectProgressSummary?: {
    completedHours?: GraphCacheResolver<
      WithTypename<ProjectProgressSummary>,
      Record<string, never>,
      Scalars["Float"] | string
    >
    completedUnits?: GraphCacheResolver<
      WithTypename<ProjectProgressSummary>,
      Record<string, never>,
      Scalars["Float"] | string
    >
    completionPercentage?: GraphCacheResolver<
      WithTypename<ProjectProgressSummary>,
      Record<string, never>,
      Scalars["Float"] | string
    >
    noHours?: GraphCacheResolver<
      WithTypename<ProjectProgressSummary>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
    targetHours?: GraphCacheResolver<
      WithTypename<ProjectProgressSummary>,
      Record<string, never>,
      Scalars["Float"] | string
    >
    targetUnits?: GraphCacheResolver<
      WithTypename<ProjectProgressSummary>,
      Record<string, never>,
      Scalars["Float"] | string
    >
  }
  QueryActiveProjectsPaginatedConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<QueryActiveProjectsPaginatedConnection>,
      Record<string, never>,
      Array<WithTypename<QueryActiveProjectsPaginatedConnectionEdge> | string>
    >
    pageInfo?: GraphCacheResolver<
      WithTypename<QueryActiveProjectsPaginatedConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >
  }
  QueryActiveProjectsPaginatedConnectionEdge?: {
    cursor?: GraphCacheResolver<
      WithTypename<QueryActiveProjectsPaginatedConnectionEdge>,
      Record<string, never>,
      Scalars["String"] | string
    >
    node?: GraphCacheResolver<
      WithTypename<QueryActiveProjectsPaginatedConnectionEdge>,
      Record<string, never>,
      WithTypename<Project> | string
    >
  }
  QueryArchivedAssetsConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<QueryArchivedAssetsConnection>,
      Record<string, never>,
      Array<WithTypename<QueryArchivedAssetsConnectionEdge> | string>
    >
    pageInfo?: GraphCacheResolver<
      WithTypename<QueryArchivedAssetsConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >
    totalCount?: GraphCacheResolver<
      WithTypename<QueryArchivedAssetsConnection>,
      Record<string, never>,
      Scalars["Int"] | string
    >
  }
  QueryArchivedAssetsConnectionEdge?: {
    cursor?: GraphCacheResolver<
      WithTypename<QueryArchivedAssetsConnectionEdge>,
      Record<string, never>,
      Scalars["String"] | string
    >
    node?: GraphCacheResolver<
      WithTypename<QueryArchivedAssetsConnectionEdge>,
      Record<string, never>,
      WithTypename<Asset> | string
    >
  }
  QueryAssets_2Connection?: {
    edges?: GraphCacheResolver<
      WithTypename<QueryAssets_2Connection>,
      Record<string, never>,
      Array<WithTypename<QueryAssets_2ConnectionEdge> | string>
    >
    pageInfo?: GraphCacheResolver<
      WithTypename<QueryAssets_2Connection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >
    totalCount?: GraphCacheResolver<
      WithTypename<QueryAssets_2Connection>,
      Record<string, never>,
      Scalars["Int"] | string
    >
  }
  QueryAssets_2ConnectionEdge?: {
    cursor?: GraphCacheResolver<
      WithTypename<QueryAssets_2ConnectionEdge>,
      Record<string, never>,
      Scalars["String"] | string
    >
    node?: GraphCacheResolver<
      WithTypename<QueryAssets_2ConnectionEdge>,
      Record<string, never>,
      WithTypename<Asset> | string
    >
  }
  QueryProjectsPaginatedConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<QueryProjectsPaginatedConnection>,
      Record<string, never>,
      Array<WithTypename<QueryProjectsPaginatedConnectionEdge> | string>
    >
    pageInfo?: GraphCacheResolver<
      WithTypename<QueryProjectsPaginatedConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >
  }
  QueryProjectsPaginatedConnectionEdge?: {
    cursor?: GraphCacheResolver<
      WithTypename<QueryProjectsPaginatedConnectionEdge>,
      Record<string, never>,
      Scalars["String"] | string
    >
    node?: GraphCacheResolver<
      WithTypename<QueryProjectsPaginatedConnectionEdge>,
      Record<string, never>,
      WithTypename<Project> | string
    >
  }
  QuerySubtasksPaginatedConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<QuerySubtasksPaginatedConnection>,
      Record<string, never>,
      Array<WithTypename<QuerySubtasksPaginatedConnectionEdge> | string>
    >
    pageInfo?: GraphCacheResolver<
      WithTypename<QuerySubtasksPaginatedConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >
  }
  QuerySubtasksPaginatedConnectionEdge?: {
    cursor?: GraphCacheResolver<
      WithTypename<QuerySubtasksPaginatedConnectionEdge>,
      Record<string, never>,
      Scalars["String"] | string
    >
    node?: GraphCacheResolver<
      WithTypename<QuerySubtasksPaginatedConnectionEdge>,
      Record<string, never>,
      WithTypename<Task> | string
    >
  }
  QueryTasksPaginatedConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<QueryTasksPaginatedConnection>,
      Record<string, never>,
      Array<WithTypename<QueryTasksPaginatedConnectionEdge> | string>
    >
    pageInfo?: GraphCacheResolver<
      WithTypename<QueryTasksPaginatedConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >
  }
  QueryTasksPaginatedConnectionEdge?: {
    cursor?: GraphCacheResolver<
      WithTypename<QueryTasksPaginatedConnectionEdge>,
      Record<string, never>,
      Scalars["String"] | string
    >
    node?: GraphCacheResolver<
      WithTypename<QueryTasksPaginatedConnectionEdge>,
      Record<string, never>,
      WithTypename<Task> | string
    >
  }
  QueryUsersConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<QueryUsersConnection>,
      Record<string, never>,
      Array<WithTypename<QueryUsersConnectionEdge> | string>
    >
    pageInfo?: GraphCacheResolver<
      WithTypename<QueryUsersConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >
    totalCount?: GraphCacheResolver<WithTypename<QueryUsersConnection>, Record<string, never>, Scalars["Int"] | string>
  }
  QueryUsersConnectionEdge?: {
    cursor?: GraphCacheResolver<
      WithTypename<QueryUsersConnectionEdge>,
      Record<string, never>,
      Scalars["String"] | string
    >
    node?: GraphCacheResolver<
      WithTypename<QueryUsersConnectionEdge>,
      Record<string, never>,
      WithTypename<User> | string
    >
  }
  RequestInfo?: {
    browser?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars["String"] | string>
    city?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars["String"] | string>
    country?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars["String"] | string>
    isBot?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars["Boolean"] | string>
    isCurl?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars["Boolean"] | string>
    isDesktop?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars["Boolean"] | string>
    isMobile?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars["Boolean"] | string>
    isTablet?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars["Boolean"] | string>
    os?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars["String"] | string>
    platform?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars["String"] | string>
    region?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars["String"] | string>
  }
  Role?: {
    dateCreated?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars["DateTime"] | string>
    description?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars["String"] | string>
    id?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars["String"] | string>
    name?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars["String"] | string>
    order?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars["Int"] | string>
    organization?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, WithTypename<Organization> | string>
    organizationId?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars["String"] | string>
    permissions?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars["JSON"] | string>
    widgets?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars["JSON"] | string>
  }
  ScheduleTemplate?: {
    id?: GraphCacheResolver<WithTypename<ScheduleTemplate>, Record<string, never>, Scalars["String"] | string>
    isDefault?: GraphCacheResolver<WithTypename<ScheduleTemplate>, Record<string, never>, Scalars["Boolean"] | string>
    nonWorkDays?: GraphCacheResolver<
      WithTypename<ScheduleTemplate>,
      Record<string, never>,
      Array<WithTypename<ScheduleTemplateNonWorkDays> | string>
    >
    workDays?: GraphCacheResolver<
      WithTypename<ScheduleTemplate>,
      Record<string, never>,
      Array<WithTypename<ScheduleTemplateWorkDay> | string>
    >
    workHours?: GraphCacheResolver<
      WithTypename<ScheduleTemplate>,
      Record<string, never>,
      WithTypename<ScheduleTemplateWorkHours> | string
    >
  }
  ScheduleTemplateNonWorkDays?: {
    active?: GraphCacheResolver<
      WithTypename<ScheduleTemplateNonWorkDays>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
    dateRange?: GraphCacheResolver<
      WithTypename<ScheduleTemplateNonWorkDays>,
      Record<string, never>,
      Array<Scalars["DateTime"] | string>
    >
    id?: GraphCacheResolver<
      WithTypename<ScheduleTemplateNonWorkDays>,
      Record<string, never>,
      Scalars["String"] | string
    >
    name?: GraphCacheResolver<
      WithTypename<ScheduleTemplateNonWorkDays>,
      Record<string, never>,
      Scalars["String"] | string
    >
  }
  ScheduleTemplateWorkDay?: {
    active?: GraphCacheResolver<
      WithTypename<ScheduleTemplateWorkDay>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
    label?: GraphCacheResolver<WithTypename<ScheduleTemplateWorkDay>, Record<string, never>, Scalars["String"] | string>
  }
  ScheduleTemplateWorkHours?: {
    endTime?: GraphCacheResolver<
      WithTypename<ScheduleTemplateWorkHours>,
      Record<string, never>,
      Scalars["String"] | string
    >
    hours?: GraphCacheResolver<WithTypename<ScheduleTemplateWorkHours>, Record<string, never>, Scalars["Int"] | string>
    startTime?: GraphCacheResolver<
      WithTypename<ScheduleTemplateWorkHours>,
      Record<string, never>,
      Scalars["String"] | string
    >
  }
  ScheduledBreak?: {
    breakTask?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, WithTypename<Task> | string>
    breakTaskId?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, Scalars["String"] | string>
    durationInMinutes?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, Scalars["Int"] | string>
    id?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, Scalars["String"] | string>
    isActive?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, Scalars["Boolean"] | string>
    localizedStartTime?: GraphCacheResolver<
      WithTypename<ScheduledBreak>,
      Record<string, never>,
      Scalars["String"] | string
    >
    name?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, Scalars["String"] | string>
    projectId?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, Scalars["String"] | string>
  }
  SecurityActivity?: {
    createdAt?: GraphCacheResolver<WithTypename<SecurityActivity>, Record<string, never>, Scalars["DateTime"] | string>
    event?: GraphCacheResolver<WithTypename<SecurityActivity>, Record<string, never>, Scalars["String"] | string>
    ip?: GraphCacheResolver<WithTypename<SecurityActivity>, Record<string, never>, Scalars["String"] | string>
    userAgent?: GraphCacheResolver<WithTypename<SecurityActivity>, Record<string, never>, Scalars["String"] | string>
  }
  StripeInvoice?: {
    accountName?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["String"] | string>
    amountDue?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["Int"] | string>
    amountPaid?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["Int"] | string>
    amountRemaining?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["Int"] | string>
    attemptCount?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["Int"] | string>
    attempted?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["Boolean"] | string>
    autoAdvance?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["Boolean"] | string>
    billingReason?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["String"] | string>
    collectionMethod?: GraphCacheResolver<
      WithTypename<StripeInvoice>,
      Record<string, never>,
      Scalars["String"] | string
    >
    created?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["DateTime"] | string>
    currency?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["String"] | string>
    customerEmail?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["String"] | string>
    customerName?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["String"] | string>
    customerPhone?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["String"] | string>
    description?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["String"] | string>
    dueDate?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["DateTime"] | string>
    hostedInvoiceUrl?: GraphCacheResolver<
      WithTypename<StripeInvoice>,
      Record<string, never>,
      Scalars["String"] | string
    >
    id?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["String"] | string>
    invoicePdf?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["String"] | string>
    livemode?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["Boolean"] | string>
    object?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["String"] | string>
    paid?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["Boolean"] | string>
    status?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["String"] | string>
    subtotal?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["Int"] | string>
    subtotalExcludingTax?: GraphCacheResolver<
      WithTypename<StripeInvoice>,
      Record<string, never>,
      Scalars["Int"] | string
    >
    tax?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["Int"] | string>
    total?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars["Int"] | string>
  }
  Task?: {
    archived?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["Boolean"] | string>
    assetCount?: GraphCacheResolver<WithTypename<Task>, TaskAssetCountArgs, Scalars["Int"] | string>
    assignees?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Array<WithTypename<UserAssignment> | string>
    >
    clockedInUserCount?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["Int"] | string>
    clockedOutUserCount?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["Int"] | string>
    completedHours?: GraphCacheResolver<WithTypename<Task>, TaskCompletedHoursArgs, Scalars["Float"] | string>
    dateCreated?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["DateTime"] | string>
    description?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["String"] | string>
    endDate?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["DateTime"] | string>
    estimatedHours?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["Float"] | string>
    group?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, WithTypename<TaskGroup> | string>
    groupId?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["String"] | string>
    hasReportableUnit?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["Boolean"] | string>
    hoursDataByDateRangeType?: GraphCacheResolver<
      WithTypename<Task>,
      TaskHoursDataByDateRangeTypeArgs,
      Scalars["JSON"] | string
    >
    id?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["String"] | string>
    injuryReports?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Array<WithTypename<InjuryReport> | string>
    >
    isComplete?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["DateTime"] | string>
    isDefault?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["Boolean"] | string>
    isParentTask?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["Boolean"] | string>
    isUnpaid?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["Boolean"] | string>
    lastExport?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["JSON"] | string>
    metadata?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["JSON"] | string>
    metadataNotes?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Array<WithTypename<MetadataNote> | string>
    >
    name?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["String"] | string>
    organizationId?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["String"] | string>
    parentTask?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, WithTypename<Task> | string>
    parentTaskId?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["String"] | string>
    primaryUnitGoals?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Array<WithTypename<UnitGoal> | string>
    >
    productionRate?: GraphCacheResolver<WithTypename<Task>, TaskProductionRateArgs, Scalars["Float"] | string>
    project?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, WithTypename<Project> | string>
    projectId?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["String"] | string>
    scheduleTemplate?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      WithTypename<ScheduleTemplate> | string
    >
    scheduledBreaks?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Array<WithTypename<ScheduledBreak> | string>
    >
    sortOrder?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["Int"] | string>
    startDate?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["DateTime"] | string>
    subtaskCount?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["Int"] | string>
    subtasks?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<Task> | string>>
    sumOfDeliveredUnits?: GraphCacheResolver<WithTypename<Task>, TaskSumOfDeliveredUnitsArgs, Scalars["Float"] | string>
    taskProgressEvents?: GraphCacheResolver<
      WithTypename<Task>,
      TaskTaskProgressEventsArgs,
      Array<WithTypename<TaskProgressEvent> | string>
    >
    taskProgressEventsWithUnitReports?: GraphCacheResolver<
      WithTypename<Task>,
      TaskTaskProgressEventsWithUnitReportsArgs,
      Array<WithTypename<TaskProgressEvent> | string>
    >
    timeEntries?: GraphCacheResolver<WithTypename<Task>, TaskTimeEntriesArgs, Array<WithTypename<TimeEntry> | string>>
    timeEntriesSumDurationInSeconds?: GraphCacheResolver<
      WithTypename<Task>,
      TaskTimeEntriesSumDurationInSecondsArgs,
      Scalars["Float"] | string
    >
    unitGoals?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<UnitGoal> | string>>
    unitGoalsSumProgress?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["Float"] | string>
    unitGoalsSumTargetQuantity?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      Scalars["Float"] | string
    >
    userCount?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["Int"] | string>
    users?: GraphCacheResolver<WithTypename<Task>, TaskUsersArgs, Array<WithTypename<User> | string>>
    usersWhoClockedOrReportedCount?: GraphCacheResolver<
      WithTypename<Task>,
      TaskUsersWhoClockedOrReportedCountArgs,
      Scalars["Int"] | string
    >
    visibilityLevel?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["String"] | string>
    workersCompCode?: GraphCacheResolver<
      WithTypename<Task>,
      Record<string, never>,
      WithTypename<WorkersCompCode> | string
    >
    workersCompCodeId?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars["String"] | string>
  }
  TaskGroup?: {
    assetCount?: GraphCacheResolver<WithTypename<TaskGroup>, TaskGroupAssetCountArgs, Scalars["Int"] | string>
    completedHours?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["Float"] | string>
    completedTaskCount?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["Int"] | string>
    createdAt?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["DateTime"] | string>
    description?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["String"] | string>
    endDate?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["DateTime"] | string>
    estimatedHours?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["Float"] | string>
    id?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["ID"] | string>
    isComplete?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["Boolean"] | string>
    name?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["String"] | string>
    notes?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["String"] | string>
    organizationId?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["ID"] | string>
    productionRate?: GraphCacheResolver<WithTypename<TaskGroup>, TaskGroupProductionRateArgs, Scalars["Float"] | string>
    projectId?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["String"] | string>
    sortOrder?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["Int"] | string>
    startDate?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["DateTime"] | string>
    taskCount?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["Int"] | string>
    tasks?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Array<WithTypename<Task> | string>>
    unitProgress?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["Int"] | string>
    unitTargetGoal?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["Int"] | string>
    updatedAt?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars["DateTime"] | string>
    userCount?: GraphCacheResolver<WithTypename<TaskGroup>, TaskGroupUserCountArgs, Scalars["Int"] | string>
  }
  TaskListItem?: {
    assetCount?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["Int"] | string>
    completedHours?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["Float"] | string>
    completedTaskCount?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["Int"] | string>
    createdAt?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["DateTime"] | string>
    endDate?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["DateTime"] | string>
    estimatedHours?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["Float"] | string>
    groupId?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["String"] | string>
    isComplete?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["Boolean"] | string>
    isDefault?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["Boolean"] | string>
    name?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["String"] | string>
    sortOrder?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["Int"] | string>
    startDate?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["DateTime"] | string>
    taskCount?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["Int"] | string>
    taskGroupId?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["String"] | string>
    taskId?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["String"] | string>
    unitProgress?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["Int"] | string>
    unitTargetGoal?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["Int"] | string>
    userCount?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars["Int"] | string>
  }
  TaskProgressEvent?: {
    createdAt?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Scalars["DateTime"] | string>
    id?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Scalars["String"] | string>
    imageUrls?: GraphCacheResolver<
      WithTypename<TaskProgressEvent>,
      Record<string, never>,
      Array<Scalars["String"] | string>
    >
    note?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Scalars["String"] | string>
    reporter?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, WithTypename<User> | string>
    reporterId?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Scalars["String"] | string>
    task?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, WithTypename<Task> | string>
    taskId?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Scalars["String"] | string>
    unitGoalProgressCount?: GraphCacheResolver<
      WithTypename<TaskProgressEvent>,
      Record<string, never>,
      Scalars["Int"] | string
    >
    unitGoalProgressReports?: GraphCacheResolver<
      WithTypename<TaskProgressEvent>,
      Record<string, never>,
      Array<WithTypename<UnitGoalProgress> | string>
    >
    updatedAt?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Scalars["DateTime"] | string>
  }
  TimeEntry?: {
    createdAt?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["DateTime"] | string>
    durationInSeconds?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["Float"] | string>
    editedById?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["String"] | string>
    editor?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, WithTypename<User> | string>
    endAt?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["DateTime"] | string>
    evidence?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["JSON"] | string>
    id?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["String"] | string>
    isBreak?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["Boolean"] | string>
    organization?: GraphCacheResolver<
      WithTypename<TimeEntry>,
      Record<string, never>,
      WithTypename<Organization> | string
    >
    organizationId?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["String"] | string>
    project?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, WithTypename<Project> | string>
    projectId?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["String"] | string>
    signInPhotoUrl?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["String"] | string>
    signOutPhotoUrl?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["String"] | string>
    startAt?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["DateTime"] | string>
    task?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, WithTypename<Task> | string>
    taskId?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["String"] | string>
    updatedAt?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["DateTime"] | string>
    user?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, WithTypename<User> | string>
    userId?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars["String"] | string>
  }
  TimeEntryError?: {
    reason?: GraphCacheResolver<WithTypename<TimeEntryError>, Record<string, never>, TimeEntryErrorReason | string>
    userId?: GraphCacheResolver<WithTypename<TimeEntryError>, Record<string, never>, Scalars["String"] | string>
  }
  UnitGoal?: {
    createdAt?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars["DateTime"] | string>
    deliverableUnit?: GraphCacheResolver<
      WithTypename<UnitGoal>,
      Record<string, never>,
      WithTypename<DeliverableUnit> | string
    >
    deliverableUnitId?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars["ID"] | string>
    id?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars["ID"] | string>
    isPrimary?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars["Boolean"] | string>
    organizationId?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars["ID"] | string>
    previousProgress?: GraphCacheResolver<
      WithTypename<UnitGoal>,
      UnitGoalPreviousProgressArgs,
      Scalars["Float"] | string
    >
    progressInDateRange?: GraphCacheResolver<
      WithTypename<UnitGoal>,
      UnitGoalProgressInDateRangeArgs,
      Scalars["Float"] | string
    >
    project?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, WithTypename<Project> | string>
    projectId?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars["ID"] | string>
    targetQuantity?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars["Float"] | string>
    task?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, WithTypename<Task> | string>
    taskId?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars["ID"] | string>
    totalProgress?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars["Float"] | string>
    unitGoalProgress?: GraphCacheResolver<
      WithTypename<UnitGoal>,
      Record<string, never>,
      Array<WithTypename<UnitGoalProgress> | string>
    >
    updatedAt?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars["DateTime"] | string>
  }
  UnitGoalProgress?: {
    createdAt?: GraphCacheResolver<WithTypename<UnitGoalProgress>, Record<string, never>, Scalars["DateTime"] | string>
    id?: GraphCacheResolver<WithTypename<UnitGoalProgress>, Record<string, never>, Scalars["String"] | string>
    progress?: GraphCacheResolver<WithTypename<UnitGoalProgress>, Record<string, never>, Scalars["Float"] | string>
    unitGoal?: GraphCacheResolver<
      WithTypename<UnitGoalProgress>,
      Record<string, never>,
      WithTypename<UnitGoal> | string
    >
    unitGoalId?: GraphCacheResolver<WithTypename<UnitGoalProgress>, Record<string, never>, Scalars["String"] | string>
    updatedAt?: GraphCacheResolver<WithTypename<UnitGoalProgress>, Record<string, never>, Scalars["DateTime"] | string>
  }
  UnitOfMeasure?: {
    id?: GraphCacheResolver<WithTypename<UnitOfMeasure>, Record<string, never>, Scalars["Int"] | string>
    name?: GraphCacheResolver<WithTypename<UnitOfMeasure>, Record<string, never>, Scalars["String"] | string>
    organizationId?: GraphCacheResolver<WithTypename<UnitOfMeasure>, Record<string, never>, Scalars["String"] | string>
    symbol?: GraphCacheResolver<WithTypename<UnitOfMeasure>, Record<string, never>, Scalars["String"] | string>
  }
  UpdateSortOrderPayload?: {
    id?: GraphCacheResolver<WithTypename<UpdateSortOrderPayload>, Record<string, never>, Scalars["String"] | string>
    sortOrder?: GraphCacheResolver<WithTypename<UpdateSortOrderPayload>, Record<string, never>, Scalars["Int"] | string>
  }
  User?: {
    archived?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["Boolean"] | string>
    assignedProjectIds?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Array<Scalars["String"] | string>
    >
    assignedTaskIds?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<Scalars["String"] | string>>
    assignments?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Array<WithTypename<UserAssignment> | string>
    >
    childAncestorNodes?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Array<WithTypename<ChildAncestorNode> | string>
    >
    children?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<WithTypename<User> | string>>
    companyUserId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    currentProject?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<Project> | string>
    currentProjectId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    currentTask?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<Task> | string>
    currentTaskId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    deviceSessions?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Array<WithTypename<UserDeviceSession> | string>
    >
    editedTimeEntries?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Array<WithTypename<TimeEntry> | string>
    >
    email?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    emailVerifiedAt?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["DateTime"] | string>
    firstName?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    fullName?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    id?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    imageUrl?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    injuryReports?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Array<WithTypename<InjuryReport> | string>
    >
    invitationPending?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["Boolean"] | string>
    isBlackthornEmployee?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["Boolean"] | string>
    isClockedIn?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["Boolean"] | string>
    isOnBreak?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["Boolean"] | string>
    jobTitle?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    lastName?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    latestTimeEntry?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<TimeEntry> | string>
    myFiles?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<WithTypename<File> | string>>
    offlineEvents?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Array<WithTypename<OfflineEvent> | string>
    >
    organization?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<Organization> | string>
    organizationId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    parents?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<WithTypename<User> | string>>
    passwordUpdatedAt?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["DateTime"] | string>
    payRate?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    payType?: GraphCacheResolver<WithTypename<User>, Record<string, never>, PayType | string>
    phoneNumber?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    phoneNumberExt?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    project?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<Project> | string>
    projectId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    reportedUnitsInDateRangeByProjectOrTask?: GraphCacheResolver<
      WithTypename<User>,
      UserReportedUnitsInDateRangeByProjectOrTaskArgs,
      Array<WithTypename<TaskProgressEvent> | string>
    >
    roles?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<WithTypename<Role> | string>>
    secondsClockedInDateRangeOnProjectOrTask?: GraphCacheResolver<
      WithTypename<User>,
      UserSecondsClockedInDateRangeOnProjectOrTaskArgs,
      Scalars["Int"] | string
    >
    secondsClockedSinceOrgMidnight?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["Int"] | string
    >
    secondsClockedThisWeek?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["Int"] | string>
    securityActivity?: GraphCacheResolver<
      WithTypename<User>,
      UserSecurityActivityArgs,
      Array<WithTypename<SecurityActivity> | string>
    >
    sortName?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    task?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<Task> | string>
    taskId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
    timeEntries?: GraphCacheResolver<WithTypename<User>, UserTimeEntriesArgs, Array<WithTypename<TimeEntry> | string>>
    workersCompCode?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      WithTypename<WorkersCompCode> | string
    >
    workersCompCodeId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars["String"] | string>
  }
  UserAssignment?: {
    active?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars["Boolean"] | string>
    createdAt?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars["DateTime"] | string>
    id?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars["String"] | string>
    isCurrentAssignment?: GraphCacheResolver<
      WithTypename<UserAssignment>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
    organizationId?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars["String"] | string>
    project?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, WithTypename<Project> | string>
    projectId?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars["String"] | string>
    task?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, WithTypename<Task> | string>
    taskId?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars["String"] | string>
    updatedAt?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars["DateTime"] | string>
    user?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, WithTypename<User> | string>
    userId?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars["String"] | string>
  }
  UserAssignmentBatchResponse?: {
    count?: GraphCacheResolver<
      WithTypename<UserAssignmentBatchResponse>,
      Record<string, never>,
      Scalars["Int"] | string
    >
  }
  UserDeviceSession?: {
    createdAt?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, Scalars["DateTime"] | string>
    id?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, Scalars["String"] | string>
    ip?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, Scalars["String"] | string>
    isSingleUse?: GraphCacheResolver<
      WithTypename<UserDeviceSession>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >
    requestInfo?: GraphCacheResolver<
      WithTypename<UserDeviceSession>,
      Record<string, never>,
      WithTypename<RequestInfo> | string
    >
    updatedAt?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, Scalars["DateTime"] | string>
    userAgent?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, Scalars["String"] | string>
    userId?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, Scalars["String"] | string>
  }
  UserNotification?: {
    asset?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, WithTypename<Asset> | string>
    assignedUser?: GraphCacheResolver<
      WithTypename<UserNotification>,
      Record<string, never>,
      WithTypename<User> | string
    >
    assignedUserId?: GraphCacheResolver<
      WithTypename<UserNotification>,
      Record<string, never>,
      Scalars["String"] | string
    >
    createdAt?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, Scalars["DateTime"] | string>
    id?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, Scalars["ID"] | string>
    markedReadAt?: GraphCacheResolver<
      WithTypename<UserNotification>,
      Record<string, never>,
      Scalars["DateTime"] | string
    >
    modelType?: GraphCacheResolver<
      WithTypename<UserNotification>,
      Record<string, never>,
      UserNotificationModelType | string
    >
    organizationId?: GraphCacheResolver<
      WithTypename<UserNotification>,
      Record<string, never>,
      Scalars["String"] | string
    >
    type?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, UserNotificationType | string>
    user?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, WithTypename<User> | string>
  }
  UserToUser?: {
    child?: GraphCacheResolver<WithTypename<UserToUser>, Record<string, never>, WithTypename<User> | string>
    childId?: GraphCacheResolver<WithTypename<UserToUser>, Record<string, never>, Scalars["String"] | string>
    createdAt?: GraphCacheResolver<WithTypename<UserToUser>, Record<string, never>, Scalars["DateTime"] | string>
    parent?: GraphCacheResolver<WithTypename<UserToUser>, Record<string, never>, WithTypename<User> | string>
    parentId?: GraphCacheResolver<WithTypename<UserToUser>, Record<string, never>, Scalars["String"] | string>
    updatedAt?: GraphCacheResolver<WithTypename<UserToUser>, Record<string, never>, Scalars["DateTime"] | string>
  }
  WorkersCompCode?: {
    active?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars["Boolean"] | string>
    createdAt?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars["DateTime"] | string>
    id?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars["ID"] | string>
    name?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars["String"] | string>
    ncciCode?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars["String"] | string>
    ncciDescription?: GraphCacheResolver<
      WithTypename<WorkersCompCode>,
      Record<string, never>,
      Scalars["String"] | string
    >
    organizationId?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars["ID"] | string>
    override?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars["Boolean"] | string>
    rate?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars["Float"] | string>
    updatedAt?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars["DateTime"] | string>
  }
  masqueradeCredentials?: {
    accessToken?: GraphCacheResolver<
      WithTypename<MasqueradeCredentials>,
      Record<string, never>,
      Scalars["String"] | string
    >
  }
}

export type GraphCacheOptimisticUpdaters = {
  activateOrganization?: GraphCacheOptimisticMutationResolver<
    MutationActivateOrganizationArgs,
    WithTypename<Organization>
  >
  activateProject?: GraphCacheOptimisticMutationResolver<MutationActivateProjectArgs, WithTypename<Project>>
  addOrUpdateNonWorkDay?: GraphCacheOptimisticMutationResolver<
    MutationAddOrUpdateNonWorkDayArgs,
    WithTypename<ScheduleTemplate>
  >
  addQuantitiesToGroup?: GraphCacheOptimisticMutationResolver<
    MutationAddQuantitiesToGroupArgs,
    Maybe<WithTypename<Asset>>
  >
  archiveOrganization?: GraphCacheOptimisticMutationResolver<
    MutationArchiveOrganizationArgs,
    WithTypename<Organization>
  >
  archiveProject?: GraphCacheOptimisticMutationResolver<MutationArchiveProjectArgs, WithTypename<Project>>
  archiveQuantities?: GraphCacheOptimisticMutationResolver<MutationArchiveQuantitiesArgs, Array<WithTypename<Asset>>>
  archiveWorkersCompCode?: GraphCacheOptimisticMutationResolver<
    MutationArchiveWorkersCompCodeArgs,
    Maybe<WithTypename<WorkersCompCode>>
  >
  assignChildToParent?: GraphCacheOptimisticMutationResolver<MutationAssignChildToParentArgs, WithTypename<UserToUser>>
  bulkClockIn?: GraphCacheOptimisticMutationResolver<MutationBulkClockInArgs, WithTypename<BulkTimeEntryResponse>>
  bulkClockOut?: GraphCacheOptimisticMutationResolver<MutationBulkClockOutArgs, WithTypename<BulkTimeEntryResponse>>
  bulkUpdateTaskSortOrder?: GraphCacheOptimisticMutationResolver<
    MutationBulkUpdateTaskSortOrderArgs,
    Array<WithTypename<UpdateSortOrderPayload>>
  >
  bulkUpdateUserAssignments?: GraphCacheOptimisticMutationResolver<
    MutationBulkUpdateUserAssignmentsArgs,
    Maybe<WithTypename<UserAssignmentBatchResponse>>
  >
  clockIn?: GraphCacheOptimisticMutationResolver<MutationClockInArgs, WithTypename<TimeEntry>>
  clockOut?: GraphCacheOptimisticMutationResolver<MutationClockOutArgs, WithTypename<TimeEntry>>
  clockOutUser?: GraphCacheOptimisticMutationResolver<MutationClockOutUserArgs, WithTypename<TimeEntry>>
  completeProject?: GraphCacheOptimisticMutationResolver<MutationCompleteProjectArgs, WithTypename<Project>>
  createCustomer?: GraphCacheOptimisticMutationResolver<MutationCreateCustomerArgs, Maybe<WithTypename<Customer>>>
  createDeliverableUnit?: GraphCacheOptimisticMutationResolver<
    MutationCreateDeliverableUnitArgs,
    WithTypename<DeliverableUnit>
  >
  createInjuryReport?: GraphCacheOptimisticMutationResolver<MutationCreateInjuryReportArgs, WithTypename<InjuryReport>>
  createManyTaskReports?: GraphCacheOptimisticMutationResolver<
    MutationCreateManyTaskReportsArgs,
    Maybe<Array<WithTypename<Task>>>
  >
  createOfflineEvent?: GraphCacheOptimisticMutationResolver<MutationCreateOfflineEventArgs, WithTypename<OfflineEvent>>
  createOfflineEvents?: GraphCacheOptimisticMutationResolver<
    MutationCreateOfflineEventsArgs,
    WithTypename<CreateManyOfflineEventsResponsePayload>
  >
  createOneContract?: GraphCacheOptimisticMutationResolver<MutationCreateOneContractArgs, WithTypename<Contract>>
  createScheduleTemplate?: GraphCacheOptimisticMutationResolver<
    MutationCreateScheduleTemplateArgs,
    WithTypename<ScheduleTemplate>
  >
  createScheduledBreak?: GraphCacheOptimisticMutationResolver<
    MutationCreateScheduledBreakArgs,
    WithTypename<ScheduledBreak>
  >
  createUnitGoal?: GraphCacheOptimisticMutationResolver<MutationCreateUnitGoalArgs, Maybe<WithTypename<UnitGoal>>>
  createUnitOfMeasure?: GraphCacheOptimisticMutationResolver<
    MutationCreateUnitOfMeasureArgs,
    WithTypename<UnitOfMeasure>
  >
  createUser?: GraphCacheOptimisticMutationResolver<MutationCreateUserArgs, Maybe<WithTypename<User>>>
  createUserAssignment?: GraphCacheOptimisticMutationResolver<
    MutationCreateUserAssignmentArgs,
    Maybe<WithTypename<UserAssignment>>
  >
  createUserNotification?: GraphCacheOptimisticMutationResolver<
    MutationCreateUserNotificationArgs,
    Maybe<WithTypename<UserNotification>>
  >
  createWorkersCompCode?: GraphCacheOptimisticMutationResolver<
    MutationCreateWorkersCompCodeArgs,
    Maybe<WithTypename<WorkersCompCode>>
  >
  deleteCustomer?: GraphCacheOptimisticMutationResolver<MutationDeleteCustomerArgs, WithTypename<Customer>>
  deleteDeliverableUnit?: GraphCacheOptimisticMutationResolver<
    MutationDeleteDeliverableUnitArgs,
    Maybe<WithTypename<DeliverableUnit>>
  >
  deleteNonWorkDay?: GraphCacheOptimisticMutationResolver<MutationDeleteNonWorkDayArgs, WithTypename<ScheduleTemplate>>
  deleteOneAsset?: GraphCacheOptimisticMutationResolver<MutationDeleteOneAssetArgs, Maybe<WithTypename<Asset>>>
  deleteOneContract?: GraphCacheOptimisticMutationResolver<MutationDeleteOneContractArgs, WithTypename<Contract>>
  deleteOneFile?: GraphCacheOptimisticMutationResolver<MutationDeleteOneFileArgs, WithTypename<File>>
  deleteOneTask?: GraphCacheOptimisticMutationResolver<MutationDeleteOneTaskArgs, Maybe<WithTypename<Task>>>
  deleteOneTaskGroup?: GraphCacheOptimisticMutationResolver<
    MutationDeleteOneTaskGroupArgs,
    Maybe<WithTypename<TaskGroup>>
  >
  deleteOneTimeEntry?: GraphCacheOptimisticMutationResolver<MutationDeleteOneTimeEntryArgs, WithTypename<TimeEntry>>
  deleteOneUser?: GraphCacheOptimisticMutationResolver<MutationDeleteOneUserArgs, WithTypename<User>>
  deleteParentChildRelationship?: GraphCacheOptimisticMutationResolver<
    MutationDeleteParentChildRelationshipArgs,
    WithTypename<UserToUser>
  >
  deleteReportTemplate?: GraphCacheOptimisticMutationResolver<
    MutationDeleteReportTemplateArgs,
    WithTypename<AssetReportTemplate>
  >
  deleteScheduledBreak?: GraphCacheOptimisticMutationResolver<
    MutationDeleteScheduledBreakArgs,
    WithTypename<ScheduledBreak>
  >
  deleteUnitGoal?: GraphCacheOptimisticMutationResolver<MutationDeleteUnitGoalArgs, Maybe<WithTypename<UnitGoal>>>
  deleteUnitOfMeasure?: GraphCacheOptimisticMutationResolver<
    MutationDeleteUnitOfMeasureArgs,
    WithTypename<UnitOfMeasure>
  >
  deleteUserAssignment?: GraphCacheOptimisticMutationResolver<
    MutationDeleteUserAssignmentArgs,
    WithTypename<UserAssignment>
  >
  deleteUserDeviceSession?: GraphCacheOptimisticMutationResolver<
    MutationDeleteUserDeviceSessionArgs,
    Scalars["Boolean"]
  >
  duplicateProject?: GraphCacheOptimisticMutationResolver<MutationDuplicateProjectArgs, WithTypename<Project>>
  duplicateReportTemplate?: GraphCacheOptimisticMutationResolver<
    MutationDuplicateReportTemplateArgs,
    WithTypename<AssetReportTemplate>
  >
  duplicateScheduleTemplate?: GraphCacheOptimisticMutationResolver<
    MutationDuplicateScheduleTemplateArgs,
    WithTypename<ScheduleTemplate>
  >
  editDeliverableUnit?: GraphCacheOptimisticMutationResolver<
    MutationEditDeliverableUnitArgs,
    WithTypename<DeliverableUnit>
  >
  editOrganization?: GraphCacheOptimisticMutationResolver<MutationEditOrganizationArgs, WithTypename<Organization>>
  editScheduledBreak?: GraphCacheOptimisticMutationResolver<
    MutationEditScheduledBreakArgs,
    WithTypename<ScheduledBreak>
  >
  editUnitGoal?: GraphCacheOptimisticMutationResolver<MutationEditUnitGoalArgs, Maybe<WithTypename<UnitGoal>>>
  editUnitOfMeasure?: GraphCacheOptimisticMutationResolver<MutationEditUnitOfMeasureArgs, WithTypename<UnitOfMeasure>>
  endAllMasqueradeSessionsForUser?: GraphCacheOptimisticMutationResolver<
    MutationEndAllMasqueradeSessionsForUserArgs,
    Scalars["Boolean"]
  >
  insertManyAssetReports?: GraphCacheOptimisticMutationResolver<
    MutationInsertManyAssetReportsArgs,
    Array<WithTypename<AssetReport>>
  >
  insertOneAsset?: GraphCacheOptimisticMutationResolver<MutationInsertOneAssetArgs, Maybe<WithTypename<Asset>>>
  insertOneProject?: GraphCacheOptimisticMutationResolver<MutationInsertOneProjectArgs, WithTypename<Project>>
  insertOneTask?: GraphCacheOptimisticMutationResolver<MutationInsertOneTaskArgs, WithTypename<Task>>
  insertOneTaskGroup?: GraphCacheOptimisticMutationResolver<MutationInsertOneTaskGroupArgs, WithTypename<TaskGroup>>
  insertOneTimeEntry?: GraphCacheOptimisticMutationResolver<MutationInsertOneTimeEntryArgs, WithTypename<TimeEntry>>
  insertReportTemplate?: GraphCacheOptimisticMutationResolver<
    MutationInsertReportTemplateArgs,
    WithTypename<AssetReportTemplate>
  >
  markAllNotificationsRead?: GraphCacheOptimisticMutationResolver<
    MutationMarkAllNotificationsReadArgs,
    Maybe<Array<WithTypename<UserNotification>>>
  >
  markNotificationRead?: GraphCacheOptimisticMutationResolver<
    MutationMarkNotificationReadArgs,
    Maybe<WithTypename<UserNotification>>
  >
  markNotificationsReadById?: GraphCacheOptimisticMutationResolver<
    MutationMarkNotificationsReadByIdArgs,
    Maybe<Array<WithTypename<UserNotification>>>
  >
  markOrganizationNotPastDue?: GraphCacheOptimisticMutationResolver<
    MutationMarkOrganizationNotPastDueArgs,
    WithTypename<Organization>
  >
  markOrganizationPastDue?: GraphCacheOptimisticMutationResolver<
    MutationMarkOrganizationPastDueArgs,
    WithTypename<Organization>
  >
  markTaskCompletion?: GraphCacheOptimisticMutationResolver<MutationMarkTaskCompletionArgs, Maybe<WithTypename<Task>>>
  masqueradeUser?: GraphCacheOptimisticMutationResolver<MutationMasqueradeUserArgs, WithTypename<MasqueradeCredentials>>
  reassignUser?: GraphCacheOptimisticMutationResolver<MutationReassignUserArgs, WithTypename<User>>
  reassignUsers?: GraphCacheOptimisticMutationResolver<MutationReassignUsersArgs, Array<WithTypename<User>>>
  reportTaskProgress?: GraphCacheOptimisticMutationResolver<MutationReportTaskProgressArgs, Maybe<WithTypename<Task>>>
  resendUserInvites?: GraphCacheOptimisticMutationResolver<MutationResendUserInvitesArgs, Maybe<Scalars["Boolean"]>>
  restoreOneAsset?: GraphCacheOptimisticMutationResolver<MutationRestoreOneAssetArgs, Maybe<WithTypename<Asset>>>
  restoreOneUser?: GraphCacheOptimisticMutationResolver<MutationRestoreOneUserArgs, WithTypename<User>>
  returnQuantityToInventory?: GraphCacheOptimisticMutationResolver<
    MutationReturnQuantityToInventoryArgs,
    Array<WithTypename<Asset>>
  >
  transferAssets?: GraphCacheOptimisticMutationResolver<MutationTransferAssetsArgs, Array<WithTypename<Asset>>>
  unarchiveQuantities?: GraphCacheOptimisticMutationResolver<
    MutationUnarchiveQuantitiesArgs,
    Array<WithTypename<Asset>>
  >
  updateCustomer?: GraphCacheOptimisticMutationResolver<MutationUpdateCustomerArgs, Maybe<WithTypename<Customer>>>
  updateInjuryReport?: GraphCacheOptimisticMutationResolver<MutationUpdateInjuryReportArgs, WithTypename<InjuryReport>>
  updateLastExport?: GraphCacheOptimisticMutationResolver<MutationUpdateLastExportArgs, Maybe<WithTypename<LastExport>>>
  updateOneAsset?: GraphCacheOptimisticMutationResolver<MutationUpdateOneAssetArgs, Maybe<WithTypename<Asset>>>
  updateOneContract?: GraphCacheOptimisticMutationResolver<MutationUpdateOneContractArgs, WithTypename<Contract>>
  updateOneFile?: GraphCacheOptimisticMutationResolver<MutationUpdateOneFileArgs, WithTypename<File>>
  updateOneProject?: GraphCacheOptimisticMutationResolver<MutationUpdateOneProjectArgs, WithTypename<Project>>
  updateOneTask?: GraphCacheOptimisticMutationResolver<MutationUpdateOneTaskArgs, WithTypename<Task>>
  updateOneTaskGroup?: GraphCacheOptimisticMutationResolver<MutationUpdateOneTaskGroupArgs, WithTypename<TaskGroup>>
  updateOneTimeEntry?: GraphCacheOptimisticMutationResolver<MutationUpdateOneTimeEntryArgs, WithTypename<TimeEntry>>
  updateOneUser?: GraphCacheOptimisticMutationResolver<MutationUpdateOneUserArgs, WithTypename<User>>
  updatePassword?: GraphCacheOptimisticMutationResolver<MutationUpdatePasswordArgs, Scalars["Boolean"]>
  updateReportTemplate?: GraphCacheOptimisticMutationResolver<
    MutationUpdateReportTemplateArgs,
    WithTypename<AssetReportTemplate>
  >
  updateScheduleTemplate?: GraphCacheOptimisticMutationResolver<
    MutationUpdateScheduleTemplateArgs,
    WithTypename<ScheduleTemplate>
  >
  updateTaskSortOrder?: GraphCacheOptimisticMutationResolver<
    MutationUpdateTaskSortOrderArgs,
    Maybe<WithTypename<UpdateSortOrderPayload>>
  >
  updateUserAssignment?: GraphCacheOptimisticMutationResolver<
    MutationUpdateUserAssignmentArgs,
    Maybe<WithTypename<UserAssignment>>
  >
}

export type GraphCacheUpdaters = {
  Mutation?: {
    activateOrganization?: GraphCacheUpdateResolver<
      { activateOrganization: WithTypename<Organization> },
      MutationActivateOrganizationArgs
    >
    activateProject?: GraphCacheUpdateResolver<{ activateProject: WithTypename<Project> }, MutationActivateProjectArgs>
    addOrUpdateNonWorkDay?: GraphCacheUpdateResolver<
      { addOrUpdateNonWorkDay: WithTypename<ScheduleTemplate> },
      MutationAddOrUpdateNonWorkDayArgs
    >
    addQuantitiesToGroup?: GraphCacheUpdateResolver<
      { addQuantitiesToGroup: Maybe<WithTypename<Asset>> },
      MutationAddQuantitiesToGroupArgs
    >
    archiveOrganization?: GraphCacheUpdateResolver<
      { archiveOrganization: WithTypename<Organization> },
      MutationArchiveOrganizationArgs
    >
    archiveProject?: GraphCacheUpdateResolver<{ archiveProject: WithTypename<Project> }, MutationArchiveProjectArgs>
    archiveQuantities?: GraphCacheUpdateResolver<
      { archiveQuantities: Array<WithTypename<Asset>> },
      MutationArchiveQuantitiesArgs
    >
    archiveWorkersCompCode?: GraphCacheUpdateResolver<
      { archiveWorkersCompCode: Maybe<WithTypename<WorkersCompCode>> },
      MutationArchiveWorkersCompCodeArgs
    >
    assignChildToParent?: GraphCacheUpdateResolver<
      { assignChildToParent: WithTypename<UserToUser> },
      MutationAssignChildToParentArgs
    >
    bulkClockIn?: GraphCacheUpdateResolver<
      { bulkClockIn: WithTypename<BulkTimeEntryResponse> },
      MutationBulkClockInArgs
    >
    bulkClockOut?: GraphCacheUpdateResolver<
      { bulkClockOut: WithTypename<BulkTimeEntryResponse> },
      MutationBulkClockOutArgs
    >
    bulkUpdateTaskSortOrder?: GraphCacheUpdateResolver<
      { bulkUpdateTaskSortOrder: Array<WithTypename<UpdateSortOrderPayload>> },
      MutationBulkUpdateTaskSortOrderArgs
    >
    bulkUpdateUserAssignments?: GraphCacheUpdateResolver<
      { bulkUpdateUserAssignments: Maybe<WithTypename<UserAssignmentBatchResponse>> },
      MutationBulkUpdateUserAssignmentsArgs
    >
    clockIn?: GraphCacheUpdateResolver<{ clockIn: WithTypename<TimeEntry> }, MutationClockInArgs>
    clockOut?: GraphCacheUpdateResolver<{ clockOut: WithTypename<TimeEntry> }, MutationClockOutArgs>
    clockOutUser?: GraphCacheUpdateResolver<{ clockOutUser: WithTypename<TimeEntry> }, MutationClockOutUserArgs>
    completeProject?: GraphCacheUpdateResolver<{ completeProject: WithTypename<Project> }, MutationCompleteProjectArgs>
    createCustomer?: GraphCacheUpdateResolver<
      { createCustomer: Maybe<WithTypename<Customer>> },
      MutationCreateCustomerArgs
    >
    createDeliverableUnit?: GraphCacheUpdateResolver<
      { createDeliverableUnit: WithTypename<DeliverableUnit> },
      MutationCreateDeliverableUnitArgs
    >
    createInjuryReport?: GraphCacheUpdateResolver<
      { createInjuryReport: WithTypename<InjuryReport> },
      MutationCreateInjuryReportArgs
    >
    createManyTaskReports?: GraphCacheUpdateResolver<
      { createManyTaskReports: Maybe<Array<WithTypename<Task>>> },
      MutationCreateManyTaskReportsArgs
    >
    createOfflineEvent?: GraphCacheUpdateResolver<
      { createOfflineEvent: WithTypename<OfflineEvent> },
      MutationCreateOfflineEventArgs
    >
    createOfflineEvents?: GraphCacheUpdateResolver<
      { createOfflineEvents: WithTypename<CreateManyOfflineEventsResponsePayload> },
      MutationCreateOfflineEventsArgs
    >
    createOneContract?: GraphCacheUpdateResolver<
      { createOneContract: WithTypename<Contract> },
      MutationCreateOneContractArgs
    >
    createScheduleTemplate?: GraphCacheUpdateResolver<
      { createScheduleTemplate: WithTypename<ScheduleTemplate> },
      MutationCreateScheduleTemplateArgs
    >
    createScheduledBreak?: GraphCacheUpdateResolver<
      { createScheduledBreak: WithTypename<ScheduledBreak> },
      MutationCreateScheduledBreakArgs
    >
    createUnitGoal?: GraphCacheUpdateResolver<
      { createUnitGoal: Maybe<WithTypename<UnitGoal>> },
      MutationCreateUnitGoalArgs
    >
    createUnitOfMeasure?: GraphCacheUpdateResolver<
      { createUnitOfMeasure: WithTypename<UnitOfMeasure> },
      MutationCreateUnitOfMeasureArgs
    >
    createUser?: GraphCacheUpdateResolver<{ createUser: Maybe<WithTypename<User>> }, MutationCreateUserArgs>
    createUserAssignment?: GraphCacheUpdateResolver<
      { createUserAssignment: Maybe<WithTypename<UserAssignment>> },
      MutationCreateUserAssignmentArgs
    >
    createUserNotification?: GraphCacheUpdateResolver<
      { createUserNotification: Maybe<WithTypename<UserNotification>> },
      MutationCreateUserNotificationArgs
    >
    createWorkersCompCode?: GraphCacheUpdateResolver<
      { createWorkersCompCode: Maybe<WithTypename<WorkersCompCode>> },
      MutationCreateWorkersCompCodeArgs
    >
    deleteCustomer?: GraphCacheUpdateResolver<{ deleteCustomer: WithTypename<Customer> }, MutationDeleteCustomerArgs>
    deleteDeliverableUnit?: GraphCacheUpdateResolver<
      { deleteDeliverableUnit: Maybe<WithTypename<DeliverableUnit>> },
      MutationDeleteDeliverableUnitArgs
    >
    deleteNonWorkDay?: GraphCacheUpdateResolver<
      { deleteNonWorkDay: WithTypename<ScheduleTemplate> },
      MutationDeleteNonWorkDayArgs
    >
    deleteOneAsset?: GraphCacheUpdateResolver<
      { deleteOneAsset: Maybe<WithTypename<Asset>> },
      MutationDeleteOneAssetArgs
    >
    deleteOneContract?: GraphCacheUpdateResolver<
      { deleteOneContract: WithTypename<Contract> },
      MutationDeleteOneContractArgs
    >
    deleteOneFile?: GraphCacheUpdateResolver<{ deleteOneFile: WithTypename<File> }, MutationDeleteOneFileArgs>
    deleteOneTask?: GraphCacheUpdateResolver<{ deleteOneTask: Maybe<WithTypename<Task>> }, MutationDeleteOneTaskArgs>
    deleteOneTaskGroup?: GraphCacheUpdateResolver<
      { deleteOneTaskGroup: Maybe<WithTypename<TaskGroup>> },
      MutationDeleteOneTaskGroupArgs
    >
    deleteOneTimeEntry?: GraphCacheUpdateResolver<
      { deleteOneTimeEntry: WithTypename<TimeEntry> },
      MutationDeleteOneTimeEntryArgs
    >
    deleteOneUser?: GraphCacheUpdateResolver<{ deleteOneUser: WithTypename<User> }, MutationDeleteOneUserArgs>
    deleteParentChildRelationship?: GraphCacheUpdateResolver<
      { deleteParentChildRelationship: WithTypename<UserToUser> },
      MutationDeleteParentChildRelationshipArgs
    >
    deleteReportTemplate?: GraphCacheUpdateResolver<
      { deleteReportTemplate: WithTypename<AssetReportTemplate> },
      MutationDeleteReportTemplateArgs
    >
    deleteScheduledBreak?: GraphCacheUpdateResolver<
      { deleteScheduledBreak: WithTypename<ScheduledBreak> },
      MutationDeleteScheduledBreakArgs
    >
    deleteUnitGoal?: GraphCacheUpdateResolver<
      { deleteUnitGoal: Maybe<WithTypename<UnitGoal>> },
      MutationDeleteUnitGoalArgs
    >
    deleteUnitOfMeasure?: GraphCacheUpdateResolver<
      { deleteUnitOfMeasure: WithTypename<UnitOfMeasure> },
      MutationDeleteUnitOfMeasureArgs
    >
    deleteUserAssignment?: GraphCacheUpdateResolver<
      { deleteUserAssignment: WithTypename<UserAssignment> },
      MutationDeleteUserAssignmentArgs
    >
    deleteUserDeviceSession?: GraphCacheUpdateResolver<
      { deleteUserDeviceSession: Scalars["Boolean"] },
      MutationDeleteUserDeviceSessionArgs
    >
    duplicateProject?: GraphCacheUpdateResolver<
      { duplicateProject: WithTypename<Project> },
      MutationDuplicateProjectArgs
    >
    duplicateReportTemplate?: GraphCacheUpdateResolver<
      { duplicateReportTemplate: WithTypename<AssetReportTemplate> },
      MutationDuplicateReportTemplateArgs
    >
    duplicateScheduleTemplate?: GraphCacheUpdateResolver<
      { duplicateScheduleTemplate: WithTypename<ScheduleTemplate> },
      MutationDuplicateScheduleTemplateArgs
    >
    editDeliverableUnit?: GraphCacheUpdateResolver<
      { editDeliverableUnit: WithTypename<DeliverableUnit> },
      MutationEditDeliverableUnitArgs
    >
    editOrganization?: GraphCacheUpdateResolver<
      { editOrganization: WithTypename<Organization> },
      MutationEditOrganizationArgs
    >
    editScheduledBreak?: GraphCacheUpdateResolver<
      { editScheduledBreak: WithTypename<ScheduledBreak> },
      MutationEditScheduledBreakArgs
    >
    editUnitGoal?: GraphCacheUpdateResolver<{ editUnitGoal: Maybe<WithTypename<UnitGoal>> }, MutationEditUnitGoalArgs>
    editUnitOfMeasure?: GraphCacheUpdateResolver<
      { editUnitOfMeasure: WithTypename<UnitOfMeasure> },
      MutationEditUnitOfMeasureArgs
    >
    endAllMasqueradeSessionsForUser?: GraphCacheUpdateResolver<
      { endAllMasqueradeSessionsForUser: Scalars["Boolean"] },
      MutationEndAllMasqueradeSessionsForUserArgs
    >
    insertManyAssetReports?: GraphCacheUpdateResolver<
      { insertManyAssetReports: Array<WithTypename<AssetReport>> },
      MutationInsertManyAssetReportsArgs
    >
    insertOneAsset?: GraphCacheUpdateResolver<
      { insertOneAsset: Maybe<WithTypename<Asset>> },
      MutationInsertOneAssetArgs
    >
    insertOneProject?: GraphCacheUpdateResolver<
      { insertOneProject: WithTypename<Project> },
      MutationInsertOneProjectArgs
    >
    insertOneTask?: GraphCacheUpdateResolver<{ insertOneTask: WithTypename<Task> }, MutationInsertOneTaskArgs>
    insertOneTaskGroup?: GraphCacheUpdateResolver<
      { insertOneTaskGroup: WithTypename<TaskGroup> },
      MutationInsertOneTaskGroupArgs
    >
    insertOneTimeEntry?: GraphCacheUpdateResolver<
      { insertOneTimeEntry: WithTypename<TimeEntry> },
      MutationInsertOneTimeEntryArgs
    >
    insertReportTemplate?: GraphCacheUpdateResolver<
      { insertReportTemplate: WithTypename<AssetReportTemplate> },
      MutationInsertReportTemplateArgs
    >
    markAllNotificationsRead?: GraphCacheUpdateResolver<
      { markAllNotificationsRead: Maybe<Array<WithTypename<UserNotification>>> },
      MutationMarkAllNotificationsReadArgs
    >
    markNotificationRead?: GraphCacheUpdateResolver<
      { markNotificationRead: Maybe<WithTypename<UserNotification>> },
      MutationMarkNotificationReadArgs
    >
    markNotificationsReadById?: GraphCacheUpdateResolver<
      { markNotificationsReadById: Maybe<Array<WithTypename<UserNotification>>> },
      MutationMarkNotificationsReadByIdArgs
    >
    markOrganizationNotPastDue?: GraphCacheUpdateResolver<
      { markOrganizationNotPastDue: WithTypename<Organization> },
      MutationMarkOrganizationNotPastDueArgs
    >
    markOrganizationPastDue?: GraphCacheUpdateResolver<
      { markOrganizationPastDue: WithTypename<Organization> },
      MutationMarkOrganizationPastDueArgs
    >
    markTaskCompletion?: GraphCacheUpdateResolver<
      { markTaskCompletion: Maybe<WithTypename<Task>> },
      MutationMarkTaskCompletionArgs
    >
    masqueradeUser?: GraphCacheUpdateResolver<
      { masqueradeUser: WithTypename<MasqueradeCredentials> },
      MutationMasqueradeUserArgs
    >
    reassignUser?: GraphCacheUpdateResolver<{ reassignUser: WithTypename<User> }, MutationReassignUserArgs>
    reassignUsers?: GraphCacheUpdateResolver<{ reassignUsers: Array<WithTypename<User>> }, MutationReassignUsersArgs>
    reportTaskProgress?: GraphCacheUpdateResolver<
      { reportTaskProgress: Maybe<WithTypename<Task>> },
      MutationReportTaskProgressArgs
    >
    resendUserInvites?: GraphCacheUpdateResolver<
      { resendUserInvites: Maybe<Scalars["Boolean"]> },
      MutationResendUserInvitesArgs
    >
    restoreOneAsset?: GraphCacheUpdateResolver<
      { restoreOneAsset: Maybe<WithTypename<Asset>> },
      MutationRestoreOneAssetArgs
    >
    restoreOneUser?: GraphCacheUpdateResolver<{ restoreOneUser: WithTypename<User> }, MutationRestoreOneUserArgs>
    returnQuantityToInventory?: GraphCacheUpdateResolver<
      { returnQuantityToInventory: Array<WithTypename<Asset>> },
      MutationReturnQuantityToInventoryArgs
    >
    transferAssets?: GraphCacheUpdateResolver<
      { transferAssets: Array<WithTypename<Asset>> },
      MutationTransferAssetsArgs
    >
    unarchiveQuantities?: GraphCacheUpdateResolver<
      { unarchiveQuantities: Array<WithTypename<Asset>> },
      MutationUnarchiveQuantitiesArgs
    >
    updateCustomer?: GraphCacheUpdateResolver<
      { updateCustomer: Maybe<WithTypename<Customer>> },
      MutationUpdateCustomerArgs
    >
    updateInjuryReport?: GraphCacheUpdateResolver<
      { updateInjuryReport: WithTypename<InjuryReport> },
      MutationUpdateInjuryReportArgs
    >
    updateLastExport?: GraphCacheUpdateResolver<
      { updateLastExport: Maybe<WithTypename<LastExport>> },
      MutationUpdateLastExportArgs
    >
    updateOneAsset?: GraphCacheUpdateResolver<
      { updateOneAsset: Maybe<WithTypename<Asset>> },
      MutationUpdateOneAssetArgs
    >
    updateOneContract?: GraphCacheUpdateResolver<
      { updateOneContract: WithTypename<Contract> },
      MutationUpdateOneContractArgs
    >
    updateOneFile?: GraphCacheUpdateResolver<{ updateOneFile: WithTypename<File> }, MutationUpdateOneFileArgs>
    updateOneProject?: GraphCacheUpdateResolver<
      { updateOneProject: WithTypename<Project> },
      MutationUpdateOneProjectArgs
    >
    updateOneTask?: GraphCacheUpdateResolver<{ updateOneTask: WithTypename<Task> }, MutationUpdateOneTaskArgs>
    updateOneTaskGroup?: GraphCacheUpdateResolver<
      { updateOneTaskGroup: WithTypename<TaskGroup> },
      MutationUpdateOneTaskGroupArgs
    >
    updateOneTimeEntry?: GraphCacheUpdateResolver<
      { updateOneTimeEntry: WithTypename<TimeEntry> },
      MutationUpdateOneTimeEntryArgs
    >
    updateOneUser?: GraphCacheUpdateResolver<{ updateOneUser: WithTypename<User> }, MutationUpdateOneUserArgs>
    updatePassword?: GraphCacheUpdateResolver<{ updatePassword: Scalars["Boolean"] }, MutationUpdatePasswordArgs>
    updateReportTemplate?: GraphCacheUpdateResolver<
      { updateReportTemplate: WithTypename<AssetReportTemplate> },
      MutationUpdateReportTemplateArgs
    >
    updateScheduleTemplate?: GraphCacheUpdateResolver<
      { updateScheduleTemplate: WithTypename<ScheduleTemplate> },
      MutationUpdateScheduleTemplateArgs
    >
    updateTaskSortOrder?: GraphCacheUpdateResolver<
      { updateTaskSortOrder: Maybe<WithTypename<UpdateSortOrderPayload>> },
      MutationUpdateTaskSortOrderArgs
    >
    updateUserAssignment?: GraphCacheUpdateResolver<
      { updateUserAssignment: Maybe<WithTypename<UserAssignment>> },
      MutationUpdateUserAssignmentArgs
    >
  }
  Subscription?: {}
}

export type GraphCacheConfig = Parameters<typeof offlineExchange>[0] & {
  updates?: GraphCacheUpdaters
  keys?: GraphCacheKeysConfig
  optimistic?: GraphCacheOptimisticUpdaters
  resolvers?: GraphCacheResolvers
}
