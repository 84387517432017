import { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"
import { classNames } from "../../helpers/classNames"

type Props = {
  children: ReactNode
  className?: string
}

export const H3: FC<Props> = ({ children, className = "", ...props }) => (
  <h3
    {...props}
    className={overrideTailwindClasses(classNames("text-2xl lg:text-3xl text-gray-800 font-bold mt-2 mb-4", className))}
  >
    {children}
  </h3>
)
