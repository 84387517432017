import { Button, ButtonProps, IconButton, IconButtonProps, ListItemIcon, Menu, MenuItem } from "@mui/material"
import Link from "next/link"
import { AnchorHTMLAttributes, FC, ReactNode, useContext, useState } from "react"
import { DevelopmentFeatureFlagName } from "../helpers/api/developmentFeatureFlags"
import { classNames } from "../helpers/classNames"
import { DevelopmentFeatureFlagContext } from "../providers/DevelopmentFeatureFlagProvider"
import { DecisionContext, PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { CheckedPermission } from "../types/Permission"
import { TailwindIcon } from "../types/tailwind"
import { DrawerLink } from "./Partials/Drawer/DrawerLink"

export const testLabel_QuickMenuActionsButton = "quick-menu-actions-button"
export const testLabel_QuickMenuActionsMenuSection = "quick-menu-actions-menu-section"
export const testLabel_QuickMenuActionsMenuItem = "quick-menu-actions-menu-item"

type Colors = "red" | "green"

export type MenuItem = {
  value: ReactNode
  onClick?: () => void
  color?: Colors
  Icon?: TailwindIcon
  iconStyles?: string
  isDisabled?: boolean
  testId?: string
  requiredPermission?: CheckedPermission
  requiredPermissionContext?: DecisionContext
  requiredFeatureFlag?: DevelopmentFeatureFlagName
  drawer?: { href: string; component: ReactNode }
  href?: string
  target?: AnchorHTMLAttributes<HTMLAnchorElement>["target"]
}

type Props = {
  children: ReactNode
  className?: string
  items: MenuItem[][]
  noHover?: boolean
  buttonProps?: ButtonProps | IconButtonProps
  buttonShape?: "default" | "round"
  disabled?: boolean
}

const menuItemFormatting = "rounded whitespace-no-wrap flex items-center hover:bg-gray-100"

export const QuickMenuMui: FC<Props> = ({
  className,
  items,
  children,
  buttonProps,
  buttonShape = "default",
  disabled,
}) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)

  const [anchorEl, setAnchorElement] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorElement(e.currentTarget)
  const handleClose = () => setAnchorElement(null)

  if (items.flat().some((i) => i.requiredPermissionContext && !i.requiredPermission)) {
    // if requiredPermissionContext is present, requiredPermission must be passed, too.
    console.error("requiredPermissionContext must be used with requiredPermission")
    return null
  }

  return items.flat().length > 0 ? (
    <>
      <div className="flex justify-end">
        {buttonShape === "round" ? (
          <IconButton
            id="basic-button"
            className={className}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={!disabled ? handleClick : undefined}
            color="inherit"
            disabled={disabled}
            {...(buttonProps as IconButtonProps)}
          >
            {children}
          </IconButton>
        ) : (
          <Button
            id="basic-button"
            test-label={testLabel_QuickMenuActionsButton}
            className={className}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={!disabled ? handleClick : undefined}
            color="inherit"
            disabled={disabled}
            {...(buttonProps as ButtonProps)}
          >
            {children}
          </Button>
        )}
        {!disabled && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{ padding: 0 }}
          >
            {items.map((section, i) => (
              <div
                key={`section-${i}`}
                className={classNames(
                  "border-b border-gray-200 last:border-b-0 px-2",
                  items.length > 1 && "last:pt-2 first:pb-2"
                )}
                style={{ minWidth: "240px" }}
                test-label={testLabel_QuickMenuActionsMenuSection}
              >
                {section
                  .filter((item) =>
                    item.requiredPermission || item.requiredPermissionContext
                      ? hasPermissionTo(item.requiredPermission, item.requiredPermissionContext)
                      : true
                  )
                  .filter((item) => (item.requiredFeatureFlag ? flagIsEnabled(item.requiredFeatureFlag) : true))
                  .map(
                    (
                      {
                        color,
                        onClick,
                        isDisabled: disabled,
                        value,
                        Icon,
                        iconStyles = "h-5 w-5",
                        testId,
                        drawer,
                        href,
                        target,
                      },
                      i
                    ) => {
                      const iconClassName = classNames("mr-2.5", iconStyles)
                      if (drawer)
                        return (
                          <MenuItem
                            key={i}
                            className={classNames(menuItemFormatting, "p-0")}
                            data-test={testId}
                            test-label={testLabel_QuickMenuActionsMenuItem}
                            disabled={disabled}
                          >
                            <DrawerLink className="px-4 py-3 w-full" href={drawer.href} component={drawer.component}>
                              <div className="flex flex-row" onClick={handleClose}>
                                {Icon && <Icon className={iconClassName} />}
                                {value}
                              </div>
                            </DrawerLink>
                          </MenuItem>
                        )

                      if (href)
                        return (
                          <Link key={i} href={href} target={target}>
                            <MenuItem
                              className={classNames(
                                menuItemFormatting,
                                "px-4 py-3",
                                color === "red" && "text-red-600",
                                color === "green" && "text-green-600"
                              )}
                              data-test={testId}
                              test-label={testLabel_QuickMenuActionsMenuItem}
                              disabled={disabled}
                            >
                              {Icon && <Icon className={iconClassName} />}
                              {value}
                            </MenuItem>
                          </Link>
                        )

                      return (
                        <MenuItem
                          key={i}
                          className={classNames(
                            menuItemFormatting,
                            "px-4 py-3",
                            color === "red" && "text-red-600",
                            color === "green" && "text-green-600"
                          )}
                          data-test={testId}
                          test-label={testLabel_QuickMenuActionsMenuItem}
                          disabled={disabled}
                          onClick={(e) => {
                            e.stopPropagation()
                            onClick?.()
                            handleClose()
                          }}
                        >
                          {Icon && (
                            <ListItemIcon>
                              <Icon className={iconClassName} />
                            </ListItemIcon>
                          )}
                          {value}
                        </MenuItem>
                      )
                    }
                  )}
              </div>
            ))}
          </Menu>
        )}
      </div>
    </>
  ) : null
}
