import React, { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"
import { classNames } from "../../helpers/classNames"

type Props = {
  children: ReactNode
  className?: string
}

export const H4: FC<Props> = ({ children, className = "", ...props }) => (
  <h4
    {...props}
    className={overrideTailwindClasses(classNames("text-2xl text-gray-800 font-bold mt-2 mb-6", className))}
  >
    {children}
  </h4>
)
