import { useRouter } from "next/router"
import { FC } from "react"
import { BiChevronDown } from "react-icons/bi"
import { headerTestSelectors } from "../../../cypress/support/testSelectors"
import { classNames } from "../../../helpers/classNames"
import { useIsOrganizationMenuActive } from "../../../hooks/useIsOrganizationMenuActive"
import { useCurrentUser } from "../../../providers/PermissionsProvider/currentUserProvider"
import { QuickMenu } from "../../QuickMenu"
import { buildOrgLinks } from "../Header/Header"
import { FeatureFlagNavItem } from "./FeatureFlagNavItem"
import Logo from "./Logo"
import NavItem, { NavItemData } from "./NavItem"
import { WhatsNewNavItem } from "./WhatsNewNavItem"

type DesktopMenuProps = {
  navigation: NavItemData[]
}

export const DesktopMenu: FC<DesktopMenuProps> = ({ navigation }) => {
  const router = useRouter()
  const { isOrganizationMenuActive } = useIsOrganizationMenuActive()
  const { organizationId } = useCurrentUser()

  return (
    <div className="hidden xl:flex xl:w-64 xl:flex-col xl:fixed xl:inset-y-0 text-gray-800 z-20">
      <div className="flex-1 flex flex-col min-h-0 border-r bg-white">
        <div className="border-b h-16 pt-3">
          <QuickMenu menuButtonClassName="flex-1 h-10 mx-4 xl:w-fit" items={buildOrgLinks(organizationId, router)}>
            <div
              className={classNames(
                "flex flex-1 items-center justify-between max-w-full rounded-md transition-colors group hover:bg-gray-100"
              )}
              data-test={headerTestSelectors.orgMenu}
            >
              <Logo
                className="max-w-[200px] xl:max-w-auto"
                labelClassName={isOrganizationMenuActive ? "text-blue-600" : "text-gray-800"}
              />
              <BiChevronDown className="w-6 h-6 pb-[2px]" />
            </div>
          </QuickMenu>
        </div>
        <div className="flex-1 flex flex-col overflow-y-auto px-4 py-4 justify-between">
          <nav className="space-y-4">
            {navigation
              .filter(({ bottom }) => !bottom)
              .map((item) => (
                <NavItem item={item} isMobile={true} key={item.name} />
              ))}
          </nav>
          <nav className="mt-4 space-y-4">
            <FeatureFlagNavItem />
            <WhatsNewNavItem />
            {navigation
              .filter(({ bottom }) => !!bottom)
              .map((item) => (
                <NavItem item={item} isMobile={true} key={item.name} />
              ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
