import { FC, useContext } from "react"
import { BiFlag } from "react-icons/bi"
import { DevelopmentFeatureFlagContext } from "../../../providers/DevelopmentFeatureFlagProvider"
import { RenderIf } from "../../RenderIf"

type FeatureFlagNavItemProps = {
  handleClick?: () => void
}

export const FeatureFlagNavItem: FC<FeatureFlagNavItemProps> = ({ handleClick = () => {} }) => {
  const { setIsOpen } = useContext(DevelopmentFeatureFlagContext)

  return (
    <RenderIf permissionsInclude={"blackthornEmployee:*"}>
      <button
        className="w-full text-gray-700 hover:bg-gray-100 hover:text-gray-800 group flex items-center px-2 py-2 font-bold rounded-md transition-all text-base"
        onClick={() => {
          handleClick()
          setIsOpen(true)
        }}
      >
        <BiFlag className="mr-3 flex-shrink-0 h-6 w-6" />
        Feature Flags
      </button>
    </RenderIf>
  )
}
