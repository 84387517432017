import { createContext, FC, ReactNode, useContext } from "react"
import { useQuery } from "urql"
import { UserNotification, UserNotificationType } from "../graphql/generated/client-types-and-hooks"
import { graphql } from "../graphql/generated/gql"
import { useHandleError } from "../hooks/useHandleError"
import { PickPlus } from "../types/helpers"
import { SessionContext } from "./SessionProvider"

type ContextValue = {
  hasUnreadNotifications: boolean
  notifications: UserNotificationExpectation[]
  unreadNotifications: UserNotificationExpectation[]
}

type NotificationsProviderProps = {
  children: ReactNode
}

export type UserNotificationExpectation = PickPlus<UserNotification, "id" | "type" | "createdAt"> & {
  markedReadAt?: UserNotification["markedReadAt"]
  modelType: UserNotification["modelType"]
  asset?: { id: string; name: string } | null
  user?: { id: string; firstName: string; lastName: string } | null
}

export const NOTIFICATION_TYPES: Record<UserNotificationType, string> = {
  [UserNotificationType.AssetInspectionFailed]: "Asset Inspection Failed",
  [UserNotificationType.InjuryReportCreated]: "Injury Report",
}

export const NotificationsProviderDocument = graphql(`
  query NotificationsProvider($take: Int) {
    myNotifications(take: $take) {
      id
      modelType
      type
      markedReadAt
      createdAt

      asset {
        id
        name
      }

      user {
        id
        firstName
        lastName
      }
    }
  }
`)

export const NotificationsContext = createContext<ContextValue>({} as ContextValue)

export const NotificationsProvider: FC<NotificationsProviderProps> = ({ children }) => {
  const { flagIsEnabled } = useContext(SessionContext)

  const [{ data, error }] = useQuery({
    query: NotificationsProviderDocument,
    requestPolicy: "cache-first",
    variables: {
      take: 100,
    },
    pause: !flagIsEnabled("Notifications"),
  })

  useHandleError(error, "An error occurred while loading notifications. Please reload the page to try again.")

  const unreadNotifications = data?.myNotifications?.filter((notification) => !notification.markedReadAt) || []
  const hasUnreadNotifications = unreadNotifications.length > 0

  const notifications = data?.myNotifications || []

  return (
    <NotificationsContext.Provider value={{ hasUnreadNotifications, notifications, unreadNotifications }}>
      {children}
    </NotificationsContext.Provider>
  )
}
