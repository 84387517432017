import { devtoolsExchange } from "@urql/devtools"
import { authExchange } from "@urql/exchange-auth"
import { Cache, cacheExchange, Data, Entity, FieldInfo, Link } from "@urql/exchange-graphcache"
import { refocusExchange as visibilityChangeExchange } from "@urql/exchange-refocus"
import { parseISO } from "date-fns"
import { IntrospectionQuery } from "graphql"
import { createClient, Exchange, fetchExchange, gql, mapExchange } from "urql"
import customScalarsExchange from "urql-custom-scalars-exchange"
import { refreshSession } from "../data/api"
import { EXPIRED_TOKEN, INVALID_SESSION } from "../graphql/errors"
import {
  AssetAssignableType,
  ProjectStatus,
  QueryAssets_2Args,
  QueryAssetsArgs,
  QueryAssignedAssetsArgs,
} from "../graphql/generated/client-types-and-hooks"
import { GraphCacheConfig, QueryUsersArgs, UnitGoal, UserAssignment } from "../graphql/generated/graphcache"
import introspectionSchema from "../graphql/generated/introspection.json"
import { UserSearchFilter } from "../graphql/types/User"
import { Available } from "../helpers/assetStatus"
import { FeatureFlagContextValue } from "../providers/DevelopmentFeatureFlagProvider"
import { SessionContextValue } from "../providers/SessionProvider"
import { getSessionExp, sessionKeys, shouldRefresh } from "./jwtHelpers"
import { linkUserAssignment, updateImageCache, updateOrganizationClockedInCount } from "./urql/cacheHelpers"
import { refocusExchange } from "./urql/exchanges/focus"

// HELPER FUNCTIONS
const removeFromQuery = (cache: Cache, query: FieldInfo, key: string | null) => {
  const data = cache.resolve("Query", query.fieldKey)
  if (data && Array.isArray(data)) {
    cache.link("Query", query.fieldKey, data.filter((k) => k !== key) as Link<Entity>)
  }
}
const addToQuery = (cache: Cache, query: FieldInfo, newData: Data) => {
  const data = cache.resolve("Query", query.fieldKey)
  if (data && Array.isArray(data)) {
    data.push(newData)
    cache.link("Query", query.fieldKey, data as Link<Entity>)
  }
}
const removeManyFromQuery = (cache: Cache, query: FieldInfo, keys: (string | null)[]) => {
  const data = cache.resolve("Query", query.fieldKey)
  if (data && Array.isArray(data)) {
    cache.link("Query", query.fieldKey, data.filter((k) => !keys?.includes(k)) as Link<Entity>)
  }
}
const addManyToQuery = (cache: Cache, query: FieldInfo, newData: Data[]) => {
  const data = cache.resolve("Query", query.fieldKey)
  if (data && Array.isArray(data)) {
    data.push(...newData)
    cache.link("Query", query.fieldKey, data as Link<Entity>)
  }
}

const nullToUndefined = (obj: {}) =>
  Object.keys(obj).reduce((acc, key) => {
    // @ts-ignore The typing from the library doesn't allow for key iteration on this object type
    acc[key] = obj[key] != null ? obj[key] : undefined
    return acc
  }, {})

const scalarsExchange = customScalarsExchange({
  schema: introspectionSchema as unknown as IntrospectionQuery,
  scalars: {
    DateTime(value: string) {
      return parseISO(value)
    },
  },
})

export const urqlClient = (
  session: SessionContextValue,
  { flagIsEnabled: _flagIsEnabled }: FeatureFlagContextValue
) => {
  const exchanges = [
    visibilityChangeExchange(),
    scalarsExchange,

    // @ts-ignore the `GraphCacheConfig` type was broken recently so it no longer is properly typed for 'not
    // offline' uses.  I've got a bug/comment in w/ the author to see if there's a reason this is the case.
    // https://github.com/dotansimha/graphql-code-generator-community/pull/338
    cacheExchange<GraphCacheConfig>({
      schema: introspectionSchema as unknown as IntrospectionQuery,
      keys: {
        AssetGroup: (data) =>
          data.compositeKey || [data.assetGroupId, data.assignableId, data.assignableType, data.status].join("|"),
        AssetInventoryRequirements: () => null,
        AssetManufacturer: () => null,
        AssetPurchaseDetails: () => null,
        AssetRentalAgreement: () => null,
        AssetRentalAgreementRate: () => null,
        AssetReportInventoryReport: () => null,
        AssetReportInspectionSubmission: () => null,
        AssetStatusChange: () => null,
        ChildAncestorNode: ({ userId }) => userId || null,
        Customer: (data) => data.id || null,
        AssetReportTransferAssignment: () => null,
        AssetReportTransferReport: () => null,
        AssetVendorContact: () => null,
        TaskListItem: (data) => `task:${data.taskId};taskGroup:${data.taskGroupId}`,
        QueryUsersConnectionEdge: (data) => data.node!.id,
        // Offline event fields
        ClockInData: () => null,
        ClockOutData: () => null,
      },
      resolvers: {
        Query: {},
      },
      optimistic: {
        createUserAssignment: (args) => ({ __typename: "UserAssignment", id: new Date().toString(), ...args }),
        createWorkersCompCode: (args) => ({ __typename: "WorkersCompCode", id: new Date().toString(), ...args }),
        deleteOneAsset: (args, _cache) => {
          const now = new Date()
          return { ...args, updatedAt: now, deletedAt: now, __typename: "Asset" }
        },
        editOrganization: (args, _cache) => ({
          ...nullToUndefined(args),
          __typename: "Organization",
        }),
        editScheduledBreak: (args, _cache) => ({
          ...nullToUndefined(args),
          __typename: "ScheduledBreak",
        }),
        insertOneProject: (args, _cache) => {
          const dateStr = new Date().toISOString()
          return {
            id: dateStr,
            dateCreated: dateStr,
            isDefault: false,
            __typename: "Project",
            name: args.name,
            description: args.description,
          }
        },
        insertOneTask: (args, _cache) => ({
          ...args,
          scheduledBreaks: args.scheduledBreaks ? JSON.parse(JSON.stringify(args.scheduledBreaks)) : undefined,
          isDefault: false,
          unitGoals: (args.unitGoals as UnitGoal[]) || undefined,
          __typename: "Task",
        }),
        insertOneTimeEntry: (args) => ({ ...args, __typename: "TimeEntry" }),
        reassignUsers: (args, _cache) =>
          args.assignments.map((a) => ({
            __typename: "User",
            id: a.userId,
            taskId: a.taskId,
          })),
        restoreOneAsset: (args, _cache) => {
          return { ...args, updatedAt: new Date(), deletedAt: null, __typename: "Asset" }
        },
        updateOneAsset: (args, _cache, _info) => {
          return {
            ...nullToUndefined(args),
            __typename: "Asset",
            updatedAt: new Date(),
          }
        },
      },
      updates: {
        // TL;DR;
        // - Updates should include as much data as you can in order to "fix" the cache and shouldn't require any update logic here
        // - Deletes should just invalidate which removes it from the cache entirely
        // - Creates can just be "added" by pushing the new thing into anything that renders/links them
        Mutation: {
          activateOrganization: (result, args, cache) => {
            cache.invalidate("Query", "organizations", { archived: true })
            cache.invalidate("Query", "organizations", { archived: false })
            return { ...args, ...result.activateOrganization }
          },
          addOrUpdateNonWorkDay: (result, args, cache) => {
            const updatedTemplate = { ...args, ...result.addOrUpdateNonWorkDay, __typename: "ScheduleTemplate" }

            const queries = cache.inspectFields("Query").filter((field) => field.fieldName === "scheduleTemplates")

            queries.forEach((query) => {
              let data = cache.resolve("Query", query.fieldKey)

              if (data && Array.isArray(data)) {
                data.push(updatedTemplate)
                cache.link("Query", query.fieldKey, data as Link<Entity>)
              }
            })
          },
          addQuantitiesToGroup: (result, _args, cache) => {
            const key = cache.keyOfEntity(result.addQuantitiesToGroup)

            const inventoryGroup = cache.resolve(key, "childAssetGroups")

            // updates the inventory group
            if (Array.isArray(inventoryGroup)) {
              inventoryGroup.push(result.addQuantitiesToGroup)
              cache.link(key, "assetGroups", result.addQuantitiesToGroup as Link<Entity>)

              // updates the other groups for this asset
              cache
                .inspectFields("Query")
                .filter(
                  (query) => query.fieldName === "assetGroups" && query.arguments?.assetGroupId === _args.assetGroupId
                )
                // we have to invalidate the specific assetGroup since prisma.createMany() doesn't return the created assets
                .forEach((query) => cache.invalidate("Query", query.fieldKey))
            }
          },
          archiveQuantities: (result, args, cache) => {
            const keys = result.archiveQuantities.map((asset) => ({
              __typename: "Asset",
              id: asset.id || args.assetGroupId,
            }))

            cache
              .inspectFields("Query")
              .filter((query) => query.fieldName === "assetGroups")
              .forEach((query) => {
                addManyToQuery(cache, query, keys)
              })
          },
          archiveOrganization: (result, args, cache) => {
            cache.invalidate("Query", "organizations", { archived: true })
            cache.invalidate("Query", "organizations", { archived: false })
            return { ...args, ...result.archiveOrganization }
          },
          bulkClockIn: (result, args, cache) => {
            const successes = args.candidates.filter(
              (candidate) => !result.bulkClockIn.errors?.map(({ userId }) => userId).includes(candidate.userId)
            )

            successes.map((candidate) => {
              cache.writeFragment(
                gql`
                  fragment _ on QueryUsersConnectionEdge {
                    node {
                      id
                      isClockedIn
                    }
                  }
                `,
                { node: { id: candidate.userId, isClockedIn: true }, __typename: "QueryUsersConnectionEdge" }
              )

              cache.writeFragment(
                gql`
                  fragment _ on User {
                    id
                    isClockedIn
                  }
                `,
                {
                  id: candidate.userId,
                  isClockedIn: true,
                  __typename: "User",
                }
              )
            })

            updateOrganizationClockedInCount(cache, successes.length)
          },
          bulkClockOut: (result, args, cache) => {
            const successes = args.candidates.filter(
              (candidate) => !result.bulkClockOut.errors?.map(({ userId }) => userId).includes(candidate.userId)
            )

            successes.map((candidate) => {
              cache.writeFragment(
                gql`
                  fragment _ on QueryUsersConnectionEdge {
                    node {
                      id
                      isClockedIn
                    }
                  }
                `,
                { node: { id: candidate.userId, isClockedIn: false }, __typename: "QueryUsersConnectionEdge" }
              )

              cache.writeFragment(
                gql`
                  fragment _ on User {
                    id
                    isClockedIn
                  }
                `,
                {
                  id: candidate.userId,
                  isClockedIn: false,
                  __typename: "User",
                }
              )
            })

            updateOrganizationClockedInCount(cache, -successes.length)
          },
          bulkUpdateUserAssignments: (_r, args, cache, _i) => {
            args.assignmentsToDelete?.forEach((assignmentId) =>
              cache.invalidate({ __typename: "UserAssignment", id: assignmentId })
            )

            args.assignmentsToCreate?.forEach((assignment) => {
              const userKey = cache.keyOfEntity({ __typename: "User", id: assignment.userId })
              const projectKey = cache.keyOfEntity({ __typename: "Project", id: assignment.projectId })

              const newUserAssignment = {
                ...assignment,
                __typename: "UserAssignment",
                id: `${new Date().toISOString()}:u${assignment.userId}:p${assignment.projectId}`,
              } as UserAssignment

              linkUserAssignment(cache, newUserAssignment, userKey, projectKey)
            })
          },
          bulkUpdateTaskSortOrder: (_r, args, cache, _i) => {
            const { updates = [] } = args

            updates.forEach((update) => {
              const key = cache.keyOfEntity({ __typename: update.type, id: update.id })

              if (cache.resolve(key, "sortOrder")) {
                cache.writeFragment(
                  gql`
                    fragment _ on TaskListItem {
                      id
                      sortOrder
                    }
                  `,
                  {
                    id: update.id,
                    sortOrder: update.sortOrder,
                    __typename: "TaskListItem",
                  }
                )
              }
            })
          },
          clockIn: (result, _args, cache) => {
            if (result.clockIn) {
              const userData = result.clockIn.user
              cache.writeFragment(
                gql`
                  fragment _ on User {
                    id
                    isClockedIn
                    secondsClockedSinceOrgMidnight
                    latestTimeEntry {
                      id
                      endAt
                      evidence
                      startAt
                      taskId
                      userId
                    }
                  }
                `,
                {
                  id: result.clockIn.userId,
                  isClockedIn: userData?.isClockedIn || true,
                  latestTimeEntry: result.clockIn,
                  secondsClockedSinceOrgMidnight: userData?.secondsClockedSinceOrgMidnight,
                  __typename: "User",
                }
              )

              updateOrganizationClockedInCount(cache, 1)
            }
          },
          clockOut: (result, _args, cache) => {
            const { userId } = result.clockOut
            if (result.clockOut) {
              const userData = result.clockOut.user
              cache.writeFragment(
                gql`
                  fragment _ on User {
                    id
                    isClockedIn
                    secondsClockedSinceOrgMidnight
                    latestTimeEntry {
                      id
                      endAt
                      evidence
                      startAt
                      taskId
                      userId
                    }
                  }
                `,
                {
                  id: userId,
                  isClockedIn: userData?.isClockedIn || false,
                  latestTimeEntry: result.clockOut,
                  secondsClockedSinceOrgMidnight: userData?.secondsClockedSinceOrgMidnight,
                }
              )
            }
          },
          clockOutUser: (result, _args, cache) => {
            if (result.clockOutUser) {
              updateOrganizationClockedInCount(cache, -1)
            }
          },
          createDeliverableUnit: (result, args, cache) => {
            const newUnit = { ...args, ...result.createDeliverableUnit, __typename: "DeliverableUnit" }
            const data = cache.resolve("Query", "deliverableUnits")

            if (data && Array.isArray(data)) {
              data.push(newUnit)
              cache.link("Query", "deliverableUnits", data as Link<Entity>)
            }
          },
          createScheduledBreak: (result, args, cache) => {
            const newBreak = { ...args, ...result.createScheduledBreak, __typename: "ScheduledBreak" }
            const data = cache.resolve("Query", "scheduledBreaks")

            if (data && Array.isArray(data)) {
              data.push(newBreak)
              cache.link("Query", "scheduledBreaks", data as Link<Entity>)
            }
          },
          createScheduleTemplate: (result, args, cache) => {
            const newTemplate = { ...args, ...result.createScheduleTemplate, __typename: "ScheduleTemplate" }

            const queries = cache.inspectFields("Query").filter((field) => field.fieldName === "scheduleTemplates")

            queries.forEach((query) => {
              let data = cache.resolve("Query", query.fieldKey)

              if (data && Array.isArray(data)) {
                data.push(newTemplate)
                cache.link("Query", query.fieldKey, data as Link<Entity>)
              }
            })
          },
          createUser: (result, args, cache) => {
            const newUser = { ...args, ...result.createUser, __typename: "User" }
            const newEdge = {
              __typename: "QueryUsersConnectionEdge",
              node: newUser,
              cursor: btoa(`GPC:S:${newUser.id}`),
            }

            cache
              .inspectFields("Query")
              .filter((field) => field.fieldName === "users")
              .forEach((field) => {
                const newEdgeKey = cache.keyOfEntity(newEdge)

                let data = cache.resolve(cache.resolve("Query", field.fieldKey) as Entity, "edges")
                if (data && Array.isArray(data)) {
                  data.push(newEdge)

                  cache.link(newEdge, `${cache.resolve("Query", field.fieldKey)}.edges`, newEdgeKey)
                }
              })
          },
          createUserAssignment: (result, args, cache) => {
            const userKey = cache.keyOfEntity({ __typename: "User", id: args.userId })
            const projectKey = cache.keyOfEntity({ __typename: "Project", id: args.projectId })

            const newUA = { ...args, ...result?.createUserAssignment, __typename: "UserAssignment" } as UserAssignment

            linkUserAssignment(cache, newUA, userKey, projectKey)

            return newUA
          },
          createUserNotification: (result, args, cache) => {
            // refetch the notifications count
            cache.invalidate("Query", "myNotifications", { take: 100 })

            // might as well update the user's notifications while we're at it
            const newNotification = { ...args, ...result.createUserNotification, __typename: "UserNotification" }
            const data = cache.resolve("Query", "myNotifications")

            if (data && Array.isArray(data)) {
              data.push(newNotification)
              cache.link("Query", "myNotifications", data as Link<Entity>)
            }
          },
          markAllNotificationsRead: (_result, _args, cache) => {
            // refetch the notifications count
            cache.invalidate("Query", "myNotifications", { take: 100 })

            // might as well update the user's notifications while we're at it
            const data = cache.resolve("Query", "myNotifications")

            if (data && Array.isArray(data)) {
              data.forEach((notification) => {
                notification.readAt = new Date().toISOString()
              })
              cache.link("Query", "myNotifications", data as Link<Entity>)
            }
          },
          markNotificationsReadById: (_result, _args, cache) => {
            // refetch the notifications count
            cache.invalidate("Query", "myNotifications", { take: 100 })
          },
          createUnitGoal: (result, args, cache) => {
            const newUnitGoal = { ...args, ...result.createUnitGoal, __typename: "UnitGoal" }
            const key = cache.keyOfEntity({ __typename: "Task", id: args.taskId })

            const data = cache.resolve(key, "unitGoals")
            if (data && Array.isArray(data)) {
              data.push(newUnitGoal)
              cache.link(key, "unitGoals", data as Link<Entity>)
            }
          },
          createUnitOfMeasure: (result, args, cache) => {
            const newUnitOfMeasure = { ...args, ...result.createUnitOfMeasure, __typename: "UnitOfMeasure" }
            // UnitsOfMeasure are queried through organization.unitsOfMeasure
            // 1. Get the organization cache key
            const key = cache.keyOfEntity({
              __typename: "Organization",
              id: result.createUnitOfMeasure.organizationId || "",
            })

            // 2. Get the unitsOfMeasure field from the Organization cache
            const data = cache.resolve(key, "unitsOfMeasure")

            // 3. Append the new item to the list and link the cache
            if (data && Array.isArray(data)) {
              data.push(newUnitOfMeasure)
              cache.link("Query", "unitsOfMeasure", data as Link<Entity>)
            }
          },
          createWorkersCompCode: (result, args, cache) => {
            const newCode = { ...args, ...result.createWorkersCompCode, __typename: "WorkersCompCode" }
            const data = cache.resolve("Query", "workersCompCodes")
            if (data && Array.isArray(data)) {
              data.push(newCode)
              cache.link("Query", "workersCompCodes", data as Link<Entity>)
            } else {
              cache.link("Query", "workersCompCodes", [newCode])
            }
          },
          deleteOneAsset: (result, args, cache, _info) => {
            const deletedAsset = { ...args, ...result.deleteOneAsset, __typename: "Asset" }
            const key = cache.keyOfEntity(deletedAsset)!

            const assetQueries = cache.inspectFields("Query").filter((field) => field.fieldName === "assets")

            assetQueries.forEach((query) => {
              const { deleted, active } = query.arguments || {}
              let data = cache.resolve("Query", query.fieldKey)
              if (data && Array.isArray(data)) {
                if (deleted) data.push(deletedAsset)
                if (active) data = data.filter((k) => k !== key)
                cache.link("Query", query.fieldKey, data as Link<Entity>)
              }
            })

            return deletedAsset
          },
          deleteOneTask: (_result, args, cache, _info) => cache.invalidate({ __typename: "Task", id: args.id }),
          deleteOneTimeEntry: (_result, args, cache) => cache.invalidate({ __typename: "TimeEntry", id: args.id }),
          deleteUnitGoal: (_result, args, cache) => cache.invalidate({ __typename: "UnitGoal", id: args.id }),
          deleteUnitOfMeasure: (result, _args, cache) => {
            // UnitsOfMeasure are queried through organization.unitsOfMeasure
            return cache.invalidate({ __typename: "Organization", id: result.deleteUnitOfMeasure.organizationId || "" })
          },
          deleteOneUser: (result, args, cache) => {
            const userListQueryNames = ["users", "usersList"]
            const userKey = cache.keyOfEntity({ __typename: "User", id: args.id })
            const newUser = { ...args, ...result.deleteOneUser, __typename: "User" }

            const userQueries = cache
              .inspectFields("Query")
              .filter((field) => userListQueryNames.includes(field.fieldName))

            userQueries.forEach((query) => {
              // Remove the user from the list if it is an "active" userList status OR if it is a "users" query with no archived filter

              query.fieldName === "usersList" && query.arguments?.status === "active"
                ? removeFromQuery(cache, query, userKey)
                : addToQuery(cache, query, newUser)
              query.fieldName === "users" && ((query.arguments?.filter || {}) as UserSearchFilter).archived
                ? addToQuery(cache, query, newUser)
                : removeFromQuery(cache, query, userKey)
            })
          },
          deleteReportTemplate: (_result, args, cache) => {
            const key = cache.keyOfEntity({ id: args.id, __typename: "AssetReportTemplate" })
            const queries = cache
              .inspectFields("Query")
              .filter((field) => field.fieldName === "reusableAssetReportTemplates")

            queries.forEach((query) => removeFromQuery(cache, query, key))
          },
          deleteScheduledBreak: (_result, args, cache) =>
            cache.invalidate({ __typename: "ScheduledBreak", id: args.id }),
          deleteUserAssignment: (_r, args, cache, _i) =>
            cache.invalidate({ __typename: "UserAssignment", id: args.id }),
          deleteUserDeviceSession: (_r, args, cache, _i) =>
            cache.invalidate({ __typename: "UserDeviceSession", id: args.deviceSessionId }),
          duplicateReportTemplate: (result, _args, cache) => {
            const newTemplate = { ...result.duplicateReportTemplate, __typename: "AssetReportTemplate" }
            const data = cache.resolve("Query", "reusableAssetReportTemplates")

            if (data && Array.isArray(data)) {
              data.push(newTemplate)
              cache.link("Query", "reusableAssetReportTemplates", data as Link<Entity>)
            }
          },
          editOrganization: (result, args, cache) => {
            cache.invalidate({ __typename: result.editOrganization.__typename, id: args.id })
          },
          editUnitGoal: (result, args, _cache) => {
            return { ...args, ...result.editUnitGoal }
          },
          editUnitOfMeasure: (result, _args, cache) => {
            // UnitsOfMeasure are queried through organization.unitsOfMeasure
            cache.invalidate({
              __typename: result.editUnitOfMeasure.__typename,
              id: result.editUnitOfMeasure.organizationId || "",
            })
          },
          insertManyAssetReports: (result, args, cache) => {
            return result.insertManyAssetReports.forEach((report) => {
              const newReport = { ...args, ...report, __typename: "AssetReport" }

              cache
                .inspectFields("Query")
                .filter((query) => query.fieldName === "assetReports")
                .forEach((query) => {
                  const data = cache.resolve("Query", query.fieldKey)
                  if (Array.isArray(data)) {
                    data.push(newReport)
                    cache.link(newReport, `Query.${query.fieldKey}`, data as Link<Entity>)
                  }
                })

              return newReport
            })
          },
          insertOneAsset: (result, args, cache, _info) => {
            const newAsset = { ...args, ...result.insertOneAsset, __typename: "Asset" }
            const newAssetEdge = { __typename: "QueryAssets_2EdgeConnection" }

            if (newAsset.assignableId && newAsset.assignableType === "Asset") {
              const assetKey = cache.keyOfEntity({ __typename: "Asset", id: newAsset.assignableId })
              const asset = cache.resolve(assetKey, "childAssetGroups")
              if (Array.isArray(asset)) {
                asset.push(newAsset)
                cache.link(assetKey, "childAssetGroups", asset as Link<Entity>)
              }
            } else {
              cache
                .inspectFields("Query")
                .filter((field) => field.fieldName === "assets")
                .forEach((query) => {
                  if (!query.arguments?.deleted) {
                    const data = cache.resolve("Query", query.fieldKey)
                    if (Array.isArray(data)) {
                      data.push(newAsset)
                      cache.link(newAsset, `Query.${query.fieldKey}`, data as Link<Entity>)
                    }
                  }
                })

              cache
                .inspectFields("Query")
                .filter((field) => field.fieldName === "assets_2")
                .forEach((query) => {
                  const newEdgeKey = cache.keyOfEntity(newAssetEdge)
                  if (!query.arguments?.isDeleted) {
                    const data = cache.resolve(cache.resolve("Query", query.fieldKey) as Entity, "edges")
                    if (Array.isArray(data)) {
                      data.push(newAssetEdge)
                      cache.link(newAssetEdge, `Query.${query.fieldKey}.edges`, newEdgeKey)
                    }
                  }
                })
            }

            return newAsset
          },
          insertOneProject: (result, args, cache, _info) => {
            const newProject = { ...args, ...result.insertOneProject, __typename: "Project" }
            const projectsByStatusKey = cache.keyOfField("projectsByStatus", { status: ProjectStatus.Active })
            const projectsByStatusActive = cache.resolve("Query", projectsByStatusKey!)
            const activeProjects = cache.resolve("Query", "activeProjects")
            if (Array.isArray(projectsByStatusActive)) {
              projectsByStatusActive.push(newProject)
              cache.link("Query", projectsByStatusKey!, projectsByStatusActive as Link<Entity>)
            }
            if (Array.isArray(activeProjects)) {
              activeProjects.push(newProject)
              cache.link("Query", "activeProjects", activeProjects as Link<Entity>)
            }
            return newProject
          },
          insertOneTask: (result, args, cache) => {
            cache.invalidate("Query", "project", { id: args.projectId })
            return { ...args, ...result, __typename: "Task" }
          },
          insertReportTemplate: (result, args, cache) => {
            const newTemplate = { ...args, ...result.insertReportTemplate, __typename: "AssetReportTemplate" }

            const queries = cache
              .inspectFields("Query")
              .filter((field) => field.fieldName === "reusableAssetReportTemplates")

            queries.forEach((query) => {
              let data = cache.resolve("Query", query.fieldKey)
              if (data && Array.isArray(data)) {
                data.push(newTemplate)
                cache.link("Query", query.fieldKey, data as Link<Entity>)
              }
            })
          },
          markTaskCompletion: (result, args, cache, _info) => {
            const { markTaskCompletion: completedTask } = result
            const fragment = gql`
              fragment _ on TaskListItem {
                taskId
                taskGroupId
                isComplete
                completedTaskCount
              }
            `
            const isTaskComplete = Boolean(completedTask?.isComplete)

            // Update the task
            cache.writeFragment(fragment, {
              taskId: args.id,
              taskGroupId: null,
              isComplete: isTaskComplete,
              completedTaskCount: isTaskComplete ? 1 : 0,
              __typename: "TaskListItem",
            })

            // If there is a group, update it too
            if (completedTask?.groupId) {
              const existingTaskItem = cache.readFragment(
                fragment,
                `TaskListItem:task:null;taskGroup:${completedTask.groupId}`
              )

              const completedTaskCount = isTaskComplete
                ? existingTaskItem?.completedTaskCount + 1
                : existingTaskItem.completedTaskCount - 1

              cache.writeFragment(fragment, {
                taskId: null,
                taskGroupId: completedTask.groupId,
                isComplete: isTaskComplete,
                completedTaskCount,
                __typename: "TaskListItem",
              })
            }
          },
          reassignUser: (result, args, cache) => {
            const userListingQueryNames = ["usersList", "users"]

            const newData = {
              ...{ id: args.userId, currentTaskId: args.taskId, taskId: args.taskId, latestTimeEntry: {} },
              ...result.reassignUser,
            }
            cache.writeFragment(
              gql`
                fragment _ on User {
                  id
                  currentProjectId
                  currentTaskId
                  projectId
                  taskId
                  firstName
                  imageUrl
                  isClockedIn
                  jobTitle
                  lastName
                  secondsClockedSinceOrgMidnight
                  latestTimeEntry {
                    id
                    durationInSeconds
                    startAt
                  }
                  currentProject {
                    id
                    name
                  }
                  currentTask {
                    id
                    name
                  }
                  project {
                    id
                    name
                  }
                  task {
                    id
                    name
                  }
                }
              `,
              newData
            )

            // Also, update any queries we can find that might be affected by this change.
            const userKey = cache.keyOfEntity({ id: args.userId, __typename: "User" })
            const connectionQueries = cache
              .inspectFields("Query")
              .filter((field) => userListingQueryNames.includes(field.fieldName))
            connectionQueries.forEach((query) => {
              if (query.arguments?.taskId !== args.taskId) {
                let data = cache.resolve("Query", query.fieldKey)
                if (data && Array.isArray(data)) {
                  const newList = data.filter((k) => k !== userKey)
                  cache.link("Query", query.fieldKey, newList)
                }
              } else {
                // Ok, it matches... let's instead add it.
                let data = cache.resolve("Query", query.fieldKey)
                if (data && Array.isArray(data)) {
                  const newList = data.slice()
                  newList.push(newData)
                  cache.link("Query", query.fieldKey, newList)
                }
              }
            })
          },
          reassignUsers: (result, args, cache) => {
            const userListingQueryNames = ["users"]

            // Zip result and args together.
            const zipped = args.assignments.map((assignment) => ({
              ...{
                id: assignment.userId,
                currentTaskId: assignment.taskId,
                taskId: assignment.taskId,
                latestTimeEntry: {},
              },
              ...(result.reassignUsers.find((r) => r.id === assignment.userId) || {}),
            }))
            zipped.forEach((newData) => {
              cache.writeFragment(
                gql`
                  fragment _ on User {
                    id
                    currentProjectId
                    currentTaskId
                    projectId
                    taskId
                    firstName
                    imageUrl
                    isClockedIn
                    jobTitle
                    lastName
                    secondsClockedSinceOrgMidnight
                    latestTimeEntry {
                      id
                      durationInSeconds
                      startAt
                    }
                    currentProject {
                      id
                      name
                    }
                    currentTask {
                      id
                      name
                    }
                    project {
                      id
                      name
                    }
                    task {
                      id
                      name
                    }
                  }
                `,
                newData
              )

              const newEdge = {
                __typename: "QueryUsersConnectionEdge",
                node: newData,
                cursor: btoa(`GPC:S:${newData.id}`),
              }
              const newEdgeKey = cache.keyOfEntity(newEdge)

              const connectionQueries = cache
                .inspectFields("Query")
                .filter((field) => userListingQueryNames.includes(field.fieldName))

              connectionQueries.forEach((query) => {
                if (query && query.arguments) {
                  const { filter } = query.arguments as QueryUsersArgs

                  let data = cache.resolve(cache.resolve("Query", query.fieldKey) as Entity, "edges")
                  if (!Array.isArray(data)) return

                  // This currently only works for updating the users filtered by task
                  if (!filter?.taskId) return

                  if (filter?.taskId === newData.taskId) {
                    // add the user to the list
                    data.push(newEdge)
                    cache.link("Query", query.fieldKey, data as Link<Entity>)
                  } else {
                    // remove the user from the list
                    const filteredData = data.filter((cacheKey) => cacheKey !== newEdgeKey)
                    cache.link("Query", query.fieldKey, filteredData as Link<Entity>)
                  }
                }
              })
            })
          },
          reportTaskProgress: (result, args, _cache) => ({
            ...args,
            ...result.reportTaskProgress,
            __typename: "TaskProgressEvent",
          }),
          restoreOneAsset: (result, args, cache, _info) => {
            const restoredAsset = { ...args, ...result.restoreOneAsset, __typename: "Asset" }
            const key = cache.keyOfEntity(restoredAsset)

            const assetQueries = cache.inspectFields("Query").filter((field) => field.fieldName === "assets")

            assetQueries.forEach((query) => {
              const { deleted } = (query?.arguments as QueryAssetsArgs) || {}
              let data = cache.resolve("Query", query.fieldKey)

              if (data && Array.isArray(data)) {
                if (!deleted) data.push(restoredAsset)
                if (deleted) data = data?.filter((k) => k !== key)
                cache.link("Query", query.fieldKey, data as Link<Entity>)
              }
            })

            return { ...args, ...result, __typename: "Asset" }
          },

          restoreOneUser: (result, args, cache) => {
            const userListQueryNames = ["users", "usersList"]
            const userKey = cache.keyOfEntity({ __typename: "User", id: args.id })
            const newUser = { ...args, ...result.restoreOneUser }

            const userQueries = cache
              .inspectFields("Query")
              .filter((field) => userListQueryNames.includes(field.fieldName))

            userQueries.forEach((query) => {
              // Remove the user from the list if it is an "active" userList status OR if it is a "users" query with no archived filter
              query.fieldName === "usersList" && query.arguments?.status === "active"
                ? addToQuery(cache, query, newUser)
                : removeFromQuery(cache, query, userKey)
              query.fieldName === "users" && ((query.arguments?.filter || {}) as UserSearchFilter).archived
                ? removeFromQuery(cache, query, userKey)
                : addToQuery(cache, query, newUser)
            })
          },
          returnQuantityToInventory: (_result, args, cache) => {
            const key = cache.keyOfEntity({ ...args, __typename: "AssetGroup" })

            const assetGroupFragment = gql`
              fragment _ on AssetGroup {
                assetGroupId
                assignableId
                assignableType
                status
                count
              }
            `
            // get the assetGroup we're returning
            const data = cache.readFragment(assetGroupFragment, args)
            const newCount = (data?.count || 0) - args.quantityToReturn

            // if there is at least one asset left in this group, update the count
            if (newCount >= 1) {
              cache.writeFragment(assetGroupFragment, {
                ...args,
                count: newCount,
                __typename: "AssetGroup",
              })
            }

            // otherwise, remove the grouped asset from the list
            if (newCount < 1) {
              cache
                .inspectFields("Query")
                .filter((query) => query.fieldName === "assetGroups")
                .forEach((query) => {
                  const queryData = cache.resolve("Query", query.fieldKey)

                  if (queryData && Array.isArray(queryData)) {
                    const filteredData = queryData.filter((cacheKey) => cacheKey !== key)
                    cache.link("Query", query.fieldKey, filteredData)
                  }
                })
            }

            // inventory row is where assetGroupId === assignableId && assignableType === 'Asset'
            const inventoryGroupArgs = {
              ...args,
              assignableId: args.assetGroupId,
              assignableType: AssetAssignableType.Asset,
              status: Available,
            }

            // update the count on the inventory row
            const inventoryData = cache.readFragment(assetGroupFragment, inventoryGroupArgs)
            const newInventoryCount = (inventoryData?.count || 0) + args.quantityToReturn

            cache.writeFragment(assetGroupFragment, {
              ...inventoryGroupArgs,
              count: newInventoryCount,
            })
          },
          transferAssets: (
            result,
            { assetIds, assignableId, assignableType, assetGroupReassignments, projectIdIfTask },
            cache,
            _info
          ) => {
            // There's no way to remove the asset from any old listings right now... but at least if we update it to the current listings that'll be a win.
            const keys = assetIds.map((id) => ({ __typename: "Asset", id }))

            const asset_v1_queries = cache.inspectFields("Query").filter((query) => query.fieldName === "assets")
            const asset_v2_queries = cache.inspectFields("Query").filter((query) => query.fieldName === "assets_2")
            const assigned_asset_queries = cache
              .inspectFields("Query")
              .filter((query) => query.fieldName === "assignedAssets")

            asset_v1_queries.forEach((query) => {
              const { userId, projectId, taskId } = (query?.arguments || {}) as QueryAssetsArgs
              // For each filter, if the asset is in the list, remove it unless it is the new assignment... then add it.

              if (assignableType === "User") {
                userId === assignableId
                  ? addManyToQuery(cache, query, keys)
                  : removeManyFromQuery(
                      cache,
                      query,
                      keys.map((k) => cache.keyOfEntity(k))
                    )
              }
              if (assignableType === "Task") {
                taskId === assignableId || projectIdIfTask === projectId
                  ? addManyToQuery(cache, query, keys)
                  : removeManyFromQuery(
                      cache,
                      query,
                      keys.map((k) => cache.keyOfEntity(k))
                    )
              }
            })

            asset_v2_queries.forEach((query) => {
              const { filter } = (query?.arguments || {}) as QueryAssets_2Args
              const { userId, projectId, taskId } = filter || {}
              // For each filter, if the asset is in the list, remove it unless it is the new assignment... then add it.

              if (assignableType === "User") {
                userId === assignableId
                  ? addManyToQuery(cache, query, keys)
                  : removeManyFromQuery(
                      cache,
                      query,
                      keys.map((k) => cache.keyOfEntity(k))
                    )
              }
              if (assignableType === "Task") {
                taskId === assignableId || projectIdIfTask === projectId
                  ? addManyToQuery(cache, query, keys)
                  : removeManyFromQuery(
                      cache,
                      query,
                      keys.map((k) => cache.keyOfEntity(k))
                    )
              }
            })

            assigned_asset_queries.forEach((query) => {
              const { filter } = (query?.arguments || {}) as QueryAssignedAssetsArgs
              const { userId, projectId, taskId } = filter || {}

              if (assignableType === "User") {
                userId === assignableId || projectIdIfTask === projectId
                  ? addManyToQuery(cache, query, keys)
                  : removeManyFromQuery(
                      cache,
                      query,
                      keys.map((k) => cache.keyOfEntity(k))
                    )
              }

              if (assignableType === "Task") {
                taskId === assignableId || projectIdIfTask === projectId
                  ? addManyToQuery(cache, query, keys)
                  : removeManyFromQuery(
                      cache,
                      query,
                      keys.map((k) => cache.keyOfEntity(k))
                    )
              }
            })

            /* The below fragments should update the current groups/assets themselves...
              NOTE: These updates rely on "unneeded at the backend but useful for cache updates" data which is passed to the API (probs should just use that at the API instead...)
            */
            const groupIds = assetGroupReassignments?.flatMap((g) => g.ids || []) || []

            // Ok, normal asset assignments...
            assetIds.forEach((id) => {
              if (groupIds.includes(id)) return null
              cache.writeFragment(
                gql`
                  fragment _ on Asset {
                    id
                    assignableId
                    assignableType
                  }
                `,
                {
                  id,
                  assignableId,
                  assignableType,
                }
              )
            })

            // Also update the asset groups
            assetGroupReassignments?.map(({ filter, ids }) => {
              const newGroupKey = cache.keyOfEntity({
                ...filter,
                assignableId,
                assignableType,
                __typename: "AssetGroup",
              })

              const countOfCurrent = cache.resolve(newGroupKey, "count") as number | null

              result.transferAssets.forEach((assetTransferred) => {
                const key = cache.keyOfEntity(assetTransferred)
                const entityInCache = cache.resolve(newGroupKey, "compositeKey")

                // if assetTransferred is in the cache
                if (entityInCache) {
                  // update it
                  cache.writeFragment(
                    gql`
                      fragment _ on AssetGroup {
                        assetGroupId
                        assignableId
                        assignableType
                        status
                        count
                      }
                    `,
                    {
                      ...filter,
                      assignableId,
                      assignableType,
                      count: (countOfCurrent || 0) + ids.length,
                    }
                  )
                } else {
                  // create the entity
                  cache
                    .inspectFields("Query")
                    .filter((query) => query.fieldName === "assetGroups")
                    .forEach((query) => {
                      const data = cache.resolve("Query", query.fieldKey)

                      if (Array.isArray(data)) {
                        data.push(assetTransferred)
                        cache.link(key, query.fieldKey, assetTransferred as Link<Entity>)
                      }
                    })
                }
              })

              const oldGroupKey = cache.keyOfEntity({
                ...filter,
                __typename: "AssetGroup",
              })

              const countOfOldAssignment = cache.resolve(oldGroupKey, "count") as number | null
              const newCount = countOfOldAssignment ? countOfOldAssignment - ids.length : 0

              // if old group still has a quantity, update it
              if (newCount > 0) {
                cache.writeFragment(
                  gql`
                    fragment _ on AssetGroup {
                      assetGroupId
                      assignableId
                      assignableType
                      status
                      count
                    }
                  `,
                  {
                    ...filter,
                    assetChildCount: countOfOldAssignment ? countOfOldAssignment - ids.length : 0,
                    count: countOfOldAssignment ? countOfOldAssignment - ids.length : 0,
                  }
                )
              } else {
                // remove the group
                cache
                  .inspectFields("Query")
                  .filter((query) => query.fieldName === "assetGroups")
                  .forEach((query) => {
                    const data = cache.resolve("Query", query.fieldKey)

                    if (Array.isArray(data)) {
                      const fromGroupCompositeKey = cache.resolve(oldGroupKey, "compositeKey")
                      const filtered = data.filter(
                        (compositeKey) => compositeKey !== `AssetGroup:${fromGroupCompositeKey}`
                      )
                      cache.link("Query", query.fieldKey, filtered)
                    }
                  })
              }
            })
          },
          unarchiveQuantities: (_result, args, cache) => {
            const key = cache.keyOfEntity({ ...args, __typename: "AssetGroup" })

            const fragment = gql`
              fragment _ on AssetGroup {
                assetGroupId
                assignableId
                assignableType
                status
                count
              }
            `
            const data = cache.readFragment(fragment, args)
            const newCount = (data?.count || 0) - args.quantityToUnarchive

            // if there are still some quantity in archived status, update the count
            if (newCount >= 1) {
              cache.writeFragment(fragment, {
                ...args,
                count: newCount,
                __typename: "AssetGroup",
              })
            }

            // otherwise, remove the grouped asset from the archived list
            if (newCount < 1) {
              cache
                .inspectFields("Query")
                .filter((query) => query.fieldName === "archivedAssetGroups")
                .forEach((query) => {
                  const queryData = cache.resolve("Query", query.fieldKey)

                  if (queryData && Array.isArray(queryData)) {
                    const filteredData = queryData.filter((cacheKey) => cacheKey !== key)
                    cache.link("Query", query.fieldKey, filteredData)
                  }
                })
            }
          },
          updateOneAsset: async (result, args, _cache, _info) => {
            if (args.photoId && result.updateOneAsset?.imageUrl) {
              updateImageCache(result.updateOneAsset.imageUrl)
            }

            return {
              ...args,
              ...result.updateOneAsset,
              __typename: "Asset",
            }
          },
          updateOneProject: (result, args, cache, _info) => {
            if (args.image && result.updateOneProject.imageUrl) {
              updateImageCache(result.updateOneProject.imageUrl)
            }

            if (args.schedule) {
              const schedule = {
                ...args.schedule,
                __typename: "ScheduleTemplate",
              }

              cache.writeFragment(
                gql`
                  fragment _ on Project {
                    id
                    scheduleTemplate {
                      id
                      isDefault
                      nonWorkDays {
                        id
                        active
                        dateRange
                        name
                      }
                      workDays {
                        active
                        label
                      }
                      workHours {
                        endTime
                        hours
                        startTime
                      }
                      __typename
                    }
                  }
                `,
                {
                  id: args.id,
                  scheduleTemplate: schedule,
                  __typename: "Project",
                }
              )
            }

            if (args.scheduledBreaks) {
              const breaks = { ...args.scheduledBreaks, __typename: "ScheduledBreak" }

              cache.writeFragment(
                gql`
                  fragment _ on Project {
                    id
                    scheduledBreaks {
                      id
                      breakTask {
                        id
                        name
                      }
                      durationInMinutes
                      isActive
                      localizedStartTime
                      name
                      projectId
                      __typename
                    }
                  }
                `,
                {
                  id: args.id,
                  scheduledBreaks: breaks,
                  __typename: "Project",
                }
              )
            }

            return {
              ...args,
              ...result.updateOneProject,
              __typename: "Project",
            }
          },
          updateOneTask: (result, args, cache) => {
            if (args.schedule) {
              const schedule = {
                ...args.schedule,
                __typename: "ScheduleTemplate",
              }
              cache.writeFragment(
                gql`
                  fragment _ on Task {
                    id
                    scheduleTemplate {
                      id
                      isDefault
                      nonWorkDays {
                        id
                        active
                        dateRange
                        name
                      }
                      workDays {
                        active
                        label
                      }
                      workHours {
                        endTime
                        hours
                        startTime
                      }
                      __typename
                    }
                  }
                `,
                {
                  id: args.id,
                  scheduleTemplate: schedule,
                  __typename: "Task",
                }
              )
            }

            if (args.scheduledBreaks) {
              const breaks = { ...args.scheduledBreaks, __typename: "ScheduledBreak" }

              cache.writeFragment(
                gql`
                  fragment _ on Task {
                    id
                    scheduledBreaks {
                      id
                      breakTask {
                        id
                        name
                      }
                      durationInMinutes
                      isActive
                      localizedStartTime
                      name
                      projectId
                      __typename
                    }
                  }
                `,
                {
                  id: args.id,
                  scheduledBreaks: breaks,
                  __typename: "Task",
                }
              )
            }

            const fragment = gql`
              fragment _ on TaskListItem {
                taskId
                taskGroupId
                name
              }
            `
            cache.writeFragment(fragment, {
              taskId: args.id,
              taskGroupId: null,
              name: args.name,
              __typename: "TaskListItem",
            })

            return { ...args, ...result.updateOneTask, __typename: "Task" }
          },
          updateOneTaskGroup: (result, args, cache) => {
            const fragment = gql`
              fragment _ on TaskListItem {
                taskId
                taskGroupId
                name
              }
            `
            cache.writeFragment(fragment, {
              taskId: null,
              taskGroupId: args.id,
              name: args.name,
              __typename: "TaskListItem",
            })
            return { ...args, ...result.updateOneTaskGroup, __typename: "Task" }
          },
          updateOneTimeEntry: (result, args, _cache) => ({
            ...args,
            ...result.updateOneTimeEntry,
            __typename: "TimeEntry",
          }),
          updateOneUser: (result, args, _cache) => {
            if (args.image && result.updateOneUser.imageUrl) {
              updateImageCache(result.updateOneUser.imageUrl)
            }
            return { ...args, ...result.updateOneUser, __typename: "User" }
          },
          updateReportTemplate: (result, args, cache) => {
            const template = gql`
              query AssetReportTemplate($id: String!) {
                assetReportTemplate(id: $id) {
                  id
                  assetsCount
                  createdAt
                  deletedAt
                  fields {
                    id
                    label
                    photoRequired
                    photoLabel
                    rule
                    failedStatus
                    required
                    type
                  }
                  name
                  reusable
                  universal
                }
              }
            `

            cache.updateQuery({ query: template, variables: { id: args.id } }, (_oldData) => {
              return { data: { assetReportTemplate: { ...result.updateReportTemplate } } }
            })
          },
        },
      },
    }),
    mapExchange({
      onError(error, _operation) {
        const isInvalidSession = error.graphQLErrors.some((e) => e.extensions?.code === INVALID_SESSION)
        if (isInvalidSession) session.logout()
      },
    }),
    authExchange(async (utils) => {
      return {
        addAuthToOperation: (operation) => {
          const accessToken = localStorage.getItem(sessionKeys.accessToken)
          if (!accessToken) return operation
          return utils.appendHeaders(operation, {
            Authorization: accessToken,
          })
        },
        didAuthError: (error, _operation) => {
          return error.graphQLErrors.some((e) => e.extensions?.code === EXPIRED_TOKEN)
        },
        refreshAuth: async () => {
          const refreshToken = localStorage.getItem(sessionKeys.refreshToken)
          await refreshSession(refreshToken)
        },
        willAuthError(_operation) {
          const exp = getSessionExp()
          if (!exp) return true
          return shouldRefresh(exp, 4)
        },
      }
    }),
    fetchExchange,
  ]
  if (process.env.NODE_ENV !== "production") {
    exchanges.unshift(devtoolsExchange, refocusExchange())
  }

  return createClient({
    url: "/api/graphql",
    requestPolicy: "cache-and-network",
    exchanges: exchanges as Exchange[],
  })
}
