// Page Layout

export const headerTestSelectors = {
  open: "header:open-quick-menu",
  logout: "header:logout",
  hamburgerMenu: "header:hamburger-menu",
  orgMenu: "header:orgMenu",
  orgMenuItems: {
    companyDetails: "header:companyDetails",
    admins: "header:admins",
    roles: "header:roles",
    units: "header:units",
    workersCompCodes: "header:workersCompCodes",
    settings: "header:settings",
  },
}
export const navigationTestSelectors = {
  assets: "navigation:assets",
  dashboard: "navigation:home",
  kitchenSink: "navigation:kitchenSink",
  organizations: "navigation:organizations",
  projects: "navigation:projects",
  reports: "navigation:reports",
  team: "navigation:team",
}

// Dashboard
export const dashboardTestSelectors = {
  activeProjects: "dashboard-card:activeProjects",
  myTime: "dashboard-card:myTime",
  counts: {
    activeProjects: "dashboard-card:counts:activeProjects",
    clockedIn: "dashboard-card:counts:clockedIn",
    hoursThisWeek: "dashboard-card:counts:hoursThisWeek",
  },
  links: {
    assets: "dashboard-card:links:assets",
    projects: "dashboard-card:links:projects",
    team: "dashboard-card:links:team",
  },
  notifications: "dashboard-card:notifications",
}

// Organizations

export const organizationTabsTestSelectors = {
  admins: "organizationTab:admins",
  details: "organizationTab:details",
  roles: "organizationTab:roles",
  settings: "organizationTab:settings",
  units: "organizationTab:units",
  workersCompCodes: "organizationTab:workersCompCodes",
}

// Projects

export const projectRowTestSelectors = {
  name: "projectRow:name",
}
export const projectPageTestSelectors = {
  tabs: {
    tasks: "projectPage:tasksTab",
    schedule: "projectPage:scheduleTab",
    team: "projectPage:teamTab",
    details: "projectPage:detailsTab",
    assets: "projectPage:assetsTab",
    documents: "projectPage:documentsTab",
  },
}
export const projectsPageTestSelectors = {
  tabs: {
    active: "projectsPage:activeTab",
    completed: "projectsPage:completedTab",
    archived: "projectsPage:archivedTab",
  },
}

// Tasks
export const taskDrawerTestSelectors = {
  tabs: {
    details: "taskDrawer:detailsTab",
    team: "taskDrawer:teamTab",
    assets: "taskDrawer:assetsTab",
    summary: "taskDrawer:summaryTab",
  },
  assetsTab: {
    reassign: "taskDrawer:assetsTab:reassignAsset",
  },
}

// Team

export const teamMemberProfilePageTestSelectors = {
  tabs: {
    assets: "teamMemberAccountPageTab:assets",
    details: "teamMemberAccountPageTab:details",
    documents: "teamMemberAccountPageTab:documents",
    offlineEvents: "teamMemberAccountPageTab:offlineEvents",
    status: "teamMemberAccountPageTab:status",
    timeCards: "teamMemberAccountPageTab:timeCards",
  },
}
export const teamPageTestSelectors = {
  tabs: {
    active: "teamPage:active",
    archived: "teamPage:archived",
  },
}

// Assets

export const assetsMemberProfilePageTestSelectors = {
  tabs: {
    status: "assetsMemberAccountPageTab:status",
    assets: "assetsMemberAccountPageTab:assets",
    reports: "assetsMemberAccountPageTab:reports",
    details: "assetsMemberAccountPageTab:details",
    documents: "assetsMemberAccountPageTab:documents",
    history: "assetsMemberAccountPageTab:history",
  },
}
export const assetsPageTestSelectors = {
  tabs: {
    active: "assetsPage:active",
    inactive: "assetsPage:inactive",
    archived: "assetsPage:archived",
  },
}

// Users

export const userBadgeTestSelectors = {
  name: "userBadge:name",
}
