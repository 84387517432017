import Error from "next/error"
import { FC, Fragment, useState } from "react"
import { BiCheck, BiX } from "react-icons/bi"
import { useQuery } from "urql"
import { H4, H5 } from "../../components/Elements"
import Layout from "../../components/Layout/Layout"
import { OrganizationsSkeleton } from "../../components/Partials/Organizations/Organizations.skeleton"
import { RenderIf } from "../../components/RenderIf"
import { RoleNameSet as PermissionNames } from "../../data/roleDefinitions"
import { Role } from "../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../graphql/generated/gql"
import { classNames } from "../../helpers/classNames"
import { getHumanReadablePermissionName } from "../../helpers/getHumanReadablePermissionName"
import { useHandleError } from "../../hooks/useHandleError"
import { GrantedPermission } from "../../types/Permission"
import { PickPlus } from "../../types/helpers"
import OrganizationHeader from "./_organizationHeader"
import Tabs from "./_tabs"

type PermissionGroup = {
  title: string
  permissions: GrantedPermission[]
}

type RoleExpectation = PickPlus<Role, "id" | "name" | "permissions">

const prefixFilteredRoleNames = function (...prefixes: string[]): GrantedPermission[] {
  return [...PermissionNames].sort().filter((permissionName) => {
    return prefixes.some((prefix) => permissionName.startsWith(prefix))
  })
}

export const permissionGroups = [
  {
    title: "Customization",
    permissions: prefixFilteredRoleNames("app:"),
  },
  {
    title: "Team Management",
    permissions: prefixFilteredRoleNames("user:", "timeEntry:"),
  },
  {
    title: "Project Management",
    permissions: prefixFilteredRoleNames("project:", "task:"),
  },
  {
    title: "Asset Management",
    permissions: prefixFilteredRoleNames("asset:", "asset:"),
  },
  {
    title: "Organization Management",
    permissions: prefixFilteredRoleNames("unit:"),
  },
] as PermissionGroup[]

const roleHasPermission = (role: RoleExpectation, permission: GrantedPermission) => {
  return role.permissions.includes(permission)
}

const renderCheckOrX = (role: RoleExpectation, permission: GrantedPermission) => {
  if (roleHasPermission(role, permission)) {
    return <BiCheck className="h-6 w-6 text-green-600" />
  }

  // If the role has * for the scope then inherently they can do any other scope
  const [subject, action, scope] = permission.split(":")
  if (scope !== "*" && roleHasPermission(role, `${subject}:${action}:*` as GrantedPermission)) {
    return <BiCheck className="h-6 w-6 text-green-600" />
  }

  return <BiX className="h-6 w-6" />
}

type PermissionGroupProps = {
  permissionGroup: PermissionGroup
  roles: RoleExpectation[]
}
const DesktopPermissionGroup: FC<PermissionGroupProps> = ({ permissionGroup, roles }) => {
  return (
    <table className="hidden md:block">
      <thead>
        <tr>
          <th className="p-2 md:pr-24 text-left whitespace-nowrap uppercase font-medium">Permissions</th>
          {roles.map((role) => {
            return (
              <th className="p-2 md:pr-12 text-left whitespace-nowrap uppercase font-medium" key={role.id}>
                {role.name}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {permissionGroup.permissions.map((permission) => {
          return (
            <tr className="text-gray-400" key={permission}>
              <td className="p-2">
                <pre data-permission={permission}>{getHumanReadablePermissionName(permission)}</pre>
              </td>
              {roles
                .sort((a, b) => b.permissions.length - a.permissions.length)
                .map((role) => {
                  return (
                    <td className="p-2" key={role.id}>
                      {renderCheckOrX(role, permission)}
                    </td>
                  )
                })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const MobilePermissionGroup: FC<PermissionGroupProps> = ({ permissionGroup, roles }) => {
  const [selectedRole, setSelectedRole] = useState(roles.length ? roles[0] : ({} as RoleExpectation))

  return (
    <div className="block md:hidden">
      <div className="flex gap-2 overflow-x-auto pb-3">
        {roles.map((role) => {
          return (
            <button
              className={classNames(
                "py-2 px-4 md:pr-12 text-left whitespace-nowrap uppercase font-medium rounded",
                selectedRole.id === role.id && "bg-gray-200"
              )}
              key={role.id}
              onClick={() => setSelectedRole(role)}
            >
              {role.name}
            </button>
          )
        })}
      </div>
      <div>
        {permissionGroup.permissions.map((permission) => {
          return (
            <div className="text-gray-400" key={permission}>
              {roles.map((role) => {
                if (role.id !== selectedRole.id) {
                  return <Fragment key={role.id} />
                }
                return (
                  <div className="py-3 flex items-center gap-2" key={role.id}>
                    <div>{renderCheckOrX(role, permission)}</div>
                    <div className="p-2">
                      <pre data-permission={permission}>{getHumanReadablePermissionName(permission)}</pre>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const OrgRolesQueryDocument = graphql(`
  query OrganizationRolesPage {
    myOrganization {
      id
      dateCreated
      imageUrl
      name
      roles {
        id
        name
        permissions
      }
    }
  }
`)

const OrganizationRoles = () => {
  const [{ fetching: orgIsLoading, data: org, error: orgError }] = useQuery({
    query: OrgRolesQueryDocument,
    requestPolicy: "cache-first",
  })

  useHandleError(orgError, "There was an error fetching your org.")

  const roles = org?.myOrganization?.roles.sort() ?? []

  return (
    <RenderIf permissionsInclude={["fe-organizationRoles:read"]} fallbackComponent={<Error statusCode={404} />}>
      <Layout title={`${org?.myOrganization?.name} Roles`}>
        {orgIsLoading || (!org && <OrganizationsSkeleton />)}
        {!orgIsLoading && org && (
          <>
            <OrganizationHeader org={org?.myOrganization} />
            <Tabs />
            <H4>Roles &amp; Permissions</H4>

            <div className="max-w-full overflow-x-auto">
              {permissionGroups.map((permissionGroup) => {
                return (
                  <div key={permissionGroup.title}>
                    <H5 className="border-b py-4">{permissionGroup.title}</H5>
                    <DesktopPermissionGroup permissionGroup={permissionGroup} roles={roles} />
                    <MobilePermissionGroup permissionGroup={permissionGroup} roles={roles} />
                  </div>
                )
              })}
            </div>
          </>
        )}
      </Layout>
    </RenderIf>
  )
}
export default OrganizationRoles
