import resolveConfig from "tailwindcss/resolveConfig.js"
import tailwindConfig from "../tailwind.config.js"

const fullConfig = resolveConfig(tailwindConfig)

export const colors = fullConfig.theme.colors

export const swatchColors = [
  ["#c2410c", "#ea580c", "#f97316", "#fb923c", "#fdba74"],
  ["#b45309", "#d97706", "#f59e0b", "#fbb124", "#fcd34d"],
  ["#a16207", "#ca8a04", "#eab308", "#facc15", "#fde047"],
  ["#4d7c0f", "#65a30d", "#84c616", "#a3e635", "#bef264"],
  ["#15803d", "#16a34a", "#22c55e", "#4ade80", "#6eefac"],
  ["#047857", "#059669", "#10b981", "#34d399", "#86efac"],
  ["#0f766e", "#0d9488", "#14b8a6", "#2dd4bf", "#5eead4"],
  ["#0e7490", "#0891b2", "#06b6d4", "#22d3ee", "#67e8f9"],
  ["#0369a1", "#0284c7", "#0ea5e9", "#38bdf8", "#7dd3fc"],
  ["#1d4ed8", "#2563eb", "#3b82f6", "#60a5fa", "#93c5fd"],
  ["#433c9a", "#4f46e5", "#6366f1", "#818cf8", "#a5b4fc"],
  ["#6d28d9", "#7c3aed", "#8b5cf6", "#a78bfa", "#c4b5fd"],
  ["#7e22ce", "#9333ea", "#a855f7", "#c084fc", "#d8b4fe"],
  ["#a21caf", "#c026d3", "#d946ef", "#e879f9", "#f0abfc"],
  ["#be185d", "#db2777", "#ec4899", "#f472b6", "#f9a8d4"],
  ["#be123c", "#e11d48", "#f43f5e", "#fb7185", "#fda4af"],
]

export const randomColor = [ "#f97316", "#22c55e", "#0ea5e9", "#a855f7", "#f59e0b", "#10b981", "#3b82f6", "#d946ef", "#eab308", "#14b8a6", "#6366f1", "#ec4899", "#84c616", "#06b6d4", "#8b5cf6", "#f43f5e", "#ea580c", "#16a34a", "#0284c7", "#9333ea", "#d97706", "#059669", "#2563eb", "#c026d3", "#ca8a04", "#0d9488", "#4f46e5", "#db2777", "#65a30d", "#0891b2", "#7c3aed", "#e11d48", "#c2410c", "#15803d", "#0369a1", "#7e22ce", "#b45309", "#047857", "#1d4ed8", "#a21caf", "#a16207", "#0f766e", "#433c9a", "#be185d", "#4d7c0f", "#0e7490", "#6d28d9", "#be123c" ]