import { FC, ReactNode } from "react"
import { classNames } from "../../helpers/classNames"

type Props = {
  children: ReactNode
  className?: string
}

export const Table: FC<Props> = ({ className = "", children }) => {
  return (
    <div className={classNames("flex flex-col gap-y-2", "md:border-t md:border-gray-200 md:gap-y-0", className)}>
      {children}
    </div>
  )
}
