import { FC } from "react"
import { classNames } from "../../../helpers/classNames"

type DrawerBodyProps = {
  children: React.ReactNode
  className?: string
}

export const DrawerBody: FC<DrawerBodyProps> = ({ children, className }) => {
  return <div className={classNames("px-4 py-3.5", "sm:px-6", "md:py-6 md:px-8", className)}>{children}</div>
}
