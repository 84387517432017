import { DevelopmentFeatureFlag } from "../helpers/api/developmentFeatureFlags"
import { sessionKeys } from "../lib/jwtHelpers"
import { RefreshResponse, SessionUser } from "../services/auth"
import { ContentType } from "../types/ContentType"
import { Organization } from "../types/Organization"
import { Role } from "../types/Role"

type Options = {
  headers?: any
}

async function post<T>(url: string, body: any = {}, options: Options = {}): Promise<T> {
  const headers: RequestInit["headers"] = {
    "Content-Type": "application/json",
  }
  if (options?.headers?.authorization) headers["Authorization"] = options.headers.authorization

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    credentials: "same-origin",
    headers,
  })
  return response.json()
}

async function get<T>(url: string, params?: any, options: Options = {}): Promise<T> {
  const query = new URLSearchParams(params)
  if (params) url += `?${query.toString()}`

  const headers: RequestInit["headers"] = {}
  if (options?.headers?.authorization) headers["Authorization"] = options.headers.authorization

  const response = await fetch(url, {
    method: "GET",
    credentials: "same-origin",
    headers,
  })

  return response.json()
}

async function remove<T>(url: string, params?: any, options: Options = {}): Promise<T> {
  const query = new URLSearchParams(params)
  if (params) url += `?${query.toString()}`

  const headers: RequestInit["headers"] = {}
  if (options?.headers?.authorization) headers["Authorization"] = options.headers.authorization

  const response = await fetch(url, {
    method: "DELETE",
    credentials: "same-origin",
    headers,
  })

  return response.json()
}

export const checkSingleUseToken = async (token?: string) => {
  const body = token ? { token } : {}
  const data = await get("/api/auth/check_single_use_token", body)
  return data as { valid: boolean }
}

export const refreshSession = async (refreshToken: string | null) => {
  const data: RefreshResponse = await post("/api/auth/refresh", { refreshToken })
  if (data.accessToken) localStorage.setItem(sessionKeys.accessToken, data.accessToken)
  if (data.refreshToken) localStorage.setItem(sessionKeys.refreshToken, data.refreshToken)

  return data
}

export const fetchSession = async () => {
  const data = await get(
    "/api/auth/user_session",
    {},
    { headers: { authorization: localStorage.getItem(sessionKeys.accessToken) } }
  )
  return data as { user: SessionUser }
}

export const getFeatureFlags = async (email?: string) => {
  const data = await get("/api/auth/feature_flags", email ? { email } : {}, {
    headers: { authorization: localStorage.getItem(sessionKeys.accessToken) },
  })
  return data as { featureFlags: DevelopmentFeatureFlag[] }
}

export const logout = async (params: { allSessions?: boolean } = {}) => {
  return get("/api/auth/logout", params, { headers: { authorization: localStorage.getItem(sessionKeys.accessToken) } })
}

type LoginParams =
  | {
      email: string
      password: string
      persistSession?: boolean
    }
  | {
      token: string
    }
export const login = async (params: LoginParams) => {
  const data: {
    accessToken: string
    refreshToken: string
  } = await post("/api/auth/login", params)
  if (data.accessToken) localStorage.setItem(sessionKeys.accessToken, data.accessToken)
  if (data.refreshToken) localStorage.setItem(sessionKeys.refreshToken, data.refreshToken)
}

export const resetPasswordRequest = async (email: string) => {
  return post("/api/auth/send_password_reset_email", { email })
}

export const sendLoginLink = async (email: string) => {
  return post("/api/auth/send_login_link", { email })
}

export const resetPassword = async (password: string, token: string) => {
  return post("/api/auth/reset_password", { password, token })
}

export const getEmailStatus = async (email: string) => {
  const data = await get("/api/auth/email_status", { email })
  return data as { passwordResetRequired: boolean }
}

export const addOrganization = async (name: string, defaultRoles: Role[]) => {
  const data = await post(
    "/api/add-organization",
    {
      name,
      defaultRoles,
    },
    { headers: { authorization: localStorage.getItem(sessionKeys.accessToken) } }
  )

  return data as Organization
}

export const getFileUploadSignedPutUrl = async (name: string, type: string) => {
  const data = await post<{ url: string; objectKey: string; expiration: string; fileId: string }>(
    "/api/file",
    {
      name,
      type,
    },
    { headers: { authorization: localStorage.getItem(sessionKeys.accessToken) } }
  )
  return data
}

export const getAssetDocumentsSignedPutUrl = async (
  name: string,
  type: string,
  assetId: string,
  status?: string,
  expiresAt?: Date | string
) => {
  const data = await post<{
    url: string
    objectKey: string
    expiration: string
    fileId: string
    assetId: string
    status: string
    expiresAt?: string
  }>(
    "/api/asset-document-signed-put-url",
    {
      name,
      type,
      assetId,
      status,
      expiresAt,
    },
    { headers: { authorization: localStorage.getItem(sessionKeys.accessToken) } }
  )

  return data
}

export const getUserDocumentsSignedPutUrl = async (
  name: string,
  type: string,
  userId: string,
  status?: string,
  expiresAt?: Date | string
) => {
  const data = await post<{
    url: string
    objectKey: string
    expiration: string
    fileId: string
    userId: string
    status: string
    expiresAt?: string
  }>(
    "/api/user-document-signed-put-url",
    {
      name,
      type,
      userId,
      status,
      expiresAt,
    },
    { headers: { authorization: localStorage.getItem(sessionKeys.accessToken) } }
  )

  return data
}

export const getProjectDocumentSignedPutUrl = async (
  name: string,
  type: string,
  projectId?: string,
  status?: string,
  expiresAt?: Date | string
) => {
  const data = await post<{
    url: string
    objectKey: string
    expiration: string
    fileId: string
    projectId: string
    status: string
    expiresAt?: string
  }>(
    "/api/project-document-signed-put-url",
    {
      name,
      type,
      projectId,
      status,
      expiresAt,
    },
    { headers: { authorization: localStorage.getItem(sessionKeys.accessToken) } }
  )

  return data
}

export const deleteFile = async (fileId: string) => {
  const data = await remove(
    "/api/file",
    { fileId },
    { headers: { authorization: localStorage.getItem(sessionKeys.accessToken) } }
  )
  return data
}

export const getTimeEntryEvidenceSignedPutUrl = async (contentType: ContentType, userId: string) => {
  const data = await get<{ url: string; path: string }>(
    "/api/time-entry-evidence-signed-put-url",
    {
      contentType,
      id: userId,
    },
    { headers: { authorization: localStorage.getItem(sessionKeys.accessToken) } }
  )
  return data
}
