import { format } from "date-fns"
import { FC } from "react"
import { BiCalendar } from "react-icons/bi"
import { H2 } from "../../components/Elements"
import { OrganizationImage } from "../../components/OrganizationImage"
import { Organization } from "../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../types/helpers"

type Props = {
  org: PickPlus<Organization, "dateCreated" | "imageUrl" | "name">
}

const OrganizationHeader: FC<Props> = ({ org }) => {
  if (!org) {
    return <></>
  }

  return (
    <div className="flex mb-4 gap-x-4">
      <OrganizationImage organization={org} />
      <div className="md:ml-4 flex flex-col justify-center truncate">
        <H2 className="text-gray-800 truncate my-0 w-full">{org.name}</H2>
        {org.dateCreated && (
          <div className="flex items-center gap-0.5 text-gray-600">
            <BiCalendar />
            <div className="truncate">Created {format(new Date(org.dateCreated), "MMMM yyyy")}</div>
          </div>
        )}
      </div>
    </div>
  )
}
export default OrganizationHeader
