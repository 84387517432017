import { Box, Typography } from "@mui/material"
import { useRouter } from "next/dist/client/router"
import Link from "next/link"
import { AnchorHTMLAttributes, FC, useContext } from "react"
import { DevelopmentFeatureFlagName } from "../../../helpers/api/developmentFeatureFlags"
import { classNames } from "../../../helpers/classNames"
import { colors } from "../../../helpers/colors"
import { DevelopmentFeatureFlagContext } from "../../../providers/DevelopmentFeatureFlagProvider"
import { NotificationsContext, UserNotificationExpectation } from "../../../providers/NotificationsProvider"
import { CheckedPermission } from "../../../types/Permission"
import { TailwindIcon } from "../../../types/tailwind"
import { RenderIf } from "../../RenderIf"

export type NavItemData = {
  name: string
  icon: TailwindIcon
  href: string
  count?: number
  requiredPermission?: CheckedPermission
  requiredDevelopmentFeatureFlag?: DevelopmentFeatureFlagName
  target?: AnchorHTMLAttributes<HTMLAnchorElement>["target"]
  testSelector?: string
  bottom?: boolean
  getApplicableNotificationsCount?: (notifications: UserNotificationExpectation[]) => number
}

type NavItemProps = {
  item: NavItemData
  isMobile: boolean
}

type ItemProps = {
  item: NavItemData
  isMobile: boolean
}

const Item: FC<ItemProps> = ({ item, isMobile }) => {
  const router = useRouter()
  const isHighlighted = () => {
    if (item.href === "/" && router.pathname !== "/") return false
    else return router.pathname.startsWith(item.href)
  }

  const { unreadNotifications } = useContext(NotificationsContext)

  return (
    <Link
      href={item.href}
      className={classNames(
        isHighlighted() ? "bg-blue-50 text-blue-600" : "text-gray-700 hover:bg-gray-100 hover:text-gray-800",
        "group flex justify-between items-center px-2 py-2 font-bold rounded-md transition-all",
        isMobile ? "text-base" : "text-lg"
      )}
      target={item.target}
      data-test={item.testSelector}
    >
      <Box display="flex" alignItems="center">
        <item.icon
          className={classNames("mr-3 flex-shrink-0 h-6 w-6", isMobile ? "mr-3" : "mr-4")}
          aria-hidden="true"
        />
        {item.name}
      </Box>
      {item.getApplicableNotificationsCount && item.getApplicableNotificationsCount(unreadNotifications) > 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="18px"
          height="18px"
          className="rounded-full bg-orange-600"
        >
          <Typography fontWeight="bold" fontSize="10px" color={colors.white}>
            {item.getApplicableNotificationsCount(unreadNotifications)}
            <span className="sr-only">unread messages</span>
          </Typography>
        </Box>
      )}
    </Link>
  )
}

const NavItem: FC<NavItemProps> = ({ item, isMobile }) => {
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)

  if (!item.requiredDevelopmentFeatureFlag || flagIsEnabled(item.requiredDevelopmentFeatureFlag)) {
    return item.requiredPermission ? (
      <RenderIf permissionsInclude={item.requiredPermission}>
        <Item item={item} isMobile={isMobile} />
      </RenderIf>
    ) : (
      <Item item={item} isMobile={isMobile} />
    )
  }

  return null
}

export default NavItem
