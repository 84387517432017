import { ComponentType, createContext, FC, ReactNode, useState } from "react"
import { ModalProps } from "../hooks/useModalProps"

type PropType = { modalProps: ModalProps; [key: string]: any }
type ShowFunction = <P extends { modalProps: ModalProps }>(component: ComponentType<P> | FC<P>, props: P) => void

type ContextValue = {
  state: State | null
  showModal: ShowFunction
}

type ModalProviderProps = {
  children: ReactNode
}

type State = {
  component: ComponentType<any>
  props: PropType
}

export const ModalContext = createContext<ContextValue>({} as ContextValue)

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [state, setState] = useState<State | null>(null)
  function showModal<P extends { modalProps: ModalProps }>(component: ComponentType<P> | FC<P>, props: P): void {
    setState({ component, props })
  }

  return (
    <ModalContext.Provider value={{ showModal, state: null }}>
      {state !== null && (
        <state.component
          {...state.props}
          modalProps={{
            ...state.props.modalProps,
            isOpen: true,
            handleCloseModal: () => {
              setState(null)
              state.props.modalProps.handleCloseModal()
            },
          }}
        />
      )}
      {children}
    </ModalContext.Provider>
  )
}
