import React, { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"
import { classNames } from "../../helpers/classNames"

type Props = {
  children: ReactNode
  className?: string
}

export const H1: FC<Props> = ({ children, className = "", ...props }) => (
  <h1
    {...props}
    className={overrideTailwindClasses(classNames("text-4xl lg:text-5xl text-gray-800 font-sans mt-2 mb-4", className))}
  >
    {children}
  </h1>
)
