import { useRouter } from "next/router"
import { createContext, FC, ReactNode, useEffect, useState } from "react"
import { Drawer } from "../components/Partials/Drawer/Drawer"

type ContextValue = {
  push: (children: ReactNode) => void
  pop: () => void
  clearAll: () => void
  stack: ReactNode[] | null
}

export const DrawerContext = createContext<ContextValue>({} as ContextValue)

export const DrawerProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter()
  const [stack, setStack] = useState<ReactNode[] | null>(null)

  const push = (children: ReactNode) => {
    setStack((previous) => {
      if (!previous) return [children]

      return [...previous, children]
    })
  }

  const pop = () => {
    setStack((previous) => {
      if (!previous) return null

      return previous.slice(0, -1)
    })
  }

  const clearAll = () => {
    setStack(null)
  }

  useEffect(() => {
    const handleRouteChange = (_: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        setStack(null)
      }
    }

    router.events.on("routeChangeStart", handleRouteChange)

    return () => router.events.off("routeChangeStart", handleRouteChange)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DrawerContext.Provider
      value={{
        clearAll,
        pop,
        push,
        stack,
      }}
    >
      {stack?.map((drawerContent, index) => (
        <Drawer level={index} key={index}>
          {drawerContent}
        </Drawer>
      ))}
      {children}
    </DrawerContext.Provider>
  )
}
