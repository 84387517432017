import Link from "next/link"
import { useRouter } from "next/router"
import { FC, Fragment, useContext, useEffect, useState } from "react"
import { BiLeftArrowAlt } from "react-icons/bi"
import { classNames } from "../helpers/classNames"
import { BreadcrumbsContext } from "../providers/BreadcrumbsProvider"
import { BreadcrumbsSkeleton } from "./Breadcrumbs.skeleton"

export type Breadcrumb = {
  label: string
  href: string
  spliceAtIndex?: number
}

export type LabelSubstitution = {
  original: string
  replacement: string
}

type Props = {
  labelSubstitutions?: LabelSubstitution[]
  additionalBreadcrumbs?: Breadcrumb[]
}

const buildBreadcrumbs = (
  routerPath: string,
  labelSubstitutions?: LabelSubstitution[],
  additionalBreadcrumbs?: Breadcrumb[]
): Breadcrumb[] => {
  const paths = routerPath.split("?")[0].split("/") // Handle query params by excluding them from breadcrumbing
  const breadcrumbs = paths
    .map((path, i) => {
      return {
        label: convertBreadcrumb(
          path,
          labelSubstitutions?.map(({ original }) => original) || [],
          labelSubstitutions?.map(({ replacement }) => replacement) || []
        ),
        href: paths.slice(0, i + 1).join("/"),
      }
    })
    .filter((crumb) => crumb.label)

  if (additionalBreadcrumbs) {
    additionalBreadcrumbs?.forEach((additionalBreadcrumb, i) =>
      breadcrumbs.splice(additionalBreadcrumb?.spliceAtIndex || i, 0, additionalBreadcrumb)
    )
  }
  // remove the active tab from the breadcrumb
  if (breadcrumbs.length > 1) breadcrumbs.pop()
  return breadcrumbs
}

const convertBreadcrumb = (value: string, find: string[], replace: string[]) => {
  return find
    .reduce((acc, cur, i) => {
      return acc.replace(cur, replace[i])
    }, value)
    .replace(/-/g, " ")
}

export const Breadcrumbs: FC<Props> = () => {
  const router = useRouter()
  const { additionalBreadcrumbs, countSegments, isLoading, labelSubstitutions } = useContext(BreadcrumbsContext)
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])

  useEffect(() => {
    setBreadcrumbs(buildBreadcrumbs(router.asPath, labelSubstitutions, additionalBreadcrumbs))
  }, [additionalBreadcrumbs, labelSubstitutions, router.asPath])

  if (isLoading) return <BreadcrumbsSkeleton countSegments={countSegments || 2} />

  return breadcrumbs?.length > 0 ? (
    <ol className={`flex gap-2 items-center text-gray-600 truncate`}>
      <li className={"cursor-pointer mr-1"}>
        <div
          className="flex items-center justify-center leading-[0] hover:text-gray-800 transition-colors"
          onClick={() => router.back()}
        >
          <BiLeftArrowAlt className="h-5 w-5" />
        </div>
      </li>
      {breadcrumbs.map((breadcrumb, i) => {
        const isLastItem = i >= breadcrumbs.length - 1
        const isSameRoute = router.asPath === breadcrumb.href
        const isLink = !(isLastItem || isSameRoute)

        return (
          <Fragment key={breadcrumb.href}>
            <li className="gap-1 md:gap-2 text-gray-600 capitalize truncate">
              <div className="md:max-w-[14em] truncate">
                <Link
                  href={breadcrumb.href}
                  className={classNames(isLink && "hover:text-gray-800 transition-colors", !isLink && "cursor-default")}
                  title={breadcrumb.label}
                >
                  {breadcrumb.label}
                </Link>
              </div>
            </li>
            <li className={classNames(isLastItem && "hidden", "text-gray-600")}>/</li>
          </Fragment>
        )
      })}
    </ol>
  ) : (
    <div></div>
  )
}
