import { SwipeableDrawer } from "@mui/material"
import { createContext, FC, useContext, useEffect, useMemo, useState } from "react"
import { classNames } from "../../../helpers/classNames"
import { useIsIos } from "../../../hooks/useIsIos"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { DrawerContext } from "../../../providers/DrawerProvider"

type DrawerProps = {
  children: React.ReactNode
  level: number
}

type ContextValue = {
  handleClose: () => void
  level: number
}

export const SingleDrawerContext = createContext<ContextValue>({} as ContextValue)

export const Drawer: FC<DrawerProps> = ({ children, level }) => {
  const [isOpen, setIsOpen] = useState(false)
  const drawerContext = useContext(DrawerContext)
  const { width: browserWidth } = useWindowSize()
  const isMobile = useMemo(() => (browserWidth || 0) < 768, [browserWidth])
  const isIos = useIsIos()

  // This is prevents the drawer from opening on mount and not getting the nice animation
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true)
    }, 10)
  }, [])

  const handleClose = () => {
    setIsOpen(false)

    setTimeout(() => {
      drawerContext.pop()
    }, 200)
  }

  return (
    <SwipeableDrawer
      anchor={isMobile ? "bottom" : "right"}
      disableBackdropTransition={isMobile}
      disableDiscovery={isIos}
      onClose={handleClose}
      onOpen={() => {}}
      open={isOpen}
      PaperProps={{
        classes: {
          root: classNames(
            `top-[${(level + 1) * 10}px] max-w-full rounded-t-xl`,
            "md:top-0 md:rounded-t-none",
            `lg:w-[90%] lg:max-w-[${1000 - level * 10}px]`
          ),
        },
      }}
    >
      <SingleDrawerContext.Provider value={{ handleClose, level }}>{children}</SingleDrawerContext.Provider>
    </SwipeableDrawer>
  )
}

// don't purge these :)
// lg:max-w-[1000px] lg:max-w-[990px] lg:max-w-[980px] lg:max-w-[970px] lg:max-w-[960px] lg:max-w-[950px] lg:max-w-[940px] lg:max-w-[930px] lg:max-w-[920px] lg:max-w-[910px] lg:max-w-[900px] lg:max-w-[890px] lg:max-w-[880px] lg:max-w-[870px] lg:max-w-[860px] lg:max-w-[850px] lg:max-w-[840px] lg:max-w-[830px] lg:max-w-[820px] lg:max-w-[810px] lg:max-w-[800px] lg:max-w-[790px] lg:max-w-[780px] lg:max-w-[770px] lg:max-w-[760px] lg:max-w-[750px] lg:max-w-[740px] lg:max-w-[730px] lg:max-w-[720px] lg:max-w-[710px]
// top-[10px] top-[20px] top-[30px] top-[40px] top-[50px] top-[60px] top-[70px] top-[80px] top-[90px] top-[100px] top-[110px] top-[120px] top-[130px] top-[140px] top-[150px] top-[160px] top-[170px] top-[180px] top-[190px] top-[200px] top-[210px] top-[220px] top-[230px] top-[240px] top-[250px] top-[260px] top-[270px] top-[280px] top-[290px]
