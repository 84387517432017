import { FC } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { classNames } from "../../../helpers/classNames"
import { OrganizationImage } from "../../OrganizationImage"

type Props = {
  className?: string
  labelClassName?: string
}

const LogoQueryDocument = graphql(`
  query LogoQuery {
    myOrganization {
      id
      name
      imageUrl
    }
  }
`)

const Logo: FC<Props> = ({ className, labelClassName }) => {
  const [{ data }] = useQuery({ query: LogoQueryDocument, requestPolicy: "cache-first" })

  return (
    <div
      className={classNames(
        "xl:flex-1 w-full flex items-center justify-start gap-2 h-10 leading-tight truncate",
        className
      )}
    >
      <span className={classNames("rounded-md flex-shrink-0", "xl:pl-0")}>
        <OrganizationImage className="w-6 h-6" organization={data?.myOrganization} />
      </span>
      <div className={classNames("text-sm uppercase font-bold leading-tight truncate pr-2", labelClassName)}>
        {data?.myOrganization?.name || "..."}
      </div>
    </div>
  )
}

export default Logo
