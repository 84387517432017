import React, { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"
import { classNames } from "../../helpers/classNames"

type Props = {
  children: ReactNode
  className?: string
}

export const H5: FC<Props> = ({ children, className = "", ...props }) => (
  <h5
    {...props}
    className={overrideTailwindClasses(classNames("text-xl text-gray-800 font-bold mt-2 mb-4", className))}
  >
    {children}
  </h5>
)
