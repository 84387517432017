import { useMemo } from "react"
import { gql, useQuery } from "urql"
import { OrganizationBillingStatus } from "../graphql/generated/graphcache"

const MY_ORGANIZATION_QUERY = gql`
  query OrganizationBillingStatus {
    myOrganization {
      id
      billingStatus

      lastStripeInvoice {
        id
        hostedInvoiceUrl
      }
    }
  }
`

export const useOrganizationBillingStatus = () => {
  const [{ data, ...rest }] = useQuery({ query: MY_ORGANIZATION_QUERY, requestPolicy: "cache-first" })

  const organizationBillingStatus = useMemo(() => {
    if (!data?.myOrganization?.billingStatus) return OrganizationBillingStatus.Active

    return data?.myOrganization?.billingStatus
  }, [data?.myOrganization?.billingStatus])

  const linkToStripeInvoiceToPay = data?.myOrganization?.lastStripeInvoice?.hostedInvoiceUrl || undefined

  return { linkToStripeInvoiceToPay, organizationBillingStatus, ...rest }
}
