import { setUser } from "@sentry/nextjs"
import { createContext, FC, ReactNode, useContext } from "react"
import { SessionUser } from "../../services/auth"
import { SessionContext } from "../SessionProvider"

type ContextProps = {
  currentUser: SessionUser
}

type CurrentUserProviderProps = {
  children: ReactNode
}

declare const window: Window & {
  FS: {
    identify(a: string, b: Object): void
  }
}

export const CurrentUserContext = createContext<ContextProps>({} as ContextProps)

export const CurrentUserProvider: FC<CurrentUserProviderProps> = ({ children }) => {
  const { data: sessionData } = useContext(SessionContext)

  if (sessionData?.user) {
    setUser({ email: sessionData.user.email! })

    return (
      <CurrentUserContext.Provider
        value={{
          currentUser: sessionData.user,
        }}
      >
        {children}
      </CurrentUserContext.Provider>
    )
  }

  return null // loading...
}

export const useCurrentUser = () => {
  const { currentUser } = useContext(CurrentUserContext)
  return currentUser
}
