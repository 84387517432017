import { FC } from "react"
import { BiBuildings } from "react-icons/bi"
import { Organization } from "../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../types/helpers"

type OrganizationImageProps = {
  className?: string
  organization?: PickPlus<Organization, "name" | "imageUrl">
}

export const OrganizationImage: FC<OrganizationImageProps> = ({ className = "h-20 w-20", organization }) => {
  const classes = `flex items-center justify-center rounded ${className}`

  if (organization?.imageUrl) {
    // eslint-disable-next-line @next/next/no-img-element
    return <img alt={organization?.name || "Organization Image"} className={classes} src={organization.imageUrl} />
  }

  return (
    <div className={`${classes} bg-gray-100`}>
      <BiBuildings />
    </div>
  )
}
