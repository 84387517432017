// THIS FILE IS GENERATED by `npm run generate:graphql`, DO NOT EDIT MANUALLY!
import gql from "graphql-tag"
import * as Urql from "urql"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: Date
  JSON: any
}

export type Address = {
  addressComponents: Array<AddressComponent>
  formattedAddress?: Maybe<Scalars["String"]>
}

export type AddressComponent = {
  longName: Scalars["String"]
  shortName: Scalars["String"]
  types: Array<Scalars["String"]>
}

export type AddressComponentInput = {
  longName?: InputMaybe<Scalars["String"]>
  shortName?: InputMaybe<Scalars["String"]>
  types?: InputMaybe<Array<Scalars["String"]>>
}

export type AddressInput = {
  addressComponents?: InputMaybe<Array<AddressComponentInput>>
  formattedAddress?: InputMaybe<Scalars["String"]>
}

export type Asset = {
  active: Scalars["Boolean"]
  activeChangedAt: Scalars["DateTime"]
  assetChildCount: Scalars["Int"]
  assetGroupId?: Maybe<Scalars["String"]>
  assetGroupMemberCount: Scalars["Int"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  assignedAsset?: Maybe<Asset>
  assignedAssetId?: Maybe<Scalars["String"]>
  assignedTask?: Maybe<Task>
  assignedTaskId?: Maybe<Scalars["String"]>
  assignedUser?: Maybe<User>
  assignedUserId?: Maybe<Scalars["String"]>
  assignmentsCount: Scalars["Int"]
  category: AssetCategory
  childAssetGroups?: Maybe<Array<AssetGroup>>
  childAssets?: Maybe<Array<Asset>>
  companyAssetNumber?: Maybe<Scalars["String"]>
  compositeKey: Scalars["String"]
  createdAt: Scalars["DateTime"]
  deletedAt?: Maybe<Scalars["DateTime"]>
  description?: Maybe<Scalars["String"]>
  files: Array<File>
  groupQuantity?: Maybe<Scalars["Int"]>
  id: Scalars["String"]
  imageUrl?: Maybe<Scalars["String"]>
  inferredOwner?: Maybe<User>
  inferredProjectId?: Maybe<Scalars["String"]>
  inspectionTemplatesAssignments: Array<AssetInspectionTemplateAssignments>
  inventoryRequirements?: Maybe<AssetInventoryRequirements>
  isArchived?: Maybe<Scalars["Boolean"]>
  isAssetGroup: Scalars["Boolean"]
  lastInventoriedAt?: Maybe<Scalars["DateTime"]>
  manufacturer?: Maybe<AssetManufacturer>
  name: Scalars["String"]
  organization: Organization
  organizationId: Scalars["String"]
  ownershipType: Scalars["String"]
  photo: File
  photoId?: Maybe<Scalars["String"]>
  purchaseDetails?: Maybe<AssetPurchaseDetails>
  rentalAgreement?: Maybe<AssetRentalAgreement>
  reportCount: Scalars["Int"]
  /** @deprecated Use inspectionTemplatesAssignments instead, since the table is no longer directly associated */
  reportTemplates: Array<AssetReportTemplate>
  reports: Array<AssetReport>
  status: AssetStatus
  updatedAt: Scalars["DateTime"]
  vendorContact?: Maybe<AssetVendorContact>
}

export enum AssetAssignableType {
  Asset = "Asset",
  Task = "Task",
  User = "User",
}

export enum AssetCategory {
  Electronics = "Electronics",
  HeavyEquipment = "HeavyEquipment",
  Other = "Other",
  SmallEquipment = "SmallEquipment",
  Tools = "Tools",
  Trailers = "Trailers",
  Vehicles = "Vehicles",
}

export type AssetFilter = {
  assetGroupId?: InputMaybe<Scalars["String"]>
  assetId?: InputMaybe<Scalars["String"]>
  assignableId?: InputMaybe<Scalars["String"]>
  assignableType?: InputMaybe<AssetAssignableType>
  categories?: InputMaybe<Array<AssetCategory>>
  includeGroupedAssets?: InputMaybe<Scalars["Boolean"]>
  isArchived?: InputMaybe<Scalars["Boolean"]>
  projectId?: InputMaybe<Scalars["String"]>
  searchText?: InputMaybe<Scalars["String"]>
  statuses?: InputMaybe<Array<AssetStatus>>
  taskGroupId?: InputMaybe<Scalars["String"]>
  taskId?: InputMaybe<Scalars["String"]>
  userId?: InputMaybe<Scalars["String"]>
}

export type AssetGroup = {
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  assignedAsset?: Maybe<Asset>
  assignedAssetId?: Maybe<Scalars["String"]>
  assignedTask?: Maybe<Task>
  assignedTaskId?: Maybe<Scalars["String"]>
  assignedUser?: Maybe<User>
  assignedUserId?: Maybe<Scalars["String"]>
  compositeKey: Scalars["String"]
  count: Scalars["Int"]
  groupParent: Asset
  status: AssetStatus
}

export type AssetGroupFilterSpecification = {
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: Scalars["String"]
  count: Scalars["Int"]
  status: AssetStatus
}

export type AssetGroupReassignmentSpecification = {
  filter: AssetGroupFilterSpecification
  ids: Array<Scalars["String"]>
}

/** AssetInspectionRequirements */
export type AssetInspectionRequirements = {
  intervalInSeconds?: Maybe<Scalars["Int"]>
  startDate?: Maybe<Scalars["DateTime"]>
}

export type AssetInspectionRequirementsInput = {
  assetReportTemplateId?: InputMaybe<Scalars["String"]>
  intervalInSeconds?: InputMaybe<Scalars["Int"]>
  startDate?: InputMaybe<Scalars["DateTime"]>
}

export type AssetInspectionTemplateAssignments = {
  asset: Asset
  assetId: Scalars["String"]
  assetReportTemplateId: Scalars["String"]
  /** helper method to make it simple on the client side */
  id: Scalars["ID"]
  inspectionTemplate: AssetReportTemplate
  intervalInSeconds?: Maybe<Scalars["Int"]>
  intervalRequired: Scalars["Boolean"]
  lastReportedAt?: Maybe<Scalars["DateTime"]>
  organizationId: Scalars["String"]
  startDate?: Maybe<Scalars["DateTime"]>
}

/** AssetInventoryRequirements */
export type AssetInventoryRequirements = {
  intervalInSeconds?: Maybe<Scalars["Int"]>
  photoRequired?: Maybe<Scalars["Boolean"]>
}

export type AssetInventoryRequirementsInput = {
  intervalInSeconds?: InputMaybe<Scalars["Int"]>
  photoRequired?: InputMaybe<Scalars["Boolean"]>
}

/** AssetManufacturer */
export type AssetManufacturer = {
  id?: Maybe<Scalars["String"]>
  make?: Maybe<Scalars["String"]>
  model?: Maybe<Scalars["String"]>
  name?: Maybe<Scalars["String"]>
  year?: Maybe<Scalars["Int"]>
}

export type AssetManufacturerInput = {
  id?: InputMaybe<Scalars["String"]>
  make?: InputMaybe<Scalars["String"]>
  model?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  year?: InputMaybe<Scalars["Int"]>
}

/** AssetPurchaseDetails */
export type AssetPurchaseDetails = {
  date?: Maybe<Scalars["String"]>
  price?: Maybe<Scalars["Float"]>
}

export type AssetPurchaseDetailsInput = {
  date?: InputMaybe<Scalars["String"]>
  price?: InputMaybe<Scalars["Float"]>
}

/** AssetRentalAgreement */
export type AssetRentalAgreement = {
  endOn?: Maybe<Scalars["String"]>
  rate?: Maybe<AssetRentalAgreementRate>
  startOn?: Maybe<Scalars["String"]>
}

export type AssetRentalAgreementInput = {
  endOn?: InputMaybe<Scalars["String"]>
  rate?: InputMaybe<AssetRentalAgreementRateInput>
  startOn?: InputMaybe<Scalars["String"]>
}

/** AssetRentalAgreementRate */
export type AssetRentalAgreementRate = {
  daily: Scalars["Float"]
  monthly: Scalars["Float"]
  weekly: Scalars["Float"]
}

export type AssetRentalAgreementRateInput = {
  daily?: InputMaybe<Scalars["Float"]>
  monthly?: InputMaybe<Scalars["Float"]>
  weekly?: InputMaybe<Scalars["Float"]>
}

export type AssetReport = {
  asset: Asset
  assetId: Scalars["String"]
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  inspectionReport?: Maybe<Array<AssetReportInspectionSubmission>>
  inspectionReportAggregations?: Maybe<InspectionReportAggregations>
  inventoryReport?: Maybe<AssetReportInventoryReport>
  notes?: Maybe<Scalars["String"]>
  organization: Organization
  organizationId: Scalars["String"]
  quantityReported?: Maybe<Scalars["Int"]>
  reporter: User
  reporterId: Scalars["String"]
  statusChange?: Maybe<AssetStatusChange>
  transferReport?: Maybe<AssetReportTransferReport>
  type: AssetReportType
  updatedAt: Scalars["DateTime"]
}

export type AssetReportInput = {
  assetGroupId?: InputMaybe<Scalars["String"]>
  assetId: Scalars["String"]
  assetReportTemplateId?: InputMaybe<Scalars["String"]>
  inspectionReport?: InputMaybe<Array<AssetReportInspectionSubmissionInput>>
  inventoryReport?: InputMaybe<AssetReportInventoryReportType>
  notes?: InputMaybe<Scalars["String"]>
  quantityReported?: InputMaybe<Scalars["Int"]>
  statusChange?: InputMaybe<AssetStatusChangeInput>
  type: AssetReportType
}

/** AssetReportInspectionSubmission */
export type AssetReportInspectionSubmission = {
  failedStatus?: Maybe<Scalars["String"]>
  fileIds?: Maybe<Array<Scalars["String"]>>
  id?: Maybe<Scalars["String"]>
  input?: Maybe<Scalars["String"]>
  label?: Maybe<Scalars["String"]>
  photoLabel?: Maybe<Scalars["String"]>
  photoRequired?: Maybe<Scalars["Boolean"]>
  photoUrls?: Maybe<Array<Scalars["String"]>>
  required?: Maybe<Scalars["Boolean"]>
  rule?: Maybe<Scalars["String"]>
  type?: Maybe<Scalars["String"]>
}

/** AssetReportInspectionSubmission */
export type AssetReportInspectionSubmissionInput = {
  failedStatus?: InputMaybe<Scalars["String"]>
  fileIds?: InputMaybe<Array<Scalars["String"]>>
  id?: InputMaybe<Scalars["String"]>
  input?: InputMaybe<Scalars["String"]>
  label?: InputMaybe<Scalars["String"]>
  photoLabel?: InputMaybe<Scalars["String"]>
  photoRequired?: InputMaybe<Scalars["Boolean"]>
  photoUrls?: InputMaybe<Array<Scalars["String"]>>
  photos?: InputMaybe<Array<Scalars["String"]>>
  required?: InputMaybe<Scalars["Boolean"]>
  rule?: InputMaybe<Scalars["String"]>
  type?: InputMaybe<Scalars["String"]>
}

/** AssetReportInventoryReport */
export type AssetReportInventoryReport = {
  fileIds?: Maybe<Array<Scalars["String"]>>
  note?: Maybe<Scalars["String"]>
  photoUrls?: Maybe<Array<Scalars["String"]>>
}

export type AssetReportInventoryReportType = {
  fileIds?: InputMaybe<Array<Scalars["String"]>>
  note?: InputMaybe<Scalars["String"]>
  photoUrls?: InputMaybe<Array<Scalars["String"]>>
  photos?: InputMaybe<Array<Scalars["String"]>>
}

export type AssetReportTemplate = {
  /** @deprecated Use assetAssignments in the future */
  assets: Array<Asset>
  assetsCount: Scalars["Int"]
  createdAt: Scalars["DateTime"]
  deletedAt?: Maybe<Scalars["DateTime"]>
  fields: Array<AssetReportTemplateField>
  id: Scalars["String"]
  name: Scalars["String"]
  organization: Organization
  organizationId?: Maybe<Scalars["String"]>
  reusable: Scalars["Boolean"]
  universal: Scalars["Boolean"]
  updatedAt: Scalars["DateTime"]
}

/** AssetReportInput */
export type AssetReportTemplateField = {
  failedStatus?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  label: Scalars["String"]
  photoLabel?: Maybe<Scalars["String"]>
  photoRequired: Scalars["Boolean"]
  required: Scalars["Boolean"]
  rule?: Maybe<Scalars["String"]>
  type: Scalars["String"]
}

/** AssetReportTemplateFieldInput */
export type AssetReportTemplateFieldInput = {
  failedStatus?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  label: Scalars["String"]
  photoLabel?: InputMaybe<Scalars["String"]>
  photoRequired: Scalars["Boolean"]
  required: Scalars["Boolean"]
  rule?: InputMaybe<Scalars["String"]>
  type: Scalars["String"]
}

/** AssetReportTransferAssignmentInput */
export type AssetReportTransferAssignment = {
  asset?: Maybe<Asset>
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  task?: Maybe<Task>
  user?: Maybe<User>
}

/** AssetReportTransferAssignmentInput */
export type AssetReportTransferAssignmentInput = {
  assignableId?: InputMaybe<Scalars["String"]>
  assignableType?: InputMaybe<AssetAssignableType>
}

/** AssetReportTransferReport */
export type AssetReportTransferReport = {
  from: AssetReportTransferAssignment
  to: AssetReportTransferAssignment
}

/** AssetReportTransferReport */
export type AssetReportTransferReportInput = {
  from?: InputMaybe<AssetReportTransferAssignmentInput>
  to?: InputMaybe<AssetReportTransferAssignmentInput>
}

export enum AssetReportType {
  Inspection = "Inspection",
  Inventory = "Inventory",
  StatusChange = "StatusChange",
  Transfer = "Transfer",
}

export type AssetSort = {
  by?: AssetSortByInput
  direction?: AssetSortDirectionInput
}

export enum AssetSortByInput {
  Name = "name",
}

export enum AssetSortDirectionInput {
  Asc = "asc",
  Desc = "desc",
}

export enum AssetStatus {
  Available = "Available",
  BeingRepaired = "BeingRepaired",
  InUse = "InUse",
  Missing = "Missing",
  NeedsRepair = "NeedsRepair",
  OutOfService = "OutOfService",
  Sold = "Sold",
  Transferred = "Transferred",
}

/** AssetStatusChange */
export type AssetStatusChange = {
  active: Scalars["Boolean"]
  missingCount?: Maybe<Scalars["Int"]>
  status: AssetStatus
}

/** AssetStatusChange */
export type AssetStatusChangeInput = {
  active?: InputMaybe<Scalars["Boolean"]>
  missingCount?: InputMaybe<Scalars["Int"]>
  status?: InputMaybe<AssetStatus>
}

/** AssetVendorContact */
export type AssetVendorContact = {
  email?: Maybe<Scalars["String"]>
  name?: Maybe<Scalars["String"]>
  phone?: Maybe<Scalars["String"]>
}

export type AssetVendorContactInput = {
  email?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  phone?: InputMaybe<Scalars["String"]>
}

export type AssignedAsset = {
  active: Scalars["Boolean"]
  assetChildCount: Scalars["Int"]
  assetGroupId?: Maybe<Scalars["String"]>
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  assignedAsset?: Maybe<Asset>
  assignedAssetId?: Maybe<Scalars["String"]>
  assignedTask?: Maybe<Task>
  assignedTaskId?: Maybe<Scalars["String"]>
  assignedUser?: Maybe<User>
  assignedUserId?: Maybe<Scalars["String"]>
  assignmentsCount: Scalars["Int"]
  category: AssetCategory
  companyAssetNumber?: Maybe<Scalars["String"]>
  compositeKey: Scalars["String"]
  deletedAt?: Maybe<Scalars["DateTime"]>
  depth?: Maybe<Scalars["Int"]>
  groupParentAsset?: Maybe<Asset>
  id: Scalars["String"]
  imageUrl?: Maybe<Scalars["String"]>
  inferredOwner?: Maybe<User>
  inventoryRequirements?: Maybe<AssetInventoryRequirements>
  isAssetGroup: Scalars["Boolean"]
  lastInventoriedAt?: Maybe<Scalars["DateTime"]>
  name: Scalars["String"]
  ownershipType: Scalars["String"]
  quantizedAssetIds?: Maybe<Array<Scalars["String"]>>
  status: AssetStatus
}

export type AssignedAssetFilter = {
  assignableId?: InputMaybe<Scalars["String"]>
  isArchived?: InputMaybe<Scalars["Boolean"]>
  projectId?: InputMaybe<Scalars["String"]>
  searchText?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<AssetStatus>
  taskId?: InputMaybe<Scalars["String"]>
  userId?: InputMaybe<Scalars["String"]>
}

export type BulkClockInCandidate = {
  projectId: Scalars["String"]
  startEvidence?: InputMaybe<StartEvidenceInput>
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type BulkClockOutCandidate = {
  endEvidence?: InputMaybe<EndEvidenceInput>
  userId: Scalars["String"]
}

/** BulkTimeEntryResponse */
export type BulkTimeEntryResponse = {
  errors: Array<TimeEntryError>
  success: Scalars["Int"]
}

export type BulkUpdateTaskSortOrderInput = {
  id: Scalars["String"]
  sortOrder: Scalars["Int"]
  taskGroupId?: InputMaybe<Scalars["String"]>
  /** Either a task or taskGroup */
  type: Scalars["String"]
}

export type ChildAncestorNode = {
  depth: Scalars["Int"]
  userId: Scalars["String"]
}

export type ClockInData = {
  imagePath?: Maybe<Scalars["String"]>
  task: Task
  taskId: Scalars["String"]
  user: User
  userId: Scalars["String"]
}

export type ClockInDataInput = {
  imagePath?: InputMaybe<Scalars["String"]>
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type ClockOutData = {
  imagePath?: Maybe<Scalars["String"]>
  signaturePath?: Maybe<Scalars["String"]>
  task?: Maybe<Task>
  taskId: Scalars["String"]
  user?: Maybe<User>
  userId: Scalars["String"]
}

export type ClockOutDataInput = {
  imagePath?: InputMaybe<Scalars["String"]>
  signaturePath?: InputMaybe<Scalars["String"]>
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type Contract = {
  createdAt: Scalars["DateTime"]
  customerId?: Maybe<Scalars["String"]>
  deliverableUnits: Array<ContractDeliverableUnit>
  description?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  name: Scalars["String"]
  number?: Maybe<Scalars["String"]>
  organization: Organization
  organizationId: Scalars["String"]
  updatedAt: Scalars["DateTime"]
}

export type ContractDeliverableUnit = {
  contract: Contract
  contractId: Scalars["String"]
  createdAt: Scalars["DateTime"]
  customerDescription?: Maybe<Scalars["String"]>
  deliverableUnit: DeliverableUnit
  deliverableUnitId: Scalars["String"]
  id: Scalars["String"]
  rate?: Maybe<Scalars["Float"]>
  updatedAt: Scalars["DateTime"]
}

export type ContractDeliverableUnitInput = {
  customerDescription?: InputMaybe<Scalars["String"]>
  deliverableUnitId: Scalars["String"]
  rate?: InputMaybe<Scalars["Float"]>
}

export type CreateManyOfflineEventsResponsePayload = {
  createdEvents: Array<OfflineEvent>
  failureCount: Scalars["Int"]
  successCount: Scalars["Int"]
}

export type Customer = {
  activeProjectCount: Scalars["Int"]
  addressLine1?: Maybe<Scalars["String"]>
  addressLine2?: Maybe<Scalars["String"]>
  city?: Maybe<Scalars["String"]>
  contracts: Array<Contract>
  country?: Maybe<Scalars["String"]>
  createdAt: Scalars["DateTime"]
  customerId?: Maybe<Scalars["String"]>
  email?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  image?: Maybe<Scalars["String"]>
  imageUrl?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  notes?: Maybe<Scalars["String"]>
  phoneNumber?: Maybe<Scalars["String"]>
  phoneNumberExt?: Maybe<Scalars["String"]>
  state?: Maybe<Scalars["String"]>
  updatedAt: Scalars["DateTime"]
  zip?: Maybe<Scalars["String"]>
}

export type DeliverableUnit = {
  archivedAt?: Maybe<Scalars["DateTime"]>
  color: Scalars["String"]
  createdAt: Scalars["DateTime"]
  deletedAt?: Maybe<Scalars["DateTime"]>
  description: Scalars["String"]
  id: Scalars["ID"]
  referenceNumber?: Maybe<Scalars["String"]>
  unitOfMeasure: Scalars["String"]
}

export type EditHistoryEvent = {
  createdAt: Scalars["DateTime"]
  data: Scalars["JSON"]
  id: Scalars["ID"]
  modelId: Scalars["String"]
  modelType: Scalars["String"]
  organizationId: Scalars["ID"]
  project: Project
  projectId?: Maybe<Scalars["String"]>
  task?: Maybe<Task>
  taskId?: Maybe<Scalars["String"]>
  type: Scalars["String"]
  updatedAt: Scalars["DateTime"]
  user: User
  userId: Scalars["String"]
}

export type EndEvidenceInput = {
  imagePath?: InputMaybe<Scalars["String"]>
  injured?: InputMaybe<Scalars["Boolean"]>
  injuryNotes?: InputMaybe<Scalars["String"]>
  location?: InputMaybe<GeolocationPositionInput>
  locationError?: InputMaybe<Scalars["String"]>
  signaturePath?: InputMaybe<Scalars["String"]>
}

export enum FeatureToggleSegments {
  DeveloperBeta = "DEVELOPER_BETA",
  PublicBeta = "PUBLIC_BETA",
}

export type File = {
  assetId?: Maybe<Scalars["String"]>
  contentType: Scalars["String"]
  createdAt: Scalars["DateTime"]
  deletedAt?: Maybe<Scalars["DateTime"]>
  documentUrl?: Maybe<Scalars["String"]>
  expiresAt?: Maybe<Scalars["DateTime"]>
  fileName: Scalars["String"]
  id: Scalars["String"]
  key: Scalars["String"]
  objectVersion: Scalars["Int"]
  organizationId: Scalars["String"]
  projectId?: Maybe<Scalars["String"]>
  status?: Maybe<Scalars["String"]>
  userId?: Maybe<Scalars["String"]>
}

export type GeolocationCoordinates = {
  accuracy: Scalars["Float"]
  altitude?: Maybe<Scalars["Float"]>
  altitudeAccuracy?: Maybe<Scalars["Float"]>
  heading?: Maybe<Scalars["Float"]>
  latitude: Scalars["Float"]
  longitude: Scalars["Float"]
  speed?: Maybe<Scalars["Float"]>
}

export type GeolocationCoordinatesInput = {
  accuracy: Scalars["Float"]
  altitude?: InputMaybe<Scalars["Float"]>
  altitudeAccuracy?: InputMaybe<Scalars["Float"]>
  heading?: InputMaybe<Scalars["Float"]>
  latitude: Scalars["Float"]
  longitude: Scalars["Float"]
  speed?: InputMaybe<Scalars["Float"]>
}

export type GeolocationPosition = {
  coords: GeolocationCoordinates
  timestamp: Scalars["DateTime"]
}

export type GeolocationPositionInput = {
  coords: GeolocationCoordinatesInput
  timestamp: Scalars["Float"]
}

export type GroupInput = {
  description?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  sortOrder?: InputMaybe<Scalars["Int"]>
  tasks?: InputMaybe<Array<TaskInput>>
}

export type InjuryReport = {
  createdAt: Scalars["DateTime"]
  id: Scalars["ID"]
  notes?: Maybe<Scalars["String"]>
  reporter: User
  reporterId: Scalars["String"]
  updatedAt: Scalars["DateTime"]
  user: User
  userId: Scalars["String"]
}

export type InspectionReportAggregations = {
  falseCount: Scalars["Int"]
  trueCount: Scalars["Int"]
}

export type LastExport = {
  exportedAt: Scalars["String"]
  userId: Scalars["String"]
  userName: Scalars["String"]
}

export type LastExportInput = {
  exportedAt: Scalars["String"]
  userId: Scalars["String"]
  userName: Scalars["String"]
}

export enum ListVisibilityFilter {
  /** Return all records */
  All = "all",
  /** Return records directly pertinent to the user making the request */
  My = "my",
}

export type LocationWithRadius = {
  /** @deprecated Use latitude instead */
  lat: Scalars["Float"]
  latitude: Scalars["Float"]
  /** @deprecated Use longitude instead */
  lng: Scalars["Float"]
  longitude: Scalars["Float"]
  /** @deprecated Use radiusInMeters instead */
  radius?: Maybe<Scalars["Float"]>
  radiusInMeters?: Maybe<Scalars["Float"]>
}

export type MetadataNote = {
  content: Scalars["String"]
  highlight?: Maybe<Scalars["Boolean"]>
  label: Scalars["String"]
}

export type MetadataNoteInput = {
  content: Scalars["String"]
  highlight?: InputMaybe<Scalars["Boolean"]>
  label: Scalars["String"]
}

export type Mutation = {
  activateOrganization: Organization
  activateProject: Project
  addOrUpdateNonWorkDay: ScheduleTemplate
  addQuantitiesToGroup?: Maybe<Asset>
  archiveOrganization: Organization
  archiveProject: Project
  archiveQuantities: Array<Asset>
  archiveWorkersCompCode?: Maybe<WorkersCompCode>
  /** Assign a user as a child of another user */
  assignChildToParent: UserToUser
  /** Clock-in users in bulk */
  bulkClockIn: BulkTimeEntryResponse
  /** Clock-out users in bulk */
  bulkClockOut: BulkTimeEntryResponse
  bulkUpdateTaskSortOrder: Array<UpdateSortOrderPayload>
  bulkUpdateUserAssignments?: Maybe<UserAssignmentBatchResponse>
  /** Clock-in a user and create a new time entry */
  clockIn: TimeEntry
  /** Clock-out a user to close their time entry */
  clockOut: TimeEntry
  /** Clock-out a user to close their time entry */
  clockOutUser: TimeEntry
  completeProject: Project
  createCustomer?: Maybe<Customer>
  createDeliverableUnit: DeliverableUnit
  /** Creates a new injury report */
  createInjuryReport: InjuryReport
  createManyTaskReports?: Maybe<Array<Task>>
  createOfflineEvent: OfflineEvent
  createOfflineEvents: CreateManyOfflineEventsResponsePayload
  createOneContract: Contract
  createScheduleTemplate: ScheduleTemplate
  createScheduledBreak: ScheduledBreak
  createUnitGoal?: Maybe<UnitGoal>
  createUnitOfMeasure: UnitOfMeasure
  createUser?: Maybe<User>
  createUserAssignment?: Maybe<UserAssignment>
  /** Allow users to create notifications for themselves */
  createUserNotification?: Maybe<UserNotification>
  createWorkersCompCode?: Maybe<WorkersCompCode>
  deleteCustomer: Customer
  deleteDeliverableUnit?: Maybe<DeliverableUnit>
  deleteNonWorkDay: ScheduleTemplate
  deleteOneAsset?: Maybe<Asset>
  deleteOneContract: Contract
  /** 'Soft delete' file metadata */
  deleteOneFile: File
  deleteOneTask?: Maybe<Task>
  /** Deletes a Task Group */
  deleteOneTaskGroup?: Maybe<TaskGroup>
  /** Delete a time entry */
  deleteOneTimeEntry: TimeEntry
  deleteOneUser: User
  /** Delete a user to user relationship */
  deleteParentChildRelationship: UserToUser
  deleteReportTemplate: AssetReportTemplate
  deleteScheduledBreak: ScheduledBreak
  deleteUnitGoal?: Maybe<UnitGoal>
  deleteUnitOfMeasure: UnitOfMeasure
  deleteUserAssignment: UserAssignment
  /** Delete a user device session */
  deleteUserDeviceSession: Scalars["Boolean"]
  /** Duplicate a project */
  duplicateProject: Project
  duplicateReportTemplate: AssetReportTemplate
  duplicateScheduleTemplate: ScheduleTemplate
  editDeliverableUnit: DeliverableUnit
  editOrganization: Organization
  editScheduledBreak: ScheduledBreak
  editUnitGoal?: Maybe<UnitGoal>
  editUnitOfMeasure: UnitOfMeasure
  /** Deletes all masquerade sessions for a user */
  endAllMasqueradeSessionsForUser: Scalars["Boolean"]
  insertManyAssetReports: Array<AssetReport>
  insertOneAsset?: Maybe<Asset>
  /** Create a new project */
  insertOneProject: Project
  insertOneTask: Task
  /** Creates a new Task Group */
  insertOneTaskGroup: TaskGroup
  /** Create a new time entry */
  insertOneTimeEntry: TimeEntry
  insertReportTemplate: AssetReportTemplate
  markAllNotificationsRead?: Maybe<Array<UserNotification>>
  markNotificationRead?: Maybe<UserNotification>
  markNotificationsReadById?: Maybe<Array<UserNotification>>
  markOrganizationNotPastDue: Organization
  markOrganizationPastDue: Organization
  markTaskCompletion?: Maybe<Task>
  /** Masquerade as a user */
  masqueradeUser: MasqueradeCredentials
  reassignUser: User
  reassignUsers: Array<User>
  reportTaskProgress?: Maybe<Task>
  resendUserInvites?: Maybe<Scalars["Boolean"]>
  restoreOneAsset?: Maybe<Asset>
  restoreOneUser: User
  returnQuantityToInventory: Array<Asset>
  transferAssets: Array<Asset>
  unarchiveQuantities: Array<Asset>
  updateCustomer?: Maybe<Customer>
  /** Updates an injury report */
  updateInjuryReport: InjuryReport
  updateLastExport?: Maybe<LastExport>
  updateOneAsset?: Maybe<Asset>
  updateOneContract: Contract
  /** Updates file metadata */
  updateOneFile: File
  /** Edit a project */
  updateOneProject: Project
  updateOneTask: Task
  /** Updates a Task Group */
  updateOneTaskGroup: TaskGroup
  /** Update a time entry */
  updateOneTimeEntry: TimeEntry
  updateOneUser: User
  /** Updates a user's password */
  updatePassword: Scalars["Boolean"]
  updateReportTemplate: AssetReportTemplate
  updateScheduleTemplate: ScheduleTemplate
  updateTaskSortOrder?: Maybe<UpdateSortOrderPayload>
  updateUserAssignment?: Maybe<UserAssignment>
}

export type MutationActivateOrganizationArgs = {
  id: Scalars["String"]
}

export type MutationActivateProjectArgs = {
  id: Scalars["String"]
}

export type MutationAddOrUpdateNonWorkDayArgs = {
  nonWorkDay: ScheduleTemplateNonWorkDaysInput
}

export type MutationAddQuantitiesToGroupArgs = {
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  quantityToAdd: Scalars["Int"]
}

export type MutationArchiveOrganizationArgs = {
  id: Scalars["String"]
}

export type MutationArchiveProjectArgs = {
  id: Scalars["String"]
}

export type MutationArchiveQuantitiesArgs = {
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  quantityToArchive: Scalars["Int"]
  status: AssetStatus
}

export type MutationArchiveWorkersCompCodeArgs = {
  id: Scalars["String"]
}

export type MutationAssignChildToParentArgs = {
  childId: Scalars["String"]
  parentId: Scalars["String"]
}

export type MutationBulkClockInArgs = {
  candidates: Array<BulkClockInCandidate>
  location?: InputMaybe<GeolocationPositionInput>
  startAt?: InputMaybe<Scalars["DateTime"]>
}

export type MutationBulkClockOutArgs = {
  candidates: Array<BulkClockOutCandidate>
  endAt?: InputMaybe<Scalars["DateTime"]>
}

export type MutationBulkUpdateTaskSortOrderArgs = {
  updates: Array<BulkUpdateTaskSortOrderInput>
}

export type MutationBulkUpdateUserAssignmentsArgs = {
  assignmentsToCreate?: InputMaybe<Array<UserAssignmentCreateInput>>
  assignmentsToDelete?: InputMaybe<Array<Scalars["String"]>>
}

export type MutationClockInArgs = {
  id?: InputMaybe<Scalars["String"]>
  startEvidence?: InputMaybe<StartEvidenceInput>
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type MutationClockOutArgs = {
  endEvidence?: InputMaybe<EndEvidenceInput>
  id: Scalars["String"]
}

export type MutationClockOutUserArgs = {
  endEvidence?: InputMaybe<EndEvidenceInput>
  userId: Scalars["String"]
}

export type MutationCompleteProjectArgs = {
  id: Scalars["String"]
}

export type MutationCreateCustomerArgs = {
  addressLine1?: InputMaybe<Scalars["String"]>
  addressLine2?: InputMaybe<Scalars["String"]>
  city?: InputMaybe<Scalars["String"]>
  country?: InputMaybe<Scalars["String"]>
  customerId?: InputMaybe<Scalars["String"]>
  email?: InputMaybe<Scalars["String"]>
  image?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  notes?: InputMaybe<Scalars["String"]>
  phoneNumber?: InputMaybe<Scalars["String"]>
  phoneNumberExt?: InputMaybe<Scalars["String"]>
  state?: InputMaybe<Scalars["String"]>
  zip?: InputMaybe<Scalars["String"]>
}

export type MutationCreateDeliverableUnitArgs = {
  color: Scalars["String"]
  description: Scalars["String"]
  referenceNumber?: InputMaybe<Scalars["String"]>
  unitOfMeasure: Scalars["String"]
}

export type MutationCreateInjuryReportArgs = {
  notes?: InputMaybe<Scalars["String"]>
  userId: Scalars["String"]
}

export type MutationCreateManyTaskReportsArgs = {
  projectId: Scalars["String"]
  taskReports: Array<TaskReportInput>
}

export type MutationCreateOfflineEventArgs = {
  clientCreatedAt: Scalars["DateTime"]
  clockInData?: InputMaybe<ClockInDataInput>
  clockOutData?: InputMaybe<ClockOutDataInput>
  location: GeolocationCoordinatesInput
  type: OfflineEventType
}

export type MutationCreateOfflineEventsArgs = {
  events: Array<OfflineEventInput>
}

export type MutationCreateOneContractArgs = {
  customerId: Scalars["String"]
  deliverableUnits: Array<ContractDeliverableUnitInput>
  description?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  number?: InputMaybe<Scalars["String"]>
}

export type MutationCreateScheduleTemplateArgs = {
  isDefault?: InputMaybe<Scalars["Boolean"]>
  nonWorkDays?: InputMaybe<Array<ScheduleTemplateNonWorkDaysInput>>
  workDays?: InputMaybe<Array<ScheduleTemplateWorkDaysInput>>
  workHours?: InputMaybe<ScheduleTemplateWorkHoursInput>
}

export type MutationCreateScheduledBreakArgs = {
  breakTaskId: Scalars["String"]
  durationInMinutes: Scalars["Int"]
  localizedStartTime: Scalars["String"]
  name: Scalars["String"]
  projectId?: InputMaybe<Scalars["String"]>
}

export type MutationCreateUnitGoalArgs = {
  goal: UnitGoalInput
  taskId: Scalars["String"]
}

export type MutationCreateUnitOfMeasureArgs = {
  name: Scalars["String"]
  symbol: Scalars["String"]
}

export type MutationCreateUserArgs = {
  sendInvite?: Scalars["Boolean"]
  user: NewUser
}

export type MutationCreateUserAssignmentArgs = {
  projectId: Scalars["String"]
  taskId?: InputMaybe<Scalars["String"]>
  userId: Scalars["String"]
}

export type MutationCreateUserNotificationArgs = {
  assetId?: InputMaybe<Scalars["String"]>
  modelType: UserNotificationModelType
  type: UserNotificationType
  userId?: InputMaybe<Scalars["String"]>
}

export type MutationCreateWorkersCompCodeArgs = {
  name: Scalars["String"]
  ncciCode: Scalars["String"]
  ncciDescription: Scalars["String"]
  override: Scalars["Boolean"]
  rate: Scalars["Float"]
}

export type MutationDeleteCustomerArgs = {
  id: Scalars["String"]
}

export type MutationDeleteDeliverableUnitArgs = {
  id: Scalars["String"]
}

export type MutationDeleteNonWorkDayArgs = {
  id: Scalars["String"]
}

export type MutationDeleteOneAssetArgs = {
  id: Scalars["String"]
  status: AssetStatus
}

export type MutationDeleteOneContractArgs = {
  id: Scalars["String"]
}

export type MutationDeleteOneFileArgs = {
  id: Scalars["String"]
}

export type MutationDeleteOneTaskArgs = {
  id: Scalars["String"]
}

export type MutationDeleteOneTaskGroupArgs = {
  id: Scalars["String"]
}

export type MutationDeleteOneTimeEntryArgs = {
  id: Scalars["String"]
}

export type MutationDeleteOneUserArgs = {
  id: Scalars["String"]
}

export type MutationDeleteParentChildRelationshipArgs = {
  childId: Scalars["String"]
  parentId: Scalars["String"]
}

export type MutationDeleteReportTemplateArgs = {
  id: Scalars["String"]
}

export type MutationDeleteScheduledBreakArgs = {
  id: Scalars["String"]
}

export type MutationDeleteUnitGoalArgs = {
  id: Scalars["String"]
}

export type MutationDeleteUnitOfMeasureArgs = {
  id: Scalars["Int"]
}

export type MutationDeleteUserAssignmentArgs = {
  id: Scalars["String"]
}

export type MutationDeleteUserDeviceSessionArgs = {
  deviceSessionId: Scalars["String"]
}

export type MutationDuplicateProjectArgs = {
  includeAdditionalUnits?: InputMaybe<Scalars["Boolean"]>
  includeReportingUnits?: InputMaybe<Scalars["Boolean"]>
  includeTasks?: InputMaybe<Scalars["Boolean"]>
  projectId: Scalars["String"]
  projectName: Scalars["String"]
}

export type MutationDuplicateReportTemplateArgs = {
  id: Scalars["String"]
}

export type MutationDuplicateScheduleTemplateArgs = {
  id: Scalars["String"]
}

export type MutationEditDeliverableUnitArgs = {
  color: Scalars["String"]
  description: Scalars["String"]
  id: Scalars["String"]
  referenceNumber?: InputMaybe<Scalars["String"]>
  unitOfMeasure: Scalars["String"]
}

export type MutationEditOrganizationArgs = {
  featureToggleSegments?: InputMaybe<Array<FeatureToggleSegments>>
  id: Scalars["String"]
  image?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  notificationSettings?: InputMaybe<Scalars["JSON"]>
  overtimeType?: InputMaybe<OvertimeTypeEnum>
  requireTimeEntryEvidence?: InputMaybe<Scalars["Boolean"]>
  stripeCustomerId?: InputMaybe<Scalars["String"]>
  timezone?: InputMaybe<Scalars["String"]>
}

export type MutationEditScheduledBreakArgs = {
  breakTaskId?: InputMaybe<Scalars["String"]>
  durationInMinutes?: InputMaybe<Scalars["Int"]>
  id: Scalars["String"]
  isActive?: InputMaybe<Scalars["Boolean"]>
  localizedStartTime?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  projectId?: InputMaybe<Scalars["String"]>
}

export type MutationEditUnitGoalArgs = {
  goal: UnitGoalInput
  id: Scalars["String"]
}

export type MutationEditUnitOfMeasureArgs = {
  id: Scalars["Int"]
  name: Scalars["String"]
  symbol: Scalars["String"]
}

export type MutationEndAllMasqueradeSessionsForUserArgs = {
  subjectUserId: Scalars["String"]
}

export type MutationInsertManyAssetReportsArgs = {
  reports: Array<AssetReportInput>
}

export type MutationInsertOneAssetArgs = {
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  category?: InputMaybe<AssetCategory>
  companyAssetNumber?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  groupQuantity?: InputMaybe<Scalars["Int"]>
  inspectionRequirements?: InputMaybe<Array<AssetInspectionRequirementsInput>>
  inventoryRequirements?: InputMaybe<AssetInventoryRequirementsInput>
  isAssetGroup?: InputMaybe<Scalars["Boolean"]>
  manufacturer?: InputMaybe<AssetManufacturerInput>
  name: Scalars["String"]
  ownershipType: Scalars["String"]
  photoId?: InputMaybe<Scalars["String"]>
  purchaseDetails?: InputMaybe<AssetPurchaseDetailsInput>
  rentalAgreement?: InputMaybe<AssetRentalAgreementInput>
  reportTemplateIds?: InputMaybe<Array<Scalars["String"]>>
  vendorContact?: InputMaybe<AssetVendorContactInput>
}

export type MutationInsertOneProjectArgs = {
  address?: InputMaybe<AddressInput>
  code?: InputMaybe<Scalars["String"]>
  customerId?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  location?: InputMaybe<Scalars["JSON"]>
  locationNotes?: InputMaybe<Scalars["String"]>
  metadata?: InputMaybe<Array<MetadataNoteInput>>
  name: Scalars["String"]
  schedule?: InputMaybe<ScheduleTemplateInput>
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>
  startDate?: InputMaybe<Scalars["DateTime"]>
  taskGroups?: InputMaybe<Array<GroupInput>>
  tasks?: InputMaybe<Array<TaskInput>>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
}

export type MutationInsertOneTaskArgs = {
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  estimatedHours?: InputMaybe<Scalars["Float"]>
  groupId?: InputMaybe<Scalars["String"]>
  metadata?: InputMaybe<Array<MetadataNoteInput>>
  name: Scalars["String"]
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId: Scalars["String"]
  schedule?: InputMaybe<ScheduleTemplateInput>
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>
  startDate?: InputMaybe<Scalars["DateTime"]>
  unitGoals?: InputMaybe<Array<UnitGoalInput>>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
}

export type MutationInsertOneTaskGroupArgs = {
  description?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  notes?: InputMaybe<Scalars["String"]>
  projectId: Scalars["String"]
}

export type MutationInsertOneTimeEntryArgs = {
  endAt?: InputMaybe<Scalars["DateTime"]>
  startAt: Scalars["DateTime"]
  startEvidence?: InputMaybe<StartEvidenceInput>
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type MutationInsertReportTemplateArgs = {
  fields: Array<AssetReportTemplateFieldInput>
  name: Scalars["String"]
  reusable: Scalars["Boolean"]
  universal?: InputMaybe<Scalars["Boolean"]>
}

export type MutationMarkAllNotificationsReadArgs = {
  id?: InputMaybe<Scalars["String"]>
}

export type MutationMarkNotificationReadArgs = {
  id?: InputMaybe<Scalars["String"]>
}

export type MutationMarkNotificationsReadByIdArgs = {
  ids?: InputMaybe<Array<Scalars["String"]>>
}

export type MutationMarkOrganizationNotPastDueArgs = {
  id: Scalars["String"]
}

export type MutationMarkOrganizationPastDueArgs = {
  id: Scalars["String"]
}

export type MutationMarkTaskCompletionArgs = {
  completed: Scalars["Boolean"]
  id: Scalars["String"]
}

export type MutationMasqueradeUserArgs = {
  subjectUserId: Scalars["String"]
}

export type MutationReassignUserArgs = {
  projectId: Scalars["String"]
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type MutationReassignUsersArgs = {
  assignments: Array<UserAssignmentAssignment>
}

export type MutationReportTaskProgressArgs = {
  fileIds?: InputMaybe<Array<Scalars["String"]>>
  note?: InputMaybe<Scalars["String"]>
  projectId: Scalars["String"]
  taskId: Scalars["String"]
  unitGoalProgress?: InputMaybe<Array<UnitGoalProgressInput>>
}

export type MutationResendUserInvitesArgs = {
  userIds: Array<Scalars["String"]>
}

export type MutationRestoreOneAssetArgs = {
  id: Scalars["String"]
}

export type MutationRestoreOneUserArgs = {
  id: Scalars["String"]
}

export type MutationReturnQuantityToInventoryArgs = {
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  quantityToReturn: Scalars["Int"]
  status: AssetStatus
}

export type MutationTransferAssetsArgs = {
  assetGroupReassignments?: InputMaybe<Array<AssetGroupReassignmentSpecification>>
  assetIds: Array<Scalars["String"]>
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  projectIdIfTask?: InputMaybe<Scalars["String"]>
}

export type MutationUnarchiveQuantitiesArgs = {
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  quantityToUnarchive: Scalars["Int"]
  status: AssetStatus
}

export type MutationUpdateCustomerArgs = {
  addressLine1?: InputMaybe<Scalars["String"]>
  addressLine2?: InputMaybe<Scalars["String"]>
  city?: InputMaybe<Scalars["String"]>
  country?: InputMaybe<Scalars["String"]>
  customerId?: InputMaybe<Scalars["String"]>
  email?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  image?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  notes?: InputMaybe<Scalars["String"]>
  phoneNumber?: InputMaybe<Scalars["String"]>
  phoneNumberExt?: InputMaybe<Scalars["String"]>
  state?: InputMaybe<Scalars["String"]>
  zip?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateInjuryReportArgs = {
  id: Scalars["String"]
  notes?: InputMaybe<Scalars["String"]>
  userId?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateLastExportArgs = {
  entityId?: InputMaybe<Scalars["String"]>
  entityType?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateOneAssetArgs = {
  active?: InputMaybe<Scalars["Boolean"]>
  assigneeId?: InputMaybe<Scalars["String"]>
  category?: InputMaybe<AssetCategory>
  companyAssetNumber?: InputMaybe<Scalars["String"]>
  deletedAt?: InputMaybe<Scalars["DateTime"]>
  description?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  inspectionRequirements?: InputMaybe<Array<AssetInspectionRequirementsInput>>
  inventoryRequirements?: InputMaybe<AssetInventoryRequirementsInput>
  manufacturer?: InputMaybe<AssetManufacturerInput>
  name?: InputMaybe<Scalars["String"]>
  ownershipType?: InputMaybe<Scalars["String"]>
  photoId?: InputMaybe<Scalars["String"]>
  purchaseDetails?: InputMaybe<AssetPurchaseDetailsInput>
  rentalAgreement?: InputMaybe<AssetRentalAgreementInput>
  reportTemplateIds?: InputMaybe<Array<Scalars["String"]>>
  status?: InputMaybe<AssetStatus>
  vendorContact?: InputMaybe<AssetVendorContactInput>
}

export type MutationUpdateOneContractArgs = {
  description?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  name?: InputMaybe<Scalars["String"]>
  number?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateOneFileArgs = {
  expiresAt?: InputMaybe<Scalars["DateTime"]>
  fileName?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  status?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateOneProjectArgs = {
  address?: InputMaybe<AddressInput>
  code?: InputMaybe<Scalars["String"]>
  customerId?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  googlePlace?: InputMaybe<Scalars["JSON"]>
  id: Scalars["String"]
  image?: InputMaybe<Scalars["String"]>
  isArchived?: InputMaybe<Scalars["DateTime"]>
  isComplete?: InputMaybe<Scalars["DateTime"]>
  location?: InputMaybe<Scalars["JSON"]>
  locationNotes?: InputMaybe<Scalars["String"]>
  metadata?: InputMaybe<Array<MetadataNoteInput>>
  name?: InputMaybe<Scalars["String"]>
  schedule?: InputMaybe<ScheduleTemplateInput>
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>
  startDate?: InputMaybe<Scalars["DateTime"]>
  unassignedTaskId?: InputMaybe<Scalars["String"]>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateOneTaskArgs = {
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  estimatedHours?: InputMaybe<Scalars["Float"]>
  groupId?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  isComplete?: InputMaybe<Scalars["DateTime"]>
  isSubtask?: InputMaybe<Scalars["Boolean"]>
  metadata?: InputMaybe<Array<MetadataNoteInput>>
  name: Scalars["String"]
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId: Scalars["String"]
  schedule?: InputMaybe<ScheduleTemplateInput>
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>
  startDate?: InputMaybe<Scalars["DateTime"]>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateOneTaskGroupArgs = {
  description?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  name?: InputMaybe<Scalars["String"]>
  notes?: InputMaybe<Scalars["String"]>
  projectId?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateOneTimeEntryArgs = {
  endAt?: InputMaybe<Scalars["DateTime"]>
  id: Scalars["String"]
  startAt?: InputMaybe<Scalars["DateTime"]>
  taskId: Scalars["String"]
}

export type MutationUpdateOneUserArgs = {
  companyUserId?: InputMaybe<Scalars["String"]>
  firstName?: InputMaybe<Scalars["String"]>
  id: Scalars["String"]
  image?: InputMaybe<Scalars["String"]>
  jobTitle?: InputMaybe<Scalars["String"]>
  lastName?: InputMaybe<Scalars["String"]>
  payRate?: InputMaybe<Scalars["String"]>
  payType?: InputMaybe<PayType>
  phoneNumber?: InputMaybe<Scalars["String"]>
  phoneNumberExt?: InputMaybe<Scalars["String"]>
  roles?: InputMaybe<Array<Scalars["String"]>>
  task?: InputMaybe<Scalars["String"]>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
}

export type MutationUpdatePasswordArgs = {
  currentPassword: Scalars["String"]
  newPassword: Scalars["String"]
}

export type MutationUpdateReportTemplateArgs = {
  fields?: InputMaybe<Array<AssetReportTemplateFieldInput>>
  id: Scalars["String"]
  name?: InputMaybe<Scalars["String"]>
  reusable?: InputMaybe<Scalars["Boolean"]>
}

export type MutationUpdateScheduleTemplateArgs = {
  id: Scalars["String"]
  isDefault?: InputMaybe<Scalars["Boolean"]>
  nonWorkDays?: InputMaybe<Array<ScheduleTemplateNonWorkDaysInput>>
  workDays?: InputMaybe<Array<ScheduleTemplateWorkDaysInput>>
  workHours?: InputMaybe<ScheduleTemplateWorkHoursInput>
}

export type MutationUpdateTaskSortOrderArgs = {
  sortOrder: Scalars["Int"]
  taskId: Scalars["String"]
  type: Scalars["String"]
}

export type MutationUpdateUserAssignmentArgs = {
  id: Scalars["String"]
  projectId: Scalars["String"]
  taskId?: InputMaybe<Scalars["String"]>
}

export type NewUser = {
  companyUserId?: InputMaybe<Scalars["String"]>
  email: Scalars["String"]
  firstName: Scalars["String"]
  jobTitle: Scalars["String"]
  lastName: Scalars["String"]
  organizationId?: InputMaybe<Scalars["String"]>
  parentId?: InputMaybe<Scalars["String"]>
  payRate?: InputMaybe<Scalars["String"]>
  payType?: InputMaybe<PayType>
  phoneNumber: Scalars["String"]
  phoneNumberExt?: InputMaybe<Scalars["String"]>
  roles: Array<Scalars["String"]>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
}

export type OfflineEvent = {
  assetReportData?: Maybe<Scalars["JSON"]>
  clientCanceledAt?: Maybe<Scalars["DateTime"]>
  clientCreatedAt: Scalars["DateTime"]
  clientUpdatedAt?: Maybe<Scalars["DateTime"]>
  clockInData?: Maybe<ClockInData>
  clockOutData?: Maybe<ClockOutData>
  createdAt: Scalars["DateTime"]
  deviceInformation?: Maybe<Scalars["JSON"]>
  id: Scalars["ID"]
  issues?: Maybe<Scalars["String"]>
  location?: Maybe<GeolocationPosition>
  mobileApplicationIsVerified: Scalars["Boolean"]
  organizationId: Scalars["ID"]
  processedAt?: Maybe<Scalars["DateTime"]>
  receivedAt: Scalars["DateTime"]
  reconciledAt?: Maybe<Scalars["DateTime"]>
  reporterId: Scalars["ID"]
  requestInformation?: Maybe<Scalars["JSON"]>
  taskProgressData?: Maybe<Scalars["JSON"]>
  type: OfflineEventType
  updatedAt: Scalars["DateTime"]
}

export type OfflineEventInput = {
  clientCreatedAt: Scalars["DateTime"]
  clockInData?: InputMaybe<ClockInDataInput>
  clockOutData?: InputMaybe<ClockOutDataInput>
  id: Scalars["String"]
  location: GeolocationPositionInput
  type: OfflineEventType
}

export enum OfflineEventType {
  AssetReportEvent = "AssetReportEvent",
  ClockInEvent = "ClockInEvent",
  ClockOutEvent = "ClockOutEvent",
  TaskProgressEvent = "TaskProgressEvent",
}

export type Organization = {
  activeProjectsCount: Scalars["Int"]
  activeUsersForDateRangeCount: Scalars["Int"]
  admins: Array<User>
  archived: Scalars["Boolean"]
  billingPastDueAt?: Maybe<Scalars["DateTime"]>
  billingStatus: OrganizationBillingStatus
  clockedInUserCount: Scalars["Int"]
  dateCreated: Scalars["DateTime"]
  featureToggleSegments: Array<Scalars["String"]>
  id: Scalars["String"]
  image?: Maybe<Scalars["String"]>
  imageUrl?: Maybe<Scalars["String"]>
  lastStripeInvoice?: Maybe<StripeInvoice>
  name?: Maybe<Scalars["String"]>
  notificationSettings: Scalars["JSON"]
  overtimeType: OvertimeTypeEnum
  projectsCount: Scalars["Int"]
  requireTimeEntryEvidence: Scalars["Boolean"]
  roles: Array<Role>
  scheduledBreaks: Array<ScheduledBreak>
  secondsClockedForCurrentWeek: Scalars["Int"]
  stripeCustomerId?: Maybe<Scalars["String"]>
  stripeInvoices: Array<StripeInvoice>
  timezone: Scalars["String"]
  unassignedProjectId: Scalars["String"]
  unitsOfMeasure: Array<UnitOfMeasure>
  userCount: Scalars["Int"]
}

export type OrganizationActiveUsersForDateRangeCountArgs = {
  rangeEnd: Scalars["DateTime"]
  rangeStart: Scalars["DateTime"]
}

export enum OrganizationBillingStatus {
  Active = "ACTIVE",
  DisabledDueToNonPayment = "DISABLED_DUE_TO_NON_PAYMENT",
  PastDue = "PAST_DUE",
}

export enum OvertimeTypeEnum {
  DailyOvertime = "DAILY_OVERTIME",
  StandardOvertime = "STANDARD_OVERTIME",
}

export type PageInfo = {
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  hasPreviousPage: Scalars["Boolean"]
  startCursor?: Maybe<Scalars["String"]>
}

export enum PayType {
  Hourly = "HOURLY",
  Salary = "SALARY",
}

export type Project = {
  activeTasksCount: Scalars["Int"]
  address?: Maybe<Address>
  assets: Array<Asset>
  assetsCount: Scalars["Int"]
  assignees: Array<UserAssignment>
  code?: Maybe<Scalars["String"]>
  customer?: Maybe<Customer>
  customerId?: Maybe<Scalars["String"]>
  dateCreated: Scalars["DateTime"]
  /** @deprecated Please use unassignedTask instead as they are redundant */
  defaultTask: Task
  deliveredPrimaryGoalUnits: Scalars["Float"]
  description?: Maybe<Scalars["String"]>
  documents: Array<File>
  editHistoryEvents: Array<EditHistoryEvent>
  editHistoryEventsCount: Scalars["Float"]
  endDate?: Maybe<Scalars["DateTime"]>
  estimatedHours: Scalars["Float"]
  /** @deprecated Not pertinent or used anymore */
  googlePlace?: Maybe<Scalars["JSON"]>
  hasReportableUnit: Scalars["Boolean"]
  hoursDataByDateRangeType: Scalars["JSON"]
  id: Scalars["String"]
  imageUrl?: Maybe<Scalars["String"]>
  injuryReports: Array<InjuryReport>
  isArchived?: Maybe<Scalars["DateTime"]>
  isComplete?: Maybe<Scalars["DateTime"]>
  isDefault: Scalars["Boolean"]
  lastExport?: Maybe<Scalars["JSON"]>
  location?: Maybe<LocationWithRadius>
  locationNotes?: Maybe<Scalars["String"]>
  /** @deprecated This field is untyped and replaced by location */
  locationUntyped?: Maybe<Scalars["JSON"]>
  /** @deprecated Please use metadataNotes instead as they are typed */
  metadata?: Maybe<Scalars["JSON"]>
  metadataNotes?: Maybe<Array<MetadataNote>>
  name: Scalars["String"]
  organization: Organization
  organizationId: Scalars["String"]
  primaryGoalTargetQuantity: Scalars["Float"]
  productionRate: Scalars["Float"]
  progressSummary: ProjectProgressSummary
  scheduleTemplate?: Maybe<ScheduleTemplate>
  scheduledBreaks?: Maybe<Array<ScheduledBreak>>
  startDate?: Maybe<Scalars["DateTime"]>
  taskGroups: Array<TaskGroup>
  taskProgressEventsWithUnitReports: Array<TaskProgressEvent>
  tasks: Array<Task>
  tasksCount: Scalars["Int"]
  timeEntries: Array<TimeEntry>
  timeEntriesSumDurationInSeconds: Scalars["Float"]
  unassignedTask: Task
  unassignedTaskId?: Maybe<Scalars["String"]>
  unitGoals: Array<UnitGoal>
  userCount: Scalars["Int"]
  users: Array<User>
  workersCompCode?: Maybe<WorkersCompCode>
  workersCompCodeId?: Maybe<Scalars["String"]>
}

export type ProjectAssetsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectAssetsCountArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectDeliveredPrimaryGoalUnitsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectHoursDataByDateRangeTypeArgs = {
  dateRangeType: Scalars["String"]
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectProductionRateArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectTaskProgressEventsWithUnitReportsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectTasksArgs = {
  includeArchived?: InputMaybe<Scalars["Boolean"]>
  includeCompleted?: InputMaybe<Scalars["Boolean"]>
  includeSubtasks?: InputMaybe<Scalars["Boolean"]>
}

export type ProjectTimeEntriesArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectTimeEntriesSumDurationInSecondsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectUsersArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type ProjectFilter = {
  searchText?: InputMaybe<Scalars["String"]>
  status?: ProjectStatus
}

export type ProjectProgressSummary = {
  completedHours: Scalars["Float"]
  completedUnits: Scalars["Float"]
  completionPercentage: Scalars["Float"]
  noHours?: Maybe<Scalars["Boolean"]>
  targetHours: Scalars["Float"]
  targetUnits: Scalars["Float"]
}

export type ProjectSort = {
  by?: ProjectSortByInput
  direction?: SortDirectionInput
}

export enum ProjectSortByInput {
  Name = "name",
}

export enum ProjectStatus {
  Active = "active",
  Archived = "archived",
  Completed = "completed",
}

export type Query = {
  activeProjectCount: Scalars["Int"]
  activeProjects: Array<Project>
  activeProjectsPaginated: QueryActiveProjectsPaginatedConnection
  archivedAssetGroups: Array<AssetGroup>
  archivedAssets: QueryArchivedAssetsConnection
  asset: Asset
  assetGroups: Array<AssetGroup>
  assetReport: AssetReport
  assetReportTemplate: AssetReportTemplate
  assetReports: Array<AssetReport>
  assets: Array<Asset>
  assets_2: QueryAssets_2Connection
  assignedAssets: Array<AssignedAsset>
  breakTasks: Array<Task>
  contract: Contract
  contracts: Array<Contract>
  currentUser: User
  customer: Customer
  customers: Array<Customer>
  deliverableUnit: DeliverableUnit
  deliverableUnits: Array<DeliverableUnit>
  editHistoryEvent: EditHistoryEvent
  editHistoryEvents: Array<EditHistoryEvent>
  getJobTitles: Array<Scalars["String"]>
  myNotifications: Array<UserNotification>
  myOrganization: Organization
  nestedAssets: Array<Asset>
  nestedAssetsForInventory: Array<AssignedAsset>
  offlineEvent: OfflineEvent
  offlineEvents: Array<OfflineEvent>
  organization: Organization
  organizations: Array<Organization>
  project?: Maybe<Project>
  projects: Array<Project>
  projectsByStatus: Array<Project>
  projectsPaginated: QueryProjectsPaginatedConnection
  reusableAssetReportTemplates: Array<AssetReportTemplate>
  roles: Array<Role>
  scheduleTemplates: Array<ScheduleTemplate>
  scheduledBreaks: Array<ScheduledBreak>
  subtasks: Array<Task>
  subtasksPaginated: QuerySubtasksPaginatedConnection
  task: Task
  taskGroup?: Maybe<TaskGroup>
  taskGroups: Array<TaskGroup>
  taskList: Array<TaskListItem>
  taskProgressEvent: TaskProgressEvent
  taskProgressEvents: Array<TaskProgressEvent>
  tasks: Array<Task>
  tasksForGroupId: Array<Task>
  tasksPaginated: QueryTasksPaginatedConnection
  unitGoal?: Maybe<UnitGoal>
  unitGoals: Array<UnitGoal>
  user: User
  users: QueryUsersConnection
  usersForSummarySection: Array<User>
  usersList: Array<User>
  workersCompCodes: Array<WorkersCompCode>
}

export type QueryActiveProjectsPaginatedArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
}

export type QueryArchivedAssetGroupsArgs = {
  assetId: Scalars["String"]
}

export type QueryArchivedAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  searchText?: InputMaybe<Scalars["String"]>
  sort?: AssetSort
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryAssetArgs = {
  id: Scalars["String"]
}

export type QueryAssetGroupsArgs = {
  assetGroupId?: InputMaybe<Scalars["String"]>
  assetId?: InputMaybe<Scalars["String"]>
  assetIds?: InputMaybe<Array<Scalars["String"]>>
  projectId?: InputMaybe<Scalars["String"]>
  taskId?: InputMaybe<Scalars["String"]>
  userId?: InputMaybe<Scalars["String"]>
}

export type QueryAssetReportArgs = {
  reportId: Scalars["String"]
}

export type QueryAssetReportTemplateArgs = {
  id: Scalars["String"]
}

export type QueryAssetReportsArgs = {
  assetId: Scalars["String"]
}

export type QueryAssetsArgs = {
  assetGroupId?: InputMaybe<Scalars["String"]>
  assetGroupIds?: InputMaybe<Array<Scalars["String"]>>
  assetId?: InputMaybe<Scalars["String"]>
  assetIds?: InputMaybe<Array<Scalars["String"]>>
  assignableId?: InputMaybe<Scalars["String"]>
  assignableType?: InputMaybe<AssetAssignableType>
  category?: InputMaybe<AssetCategory>
  deleted?: InputMaybe<Scalars["Boolean"]>
  includeGroupedAssets?: InputMaybe<Scalars["Boolean"]>
  projectId?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<AssetStatus>
  taskId?: InputMaybe<Scalars["String"]>
  userId?: InputMaybe<Scalars["String"]>
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryAssets_2Args = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  filter?: AssetFilter
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  sort?: AssetSort
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryAssignedAssetsArgs = {
  filter?: AssignedAssetFilter
  sort?: AssetSort
}

export type QueryContractArgs = {
  id: Scalars["String"]
}

export type QueryContractsArgs = {
  customerId?: InputMaybe<Scalars["String"]>
}

export type QueryCustomerArgs = {
  id: Scalars["String"]
}

export type QueryDeliverableUnitArgs = {
  id: Scalars["String"]
}

export type QueryEditHistoryEventArgs = {
  id: Scalars["String"]
}

export type QueryEditHistoryEventsArgs = {
  taskId: Scalars["String"]
}

export type QueryMyNotificationsArgs = {
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryNestedAssetsArgs = {
  id: Scalars["String"]
}

export type QueryNestedAssetsForInventoryArgs = {
  id: Scalars["String"]
}

export type QueryOfflineEventArgs = {
  id: Scalars["String"]
}

export type QueryOfflineEventsArgs = {
  processed?: InputMaybe<Scalars["Boolean"]>
  reporterId?: InputMaybe<Scalars["String"]>
}

export type QueryOrganizationArgs = {
  id: Scalars["String"]
}

export type QueryOrganizationsArgs = {
  archived?: InputMaybe<Scalars["Boolean"]>
}

export type QueryProjectArgs = {
  id: Scalars["String"]
}

export type QueryProjectsArgs = {
  filter?: ProjectFilter
  sort?: ProjectSort
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryProjectsByStatusArgs = {
  includeDefault?: InputMaybe<Scalars["Boolean"]>
  limit?: InputMaybe<Scalars["Int"]>
  status: ProjectStatus
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryProjectsPaginatedArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
}

export type QueryScheduleTemplatesArgs = {
  isDefault?: InputMaybe<Scalars["Boolean"]>
  projectId?: InputMaybe<Scalars["String"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type QueryScheduledBreaksArgs = {
  includeOrgBreaks?: InputMaybe<Scalars["Boolean"]>
  projectId?: InputMaybe<Scalars["String"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type QuerySubtasksArgs = {
  onlyActive?: InputMaybe<Scalars["Boolean"]>
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId?: InputMaybe<Scalars["String"]>
}

export type QuerySubtasksPaginatedArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  onlyActive?: InputMaybe<Scalars["Boolean"]>
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId?: InputMaybe<Scalars["String"]>
}

export type QueryTaskArgs = {
  id: Scalars["String"]
}

export type QueryTaskGroupArgs = {
  id: Scalars["String"]
}

export type QueryTaskGroupsArgs = {
  projectId?: InputMaybe<Scalars["String"]>
}

export type QueryTaskListArgs = {
  archived: Scalars["Boolean"]
  projectId: Scalars["String"]
  searchText?: InputMaybe<Scalars["String"]>
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryTaskProgressEventArgs = {
  id: Scalars["String"]
}

export type QueryTaskProgressEventsArgs = {
  filter?: InputMaybe<TaskProgressEventFilter>
  projectId: Scalars["String"]
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type QueryTasksArgs = {
  includeSubtasks?: InputMaybe<Scalars["Boolean"]>
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId?: InputMaybe<Scalars["String"]>
  sorted?: InputMaybe<Scalars["Boolean"]>
  status?: InputMaybe<TaskStatus>
}

export type QueryTasksForGroupIdArgs = {
  groupId: Scalars["String"]
}

export type QueryTasksPaginatedArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  onlyActive?: InputMaybe<Scalars["Boolean"]>
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId?: InputMaybe<Scalars["String"]>
}

export type QueryUnitGoalArgs = {
  id: Scalars["String"]
}

export type QueryUnitGoalsArgs = {
  projectId: Scalars["String"]
  taskId?: InputMaybe<Scalars["String"]>
}

export type QueryUserArgs = {
  id: Scalars["String"]
}

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  filter?: UserFilter
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  sort?: UserSort
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}

export type QueryUsersForSummarySectionArgs = {
  projectId: Scalars["String"]
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type QueryUsersListArgs = {
  projectId?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<Scalars["String"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type QueryActiveProjectsPaginatedConnection = {
  edges: Array<Maybe<QueryActiveProjectsPaginatedConnectionEdge>>
  pageInfo: PageInfo
}

export type QueryActiveProjectsPaginatedConnectionEdge = {
  cursor: Scalars["String"]
  node: Project
}

export type QueryArchivedAssetsConnection = {
  edges: Array<Maybe<QueryArchivedAssetsConnectionEdge>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type QueryArchivedAssetsConnectionEdge = {
  cursor: Scalars["String"]
  node: Asset
}

export type QueryAssets_2Connection = {
  edges: Array<Maybe<QueryAssets_2ConnectionEdge>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type QueryAssets_2ConnectionEdge = {
  cursor: Scalars["String"]
  node: Asset
}

export type QueryProjectsPaginatedConnection = {
  edges: Array<Maybe<QueryProjectsPaginatedConnectionEdge>>
  pageInfo: PageInfo
}

export type QueryProjectsPaginatedConnectionEdge = {
  cursor: Scalars["String"]
  node: Project
}

export type QuerySubtasksPaginatedConnection = {
  edges: Array<Maybe<QuerySubtasksPaginatedConnectionEdge>>
  pageInfo: PageInfo
}

export type QuerySubtasksPaginatedConnectionEdge = {
  cursor: Scalars["String"]
  node: Task
}

export type QueryTasksPaginatedConnection = {
  edges: Array<Maybe<QueryTasksPaginatedConnectionEdge>>
  pageInfo: PageInfo
}

export type QueryTasksPaginatedConnectionEdge = {
  cursor: Scalars["String"]
  node: Task
}

export type QueryUsersConnection = {
  edges: Array<Maybe<QueryUsersConnectionEdge>>
  pageInfo: PageInfo
  totalCount: Scalars["Int"]
}

export type QueryUsersConnectionEdge = {
  cursor: Scalars["String"]
  node: User
}

export type RequestInfo = {
  browser?: Maybe<Scalars["String"]>
  city?: Maybe<Scalars["String"]>
  country?: Maybe<Scalars["String"]>
  isBot?: Maybe<Scalars["Boolean"]>
  isCurl?: Maybe<Scalars["Boolean"]>
  isDesktop?: Maybe<Scalars["Boolean"]>
  isMobile?: Maybe<Scalars["Boolean"]>
  isTablet?: Maybe<Scalars["Boolean"]>
  os?: Maybe<Scalars["String"]>
  platform?: Maybe<Scalars["String"]>
  region?: Maybe<Scalars["String"]>
}

export type Role = {
  dateCreated: Scalars["DateTime"]
  description?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  name?: Maybe<Scalars["String"]>
  order?: Maybe<Scalars["Int"]>
  organization: Organization
  organizationId: Scalars["String"]
  permissions: Scalars["JSON"]
  widgets: Scalars["JSON"]
}

export type ScheduleTemplate = {
  id: Scalars["String"]
  isDefault?: Maybe<Scalars["Boolean"]>
  nonWorkDays?: Maybe<Array<ScheduleTemplateNonWorkDays>>
  workDays?: Maybe<Array<ScheduleTemplateWorkDay>>
  workHours?: Maybe<ScheduleTemplateWorkHours>
}

export type ScheduleTemplateInput = {
  id?: InputMaybe<Scalars["String"]>
  isDefault?: InputMaybe<Scalars["Boolean"]>
  nonWorkDays?: InputMaybe<Array<ScheduleTemplateNonWorkDaysInput>>
  workDays?: InputMaybe<Array<ScheduleTemplateWorkDaysInput>>
  workHours?: InputMaybe<ScheduleTemplateWorkHoursInput>
}

/** ScheduleTemplateNonWorkDays */
export type ScheduleTemplateNonWorkDays = {
  active: Scalars["Boolean"]
  dateRange: Array<Scalars["DateTime"]>
  id: Scalars["String"]
  name: Scalars["String"]
}

/** ScheduleTemplateNonWorkDaysInput */
export type ScheduleTemplateNonWorkDaysInput = {
  active: Scalars["Boolean"]
  dateRange: Array<Scalars["DateTime"]>
  id?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
}

/** ScheduleTemplateWorkDay */
export type ScheduleTemplateWorkDay = {
  active: Scalars["Boolean"]
  label: Scalars["String"]
}

/** ScheduleTemplateWorkDaysInput */
export type ScheduleTemplateWorkDaysInput = {
  active: Scalars["Boolean"]
  label: Scalars["String"]
}

/** ScheduleTemplateWorkHours */
export type ScheduleTemplateWorkHours = {
  endTime?: Maybe<Scalars["String"]>
  hours?: Maybe<Scalars["Int"]>
  startTime?: Maybe<Scalars["String"]>
}

/** ScheduleTemplateWorkHoursInput */
export type ScheduleTemplateWorkHoursInput = {
  endTime?: InputMaybe<Scalars["String"]>
  hours?: InputMaybe<Scalars["Int"]>
  startTime?: InputMaybe<Scalars["String"]>
}

export type ScheduledBreak = {
  breakTask: Task
  breakTaskId: Scalars["String"]
  durationInMinutes: Scalars["Int"]
  id: Scalars["String"]
  isActive: Scalars["Boolean"]
  localizedStartTime: Scalars["String"]
  name: Scalars["String"]
  projectId?: Maybe<Scalars["String"]>
}

export type ScheduledBreakInput = {
  breakTaskId: Scalars["String"]
  durationInMinutes: Scalars["Int"]
  id?: InputMaybe<Scalars["String"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  localizedStartTime: Scalars["String"]
  name: Scalars["String"]
}

export type SecurityActivity = {
  createdAt: Scalars["DateTime"]
  event: Scalars["String"]
  ip?: Maybe<Scalars["String"]>
  userAgent?: Maybe<Scalars["String"]>
}

export enum SortByInput {
  FirstName = "firstName",
  ProjectName = "projectName",
}

export enum SortDirectionInput {
  Asc = "asc",
  Desc = "desc",
}

export type StartEvidenceInput = {
  imagePath?: InputMaybe<Scalars["String"]>
  location?: InputMaybe<GeolocationPositionInput>
  locationError?: InputMaybe<Scalars["String"]>
}

/** Invoices created with Stripe */
export type StripeInvoice = {
  accountName?: Maybe<Scalars["String"]>
  amountDue: Scalars["Int"]
  amountPaid: Scalars["Int"]
  amountRemaining: Scalars["Int"]
  attemptCount: Scalars["Int"]
  attempted: Scalars["Boolean"]
  autoAdvance?: Maybe<Scalars["Boolean"]>
  billingReason?: Maybe<Scalars["String"]>
  collectionMethod: Scalars["String"]
  created?: Maybe<Scalars["DateTime"]>
  currency: Scalars["String"]
  customerEmail?: Maybe<Scalars["String"]>
  customerName?: Maybe<Scalars["String"]>
  customerPhone?: Maybe<Scalars["String"]>
  description?: Maybe<Scalars["String"]>
  dueDate?: Maybe<Scalars["DateTime"]>
  hostedInvoiceUrl?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  invoicePdf?: Maybe<Scalars["String"]>
  livemode: Scalars["Boolean"]
  object: Scalars["String"]
  paid: Scalars["Boolean"]
  status?: Maybe<Scalars["String"]>
  subtotal: Scalars["Int"]
  subtotalExcludingTax?: Maybe<Scalars["Int"]>
  tax?: Maybe<Scalars["Int"]>
  total: Scalars["Int"]
}

export type Task = {
  archived: Scalars["Boolean"]
  assetCount: Scalars["Int"]
  assignees: Array<UserAssignment>
  clockedInUserCount: Scalars["Int"]
  clockedOutUserCount: Scalars["Int"]
  completedHours: Scalars["Float"]
  dateCreated: Scalars["DateTime"]
  description?: Maybe<Scalars["String"]>
  endDate?: Maybe<Scalars["DateTime"]>
  estimatedHours?: Maybe<Scalars["Float"]>
  group?: Maybe<TaskGroup>
  groupId?: Maybe<Scalars["String"]>
  hasReportableUnit: Scalars["Boolean"]
  hoursDataByDateRangeType: Scalars["JSON"]
  id: Scalars["String"]
  injuryReports: Array<InjuryReport>
  isComplete?: Maybe<Scalars["DateTime"]>
  isDefault: Scalars["Boolean"]
  isParentTask: Scalars["Boolean"]
  isUnpaid: Scalars["Boolean"]
  lastExport?: Maybe<Scalars["JSON"]>
  /** @deprecated Untyped; use `metadataNotes` instead */
  metadata?: Maybe<Scalars["JSON"]>
  metadataNotes?: Maybe<Array<MetadataNote>>
  name: Scalars["String"]
  organizationId: Scalars["String"]
  parentTask?: Maybe<Task>
  parentTaskId?: Maybe<Scalars["String"]>
  primaryUnitGoals: Array<UnitGoal>
  productionRate: Scalars["Float"]
  project: Project
  projectId: Scalars["String"]
  scheduleTemplate?: Maybe<ScheduleTemplate>
  scheduledBreaks?: Maybe<Array<ScheduledBreak>>
  sortOrder?: Maybe<Scalars["Int"]>
  startDate?: Maybe<Scalars["DateTime"]>
  subtaskCount: Scalars["Int"]
  subtasks: Array<Task>
  sumOfDeliveredUnits: Scalars["Float"]
  taskProgressEvents: Array<TaskProgressEvent>
  taskProgressEventsWithUnitReports: Array<TaskProgressEvent>
  timeEntries: Array<TimeEntry>
  timeEntriesSumDurationInSeconds: Scalars["Float"]
  unitGoals: Array<UnitGoal>
  unitGoalsSumProgress: Scalars["Float"]
  unitGoalsSumTargetQuantity: Scalars["Float"]
  userCount: Scalars["Int"]
  users: Array<User>
  usersWhoClockedOrReportedCount: Scalars["Int"]
  visibilityLevel: Scalars["String"]
  workersCompCode?: Maybe<WorkersCompCode>
  workersCompCodeId?: Maybe<Scalars["String"]>
}

export type TaskAssetCountArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskCompletedHoursArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskHoursDataByDateRangeTypeArgs = {
  dateRangeType: Scalars["String"]
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskProductionRateArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskSumOfDeliveredUnitsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskTaskProgressEventsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskTaskProgressEventsWithUnitReportsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskTimeEntriesArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskTimeEntriesSumDurationInSecondsArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskUsersArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskUsersWhoClockedOrReportedCountArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskGroup = {
  assetCount: Scalars["Int"]
  completedHours: Scalars["Float"]
  completedTaskCount: Scalars["Int"]
  createdAt: Scalars["DateTime"]
  description?: Maybe<Scalars["String"]>
  endDate?: Maybe<Scalars["DateTime"]>
  estimatedHours: Scalars["Float"]
  id: Scalars["ID"]
  isComplete: Scalars["Boolean"]
  name: Scalars["String"]
  notes?: Maybe<Scalars["String"]>
  organizationId: Scalars["ID"]
  productionRate: Scalars["Float"]
  projectId: Scalars["String"]
  sortOrder?: Maybe<Scalars["Int"]>
  startDate?: Maybe<Scalars["DateTime"]>
  taskCount: Scalars["Int"]
  tasks: Array<Task>
  unitProgress: Scalars["Int"]
  unitTargetGoal: Scalars["Int"]
  updatedAt: Scalars["DateTime"]
  userCount: Scalars["Int"]
}

export type TaskGroupAssetCountArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskGroupProductionRateArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskGroupUserCountArgs = {
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
}

export type TaskInput = {
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  estimatedHours?: InputMaybe<Scalars["Int"]>
  isDefault?: InputMaybe<Scalars["Boolean"]>
  name: Scalars["String"]
  sortOrder?: InputMaybe<Scalars["Int"]>
  startDate?: InputMaybe<Scalars["DateTime"]>
  unitGoals?: InputMaybe<Array<UnitGoalInput>>
  workDays?: InputMaybe<Scalars["Int"]>
}

export type TaskListItem = {
  assetCount: Scalars["Int"]
  completedHours?: Maybe<Scalars["Float"]>
  completedTaskCount: Scalars["Int"]
  createdAt: Scalars["DateTime"]
  /** endDate */
  endDate?: Maybe<Scalars["DateTime"]>
  estimatedHours?: Maybe<Scalars["Float"]>
  groupId?: Maybe<Scalars["String"]>
  isComplete: Scalars["Boolean"]
  isDefault: Scalars["Boolean"]
  name: Scalars["String"]
  sortOrder?: Maybe<Scalars["Int"]>
  /** startDate */
  startDate?: Maybe<Scalars["DateTime"]>
  taskCount: Scalars["Int"]
  taskGroupId?: Maybe<Scalars["String"]>
  taskId?: Maybe<Scalars["String"]>
  unitProgress: Scalars["Int"]
  unitTargetGoal: Scalars["Int"]
  userCount: Scalars["Int"]
}

export type TaskProgressEvent = {
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  imageUrls: Array<Maybe<Scalars["String"]>>
  note?: Maybe<Scalars["String"]>
  reporter: User
  reporterId: Scalars["String"]
  task: Task
  taskId: Scalars["String"]
  unitGoalProgressCount: Scalars["Int"]
  unitGoalProgressReports: Array<UnitGoalProgress>
  updatedAt: Scalars["DateTime"]
}

export enum TaskProgressEventFilter {
  Images = "IMAGES",
  Notes = "NOTES",
}

export type TaskReportInput = {
  fileIds?: InputMaybe<Array<Scalars["String"]>>
  note?: InputMaybe<Scalars["String"]>
  taskId: Scalars["String"]
  unitGoalProgress?: InputMaybe<Array<UnitGoalProgressInput>>
}

export enum TaskStatus {
  Active = "active",
  Archived = "archived",
  Completed = "completed",
}

export type TimeEntry = {
  createdAt: Scalars["DateTime"]
  durationInSeconds: Scalars["Float"]
  editedById: Scalars["String"]
  editor: User
  endAt?: Maybe<Scalars["DateTime"]>
  evidence: Scalars["JSON"]
  id: Scalars["String"]
  /** Tells us if this was a break or not */
  isBreak: Scalars["Boolean"]
  organization: Organization
  organizationId: Scalars["String"]
  project: Project
  projectId: Scalars["String"]
  signInPhotoUrl?: Maybe<Scalars["String"]>
  signOutPhotoUrl?: Maybe<Scalars["String"]>
  startAt: Scalars["DateTime"]
  task: Task
  taskId: Scalars["String"]
  updatedAt: Scalars["DateTime"]
  user: User
  userId: Scalars["String"]
}

/** Time Entry error response */
export type TimeEntryError = {
  reason: TimeEntryErrorReason
  userId: Scalars["String"]
}

export enum TimeEntryErrorReason {
  AlreadyClockedIn = "ALREADY_CLOCKED_IN",
  AlreadyClockedOut = "ALREADY_CLOCKED_OUT",
  AttemptedDoubleBooking = "ATTEMPTED_DOUBLE_BOOKING",
  EndCannotBeBeforeStart = "END_CANNOT_BE_BEFORE_START",
}

export type UnitGoal = {
  createdAt: Scalars["DateTime"]
  deliverableUnit: DeliverableUnit
  deliverableUnitId: Scalars["ID"]
  id: Scalars["ID"]
  isPrimary: Scalars["Boolean"]
  organizationId: Scalars["ID"]
  previousProgress: Scalars["Float"]
  progressInDateRange: Scalars["Float"]
  project: Project
  projectId: Scalars["ID"]
  targetQuantity?: Maybe<Scalars["Float"]>
  task: Task
  taskId: Scalars["ID"]
  totalProgress: Scalars["Float"]
  unitGoalProgress: Array<UnitGoalProgress>
  updatedAt: Scalars["DateTime"]
}

export type UnitGoalPreviousProgressArgs = {
  rangeStart: Scalars["DateTime"]
}

export type UnitGoalProgressInDateRangeArgs = {
  rangeEnd: Scalars["DateTime"]
  rangeStart: Scalars["DateTime"]
}

export type UnitGoalInput = {
  deliverableUnitId: Scalars["String"]
  isPrimary: Scalars["Boolean"]
  targetQuantity?: InputMaybe<Scalars["Float"]>
}

export type UnitGoalProgress = {
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  progress: Scalars["Float"]
  unitGoal: UnitGoal
  unitGoalId: Scalars["String"]
  updatedAt: Scalars["DateTime"]
}

export type UnitGoalProgressInput = {
  progress: Scalars["Float"]
  unitGoalId: Scalars["String"]
}

export type UnitOfMeasure = {
  id?: Maybe<Scalars["Int"]>
  name: Scalars["String"]
  organizationId?: Maybe<Scalars["String"]>
  symbol: Scalars["String"]
}

/** Response from update sort order */
export type UpdateSortOrderPayload = {
  id: Scalars["String"]
  sortOrder: Scalars["Int"]
}

export type User = {
  archived: Scalars["Boolean"]
  assignedProjectIds: Array<Scalars["String"]>
  assignedTaskIds: Array<Scalars["String"]>
  assignments: Array<UserAssignment>
  childAncestorNodes: Array<ChildAncestorNode>
  children: Array<User>
  companyUserId?: Maybe<Scalars["String"]>
  currentProject: Project
  currentProjectId: Scalars["String"]
  currentTask: Task
  currentTaskId: Scalars["String"]
  deviceSessions: Array<UserDeviceSession>
  editedTimeEntries: Array<TimeEntry>
  email: Scalars["String"]
  emailVerifiedAt?: Maybe<Scalars["DateTime"]>
  firstName: Scalars["String"]
  fullName: Scalars["String"]
  id: Scalars["String"]
  imageUrl?: Maybe<Scalars["String"]>
  injuryReports: Array<InjuryReport>
  invitationPending: Scalars["Boolean"]
  isBlackthornEmployee: Scalars["Boolean"]
  isClockedIn: Scalars["Boolean"]
  /** This field is a convenience helper to encapsulate logic which tells us if a user is on a scheduled break or not.  Someday this might look different but this is adequate today */
  isOnBreak: Scalars["Boolean"]
  jobTitle: Scalars["String"]
  lastName: Scalars["String"]
  latestTimeEntry?: Maybe<TimeEntry>
  myFiles: Array<File>
  offlineEvents: Array<OfflineEvent>
  organization: Organization
  organizationId: Scalars["String"]
  parents: Array<User>
  passwordUpdatedAt?: Maybe<Scalars["DateTime"]>
  payRate?: Maybe<Scalars["String"]>
  payType?: Maybe<PayType>
  phoneNumber: Scalars["String"]
  phoneNumberExt?: Maybe<Scalars["String"]>
  project: Project
  /** @deprecated Use currentProjectId */
  projectId: Scalars["String"]
  reportedUnitsInDateRangeByProjectOrTask: Array<TaskProgressEvent>
  roles: Array<Role>
  secondsClockedInDateRangeOnProjectOrTask: Scalars["Int"]
  secondsClockedSinceOrgMidnight: Scalars["Int"]
  secondsClockedThisWeek: Scalars["Int"]
  securityActivity: Array<SecurityActivity>
  sortName: Scalars["String"]
  task: Task
  /** @deprecated Use currentTaskId */
  taskId?: Maybe<Scalars["String"]>
  timeEntries?: Maybe<Array<TimeEntry>>
  workersCompCode?: Maybe<WorkersCompCode>
  workersCompCodeId?: Maybe<Scalars["String"]>
}

export type UserReportedUnitsInDateRangeByProjectOrTaskArgs = {
  projectId?: InputMaybe<Scalars["String"]>
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type UserSecondsClockedInDateRangeOnProjectOrTaskArgs = {
  projectId?: InputMaybe<Scalars["String"]>
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type UserSecurityActivityArgs = {
  skip?: Scalars["Int"]
  take?: Scalars["Int"]
}

export type UserTimeEntriesArgs = {
  projectId?: InputMaybe<Scalars["String"]>
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  taskId?: InputMaybe<Scalars["String"]>
}

export type UserAssignment = {
  active: Scalars["Boolean"]
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  isCurrentAssignment?: Maybe<Scalars["Boolean"]>
  organizationId: Scalars["String"]
  project: Project
  projectId: Scalars["String"]
  task?: Maybe<Task>
  taskId?: Maybe<Scalars["String"]>
  updatedAt: Scalars["DateTime"]
  user: User
  userId?: Maybe<Scalars["String"]>
}

export type UserAssignmentAssignment = {
  projectId: Scalars["String"]
  taskId: Scalars["String"]
  userId: Scalars["String"]
}

export type UserAssignmentBatchResponse = {
  count: Scalars["Int"]
}

export type UserAssignmentCreateInput = {
  projectId: Scalars["String"]
  taskId?: InputMaybe<Scalars["String"]>
  userId: Scalars["String"]
}

export type UserDeviceSession = {
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  ip?: Maybe<Scalars["String"]>
  isSingleUse?: Maybe<Scalars["Boolean"]>
  requestInfo: RequestInfo
  updatedAt: Scalars["DateTime"]
  userAgent?: Maybe<Scalars["String"]>
  userId: Scalars["String"]
}

export type UserFilter = {
  archived?: InputMaybe<Scalars["Boolean"]>
  invitationPending?: InputMaybe<Scalars["Boolean"]>
  isClockedIn?: InputMaybe<Scalars["Boolean"]>
  projectId?: InputMaybe<Scalars["String"]>
  searchText?: InputMaybe<Scalars["String"]>
  taskId?: InputMaybe<Scalars["String"]>
  userIds?: InputMaybe<Array<Scalars["String"]>>
}

export type UserNotification = {
  asset?: Maybe<Asset>
  assignedUser: User
  assignedUserId: Scalars["String"]
  createdAt: Scalars["DateTime"]
  id: Scalars["ID"]
  markedReadAt?: Maybe<Scalars["DateTime"]>
  modelType: UserNotificationModelType
  organizationId: Scalars["String"]
  type: UserNotificationType
  user?: Maybe<User>
}

export enum UserNotificationModelType {
  Asset = "Asset",
  User = "User",
}

export enum UserNotificationType {
  AssetInspectionFailed = "ASSET_INSPECTION_FAILED",
  InjuryReportCreated = "INJURY_REPORT_CREATED",
}

export type UserSort = {
  by?: SortByInput
  direction?: SortDirectionInput
}

export type UserToUser = {
  child: User
  childId: Scalars["String"]
  createdAt: Scalars["DateTime"]
  parent: User
  parentId: Scalars["String"]
  updatedAt: Scalars["DateTime"]
}

export type WorkersCompCode = {
  active: Scalars["Boolean"]
  createdAt: Scalars["DateTime"]
  id: Scalars["ID"]
  name: Scalars["String"]
  ncciCode: Scalars["String"]
  ncciDescription: Scalars["String"]
  organizationId: Scalars["ID"]
  override: Scalars["Boolean"]
  rate: Scalars["Float"]
  updatedAt: Scalars["DateTime"]
}

export type MasqueradeCredentials = {
  accessToken: Scalars["String"]
}

export type AssetBaseFragment = {
  id: string
  active: boolean
  activeChangedAt: Date
  assetChildCount: number
  assetGroupId?: string | null
  assignmentsCount: number
  assignableId: string
  assignedUserId?: string | null
  assignableType: AssetAssignableType
  companyAssetNumber?: string | null
  deletedAt?: Date | null
  groupQuantity?: number | null
  imageUrl?: string | null
  isAssetGroup: boolean
  lastInventoriedAt?: Date | null
  name: string
  organizationId: string
  ownershipType: string
  status: AssetStatus
}

export type AssetAdditionalFragment = {
  purchaseDetails?: { price?: number | null; date?: string | null } | null
  rentalAgreement?: {
    endOn?: string | null
    startOn?: string | null
    rate?: { daily: number; weekly: number; monthly: number } | null
  } | null
  manufacturer?: {
    id?: string | null
    make?: string | null
    name?: string | null
    year?: number | null
    model?: string | null
  } | null
  vendorContact?: { name?: string | null; email?: string | null; phone?: string | null } | null
}

export type AssetInventoryFragment = {
  inventoryRequirements?: { intervalInSeconds?: number | null; photoRequired?: boolean | null } | null
}

export type AssetReportBaseFragment = {
  id: string
  assetId: string
  reporterId: string
  notes?: string | null
  type: AssetReportType
}

export type AssetReportDatesFragment = { createdAt: Date; updatedAt: Date }

export type AssetReportInventoryFragment = {
  inventoryReport?: { photoUrls?: Array<string> | null; note?: string | null } | null
}

export type AssetStatusFragment = { statusChange?: { active: boolean; status: AssetStatus } | null }

export type AssetReportReporterFragment = {
  reporter: { id: string; firstName: string; lastName: string; fullName: string }
}

export type CustomerFullFragment = {
  id: string
  name: string
  image?: string | null
  imageUrl?: string | null
  customerId?: string | null
  phoneNumber?: string | null
  phoneNumberExt?: string | null
  email?: string | null
  notes?: string | null
  addressLine1?: string | null
  addressLine2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  country?: string | null
}

export type CustomerBaseFragment = { id: string; name: string; image?: string | null; customerId?: string | null }

export type DeliverableUnitBaseFragment = {
  id: string
  description: string
  unitOfMeasure: string
  referenceNumber?: string | null
}

export type OrganizationBaseFragment = {
  id: string
  name?: string | null
  imageUrl?: string | null
  dateCreated: Date
  unassignedProjectId: string
  timezone: string
  archived: boolean
  requireTimeEntryEvidence: boolean
  overtimeType: OvertimeTypeEnum
}

export type ProjectBaseFragment = {
  id: string
  code?: string | null
  name: string
  dateCreated: Date
  organizationId: string
  unassignedTaskId?: string | null
  isArchived?: Date | null
  isDefault: boolean
  isComplete?: Date | null
  startDate?: Date | null
  endDate?: Date | null
  description?: string | null
  imageUrl?: string | null
  customerId?: string | null
}

export type ProjectAdditionalFragment = {
  locationNotes?: string | null
  googlePlace?: any | null
  userCount: number
  metadata?: any | null
  location?: { lat: number; lng: number; radius?: number | null } | null
  address?: {
    formattedAddress?: string | null
    addressComponents: Array<{ longName: string; shortName: string; types: Array<string> }>
  } | null
  workersCompCode?: { id: string; name: string; ncciCode: string } | null
}

export type TaskBaseFragment = { id: string; name: string; projectId: string; parentTaskId?: string | null }

export type TaskAdditionalFragment = {
  dateCreated: Date
  endDate?: Date | null
  startDate?: Date | null
  archived: boolean
  description?: string | null
  estimatedHours?: number | null
  hasReportableUnit: boolean
  isComplete?: Date | null
  isDefault: boolean
  metadata?: any | null
  workersCompCode?: {
    id: string
    name: string
    ncciCode: string
    ncciDescription: string
    override: boolean
    rate: number
  } | null
}

export type UnitGoalBaseFragment = { id: string; targetQuantity?: number | null; deliverableUnitId: string }

export type TimeEntryBaseFragment = {
  id: string
  userId: string
  taskId: string
  startAt: Date
  endAt?: Date | null
  durationInSeconds: number
  evidence: any
}

export type UserBaseFragment = {
  id: string
  organizationId: string
  projectId: string
  taskId?: string | null
  email: string
  phoneNumber: string
  phoneNumberExt?: string | null
  imageUrl?: string | null
  firstName: string
  lastName: string
  jobTitle: string
  archived: boolean
}

export type RoleBaseFragment = {
  id: string
  name?: string | null
  order?: number | null
  description?: string | null
  permissions: any
  widgets: any
}

export type AssetInspectionReportModalQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type AssetInspectionReportModalQuery = {
  assetReportTemplate: {
    id: string
    assetsCount: number
    createdAt: Date
    deletedAt?: Date | null
    name: string
    reusable: boolean
    universal: boolean
    fields: Array<{
      id: string
      label: string
      photoRequired: boolean
      photoLabel?: string | null
      rule?: string | null
      failedStatus?: string | null
      required: boolean
      type: string
    }>
  }
}

export type ConfirmClockInModalBodyQueryQueryVariables = Exact<{
  projectId: Scalars["String"]
  taskId: Scalars["String"]
}>

export type ConfirmClockInModalBodyQueryQuery = {
  project?: { id: string; name: string } | null
  task: { id: string; name: string; parentTask?: { id: string; name: string } | null }
}

export type CreateOrUpdateUserAssignmentFormQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
}>

export type CreateOrUpdateUserAssignmentFormQuery = {
  activeProjects: Array<{ id: string; name: string; tasks: Array<{ id: string; name: string }> }>
  users: {
    edges: Array<{
      node: {
        id: string
        firstName: string
        lastName: string
        jobTitle: string
        currentProjectId: string
        currentTaskId: string
        projectId: string
        taskId?: string | null
      }
    } | null>
    pageInfo: { hasNextPage: boolean; endCursor?: string | null }
  }
}

export type DeliverableUnitsListingTableQueryVariables = Exact<{ [key: string]: never }>

export type DeliverableUnitsListingTableQuery = {
  deliverableUnits: Array<{
    id: string
    deletedAt?: Date | null
    description: string
    referenceNumber?: string | null
    unitOfMeasure: string
    color: string
  }>
}

export type AssetWithAssigneeRowGetUserQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type AssetWithAssigneeRowGetUserQuery = { user: { firstName: string; lastName: string; jobTitle: string } }

export type UserWithTaskRowQueryQueryVariables = Exact<{
  taskId: Scalars["String"]
  projectId: Scalars["String"]
}>

export type UserWithTaskRowQueryQuery = {
  task: { id: string; name: string }
  project?: { id: string; name: string } | null
}

export type ProjectAndTasksMultiSelectQueryVariables = Exact<{ [key: string]: never }>

export type ProjectAndTasksMultiSelectQuery = {
  projectsByStatus: Array<{ id: string; name: string; isDefault: boolean; tasks: Array<{ id: string; name: string }> }>
}

export type TeamMembersMultiSelectListOrgProjectsQueryVariables = Exact<{
  status: ProjectStatus
}>

export type TeamMembersMultiSelectListOrgProjectsQuery = { projectsByStatus: Array<{ id: string; name: string }> }

export type UserSelectQueryVariables = Exact<{
  filter: UserFilter
  first?: InputMaybe<Scalars["Int"]>
  after?: InputMaybe<Scalars["String"]>
}>

export type UserSelectQuery = {
  users: {
    totalCount: number
    pageInfo: { endCursor?: string | null; hasNextPage: boolean }
    edges: Array<{
      cursor: string
      node: {
        id: string
        currentProjectId: string
        currentTaskId: string
        firstName: string
        lastName: string
        jobTitle: string
        imageUrl?: string | null
        projectId: string
        task: { id: string; name: string }
        project: { id: string; name: string }
      }
    } | null>
  }
}

export type WorkersCompCodeSelectQueryVariables = Exact<{ [key: string]: never }>

export type WorkersCompCodeSelectQuery = { workersCompCodes: Array<{ id: string; name: string; ncciCode: string }> }

export type LogoQueryQueryVariables = Exact<{ [key: string]: never }>

export type LogoQueryQuery = { myOrganization: { id: string; name?: string | null; imageUrl?: string | null } }

export type GetDeliverableUnitsQueryQueryVariables = Exact<{ [key: string]: never }>

export type GetDeliverableUnitsQueryQuery = {
  deliverableUnits: Array<{ id: string; description: string; unitOfMeasure: string }>
}

export type AssetHistoryReportQueryVariables = Exact<{
  reportId: Scalars["String"]
}>

export type AssetHistoryReportQuery = {
  assetReport: {
    id: string
    createdAt: Date
    notes?: string | null
    updatedAt: Date
    inspectionReport?: Array<{
      id?: string | null
      fileIds?: Array<string> | null
      input?: string | null
      label?: string | null
      photoUrls?: Array<string> | null
      photoRequired?: boolean | null
      photoLabel?: string | null
      type?: string | null
    }> | null
    inventoryReport?: { fileIds?: Array<string> | null; note?: string | null; photoUrls?: Array<string> | null } | null
    statusChange?: { status: AssetStatus } | null
    transferReport?: {
      from: {
        assignableId: string
        assignableType: AssetAssignableType
        task?: { id: string; name: string; project: { id: string; name: string } } | null
        user?: {
          id: string
          firstName: string
          imageUrl?: string | null
          jobTitle: string
          lastName: string
          isClockedIn: boolean
        } | null
        asset?: { id: string } | null
      }
      to: {
        assignableId: string
        assignableType: AssetAssignableType
        task?: { id: string; name: string; project: { id: string; name: string } } | null
        user?: {
          id: string
          firstName: string
          imageUrl?: string | null
          jobTitle: string
          lastName: string
          isClockedIn: boolean
        } | null
        asset?: { id: string } | null
      }
    } | null
  }
}

export type AssetQuantityTableQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type AssetQuantityTableQuery = {
  asset: {
    id: string
    status: AssetStatus
    assetChildCount: number
    assetGroupId?: string | null
    assignableId: string
    companyAssetNumber?: string | null
    compositeKey: string
    groupQuantity?: number | null
    imageUrl?: string | null
    isAssetGroup: boolean
    name: string
    ownershipType: string
    lastInventoriedAt?: Date | null
    inventoryRequirements?: { photoRequired?: boolean | null; intervalInSeconds?: number | null } | null
  }
  assetGroups: Array<{
    assetGroupId: string
    assignableId: string
    assignableType: AssetAssignableType
    assignedAssetId?: string | null
    assignedTaskId?: string | null
    assignedUserId?: string | null
    count: number
    status: AssetStatus
    compositeKey: string
    assignedAsset?: {
      id: string
      assetChildCount: number
      assetGroupId?: string | null
      assignableId: string
      companyAssetNumber?: string | null
      compositeKey: string
      groupQuantity?: number | null
      imageUrl?: string | null
      isAssetGroup: boolean
      name: string
      status: AssetStatus
      ownershipType: string
    } | null
    assignedTask?: { id: string; name: string; projectId: string; project: { id: string; name: string } } | null
    assignedUser?: {
      id: string
      firstName: string
      imageUrl?: string | null
      jobTitle: string
      lastName: string
    } | null
    groupParent: {
      id: string
      active: boolean
      assetChildCount: number
      imageUrl?: string | null
      isAssetGroup: boolean
      name: string
      companyAssetNumber?: string | null
      compositeKey: string
      ownershipType: string
    }
  }>
}

export type AssetQuickActionsQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type AssetQuickActionsQuery = {
  asset: {
    id: string
    name: string
    active: boolean
    assetChildCount: number
    assetGroupId?: string | null
    assignableId: string
    assignableType: AssetAssignableType
    compositeKey: string
    companyAssetNumber?: string | null
    isAssetGroup: boolean
    lastInventoriedAt?: Date | null
    ownershipType: string
    status: AssetStatus
    inferredProjectId?: string | null
    inferredOwner?: { id: string } | null
    inventoryRequirements?: { photoRequired?: boolean | null; intervalInSeconds?: number | null } | null
    assignedUser?: {
      id: string
      firstName: string
      imageUrl?: string | null
      isClockedIn: boolean
      jobTitle: string
      lastName: string
      currentProject: { id: string; name: string }
      currentTask: { id: string; name: string }
    } | null
    assignedAsset?: {
      id: string
      assignableId: string
      companyAssetNumber?: string | null
      compositeKey: string
      name: string
    } | null
    assignedTask?: {
      id: string
      name: string
      project: {
        id: string
        name: string
        imageUrl?: string | null
        isArchived?: Date | null
        isComplete?: Date | null
      }
    } | null
    inspectionTemplatesAssignments: Array<{
      id: string
      intervalInSeconds?: number | null
      lastReportedAt?: Date | null
      startDate?: Date | null
      inspectionTemplate: { id: string; name: string }
    }>
  }
  assetGroups: Array<{
    assetGroupId: string
    assignableId: string
    assignableType: AssetAssignableType
    assignedUserId?: string | null
    count: number
    status: AssetStatus
  }>
}

export type AssetQuickAddQueryQueryVariables = Exact<{ [key: string]: never }>

export type AssetQuickAddQueryQuery = {
  assets: Array<{
    id: string
    active: boolean
    assetChildCount: number
    assignableId: string
    assignableType: AssetAssignableType
    assignedAssetId?: string | null
    assignedUserId?: string | null
    companyAssetNumber?: string | null
    deletedAt?: Date | null
    imageUrl?: string | null
    isAssetGroup: boolean
    name: string
    ownershipType: string
    status: AssetStatus
    manufacturer?: { id?: string | null } | null
  }>
  usersList: Array<{
    id: string
    currentProjectId: string
    currentTaskId: string
    firstName: string
    imageUrl?: string | null
    jobTitle: string
    lastName: string
    projectId: string
    taskId?: string | null
  }>
  projectsByStatus: Array<{
    id: string
    code?: string | null
    name: string
    isArchived?: Date | null
    isComplete?: Date | null
    imageUrl?: string | null
  }>
}

export type AssetQuickAddAssignableTaskQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type AssetQuickAddAssignableTaskQuery = { tasks: Array<{ id: string; name: string }> }

export type AssetReassignmentRowQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type AssetReassignmentRowQuery = {
  asset: {
    id: string
    isAssetGroup: boolean
    compositeKey: string
    childAssets?: Array<{
      id: string
      assignableId: string
      assignableType: AssetAssignableType
      assignedUserId?: string | null
      active: boolean
      assetChildCount: number
      compositeKey: string
      imageUrl?: string | null
      isAssetGroup: boolean
      name: string
      ownershipType: string
      status: AssetStatus
    }> | null
    childAssetGroups?: Array<{
      assetGroupId: string
      assignableId: string
      assignableType: AssetAssignableType
      status: AssetStatus
      compositeKey: string
      count: number
      groupParent: { id: string; name: string; imageUrl?: string | null; ownershipType: string }
    }> | null
  }
}

export type AssignedUserDisplayBadgeQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type AssignedUserDisplayBadgeQuery = {
  user: {
    id: string
    firstName: string
    imageUrl?: string | null
    jobTitle: string
    lastName: string
    archived: boolean
    isClockedIn: boolean
  }
}

export type CreateOrEditAssetFormQueryQueryVariables = Exact<{ [key: string]: never }>

export type CreateOrEditAssetFormQueryQuery = {
  assets: Array<{
    id: string
    active: boolean
    assetChildCount: number
    assignableId: string
    assignableType: AssetAssignableType
    assignedAssetId?: string | null
    assignedUserId?: string | null
    companyAssetNumber?: string | null
    deletedAt?: Date | null
    imageUrl?: string | null
    isAssetGroup: boolean
    name: string
    ownershipType: string
    status: AssetStatus
    manufacturer?: { id?: string | null } | null
  }>
  usersList: Array<{
    id: string
    currentProjectId: string
    currentTaskId: string
    firstName: string
    imageUrl?: string | null
    jobTitle: string
    lastName: string
    projectId: string
    taskId?: string | null
  }>
  projectsByStatus: Array<{
    id: string
    code?: string | null
    name: string
    isArchived?: Date | null
    isComplete?: Date | null
    imageUrl?: string | null
  }>
}

export type AssignableTaskQueryQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type AssignableTaskQueryQuery = {
  taskList: Array<{ taskId?: string | null; taskGroupId?: string | null; name: string }>
}

export type AssetInspectionFormQueryVariables = Exact<{ [key: string]: never }>

export type AssetInspectionFormQuery = {
  reusableAssetReportTemplates: Array<{ id: string; name: string; universal: boolean }>
}

export type AssetDetailsPanelQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type AssetDetailsPanelQuery = {
  assets: Array<{
    id: string
    active: boolean
    assetChildCount: number
    assetGroupId?: string | null
    assignableId: string
    assignableType: AssetAssignableType
    assignedAssetId?: string | null
    assignedTaskId?: string | null
    assignedUserId?: string | null
    companyAssetNumber?: string | null
    compositeKey: string
    deletedAt?: Date | null
    groupQuantity?: number | null
    imageUrl?: string | null
    isAssetGroup: boolean
    name: string
    ownershipType: string
    status: AssetStatus
    assignedAsset?: { id: string; name: string; imageUrl?: string | null } | null
    childAssetGroups?: Array<{
      assetGroupId: string
      assignableId: string
      assignableType: AssetAssignableType
      compositeKey: string
      count: number
      status: AssetStatus
      groupParent: {
        id: string
        assetChildCount: number
        assetGroupId?: string | null
        assignableId: string
        assignableType: AssetAssignableType
        companyAssetNumber?: string | null
        imageUrl?: string | null
        isAssetGroup: boolean
        name: string
        ownershipType: string
        status: AssetStatus
      }
    }> | null
    inferredOwner?: { id: string; currentProject: { id: string; name: string } } | null
  }>
}

export type GroupsByAssetGroupIdQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type GroupsByAssetGroupIdQuery = {
  groupsByAssetGroupId: Array<{
    assetGroupId: string
    assignableId: string
    assignableType: AssetAssignableType
    compositeKey: string
    status: AssetStatus
    count: number
    assignedTask?: { id: string; name: string; projectId: string; project: { id: string; name: string } } | null
    assignedAsset?: {
      id: string
      compositeKey: string
      name: string
      imageUrl?: string | null
      assignedUser?: { id: string; currentProject: { id: string; name: string } } | null
    } | null
    groupParent: {
      id: string
      assetGroupId?: string | null
      imageUrl?: string | null
      name: string
      ownershipType: string
      isAssetGroup: boolean
    }
  }>
}

export type GroupsByAssetIdQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type GroupsByAssetIdQuery = {
  assetGroups: Array<{
    assetGroupId: string
    assignableId: string
    assignableType: AssetAssignableType
    compositeKey: string
    status: AssetStatus
    count: number
    assignedTask?: { id: string; name: string; projectId: string; project: { id: string; name: string } } | null
    assignedAsset?: {
      id: string
      compositeKey: string
      name: string
      imageUrl?: string | null
      assignedUser?: { id: string; currentProject: { id: string; name: string } } | null
    } | null
    groupParent: {
      id: string
      assetGroupId?: string | null
      imageUrl?: string | null
      name: string
      ownershipType: string
      isAssetGroup: boolean
    }
  }>
}

export type AssetListDataGridQueryVariables = Exact<{
  filter: AssetFilter
  sort: AssetSort
  after?: InputMaybe<Scalars["String"]>
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}>

export type AssetListDataGridQuery = {
  assets_2: {
    totalCount: number
    pageInfo: { endCursor?: string | null }
    edges: Array<{
      cursor: string
      node: {
        id: string
        name: string
        category: AssetCategory
        assetChildCount: number
        assetGroupId?: string | null
        assignableId: string
        assignableType: AssetAssignableType
        assignmentsCount: number
        assignedTaskId?: string | null
        assignedUserId?: string | null
        companyAssetNumber?: string | null
        compositeKey: string
        deletedAt?: Date | null
        groupQuantity?: number | null
        imageUrl?: string | null
        inferredProjectId?: string | null
        isAssetGroup: boolean
        status: AssetStatus
        ownershipType: string
        assignedAsset?: { id: string; name: string; compositeKey: string } | null
        assignedTask?: { id: string; name: string; projectId: string; project: { id: string; name: string } } | null
        assignedUser?: {
          id: string
          firstName: string
          lastName: string
          currentProject: { id: string; name: string }
          currentTask: { id: string; name: string }
        } | null
        inferredOwner?: {
          id: string
          currentProject: { id: string; name: string }
          currentTask: { id: string; name: string }
        } | null
      }
    } | null>
  }
}

export type AssignedAssetsQueryQueryVariables = Exact<{
  filter: AssignedAssetFilter
  sort: AssetSort
}>

export type AssignedAssetsQueryQuery = {
  assignedAssets: Array<{
    id: string
    active: boolean
    category: AssetCategory
    assetChildCount: number
    assetGroupId?: string | null
    assignableId: string
    assignableType: AssetAssignableType
    assignmentsCount: number
    assignedTaskId?: string | null
    assignedUserId?: string | null
    companyAssetNumber?: string | null
    compositeKey: string
    deletedAt?: Date | null
    imageUrl?: string | null
    name: string
    status: AssetStatus
    ownershipType: string
    assignedTask?: { id: string; name: string; projectId: string; project: { id: string; name: string } } | null
    assignedUser?: {
      id: string
      archived: boolean
      firstName: string
      lastName: string
      currentProject: { id: string; name: string }
      currentTask: { id: string; name: string }
    } | null
    groupParentAsset?: { id: string; name: string; companyAssetNumber?: string | null } | null
    inferredOwner?: {
      id: string
      currentProject: { id: string; name: string }
      currentTask: { id: string; name: string }
    } | null
  }>
}

export type GetAllAssetsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllAssetsQuery = {
  assets: Array<{
    id: string
    name: string
    imageUrl?: string | null
    companyAssetNumber?: string | null
    assignableId: string
  }>
}

export type GetAllTasksQueryVariables = Exact<{ [key: string]: never }>

export type GetAllTasksQuery = {
  projectsByStatus: Array<{ id: string; name: string; tasks: Array<{ id: string; name: string }> }>
}

export type QuantizedAssetQueryQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type QuantizedAssetQueryQuery = {
  asset: {
    id: string
    assetChildCount: number
    assetGroupId?: string | null
    assignableId: string
    companyAssetNumber?: string | null
    groupQuantity?: number | null
    imageUrl?: string | null
    isAssetGroup: boolean
    name: string
    ownershipType: string
    status: AssetStatus
  }
  assetGroups: Array<{
    assetGroupId: string
    assignableId: string
    assignableType: AssetAssignableType
    assignedAssetId?: string | null
    assignedTaskId?: string | null
    assignedUserId?: string | null
    count: number
    compositeKey: string
    status: AssetStatus
    assignedAsset?: {
      id: string
      assetChildCount: number
      assetGroupId?: string | null
      assignableId: string
      companyAssetNumber?: string | null
      groupQuantity?: number | null
      imageUrl?: string | null
      isAssetGroup: boolean
      name: string
      ownershipType: string
    } | null
    assignedTask?: { id: string; name: string; projectId: string; project: { id: string; name: string } } | null
    assignedUser?: {
      id: string
      firstName: string
      fullName: string
      imageUrl?: string | null
      jobTitle: string
      lastName: string
      currentProject: { id: string; name: string }
    } | null
    groupParent: {
      id: string
      active: boolean
      assetChildCount: number
      imageUrl?: string | null
      isAssetGroup: boolean
      name: string
      companyAssetNumber?: string | null
      ownershipType: string
      status: AssetStatus
    }
  }>
}

export type ArchivedAssetGroupsQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type ArchivedAssetGroupsQuery = {
  archivedAssetGroups: Array<{
    assetGroupId: string
    assignableId: string
    assignableType: AssetAssignableType
    assignedAssetId?: string | null
    assignedTaskId?: string | null
    assignedUserId?: string | null
    count: number
    compositeKey: string
    status: AssetStatus
    assignedAsset?: {
      id: string
      assetChildCount: number
      assetGroupId?: string | null
      assignableId: string
      companyAssetNumber?: string | null
      groupQuantity?: number | null
      imageUrl?: string | null
      isAssetGroup: boolean
      name: string
      ownershipType: string
    } | null
    assignedTask?: { id: string; name: string; projectId: string; project: { id: string; name: string } } | null
    assignedUser?: {
      id: string
      firstName: string
      fullName: string
      imageUrl?: string | null
      jobTitle: string
      lastName: string
      currentProject: { id: string; name: string }
    } | null
    groupParent: {
      id: string
      active: boolean
      assetChildCount: number
      imageUrl?: string | null
      isAssetGroup: boolean
      name: string
      companyAssetNumber?: string | null
      ownershipType: string
      status: AssetStatus
    }
  }>
}

export type AssetDocumentsQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type AssetDocumentsQuery = {
  asset: {
    id: string
    files: Array<{
      id: string
      fileName: string
      key: string
      contentType: string
      objectVersion: number
      assetId?: string | null
      status?: string | null
      expiresAt?: Date | null
      createdAt: Date
      documentUrl?: string | null
    }>
  }
}

export type TransferredAssetDetailsQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type TransferredAssetDetailsQuery = {
  asset: {
    id: string
    name: string
    assetGroupId?: string | null
    companyAssetNumber?: string | null
    isAssetGroup: boolean
    assetChildCount: number
    ownershipType: string
    groupQuantity?: number | null
  }
}

export type CustomerListQueryQueryVariables = Exact<{ [key: string]: never }>

export type CustomerListQueryQuery = {
  customers: Array<{
    id: string
    name: string
    imageUrl?: string | null
    phoneNumber?: string | null
    email?: string | null
    customerId?: string | null
    activeProjectCount: number
  }>
}

export type AssetDrawerDocumentQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type AssetDrawerDocumentQuery = {
  asset: { id: string; name: string; inferredProjectId?: string | null; inferredOwner?: { id: string } | null }
}

export type AssetReportTemplateQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type AssetReportTemplateQuery = {
  assetReportTemplate: {
    id: string
    assetsCount: number
    createdAt: Date
    deletedAt?: Date | null
    name: string
    reusable: boolean
    universal: boolean
    fields: Array<{
      id: string
      label: string
      photoRequired: boolean
      photoLabel?: string | null
      rule?: string | null
      failedStatus?: string | null
      required: boolean
      type: string
    }>
  }
}

export type CustomerEditDrawerQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type CustomerEditDrawerQuery = {
  customer: {
    id: string
    name: string
    image?: string | null
    imageUrl?: string | null
    customerId?: string | null
    phoneNumber?: string | null
    phoneNumberExt?: string | null
    email?: string | null
    notes?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    city?: string | null
    state?: string | null
    zip?: string | null
    country?: string | null
  }
}

export type NestedAssetInspectionTemplatesQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type NestedAssetInspectionTemplatesQuery = {
  nestedAssets: Array<{
    id: string
    name: string
    inspectionTemplatesAssignments: Array<{
      id: string
      inspectionTemplate: {
        id: string
        assetsCount: number
        createdAt: Date
        fields: Array<{
          id: string
          label: string
          photoRequired: boolean
          photoLabel?: string | null
          rule?: string | null
          failedStatus?: string | null
          required: boolean
          type: string
        }>
      }
    }>
  }>
}

export type NestedAssetInventoryQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type NestedAssetInventoryQuery = {
  nestedAssetsForInventory: Array<{
    id: string
    assetChildCount: number
    assetGroupId?: string | null
    companyAssetNumber?: string | null
    depth?: number | null
    imageUrl?: string | null
    isAssetGroup: boolean
    lastInventoriedAt?: Date | null
    name: string
    ownershipType: string
    status: AssetStatus
    inventoryRequirements?: { photoRequired?: boolean | null; intervalInSeconds?: number | null } | null
  }>
}

export type AssetTransferQueryQueryVariables = Exact<{
  assetIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>
  assetGroupIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>
}>

export type AssetTransferQueryQuery = {
  assets: Array<{
    id: string
    active: boolean
    assetChildCount: number
    assignableId: string
    assignableType: AssetAssignableType
    assignedAssetId?: string | null
    assignedUserId?: string | null
    companyAssetNumber?: string | null
    compositeKey: string
    deletedAt?: Date | null
    imageUrl?: string | null
    isAssetGroup: boolean
    name: string
    ownershipType: string
    status: AssetStatus
  }>
  assetsForAssetGroups: Array<{
    id: string
    assetGroupId?: string | null
    assignableId: string
    assignableType: AssetAssignableType
    compositeKey: string
    status: AssetStatus
  }>
}

export type ReassignAssetsSelectUsersTableQueryVariables = Exact<{ [key: string]: never }>

export type ReassignAssetsSelectUsersTableQuery = {
  usersList: Array<{
    id: string
    currentProjectId: string
    currentTaskId: string
    firstName: string
    imageUrl?: string | null
    jobTitle: string
    lastName: string
    projectId: string
    taskId?: string | null
    isClockedIn: boolean
    project: { id: string; name: string }
    task: { id: string; name: string }
  }>
}

export type SelectAssetTableQueryVariables = Exact<{ [key: string]: never }>

export type SelectAssetTableQuery = {
  assets: Array<{
    id: string
    active: boolean
    assetChildCount: number
    assignableId: string
    assignableType: AssetAssignableType
    assignedAssetId?: string | null
    assignedUserId?: string | null
    companyAssetNumber?: string | null
    deletedAt?: Date | null
    imageUrl?: string | null
    isAssetGroup: boolean
    name: string
    ownershipType: string
    status: AssetStatus
  }>
}

export type AssetProjectsQueryVariables = Exact<{ [key: string]: never }>

export type AssetProjectsQuery = {
  projectsByStatus: Array<{
    id: string
    code?: string | null
    name: string
    isArchived?: Date | null
    isComplete?: Date | null
    imageUrl?: string | null
    tasks: Array<{ id: string; name: string; groupId?: string | null; projectId: string; sortOrder?: number | null }>
    taskGroups: Array<{ id: string; name: string; projectId: string; sortOrder?: number | null }>
  }>
}

export type TaskDrawerQueryQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type TaskDrawerQueryQuery = {
  task: {
    id: string
    groupId?: string | null
    organizationId: string
    projectId: string
    archived: boolean
    dateCreated: Date
    description?: string | null
    endDate?: Date | null
    estimatedHours?: number | null
    completedHours: number
    hasReportableUnit: boolean
    isComplete?: Date | null
    isDefault: boolean
    metadata?: any | null
    name: string
    startDate?: Date | null
    group?: { id: string; name: string } | null
    workersCompCode?: { id: string; name: string; ncciCode: string } | null
    assignees: Array<{
      id: string
      isCurrentAssignment?: boolean | null
      projectId: string
      taskId?: string | null
      userId?: string | null
      project: { id: string; name: string }
      user: { id: string; firstName: string; lastName: string }
    }>
    project: { id: string; name: string }
    unitGoals: Array<{
      id: string
      deliverableUnitId: string
      targetQuantity?: number | null
      isPrimary: boolean
      deliverableUnit: { id: string; description: string; referenceNumber?: string | null; unitOfMeasure: string }
      unitGoalProgress: Array<{ id: string; progress: number }>
    }>
  }
}

export type TaskEditDrawerQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type TaskEditDrawerQuery = {
  task: {
    id: string
    name: string
    description?: string | null
    groupId?: string | null
    projectId: string
    startDate?: Date | null
    endDate?: Date | null
    estimatedHours?: number | null
    isDefault: boolean
    metadata?: any | null
    group?: { id: string; name: string } | null
    project: {
      id: string
      name: string
      tasksCount: number
      taskGroups: Array<{ id: string; name: string; completedTaskCount: number; taskCount: number }>
    }
    scheduleTemplate?: {
      id: string
      isDefault?: boolean | null
      workDays?: Array<{ label: string; active: boolean }> | null
      workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
      nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
    } | null
    scheduledBreaks?: Array<{
      id: string
      durationInMinutes: number
      isActive: boolean
      localizedStartTime: string
      name: string
      breakTaskId: string
      breakTask: { id: string; name: string }
    }> | null
    unitGoals: Array<{
      id: string
      targetQuantity?: number | null
      isPrimary: boolean
      deliverableUnitId: string
      deliverableUnit: { id: string; description: string; referenceNumber?: string | null; unitOfMeasure: string }
      unitGoalProgress: Array<{ id: string }>
    }>
  }
}

export type TaskGroupEditDrawerQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type TaskGroupEditDrawerQuery = {
  taskGroup?: {
    id: string
    completedTaskCount: number
    description?: string | null
    name: string
    notes?: string | null
    projectId: string
    taskCount: number
  } | null
}

export type TaskReorderDrawerTaskGroupQueryVariables = Exact<{
  groupId: Scalars["String"]
}>

export type TaskReorderDrawerTaskGroupQuery = {
  tasksForGroupId: Array<{ id: string; name: string; sortOrder?: number | null }>
}

export type TaskReorderDrawerTasksQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type TaskReorderDrawerTasksQuery = {
  taskList: Array<{
    taskId?: string | null
    groupId?: string | null
    isComplete: boolean
    isDefault: boolean
    name: string
    sortOrder?: number | null
    taskGroupId?: string | null
  }>
}

export type BulkUpdateTaskSortOrderMutationVariables = Exact<{
  updates: Array<BulkUpdateTaskSortOrderInput> | BulkUpdateTaskSortOrderInput
}>

export type BulkUpdateTaskSortOrderMutation = { bulkUpdateTaskSortOrder: Array<{ id: string; sortOrder: number }> }

export type UserDrawerDocumentQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type UserDrawerDocumentQuery = { user: { id: string; organizationId: string; currentProjectId: string } }

export type UserInjuryReportsQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type UserInjuryReportsQuery = {
  user: {
    id: string
    injuryReports: Array<{
      id: string
      createdAt: Date
      notes?: string | null
      reporter: { id: string; fullName: string }
      user: { id: string; fullName: string }
    }>
  }
}

export type ProjectInjuryReportsQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type ProjectInjuryReportsQuery = {
  project?: {
    id: string
    injuryReports: Array<{
      id: string
      createdAt: Date
      notes?: string | null
      reporter: { id: string; fullName: string }
      user: { id: string; fullName: string }
    }>
  } | null
}

export type TaskInjuryReportsQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type TaskInjuryReportsQuery = {
  task: {
    id: string
    injuryReports: Array<{
      id: string
      createdAt: Date
      notes?: string | null
      reporter: { id: string; fullName: string }
      user: { id: string; fullName: string }
    }>
  }
}

export type InspectionFailsQueryQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type InspectionFailsQueryQuery = {
  assetReports: Array<{
    id: string
    createdAt: Date
    updatedAt: Date
    inspectionReport?: Array<{
      id?: string | null
      input?: string | null
      label?: string | null
      photoLabel?: string | null
      type?: string | null
    }> | null
  }>
}

export type OrganizationBillingComponentQueryVariables = Exact<{
  id: Scalars["String"]
  rangeEnd: Scalars["DateTime"]
  rangeStart: Scalars["DateTime"]
}>

export type OrganizationBillingComponentQuery = {
  organization: {
    id: string
    dateCreated: Date
    activeUsersForDateRangeCount: number
    billingPastDueAt?: Date | null
    stripeInvoices: Array<{
      id: string
      amountRemaining: number
      created?: Date | null
      livemode: boolean
      status?: string | null
      total: number
    }>
  }
}

export type OrganizationSettingsQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationSettingsQuery = {
  scheduledBreaks: Array<{
    id: string
    durationInMinutes: number
    isActive: boolean
    localizedStartTime: string
    name: string
    breakTask: { id: string; name: string }
  }>
}

export type OrgAssetReportTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type OrgAssetReportTemplatesQuery = {
  reusableAssetReportTemplates: Array<{
    id: string
    name: string
    organizationId?: string | null
    reusable: boolean
    universal: boolean
    assetsCount: number
  }>
}

export type MyOrganizationSettingsQueryVariables = Exact<{ [key: string]: never }>

export type MyOrganizationSettingsQuery = {
  myOrganization: {
    id: string
    dateCreated: Date
    imageUrl?: string | null
    name?: string | null
    requireTimeEntryEvidence: boolean
    overtimeType: OvertimeTypeEnum
    notificationSettings: any
  }
}

export type OrganizationDeliverableUnitsQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationDeliverableUnitsQuery = {
  myOrganization: {
    id: string
    dateCreated: Date
    name?: string | null
    unitsOfMeasure: Array<{ id?: number | null; name: string; symbol: string }>
  }
}

export type NonWorkDaysQueryVariables = Exact<{ [key: string]: never }>

export type NonWorkDaysQuery = {
  scheduleTemplates: Array<{
    id: string
    nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
  }>
}

export type OrgScheduleQueryVariables = Exact<{ [key: string]: never }>

export type OrgScheduleQuery = {
  scheduleTemplates: Array<{
    id: string
    isDefault?: boolean | null
    workDays?: Array<{ label: string; active: boolean }> | null
    workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
    nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
  }>
  scheduledBreaks: Array<{
    id: string
    breakTaskId: string
    durationInMinutes: number
    isActive: boolean
    localizedStartTime: string
    name: string
    breakTask: { id: string; name: string }
  }>
}

export type OrganizationDeliverableWorkersCompCodesQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationDeliverableWorkersCompCodesQuery = {
  myOrganization: { id: string; dateCreated: Date; name?: string | null }
  workersCompCodes: Array<{
    id: string
    name: string
    ncciCode: string
    ncciDescription: string
    override: boolean
    rate: number
  }>
}

export type ProjectDocumentsQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type ProjectDocumentsQuery = {
  project?: {
    id: string
    name: string
    documents: Array<{
      id: string
      fileName: string
      key: string
      contentType: string
      objectVersion: number
      projectId?: string | null
      status?: string | null
      expiresAt?: Date | null
      createdAt: Date
      documentUrl?: string | null
    }>
  } | null
}

export type ActivityUnitGoalQueryVariables = Exact<{
  unitGoalId: Scalars["String"]
}>

export type ActivityUnitGoalQuery = {
  unitGoal?: { id: string; deliverableUnit: { id: string; description: string } } | null
}

export type EditHistoryEventModalQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type EditHistoryEventModalQuery = {
  project?: {
    id: string
    editHistoryEvents: Array<{
      id: string
      data: any
      type: string
      modelId: string
      modelType: string
      createdAt: Date
      project: { id: string; name: string }
      task?: { id: string; name: string } | null
      user: { id: string; firstName: string; lastName: string }
    }>
  } | null
  deliverableUnits: Array<{ id: string; description: string }>
}

export type ActiveProjectBannerQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type ActiveProjectBannerQuery = {
  project?: {
    id: string
    activeTasksCount: number
    assetsCount: number
    isComplete?: Date | null
    isDefault: boolean
    name: string
    tasksCount: number
    unassignedTaskId?: string | null
    unassignedTask: { id: string; name: string; userCount: number }
  } | null
}

export type ProjectListQueryVariables = Exact<{
  filter: ProjectFilter
  sort: ProjectSort
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}>

export type ProjectListQuery = {
  myOrganization: { id: string; imageUrl?: string | null; name?: string | null }
  projects: Array<{
    id: string
    name: string
    code?: string | null
    imageUrl?: string | null
    isArchived?: Date | null
    isComplete?: Date | null
    isDefault: boolean
    organizationId: string
    dateCreated: Date
    startDate?: Date | null
    endDate?: Date | null
    userCount: number
    assetsCount: number
    hasReportableUnit: boolean
    metadata?: any | null
  }>
}

export type ProjectQuickActionsQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type ProjectQuickActionsQuery = {
  project?: {
    id: string
    editHistoryEventsCount: number
    address?: { formattedAddress?: string | null } | null
    location?: { lat: number; lng: number } | null
  } | null
}

export type TaskScheduleDetailsQueryQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type TaskScheduleDetailsQueryQuery = {
  task: {
    id: string
    startDate?: Date | null
    endDate?: Date | null
    estimatedHours?: number | null
    group?: { id: string; name: string } | null
    scheduleTemplate?: {
      id: string
      isDefault?: boolean | null
      workDays?: Array<{ label: string; active: boolean }> | null
      workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
      nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
    } | null
    scheduledBreaks?: Array<{
      id: string
      durationInMinutes: number
      isActive: boolean
      localizedStartTime: string
      name: string
      breakTaskId: string
      breakTask: { id: string; name: string }
    }> | null
  }
}

export type TaskExtraAssignmentTableQueryVariables = Exact<{
  taskId: Scalars["String"]
}>

export type TaskExtraAssignmentTableQuery = {
  task: {
    id: string
    isComplete?: Date | null
    name: string
    projectId: string
    assignees: Array<{
      id: string
      isCurrentAssignment?: boolean | null
      projectId: string
      taskId?: string | null
      userId?: string | null
      user: {
        id: string
        firstName: string
        imageUrl?: string | null
        isClockedIn: boolean
        jobTitle: string
        lastName: string
      }
    }>
  }
}

export type GetDetailsForTaskGroupQueryVariables = Exact<{
  groupId: Scalars["String"]
  projectId: Scalars["String"]
}>

export type GetDetailsForTaskGroupQuery = {
  taskGroup?: {
    id: string
    name: string
    notes?: string | null
    description?: string | null
    completedTaskCount: number
    assetCount: number
    organizationId: string
    unitProgress: number
    unitTargetGoal: number
    isComplete: boolean
    taskCount: number
    updatedAt: Date
    userCount: number
    projectId: string
  } | null
  tasksForGroupId: Array<{
    id: string
    groupId?: string | null
    name: string
    projectId: string
    assetCount: number
    hasReportableUnit: boolean
    completedHours: number
    isComplete?: Date | null
    isDefault: boolean
    isParentTask: boolean
    subtaskCount: number
    userCount: number
  }>
  project?: { id: string; name: string; isComplete?: Date | null; isArchived?: Date | null } | null
}

export type ConvertTaskToSubTaskFormQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type ConvertTaskToSubTaskFormQuery = {
  tasks: Array<{
    id: string
    isDefault: boolean
    isComplete?: Date | null
    name: string
    parentTaskId?: string | null
    project: { id: string; isArchived?: Date | null; name: string }
  }>
}

export type TaskGroupHoursQueryVariables = Exact<{
  groupId: Scalars["String"]
}>

export type TaskGroupHoursQuery = {
  taskGroup?: {
    id: string
    startDate?: Date | null
    endDate?: Date | null
    unitProgress: number
    unitTargetGoal: number
    completedHours: number
    estimatedHours: number
    taskCount: number
    completedTaskCount: number
  } | null
}

export type TaskUnitProgressQueryVariables = Exact<{
  taskId: Scalars["String"]
}>

export type TaskUnitProgressQuery = {
  task: {
    id: string
    unitGoals: Array<{
      id: string
      isPrimary: boolean
      targetQuantity?: number | null
      totalProgress: number
      deliverableUnit: { id: string; description: string }
    }>
  }
}

export type TaskAssetsListQueryVariables = Exact<{
  filter: AssignedAssetFilter
  sort: AssetSort
}>

export type TaskAssetsListQuery = {
  assignedAssets: Array<{
    id: string
    active: boolean
    assetChildCount: number
    assetGroupId?: string | null
    assignableId: string
    assignableType: AssetAssignableType
    companyAssetNumber?: string | null
    imageUrl?: string | null
    isAssetGroup: boolean
    name: string
    ownershipType: string
    groupParentAsset?: { id: string; name: string; companyAssetNumber?: string | null } | null
  }>
}

export type ProjectTasksListQueryVariables = Exact<{
  projectId: Scalars["String"]
  searchText?: InputMaybe<Scalars["String"]>
  archived: Scalars["Boolean"]
}>

export type ProjectTasksListQuery = {
  project?: {
    id: string
    unassignedTaskId?: string | null
    name: string
    isArchived?: Date | null
    isComplete?: Date | null
  } | null
  taskList: Array<{
    taskId?: string | null
    taskGroupId?: string | null
    groupId?: string | null
    sortOrder?: number | null
    completedHours?: number | null
    createdAt: Date
    endDate?: Date | null
    estimatedHours?: number | null
    isComplete: boolean
    isDefault: boolean
    name: string
    startDate?: Date | null
    unitProgress: number
    unitTargetGoal: number
    assetCount: number
    completedTaskCount: number
    taskCount: number
    userCount: number
  }>
}

export type UpdateTaskSortOrderMutationVariables = Exact<{
  type: Scalars["String"]
  taskId: Scalars["String"]
  sortOrder: Scalars["Int"]
}>

export type UpdateTaskSortOrderMutation = { updateTaskSortOrder?: { id: string; sortOrder: number } | null }

export type TaskTeamListQueryVariables = Exact<{
  filter: UserFilter
}>

export type TaskTeamListQuery = {
  users: {
    edges: Array<{
      node: {
        id: string
        firstName: string
        lastName: string
        jobTitle: string
        imageUrl?: string | null
        isClockedIn: boolean
        currentProjectId: string
      }
    } | null>
  }
}

export type UsersFormQueryVariables = Exact<{
  myProjectId: Scalars["String"]
}>

export type UsersFormQuery = {
  projectsByStatus: Array<{
    id: string
    name: string
    unassignedTaskId?: string | null
    tasks: Array<{ id: string; name: string; isComplete?: Date | null; projectId: string }>
  }>
  project?: { id: string; unassignedTaskId?: string | null } | null
}

export type AssignUserFormQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type AssignUserFormQuery = {
  project?: {
    id: string
    isArchived?: Date | null
    tasks: Array<{
      id: string
      isComplete?: Date | null
      isDefault: boolean
      name: string
      subtasks: Array<{ id: string; isComplete?: Date | null; isDefault: boolean; name: string }>
    }>
  } | null
}

export type TeamAssignmentsQueryVariables = Exact<{
  filter: UserFilter
  projectId: Scalars["String"]
}>

export type TeamAssignmentsQuery = {
  users: {
    edges: Array<{
      node: {
        id: string
        archived: boolean
        currentProjectId: string
        currentTaskId: string
        firstName: string
        imageUrl?: string | null
        isClockedIn: boolean
        jobTitle: string
        lastName: string
        organizationId: string
        projectId: string
        secondsClockedSinceOrgMidnight: number
        taskId?: string | null
        latestTimeEntry?: {
          id: string
          endAt?: Date | null
          startAt: Date
          evidence: any
          taskId: string
          userId: string
          durationInSeconds: number
        } | null
        project: { id: string; name: string }
        task: { id: string; name: string }
      }
    } | null>
  }
  project?: {
    id: string
    isComplete?: Date | null
    tasks: Array<{ id: string; projectId: string; subtasks: Array<{ id: string }> }>
  } | null
}

export type CreateOrEditScheduledBreakQueryVariables = Exact<{ [key: string]: never }>

export type CreateOrEditScheduledBreakQuery = { breakTasks: Array<{ id: string; name: string; isUnpaid: boolean }> }

export type TaskOrProjectSummaryImageSectionQueryVariables = Exact<{
  projectId: Scalars["String"]
  taskId?: InputMaybe<Scalars["String"]>
  rangeEnd: Scalars["DateTime"]
  rangeStart: Scalars["DateTime"]
  filter?: InputMaybe<TaskProgressEventFilter>
}>

export type TaskOrProjectSummaryImageSectionQuery = {
  taskProgressEvents: Array<{
    id: string
    imageUrls: Array<string | null>
    createdAt: Date
    reporter: { id: string; fullName: string }
    task: { id: string; name: string }
  }>
}

export type TaskOrProjectSummaryNotesSectionQueryVariables = Exact<{
  projectId: Scalars["String"]
  taskId?: InputMaybe<Scalars["String"]>
  rangeEnd: Scalars["DateTime"]
  rangeStart: Scalars["DateTime"]
  filter?: InputMaybe<TaskProgressEventFilter>
}>

export type TaskOrProjectSummaryNotesSectionQuery = {
  taskProgressEvents: Array<{
    id: string
    note?: string | null
    createdAt: Date
    reporter: { id: string; fullName: string }
    task: { id: string; name: string }
  }>
}

export type TaskOrProjectTeamTableQueryVariables = Exact<{
  projectId: Scalars["String"]
  taskId?: InputMaybe<Scalars["String"]>
  rangeEnd: Scalars["DateTime"]
  rangeStart: Scalars["DateTime"]
}>

export type TaskOrProjectTeamTableQuery = {
  usersForSummarySection: Array<{
    id: string
    currentProjectId: string
    firstName: string
    imageUrl?: string | null
    jobTitle: string
    lastName: string
    isClockedIn: boolean
    secondsClockedInDateRangeOnProjectOrTask: number
    reportedUnitsInDateRangeByProjectOrTask: Array<{
      id: string
      unitGoalProgressReports: Array<{ id: string; progress: number }>
    }>
  }>
}

export type TimeLineProgressEventsQueryVariables = Exact<{
  taskId?: InputMaybe<Scalars["String"]>
  projectId: Scalars["String"]
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
}>

export type TimeLineProgressEventsQuery = {
  taskProgressEvents: Array<{
    id: string
    imageUrls: Array<string | null>
    note?: string | null
    updatedAt: Date
    task: { id: string; name: string }
    reporter: { id: string; firstName: string; lastName: string; jobTitle: string; imageUrl?: string | null }
    unitGoalProgressReports: Array<{ id: string; progress: number; unitGoalId: string }>
  }>
  unitGoals: Array<{ id: string; deliverableUnit: { id: string; description: string; unitOfMeasure: string } }>
}

export type ProjectUnitsTableProgressQueryVariables = Exact<{
  rangeStart: Scalars["DateTime"]
  rangeEnd: Scalars["DateTime"]
  entityId: Scalars["String"]
}>

export type ProjectUnitsTableProgressQuery = {
  project?: {
    id: string
    name: string
    startDate?: Date | null
    lastExport?: any | null
    unitGoals: Array<{
      id: string
      isPrimary: boolean
      targetQuantity?: number | null
      totalProgress: number
      previousProgress: number
      progressInDateRange: number
      task: { id: string; name: string; estimatedHours?: number | null; timeEntriesSumDurationInSeconds: number }
      deliverableUnit: { id: string; description: string; unitOfMeasure: string; color: string }
    }>
  } | null
}

export type TaskUnitsTableProgressQueryVariables = Exact<{
  rangeStart: Scalars["DateTime"]
  rangeEnd: Scalars["DateTime"]
  entityId: Scalars["String"]
}>

export type TaskUnitsTableProgressQuery = {
  task: {
    id: string
    name: string
    startDate?: Date | null
    lastExport?: any | null
    estimatedHours?: number | null
    timeEntriesSumDurationInSeconds: number
    unitGoals: Array<{
      id: string
      isPrimary: boolean
      targetQuantity?: number | null
      totalProgress: number
      previousProgress: number
      progressInDateRange: number
      deliverableUnit: { id: string; description: string; unitOfMeasure: string; color: string }
    }>
  }
}

export type ProjectSummaryAssetTableQueryVariables = Exact<{
  projectId: Scalars["String"]
  rangeEnd: Scalars["DateTime"]
  rangeStart: Scalars["DateTime"]
}>

export type ProjectSummaryAssetTableQuery = {
  project?: {
    id: string
    assets: Array<{
      id: string
      name: string
      assetChildCount: number
      assetGroupId?: string | null
      companyAssetNumber?: string | null
      imageUrl?: string | null
      ownershipType: string
      isAssetGroup: boolean
      assignedTask?: { id: string; name: string; projectId: string } | null
    }>
  } | null
}

export type ProjectSummaryTaskTableQueryVariables = Exact<{
  projectId: Scalars["String"]
  rangeEnd: Scalars["DateTime"]
  rangeStart: Scalars["DateTime"]
}>

export type ProjectSummaryTaskTableQuery = {
  project?: {
    id: string
    tasks: Array<{
      id: string
      completedHours: number
      estimatedHours?: number | null
      isComplete?: Date | null
      productionRate: number
      name: string
      assetCount: number
      usersWhoClockedOrReportedCount: number
      primaryUnitGoals: Array<{
        id: string
        targetQuantity?: number | null
        progressInDateRange: number
        deliverableUnit: { id: string; description: string; unitOfMeasure: string }
      }>
    }>
    taskGroups: Array<{
      id: string
      completedHours: number
      isComplete: boolean
      name: string
      assetCount: number
      productionRate: number
      userCount: number
      taskCount: number
    }>
  } | null
}

export type TimeEntryActivityQueryVariables = Exact<{
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  projectId: Scalars["String"]
  userId: Scalars["String"]
}>

export type TimeEntryActivityQuery = {
  user: {
    id: string
    timeEntries?: Array<{
      id: string
      projectId: string
      taskId: string
      durationInSeconds: number
      endAt?: Date | null
      startAt: Date
      task: { id: string; name: string; isComplete?: Date | null }
      project: { id: string; name: string }
    }> | null
  }
}

export type ReportedUnitsInDateRangeQueryVariables = Exact<{
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
  projectId: Scalars["String"]
  userId: Scalars["String"]
}>

export type ReportedUnitsInDateRangeQuery = {
  user: {
    reportedUnitsInDateRangeByProjectOrTask: Array<{
      id: string
      taskId: string
      createdAt: Date
      imageUrls: Array<string | null>
      note?: string | null
      task: { name: string; id: string; isComplete?: Date | null }
      unitGoalProgressReports: Array<{
        id: string
        progress: number
        unitGoal: { id: string; deliverableUnit: { id: string; description: string; unitOfMeasure: string } }
      }>
    }>
  }
}

export type ProjectUnitsGraphProgressQueryVariables = Exact<{
  dateRangeType: Scalars["String"]
  rangeStart: Scalars["DateTime"]
  rangeEnd: Scalars["DateTime"]
  entityId: Scalars["String"]
}>

export type ProjectUnitsGraphProgressQuery = {
  project?: {
    id: string
    hoursDataByDateRangeType: any
    startDate?: Date | null
    endDate?: Date | null
    taskProgressEventsWithUnitReports: Array<{
      id: string
      createdAt: Date
      unitGoalProgressReports: Array<{
        id: string
        progress: number
        unitGoal: { id: string; deliverableUnit: { description: string; color: string } }
      }>
    }>
  } | null
}

export type TaskUnitsGraphProgressQueryVariables = Exact<{
  dateRangeType: Scalars["String"]
  rangeStart: Scalars["DateTime"]
  rangeEnd: Scalars["DateTime"]
  entityId: Scalars["String"]
}>

export type TaskUnitsGraphProgressQuery = {
  task: {
    id: string
    hoursDataByDateRangeType: any
    startDate?: Date | null
    endDate?: Date | null
    taskProgressEventsWithUnitReports: Array<{
      id: string
      createdAt: Date
      unitGoalProgressReports: Array<{
        id: string
        progress: number
        unitGoal: { id: string; deliverableUnit: { description: string; color: string } }
      }>
    }>
  }
}

export type AddOrEditGoalModalQueryVariables = Exact<{ [key: string]: never }>

export type AddOrEditGoalModalQuery = {
  deliverableUnits: Array<{ id: string; description: string; unitOfMeasure: string }>
}

export type TaskCompletionAssetReassignmentTargetsQueryVariables = Exact<{ [key: string]: never }>

export type TaskCompletionAssetReassignmentTargetsQuery = {
  usersList: Array<{ id: string; firstName: string; lastName: string }>
  projectsByStatus: Array<{ id: string; name: string; tasks: Array<{ id: string; name: string }> }>
  allAssets: Array<{ id: string; name: string }>
}

export type CompleteTaskReassignAssetsQueryVariables = Exact<{
  taskId: Scalars["String"]
}>

export type CompleteTaskReassignAssetsQuery = {
  taskAssets: Array<{
    id: string
    name: string
    assignableId: string
    imageUrl?: string | null
    companyAssetNumber?: string | null
  }>
}

export type CompleteTaskReassignUsersQueryVariables = Exact<{
  taskId: Scalars["String"]
}>

export type CompleteTaskReassignUsersQuery = {
  usersList: Array<{
    id: string
    currentProjectId: string
    currentTaskId: string
    firstName: string
    imageUrl?: string | null
    isClockedIn: boolean
    jobTitle: string
    lastName: string
    projectId: string
    taskId?: string | null
  }>
  projectsByStatus: Array<{
    id: string
    name: string
    imageUrl?: string | null
    tasks: Array<{ id: string; name: string }>
  }>
}

export type GetTasksGroupsAndUnitsQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type GetTasksGroupsAndUnitsQuery = {
  tasks: Array<{
    id: string
    name: string
    projectId: string
    archived: boolean
    dateCreated: Date
    startDate?: Date | null
    endDate?: Date | null
    estimatedHours?: number | null
    isDefault: boolean
    isComplete?: Date | null
    description?: string | null
    hasReportableUnit: boolean
  }>
  taskGroups: Array<{
    assetCount: number
    completedTaskCount: number
    createdAt: Date
    description?: string | null
    id: string
    name: string
    notes?: string | null
    organizationId: string
    unitProgress: number
    unitTargetGoal: number
    taskCount: number
    updatedAt: Date
    userCount: number
  }>
  deliverableUnits: Array<{
    id: string
    description: string
    unitOfMeasure: string
    referenceNumber?: string | null
    createdAt: Date
    deletedAt?: Date | null
  }>
}

export type TaskQuickActionUserCountQueryVariables = Exact<{
  taskId: Scalars["String"]
}>

export type TaskQuickActionUserCountQuery = {
  task: {
    id: string
    isComplete?: Date | null
    clockedInUserCount: number
    clockedOutUserCount: number
    userCount: number
    project: {
      id: string
      address?: { formattedAddress?: string | null } | null
      location?: { lat: number; lng: number } | null
    }
    users: Array<{ id: string; isClockedIn: boolean; projectId: string }>
  }
}

export type BulkClockInUsersQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>
  filter: UserFilter
}>

export type BulkClockInUsersQuery = {
  users: {
    totalCount: number
    edges: Array<{
      node: {
        id: string
        firstName: string
        lastName: string
        jobTitle: string
        imageUrl?: string | null
        organizationId: string
        currentTaskId: string
        currentProjectId: string
        latestTimeEntry?: { id: string; taskId: string; endAt?: Date | null; startAt: Date; evidence: any } | null
      }
    } | null>
  }
}

export type BulkClockOutUsersQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>
  filter: UserFilter
}>

export type BulkClockOutUsersQuery = {
  users: {
    totalCount: number
    edges: Array<{
      node: {
        id: string
        firstName: string
        lastName: string
        jobTitle: string
        imageUrl?: string | null
        latestTimeEntry?: { id: string; taskId: string; endAt?: Date | null; startAt: Date; evidence: any } | null
      }
    } | null>
  }
}

export type ParentAssignmentAndControlQueryQueryVariables = Exact<{ [key: string]: never }>

export type ParentAssignmentAndControlQueryQuery = {
  users: {
    edges: Array<{
      node: {
        id: string
        currentProjectId: string
        currentTaskId: string
        firstName: string
        jobTitle: string
        lastName: string
        projectId: string
        taskId?: string | null
        childAncestorNodes: Array<{ userId: string; depth: number }>
        parents: Array<{ id: string; firstName: string; lastName: string; jobTitle: string }>
      }
    } | null>
  }
}

export type TeamListQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>
  after?: InputMaybe<Scalars["String"]>
  filter: UserFilter
  sort: UserSort
  visibilityFilter?: InputMaybe<ListVisibilityFilter>
}>

export type TeamListQuery = {
  users: {
    totalCount: number
    pageInfo: { endCursor?: string | null; hasNextPage: boolean }
    edges: Array<{
      cursor: string
      node: {
        id: string
        organizationId: string
        currentProjectId: string
        currentTaskId: string
        projectId: string
        taskId?: string | null
        invitationPending: boolean
        archived: boolean
        firstName: string
        imageUrl?: string | null
        jobTitle: string
        lastName: string
        isClockedIn: boolean
        secondsClockedSinceOrgMidnight: number
        task: { id: string; name: string }
        project: { id: string; name: string }
      }
    } | null>
  }
}

export type CreateUserFormQueryVariables = Exact<{ [key: string]: never }>

export type CreateUserFormQuery = {
  getJobTitles: Array<string>
  myOrganization: { id: string; roles: Array<{ id: string; name?: string | null; description?: string | null }> }
}

export type UserDocumentsQueryVariables = Exact<{
  userId: Scalars["String"]
}>

export type UserDocumentsQuery = {
  user: {
    id: string
    myFiles: Array<{
      id: string
      fileName: string
      key: string
      contentType: string
      objectVersion: number
      userId?: string | null
      status?: string | null
      expiresAt?: Date | null
      createdAt: Date
      documentUrl?: string | null
    }>
  }
}

export type EditUserFormQueryQueryVariables = Exact<{
  id: Scalars["String"]
  fetchPay: Scalars["Boolean"]
}>

export type EditUserFormQueryQuery = {
  getJobTitles: Array<string>
  myOrganization: { id: string; roles: Array<{ id: string; name?: string | null; description?: string | null }> }
  user: {
    id: string
    currentTaskId: string
    currentProjectId: string
    workersCompCodeId?: string | null
    email: string
    firstName: string
    imageUrl?: string | null
    jobTitle: string
    lastName: string
    phoneNumber: string
    phoneNumberExt?: string | null
    companyUserId?: string | null
    payRate?: string | null
    payType?: PayType | null
    roles: Array<{ id: string; name?: string | null }>
    task: { id: string; name: string }
  }
  usersList: Array<{ id: string; companyUserId?: string | null }>
}

export type ReassignUserTaskFormMutationVariables = Exact<{
  assignments: Array<UserAssignmentAssignment> | UserAssignmentAssignment
}>

export type ReassignUserTaskFormMutation = {
  reassignUsers: Array<{
    id: string
    imageUrl?: string | null
    currentProjectId: string
    currentTaskId: string
    projectId: string
    taskId?: string | null
    project: { id: string; name: string }
    task: { id: string; name: string }
  }>
}

export type UserTimeCardsQueryVariables = Exact<{
  userId: Scalars["String"]
}>

export type UserTimeCardsQuery = {
  user: {
    id: string
    firstName: string
    lastName: string
    currentProjectId: string
    archived: boolean
    currentTaskId: string
    project: { id: string; name: string }
    task: { id: string; name: string }
    timeEntries?: Array<{
      id: string
      durationInSeconds: number
      endAt?: Date | null
      signInPhotoUrl?: string | null
      signOutPhotoUrl?: string | null
      projectId: string
      taskId: string
      startAt: Date
      project: { id: string; isDefault: boolean; name: string }
      task: {
        id: string
        parentTaskId?: string | null
        visibilityLevel: string
        name: string
        parentTask?: { id: string; name: string } | null
      }
    }> | null
  }
}

export type GetTasksForProjectReportingQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type GetTasksForProjectReportingQuery = {
  tasks: Array<{
    id: string
    name: string
    isDefault: boolean
    group?: { id: string; name: string } | null
    unitGoals: Array<{
      id: string
      targetQuantity?: number | null
      totalProgress: number
      isPrimary: boolean
      deliverableUnit: { id: string; description: string; unitOfMeasure: string }
    }>
  }>
}

export type GetOrgRolesQueryVariables = Exact<{ [key: string]: never }>

export type GetOrgRolesQuery = { getJobTitles: Array<string> }

export type GetOrgScheduleForProjectQueryVariables = Exact<{ [key: string]: never }>

export type GetOrgScheduleForProjectQuery = {
  scheduleTemplates: Array<{
    id: string
    isDefault?: boolean | null
    workDays?: Array<{ label: string; active: boolean }> | null
    workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
    nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
  }>
}

export type DeliverableUnitsQueryVariables = Exact<{ [key: string]: never }>

export type DeliverableUnitsQuery = {
  deliverableUnits: Array<{ id: string; description: string; unitOfMeasure: string }>
}

export type TransferAssetModalGetProjectTasksQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type TransferAssetModalGetProjectTasksQuery = {
  project?: { id: string; tasks: Array<{ id: string; name: string }> } | null
  assets: Array<{
    id: string
    assignableId: string
    assignableType: AssetAssignableType
    assignedAssetId?: string | null
    assignedTaskId?: string | null
    assignedUserId?: string | null
    active: boolean
    assetChildCount: number
    companyAssetNumber?: string | null
    compositeKey: string
    deletedAt?: Date | null
    groupQuantity?: number | null
    imageUrl?: string | null
    isAssetGroup: boolean
    name: string
    ownershipType: string
    status: AssetStatus
    assignedTask?: { id: string; name: string; projectId: string; project: { id: string; name: string } } | null
    assignedUser?: {
      id: string
      firstName: string
      imageUrl?: string | null
      jobTitle: string
      lastName: string
    } | null
  }>
  assetGroups: Array<{
    assetGroupId: string
    assignableId: string
    assignableType: AssetAssignableType
    compositeKey: string
    count: number
    status: AssetStatus
    assignedTask?: { id: string; name: string; projectId: string; project: { id: string; name: string } } | null
    groupParent: { id: string; name: string; imageUrl?: string | null; ownershipType: string }
  }>
}

export type TransferAssetToProjectUserQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type TransferAssetToProjectUserQuery = {
  project?: {
    id: string
    users: Array<{
      id: string
      currentProjectId: string
      currentTaskId: string
      firstName: string
      imageUrl?: string | null
      jobTitle: string
      lastName: string
      projectId: string
      taskId?: string | null
    }>
  } | null
  allAssets: Array<{
    id: string
    name: string
    imageUrl?: string | null
    companyAssetNumber?: string | null
    assignableId: string
  }>
  projectAssets: Array<{ id: string }>
}

export type TransferAssetToTaskUserQueryQueryVariables = Exact<{
  taskId: Scalars["String"]
  filter: UserFilter
}>

export type TransferAssetToTaskUserQueryQuery = {
  allAssets: Array<{
    id: string
    name: string
    imageUrl?: string | null
    companyAssetNumber?: string | null
    assignableId: string
  }>
  taskAssets: Array<{ id: string }>
  users: {
    edges: Array<{
      node: {
        id: string
        currentProjectId: string
        currentTaskId: string
        firstName: string
        lastName: string
        jobTitle: string
        taskId?: string | null
        projectId: string
      }
    } | null>
  }
}

export type TransferAssetToUserQueryQueryVariables = Exact<{ [key: string]: never }>

export type TransferAssetToUserQueryQuery = {
  assets: Array<{
    id: string
    active: boolean
    assetChildCount: number
    assignableId: string
    assignableType: AssetAssignableType
    assignedAssetId?: string | null
    assignedUserId?: string | null
    companyAssetNumber?: string | null
    deletedAt?: Date | null
    imageUrl?: string | null
    isAssetGroup: boolean
    name: string
    ownershipType: string
    status: AssetStatus
  }>
  users: {
    edges: Array<{
      node: {
        id: string
        currentProjectId: string
        currentTaskId: string
        firstName: string
        imageUrl?: string | null
        jobTitle: string
        lastName: string
        projectId: string
        project: { id: string; name: string }
        task: { id: string; name: string }
      }
    } | null>
  }
}

export type UnitInputQueryVariables = Exact<{ [key: string]: never }>

export type UnitInputQuery = { deliverableUnits: Array<{ id: string; description: string; unitOfMeasure: string }> }

export type GetTaskForReportingQueryVariables = Exact<{
  taskId: Scalars["String"]
}>

export type GetTaskForReportingQuery = {
  task: {
    id: string
    name: string
    isDefault: boolean
    projectId: string
    group?: { id: string; name: string } | null
    unitGoals: Array<{
      id: string
      isPrimary: boolean
      targetQuantity?: number | null
      totalProgress: number
      deliverableUnit: { id: string; description: string; unitOfMeasure: string }
    }>
  }
}

export type ActiveProjectsWidget2QueryVariables = Exact<{ [key: string]: never }>

export type ActiveProjectsWidget2Query = {
  myOrganization: { id: string; activeProjectsCount: number; imageUrl?: string | null; name?: string | null }
  projectsByStatus: Array<{
    id: string
    assetsCount: number
    code?: string | null
    endDate?: Date | null
    hasReportableUnit: boolean
    imageUrl?: string | null
    isArchived?: Date | null
    isComplete?: Date | null
    isDefault: boolean
    name: string
    startDate?: Date | null
    userCount: number
  }>
}

export type CurrentAssignmentQueryVariables = Exact<{ [key: string]: never }>

export type CurrentAssignmentQuery = {
  currentUser: {
    id: string
    currentProjectId: string
    currentTaskId: string
    firstName: string
    imageUrl?: string | null
    jobTitle: string
    lastName: string
    projectId: string
    taskId?: string | null
    currentProject: {
      id: string
      isArchived?: Date | null
      isComplete?: Date | null
      imageUrl?: string | null
      name: string
      code?: string | null
      location?: { lat: number; lng: number; radius?: number | null } | null
    }
    currentTask: { id: string; name: string; projectId: string }
    organization: { id: string; imageUrl?: string | null; name?: string | null }
  }
}

export type TimeClockWidgetQueryVariables = Exact<{ [key: string]: never }>

export type TimeClockWidgetQuery = {
  currentUser: {
    id: string
    projectId: string
    taskId?: string | null
    archived: boolean
    firstName: string
    isClockedIn: boolean
    isOnBreak: boolean
    lastName: string
    secondsClockedSinceOrgMidnight: number
    secondsClockedThisWeek: number
    latestTimeEntry?: { id: string; endAt?: Date | null; startAt: Date } | null
  }
}

export type AssetsLinkWidgetQueryQueryVariables = Exact<{ [key: string]: never }>

export type AssetsLinkWidgetQueryQuery = { assets: Array<{ id: string; name: string }> }

export type ProjectsLinkWidgetQueryVariables = Exact<{ [key: string]: never }>

export type ProjectsLinkWidgetQuery = { myOrganization: { id: string; activeProjectsCount: number } }

export type GetMyOrgUserCountQueryVariables = Exact<{ [key: string]: never }>

export type GetMyOrgUserCountQuery = { myOrganization: { id: string; userCount: number } }

export type UserAssetsListQueryVariables = Exact<{
  userId: Scalars["String"]
}>

export type UserAssetsListQuery = {
  assets: Array<{
    id: string
    active: boolean
    assetChildCount: number
    assetGroupId?: string | null
    assignableId: string
    assignableType: AssetAssignableType
    companyAssetNumber?: string | null
    compositeKey: string
    imageUrl?: string | null
    isAssetGroup: boolean
    name: string
    ownershipType: string
    status: AssetStatus
  }>
  assetGroups: Array<{
    assetGroupId: string
    assignableId: string
    assignableType: AssetAssignableType
    compositeKey: string
    count: number
    status: AssetStatus
    groupParent: { id: string; name: string; imageUrl?: string | null; ownershipType: string }
  }>
}

export type ProjectProductionRateWidgetQueryVariables = Exact<{
  projectId: Scalars["String"]
  rangeStart: Scalars["DateTime"]
  rangeEnd: Scalars["DateTime"]
}>

export type ProjectProductionRateWidgetQuery = {
  project?: {
    id: string
    productionRate: number
    deliveredPrimaryGoalUnits: number
    estimatedHours: number
    primaryGoalTargetQuantity: number
  } | null
}

export type SummaryWidgetsTaskWidgetGroupQueryVariables = Exact<{
  taskId: Scalars["String"]
  rangeEnd: Scalars["DateTime"]
  rangeStart: Scalars["DateTime"]
}>

export type SummaryWidgetsTaskWidgetGroupQuery = {
  task: {
    id: string
    projectId: string
    name: string
    estimatedHours?: number | null
    completedHours: number
    sumOfDeliveredUnits: number
    usersWhoClockedOrReportedCount: number
    previousUnits: number
    previousClockedSeconds: number
    totalUnitsInRange: number
    totalClockedSeconds: number
    unitGoals: Array<{ id: string; targetQuantity?: number | null }>
  }
}

export type TimeCardExportFormQueryVariables = Exact<{ [key: string]: never }>

export type TimeCardExportFormQuery = { usersList: Array<{ id: string; firstName: string; lastName: string }> }

export type ActiveProjectsCountWidgetQueryVariables = Exact<{ [key: string]: never }>

export type ActiveProjectsCountWidgetQuery = { myOrganization: { id: string; activeProjectsCount: number } }

export type ClockedInCountWidgetQueryVariables = Exact<{ [key: string]: never }>

export type ClockedInCountWidgetQuery = {
  myOrganization: { id: string; clockedInUserCount: number; userCount: number }
}

export type WeeklyHoursClockedWidgetQueryVariables = Exact<{ [key: string]: never }>

export type WeeklyHoursClockedWidgetQuery = { myOrganization: { id: string; secondsClockedForCurrentWeek: number } }

export type AssetEditMutationVariables = Exact<{
  id: Scalars["String"]
  active?: InputMaybe<Scalars["Boolean"]>
  companyAssetNumber?: InputMaybe<Scalars["String"]>
  inventoryRequirements?: InputMaybe<AssetInventoryRequirementsInput>
  manufacturer?: InputMaybe<AssetManufacturerInput>
  name?: InputMaybe<Scalars["String"]>
  ownershipType?: InputMaybe<Scalars["String"]>
  photoId?: InputMaybe<Scalars["String"]>
  purchaseDetails?: InputMaybe<AssetPurchaseDetailsInput>
  rentalAgreement?: InputMaybe<AssetRentalAgreementInput>
  reportTemplateIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>
  status?: InputMaybe<AssetStatus>
  vendorContact?: InputMaybe<AssetVendorContactInput>
  inspectionRequirements?: InputMaybe<Array<AssetInspectionRequirementsInput> | AssetInspectionRequirementsInput>
  category?: InputMaybe<AssetCategory>
}>

export type AssetEditMutation = {
  updateOneAsset?: {
    id: string
    active: boolean
    activeChangedAt: Date
    assetChildCount: number
    assetGroupId?: string | null
    assignableId: string
    assignableType: AssetAssignableType
    assignedUserId?: string | null
    companyAssetNumber?: string | null
    deletedAt?: Date | null
    groupQuantity?: number | null
    imageUrl?: string | null
    isAssetGroup: boolean
    lastInventoriedAt?: Date | null
    name: string
    organizationId: string
    ownershipType: string
    status: AssetStatus
    category: AssetCategory
    inventoryRequirements?: { intervalInSeconds?: number | null; photoRequired?: boolean | null } | null
    manufacturer?: {
      id?: string | null
      make?: string | null
      name?: string | null
      year?: number | null
      model?: string | null
    } | null
    purchaseDetails?: { price?: number | null; date?: string | null } | null
    rentalAgreement?: {
      endOn?: string | null
      startOn?: string | null
      rate?: { daily: number; weekly: number; monthly: number } | null
    } | null
    vendorContact?: { name?: string | null; email?: string | null; phone?: string | null } | null
    inspectionTemplatesAssignments: Array<{
      id: string
      assetId: string
      intervalInSeconds?: number | null
      lastReportedAt?: Date | null
      startDate?: Date | null
      inspectionTemplate: { id: string; name: string }
    }>
  } | null
}

export type AssetCreateMutationVariables = Exact<{
  name: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  companyAssetNumber?: InputMaybe<Scalars["String"]>
  ownershipType: Scalars["String"]
  photoId?: InputMaybe<Scalars["String"]>
  isAssetGroup?: InputMaybe<Scalars["Boolean"]>
  groupQuantity?: InputMaybe<Scalars["Int"]>
  purchaseDetails?: InputMaybe<AssetPurchaseDetailsInput>
  rentalAgreement?: InputMaybe<AssetRentalAgreementInput>
  manufacturer?: InputMaybe<AssetManufacturerInput>
  vendorContact?: InputMaybe<AssetVendorContactInput>
  inspectionRequirements?: InputMaybe<Array<AssetInspectionRequirementsInput> | AssetInspectionRequirementsInput>
  inventoryRequirements?: InputMaybe<AssetInventoryRequirementsInput>
  category?: InputMaybe<AssetCategory>
}>

export type AssetCreateMutation = {
  insertOneAsset?: {
    id: string
    active: boolean
    activeChangedAt: Date
    assetChildCount: number
    assetGroupId?: string | null
    assignmentsCount: number
    assignableId: string
    assignedUserId?: string | null
    assignableType: AssetAssignableType
    companyAssetNumber?: string | null
    deletedAt?: Date | null
    groupQuantity?: number | null
    imageUrl?: string | null
    isAssetGroup: boolean
    lastInventoriedAt?: Date | null
    name: string
    organizationId: string
    ownershipType: string
    status: AssetStatus
    category: AssetCategory
    purchaseDetails?: { price?: number | null; date?: string | null } | null
    rentalAgreement?: {
      endOn?: string | null
      startOn?: string | null
      rate?: { daily: number; weekly: number; monthly: number } | null
    } | null
    manufacturer?: {
      id?: string | null
      make?: string | null
      name?: string | null
      year?: number | null
      model?: string | null
    } | null
    vendorContact?: { name?: string | null; email?: string | null; phone?: string | null } | null
    inventoryRequirements?: { intervalInSeconds?: number | null; photoRequired?: boolean | null } | null
    inspectionTemplatesAssignments: Array<{
      id: string
      assetId: string
      intervalInSeconds?: number | null
      lastReportedAt?: Date | null
      startDate?: Date | null
    }>
  } | null
}

export type TransferAssetsMutationVariables = Exact<{
  assetIds: Array<Scalars["String"]> | Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  projectIdIfTask?: InputMaybe<Scalars["String"]>
  assetGroupReassignments?: InputMaybe<Array<AssetGroupReassignmentSpecification> | AssetGroupReassignmentSpecification>
}>

export type TransferAssetsMutation = {
  transferAssets: Array<{
    id: string
    active: boolean
    activeChangedAt: Date
    assetChildCount: number
    assetGroupId?: string | null
    assignmentsCount: number
    assignableId: string
    assignedUserId?: string | null
    assignableType: AssetAssignableType
    companyAssetNumber?: string | null
    deletedAt?: Date | null
    groupQuantity?: number | null
    imageUrl?: string | null
    isAssetGroup: boolean
    lastInventoriedAt?: Date | null
    name: string
    organizationId: string
    ownershipType: string
    status: AssetStatus
    purchaseDetails?: { price?: number | null; date?: string | null } | null
    rentalAgreement?: {
      endOn?: string | null
      startOn?: string | null
      rate?: { daily: number; weekly: number; monthly: number } | null
    } | null
    manufacturer?: {
      id?: string | null
      make?: string | null
      name?: string | null
      year?: number | null
      model?: string | null
    } | null
    vendorContact?: { name?: string | null; email?: string | null; phone?: string | null } | null
  }>
}

export type DeleteAssetMutationVariables = Exact<{
  id: Scalars["String"]
  status: AssetStatus
}>

export type DeleteAssetMutation = {
  deleteOneAsset?: {
    id: string
    active: boolean
    activeChangedAt: Date
    assetChildCount: number
    assetGroupId?: string | null
    assignmentsCount: number
    assignableId: string
    assignedUserId?: string | null
    assignableType: AssetAssignableType
    companyAssetNumber?: string | null
    deletedAt?: Date | null
    groupQuantity?: number | null
    imageUrl?: string | null
    isAssetGroup: boolean
    lastInventoriedAt?: Date | null
    name: string
    organizationId: string
    ownershipType: string
    status: AssetStatus
    purchaseDetails?: { price?: number | null; date?: string | null } | null
    rentalAgreement?: {
      endOn?: string | null
      startOn?: string | null
      rate?: { daily: number; weekly: number; monthly: number } | null
    } | null
    manufacturer?: {
      id?: string | null
      make?: string | null
      name?: string | null
      year?: number | null
      model?: string | null
    } | null
    vendorContact?: { name?: string | null; email?: string | null; phone?: string | null } | null
  } | null
}

export type RestoreAssetMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type RestoreAssetMutation = {
  restoreOneAsset?: {
    id: string
    active: boolean
    activeChangedAt: Date
    assetChildCount: number
    assetGroupId?: string | null
    assignmentsCount: number
    assignableId: string
    assignedUserId?: string | null
    assignableType: AssetAssignableType
    companyAssetNumber?: string | null
    deletedAt?: Date | null
    groupQuantity?: number | null
    imageUrl?: string | null
    isAssetGroup: boolean
    lastInventoriedAt?: Date | null
    name: string
    organizationId: string
    ownershipType: string
    status: AssetStatus
    purchaseDetails?: { price?: number | null; date?: string | null } | null
    rentalAgreement?: {
      endOn?: string | null
      startOn?: string | null
      rate?: { daily: number; weekly: number; monthly: number } | null
    } | null
    manufacturer?: {
      id?: string | null
      make?: string | null
      name?: string | null
      year?: number | null
      model?: string | null
    } | null
    vendorContact?: { name?: string | null; email?: string | null; phone?: string | null } | null
  } | null
}

export type AddQuantitiesToGroupMutationVariables = Exact<{
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  quantityToAdd: Scalars["Int"]
}>

export type AddQuantitiesToGroupMutation = {
  addQuantitiesToGroup?: {
    id: string
    assetGroupId?: string | null
    assetChildCount: number
    assetGroupMemberCount: number
    name: string
    status: AssetStatus
  } | null
}

export type ReturnQuantityToInventoryMutationVariables = Exact<{
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  status: AssetStatus
  quantityToReturn: Scalars["Int"]
}>

export type ReturnQuantityToInventoryMutation = {
  returnQuantityToInventory: Array<{
    id: string
    assetGroupId?: string | null
    assetChildCount: number
    assetGroupMemberCount: number
    name: string
    status: AssetStatus
  }>
}

export type ArchiveQuantitiesMutationVariables = Exact<{
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  status: AssetStatus
  quantityToArchive: Scalars["Int"]
}>

export type ArchiveQuantitiesMutation = {
  archiveQuantities: Array<{
    id: string
    assetGroupId?: string | null
    assetChildCount: number
    assetGroupMemberCount: number
    name: string
    status: AssetStatus
    deletedAt?: Date | null
  }>
}

export type UnarchiveQuantitiesMutationVariables = Exact<{
  assetGroupId: Scalars["String"]
  assignableId: Scalars["String"]
  assignableType: AssetAssignableType
  status: AssetStatus
  quantityToUnarchive: Scalars["Int"]
}>

export type UnarchiveQuantitiesMutation = {
  unarchiveQuantities: Array<{
    id: string
    assetGroupId?: string | null
    assetChildCount: number
    assetGroupMemberCount: number
    name: string
    status: AssetStatus
    deletedAt?: Date | null
  }>
}

export type CreateAssetReportTemplateMutationVariables = Exact<{
  name: Scalars["String"]
  reusable: Scalars["Boolean"]
  universal?: InputMaybe<Scalars["Boolean"]>
  fields: Array<AssetReportTemplateFieldInput> | AssetReportTemplateFieldInput
}>

export type CreateAssetReportTemplateMutation = {
  insertReportTemplate: {
    id: string
    reusable: boolean
    fields: Array<{ id: string; label: string; photoRequired: boolean; required: boolean; type: string }>
  }
}

export type UpdateAssetReportTemplateMutationVariables = Exact<{
  id: Scalars["String"]
  name?: InputMaybe<Scalars["String"]>
  reusable?: InputMaybe<Scalars["Boolean"]>
  fields?: InputMaybe<Array<AssetReportTemplateFieldInput> | AssetReportTemplateFieldInput>
}>

export type UpdateAssetReportTemplateMutation = {
  updateReportTemplate: {
    id: string
    assetsCount: number
    createdAt: Date
    deletedAt?: Date | null
    name: string
    reusable: boolean
    universal: boolean
    fields: Array<{
      id: string
      label: string
      photoRequired: boolean
      photoLabel?: string | null
      rule?: string | null
      failedStatus?: string | null
      required: boolean
      type: string
    }>
  }
}

export type DuplicateReportTemplateMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type DuplicateReportTemplateMutation = {
  duplicateReportTemplate: {
    id: string
    assetsCount: number
    createdAt: Date
    deletedAt?: Date | null
    name: string
    reusable: boolean
    universal: boolean
    fields: Array<{
      id: string
      label: string
      photoRequired: boolean
      photoLabel?: string | null
      rule?: string | null
      failedStatus?: string | null
      required: boolean
      type: string
    }>
  }
}

export type DeleteReportTemplateMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type DeleteReportTemplateMutation = { deleteReportTemplate: { id: string; deletedAt?: Date | null } }

export type InsertManyAssetReportsMutationVariables = Exact<{
  reports: Array<AssetReportInput> | AssetReportInput
}>

export type InsertManyAssetReportsMutation = {
  insertManyAssetReports: Array<{
    id: string
    assetId: string
    reporterId: string
    notes?: string | null
    type: AssetReportType
    createdAt: Date
    updatedAt: Date
    inventoryReport?: { photoUrls?: Array<string> | null; note?: string | null } | null
    statusChange?: { active: boolean; status: AssetStatus } | null
    reporter: { id: string; firstName: string; lastName: string; fullName: string }
  }>
}

export type ContractsForCustomerQueryVariables = Exact<{
  customerId: Scalars["String"]
}>

export type ContractsForCustomerQuery = {
  contracts: Array<{ id: string; name: string; number?: string | null; description?: string | null }>
}

export type ContractQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type ContractQuery = {
  contract: {
    id: string
    name: string
    number?: string | null
    description?: string | null
    deliverableUnits: Array<{
      id: string
      customerDescription?: string | null
      rate?: number | null
      deliverableUnit: { id: string; description: string; unitOfMeasure: string }
    }>
  }
}

export type CreateOneContractMutationVariables = Exact<{
  name: Scalars["String"]
  number?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  customerId: Scalars["String"]
  deliverableUnits: Array<ContractDeliverableUnitInput> | ContractDeliverableUnitInput
}>

export type CreateOneContractMutation = {
  createOneContract: {
    id: string
    name: string
    number?: string | null
    description?: string | null
    customerId?: string | null
    deliverableUnits: Array<{
      id: string
      customerDescription?: string | null
      deliverableUnitId: string
      rate?: number | null
    }>
  }
}

export type UpdateOneContractMutationVariables = Exact<{
  id: Scalars["String"]
  name?: InputMaybe<Scalars["String"]>
  number?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
}>

export type UpdateOneContractMutation = {
  updateOneContract: { id: string; name: string; number?: string | null; description?: string | null }
}

export type DeleteOneContractMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type DeleteOneContractMutation = { deleteOneContract: { id: string } }

export type CustomerQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type CustomerQuery = {
  customer: {
    id: string
    name: string
    image?: string | null
    imageUrl?: string | null
    customerId?: string | null
    phoneNumber?: string | null
    phoneNumberExt?: string | null
    email?: string | null
    notes?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    city?: string | null
    state?: string | null
    zip?: string | null
    country?: string | null
  }
}

export type CreateCustomerMutationVariables = Exact<{
  name: Scalars["String"]
  image?: InputMaybe<Scalars["String"]>
  customerId?: InputMaybe<Scalars["String"]>
  phoneNumber?: InputMaybe<Scalars["String"]>
  phoneNumberExt?: InputMaybe<Scalars["String"]>
  email?: InputMaybe<Scalars["String"]>
  notes?: InputMaybe<Scalars["String"]>
  addressLine1?: InputMaybe<Scalars["String"]>
  addressLine2?: InputMaybe<Scalars["String"]>
  city?: InputMaybe<Scalars["String"]>
  state?: InputMaybe<Scalars["String"]>
  zip?: InputMaybe<Scalars["String"]>
  country?: InputMaybe<Scalars["String"]>
}>

export type CreateCustomerMutation = {
  createCustomer?: {
    id: string
    name: string
    image?: string | null
    imageUrl?: string | null
    customerId?: string | null
    phoneNumber?: string | null
    phoneNumberExt?: string | null
    email?: string | null
    notes?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    city?: string | null
    state?: string | null
    zip?: string | null
    country?: string | null
  } | null
}

export type CustomerEditMutationVariables = Exact<{
  id: Scalars["String"]
  name?: InputMaybe<Scalars["String"]>
  image?: InputMaybe<Scalars["String"]>
  customerId?: InputMaybe<Scalars["String"]>
  phoneNumber?: InputMaybe<Scalars["String"]>
  phoneNumberExt?: InputMaybe<Scalars["String"]>
  email?: InputMaybe<Scalars["String"]>
  notes?: InputMaybe<Scalars["String"]>
  addressLine1?: InputMaybe<Scalars["String"]>
  addressLine2?: InputMaybe<Scalars["String"]>
  city?: InputMaybe<Scalars["String"]>
  state?: InputMaybe<Scalars["String"]>
  zip?: InputMaybe<Scalars["String"]>
  country?: InputMaybe<Scalars["String"]>
}>

export type CustomerEditMutation = {
  updateCustomer?: {
    id: string
    name: string
    image?: string | null
    imageUrl?: string | null
    customerId?: string | null
    phoneNumber?: string | null
    phoneNumberExt?: string | null
    email?: string | null
    notes?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    city?: string | null
    state?: string | null
    zip?: string | null
    country?: string | null
  } | null
}

export type CustomerDeleteMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type CustomerDeleteMutation = {
  deleteCustomer: { id: string; name: string; image?: string | null; customerId?: string | null }
}

export type CreateDeliverableUnitMutationVariables = Exact<{
  description: Scalars["String"]
  unitOfMeasure: Scalars["String"]
  referenceNumber?: InputMaybe<Scalars["String"]>
  color: Scalars["String"]
}>

export type CreateDeliverableUnitMutation = {
  createDeliverableUnit: { id: string; description: string; unitOfMeasure: string; referenceNumber?: string | null }
}

export type EditDeliverableUnitMutationVariables = Exact<{
  id: Scalars["String"]
  description: Scalars["String"]
  unitOfMeasure: Scalars["String"]
  referenceNumber?: InputMaybe<Scalars["String"]>
  color: Scalars["String"]
}>

export type EditDeliverableUnitMutation = {
  editDeliverableUnit: { id: string; description: string; unitOfMeasure: string; referenceNumber?: string | null }
}

export type DeleteDeliverableUnitMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type DeleteDeliverableUnitMutation = { deleteDeliverableUnit?: { id: string; deletedAt?: Date | null } | null }

export type DeliverableUnitsBaseQueryVariables = Exact<{ [key: string]: never }>

export type DeliverableUnitsBaseQuery = {
  deliverableUnits: Array<{
    id: string
    description: string
    unitOfMeasure: string
    referenceNumber?: string | null
    color: string
  }>
}

export type UpdateOneFileMutationVariables = Exact<{
  id: Scalars["String"]
  fileName?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<Scalars["String"]>
  expiresAt?: InputMaybe<Scalars["DateTime"]>
}>

export type UpdateOneFileMutation = {
  updateOneFile: { id: string; fileName: string; status?: string | null; expiresAt?: Date | null }
}

export type DeleteOneFileMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type DeleteOneFileMutation = { deleteOneFile: { id: string } }

export type CreateInjuryReportMutationVariables = Exact<{
  userId: Scalars["String"]
  notes?: InputMaybe<Scalars["String"]>
}>

export type CreateInjuryReportMutation = {
  createInjuryReport: { id: string; userId: string; reporterId: string; notes?: string | null; createdAt: Date }
}

export type UpdateInjuryReportMutationVariables = Exact<{
  id: Scalars["String"]
  userId?: InputMaybe<Scalars["String"]>
  notes?: InputMaybe<Scalars["String"]>
}>

export type UpdateInjuryReportMutation = {
  updateInjuryReport: { id: string; userId: string; reporterId: string; notes?: string | null }
}

export type UpdateLastExportMutationVariables = Exact<{
  entityId?: InputMaybe<Scalars["String"]>
  entityType?: InputMaybe<Scalars["String"]>
}>

export type UpdateLastExportMutation = {
  updateLastExport?: { userId: string; userName: string; exportedAt: string } | null
}

export type MarkAllNotificationsReadMutationVariables = Exact<{ [key: string]: never }>

export type MarkAllNotificationsReadMutation = {
  markAllNotificationsRead?: Array<{ id: string; markedReadAt?: Date | null }> | null
}

export type MarkNotificationsReadByIdMutationVariables = Exact<{
  ids: Array<Scalars["String"]> | Scalars["String"]
}>

export type MarkNotificationsReadByIdMutation = {
  markNotificationsReadById?: Array<{ id: string; markedReadAt?: Date | null }> | null
}

export type EditOrganizationMutationVariables = Exact<{
  id: Scalars["String"]
  image?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  timezone?: InputMaybe<Scalars["String"]>
  requireTimeEntryEvidence?: InputMaybe<Scalars["Boolean"]>
  overtimeType?: InputMaybe<OvertimeTypeEnum>
  notificationSettings?: InputMaybe<Scalars["JSON"]>
  featureToggleSegments?: InputMaybe<Array<FeatureToggleSegments> | FeatureToggleSegments>
  stripeCustomerId?: InputMaybe<Scalars["String"]>
}>

export type EditOrganizationMutation = {
  editOrganization: {
    id: string
    name?: string | null
    imageUrl?: string | null
    dateCreated: Date
    unassignedProjectId: string
    timezone: string
    archived: boolean
    requireTimeEntryEvidence: boolean
    overtimeType: OvertimeTypeEnum
  }
}

export type ArchiveOrganizationMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type ArchiveOrganizationMutation = {
  archiveOrganization: {
    id: string
    name?: string | null
    imageUrl?: string | null
    dateCreated: Date
    unassignedProjectId: string
    timezone: string
    archived: boolean
    requireTimeEntryEvidence: boolean
    overtimeType: OvertimeTypeEnum
  }
}

export type ActivateOrganizationMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type ActivateOrganizationMutation = {
  activateOrganization: {
    id: string
    name?: string | null
    imageUrl?: string | null
    dateCreated: Date
    unassignedProjectId: string
    timezone: string
    archived: boolean
    requireTimeEntryEvidence: boolean
    overtimeType: OvertimeTypeEnum
  }
}

export type MarkOrganizationPastDueMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type MarkOrganizationPastDueMutation = {
  markOrganizationPastDue: {
    id: string
    name?: string | null
    imageUrl?: string | null
    dateCreated: Date
    unassignedProjectId: string
    timezone: string
    archived: boolean
    requireTimeEntryEvidence: boolean
    overtimeType: OvertimeTypeEnum
    billingPastDueAt?: Date | null
  }
}

export type MarkOrganizationNotPastDueMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type MarkOrganizationNotPastDueMutation = {
  markOrganizationNotPastDue: {
    id: string
    name?: string | null
    imageUrl?: string | null
    dateCreated: Date
    unassignedProjectId: string
    timezone: string
    archived: boolean
    requireTimeEntryEvidence: boolean
    overtimeType: OvertimeTypeEnum
    billingPastDueAt?: Date | null
  }
}

export type CreateUnitOfMeasureMutationVariables = Exact<{
  name: Scalars["String"]
  symbol: Scalars["String"]
}>

export type CreateUnitOfMeasureMutation = {
  createUnitOfMeasure: { id?: number | null; name: string; symbol: string; organizationId?: string | null }
}

export type EditUnitOfMeasureMutationVariables = Exact<{
  id: Scalars["Int"]
  name: Scalars["String"]
  symbol: Scalars["String"]
}>

export type EditUnitOfMeasureMutation = {
  editUnitOfMeasure: { id?: number | null; name: string; symbol: string; organizationId?: string | null }
}

export type DeleteUnitOfMeasureMutationVariables = Exact<{
  id: Scalars["Int"]
}>

export type DeleteUnitOfMeasureMutation = {
  deleteUnitOfMeasure: { id?: number | null; organizationId?: string | null }
}

export type ProjectCreateMutationVariables = Exact<{
  code?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  address?: InputMaybe<AddressInput>
  location?: InputMaybe<Scalars["JSON"]>
  locationNotes?: InputMaybe<Scalars["String"]>
  metadata?: InputMaybe<Array<MetadataNoteInput> | MetadataNoteInput>
  name: Scalars["String"]
  schedule?: InputMaybe<ScheduleTemplateInput>
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput> | ScheduledBreakInput>
  startDate?: InputMaybe<Scalars["DateTime"]>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
  taskGroups?: InputMaybe<Array<GroupInput> | GroupInput>
  tasks?: InputMaybe<Array<TaskInput> | TaskInput>
  customerId?: InputMaybe<Scalars["String"]>
}>

export type ProjectCreateMutation = {
  insertOneProject: {
    id: string
    code?: string | null
    name: string
    dateCreated: Date
    organizationId: string
    unassignedTaskId?: string | null
    isArchived?: Date | null
    isDefault: boolean
    isComplete?: Date | null
    startDate?: Date | null
    endDate?: Date | null
    description?: string | null
    imageUrl?: string | null
    customerId?: string | null
    locationNotes?: string | null
    googlePlace?: any | null
    userCount: number
    metadata?: any | null
    location?: { lat: number; lng: number; radius?: number | null } | null
    address?: {
      formattedAddress?: string | null
      addressComponents: Array<{ longName: string; shortName: string; types: Array<string> }>
    } | null
    workersCompCode?: { id: string; name: string; ncciCode: string } | null
    tasks: Array<{
      id: string
      name: string
      projectId: string
      parentTaskId?: string | null
      dateCreated: Date
      endDate?: Date | null
      startDate?: Date | null
      archived: boolean
      description?: string | null
      estimatedHours?: number | null
      hasReportableUnit: boolean
      isComplete?: Date | null
      isDefault: boolean
      metadata?: any | null
      workersCompCode?: {
        id: string
        name: string
        ncciCode: string
        ncciDescription: string
        override: boolean
        rate: number
      } | null
    }>
  }
}

export type DuplicateProjectMutationVariables = Exact<{
  projectId: Scalars["String"]
  projectName: Scalars["String"]
  includeTasks?: InputMaybe<Scalars["Boolean"]>
  includeReportingUnits?: InputMaybe<Scalars["Boolean"]>
  includeAdditionalUnits?: InputMaybe<Scalars["Boolean"]>
}>

export type DuplicateProjectMutation = {
  duplicateProject: {
    id: string
    code?: string | null
    name: string
    dateCreated: Date
    organizationId: string
    unassignedTaskId?: string | null
    isArchived?: Date | null
    isDefault: boolean
    isComplete?: Date | null
    startDate?: Date | null
    endDate?: Date | null
    description?: string | null
    imageUrl?: string | null
    customerId?: string | null
    locationNotes?: string | null
    googlePlace?: any | null
    userCount: number
    metadata?: any | null
    location?: { lat: number; lng: number; radius?: number | null } | null
    address?: {
      formattedAddress?: string | null
      addressComponents: Array<{ longName: string; shortName: string; types: Array<string> }>
    } | null
    workersCompCode?: { id: string; name: string; ncciCode: string } | null
    tasks: Array<{
      id: string
      name: string
      projectId: string
      parentTaskId?: string | null
      dateCreated: Date
      endDate?: Date | null
      startDate?: Date | null
      archived: boolean
      description?: string | null
      estimatedHours?: number | null
      hasReportableUnit: boolean
      isComplete?: Date | null
      isDefault: boolean
      metadata?: any | null
      workersCompCode?: {
        id: string
        name: string
        ncciCode: string
        ncciDescription: string
        override: boolean
        rate: number
      } | null
    }>
  }
}

export type ProjectEditMutationVariables = Exact<{
  id: Scalars["String"]
  code?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  isArchived?: InputMaybe<Scalars["DateTime"]>
  unassignedTaskId?: InputMaybe<Scalars["String"]>
  startDate?: InputMaybe<Scalars["DateTime"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  description?: InputMaybe<Scalars["String"]>
  image?: InputMaybe<Scalars["String"]>
  address?: InputMaybe<AddressInput>
  location?: InputMaybe<Scalars["JSON"]>
  locationNotes?: InputMaybe<Scalars["String"]>
  googlePlace?: InputMaybe<Scalars["JSON"]>
  metadata?: InputMaybe<Array<MetadataNoteInput> | MetadataNoteInput>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
  schedule?: InputMaybe<ScheduleTemplateInput>
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput> | ScheduledBreakInput>
  customerId?: InputMaybe<Scalars["String"]>
}>

export type ProjectEditMutation = {
  updateOneProject: {
    id: string
    code?: string | null
    name: string
    dateCreated: Date
    organizationId: string
    unassignedTaskId?: string | null
    isArchived?: Date | null
    isDefault: boolean
    isComplete?: Date | null
    startDate?: Date | null
    endDate?: Date | null
    description?: string | null
    imageUrl?: string | null
    customerId?: string | null
    locationNotes?: string | null
    googlePlace?: any | null
    userCount: number
    metadata?: any | null
    location?: { lat: number; lng: number; radius?: number | null } | null
    address?: {
      formattedAddress?: string | null
      addressComponents: Array<{ longName: string; shortName: string; types: Array<string> }>
    } | null
    workersCompCode?: { id: string; name: string; ncciCode: string } | null
    tasks: Array<{
      id: string
      name: string
      projectId: string
      parentTaskId?: string | null
      dateCreated: Date
      endDate?: Date | null
      startDate?: Date | null
      archived: boolean
      description?: string | null
      estimatedHours?: number | null
      hasReportableUnit: boolean
      isComplete?: Date | null
      isDefault: boolean
      metadata?: any | null
      workersCompCode?: {
        id: string
        name: string
        ncciCode: string
        ncciDescription: string
        override: boolean
        rate: number
      } | null
    }>
  }
}

export type ArchiveProjectMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type ArchiveProjectMutation = { archiveProject: { id: string; isArchived?: Date | null } }

export type MarkProjectAsCompleteMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type MarkProjectAsCompleteMutation = {
  completeProject: {
    isComplete?: Date | null
    id: string
    code?: string | null
    name: string
    dateCreated: Date
    organizationId: string
    unassignedTaskId?: string | null
    isArchived?: Date | null
    isDefault: boolean
    startDate?: Date | null
    endDate?: Date | null
    description?: string | null
    imageUrl?: string | null
    customerId?: string | null
    locationNotes?: string | null
    googlePlace?: any | null
    userCount: number
    metadata?: any | null
    location?: { lat: number; lng: number; radius?: number | null } | null
    address?: {
      formattedAddress?: string | null
      addressComponents: Array<{ longName: string; shortName: string; types: Array<string> }>
    } | null
    workersCompCode?: { id: string; name: string; ncciCode: string } | null
    tasks: Array<{
      id: string
      name: string
      projectId: string
      parentTaskId?: string | null
      dateCreated: Date
      endDate?: Date | null
      startDate?: Date | null
      archived: boolean
      description?: string | null
      estimatedHours?: number | null
      hasReportableUnit: boolean
      isComplete?: Date | null
      isDefault: boolean
      metadata?: any | null
      workersCompCode?: {
        id: string
        name: string
        ncciCode: string
        ncciDescription: string
        override: boolean
        rate: number
      } | null
    }>
  }
}

export type AddOrUpdateNonWorkDayMutationVariables = Exact<{
  nonWorkDay: ScheduleTemplateNonWorkDaysInput
}>

export type AddOrUpdateNonWorkDayMutation = {
  addOrUpdateNonWorkDay: {
    id: string
    nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
  }
}

export type CreateScheduleTemplateMutationVariables = Exact<{
  workDays?: InputMaybe<Array<ScheduleTemplateWorkDaysInput> | ScheduleTemplateWorkDaysInput>
  workHours?: InputMaybe<ScheduleTemplateWorkHoursInput>
  nonWorkDays?: InputMaybe<Array<ScheduleTemplateNonWorkDaysInput> | ScheduleTemplateNonWorkDaysInput>
  isDefault?: InputMaybe<Scalars["Boolean"]>
}>

export type CreateScheduleTemplateMutation = {
  createScheduleTemplate: {
    id: string
    isDefault?: boolean | null
    workDays?: Array<{ label: string; active: boolean }> | null
    workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
    nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
  }
}

export type DuplicateScheduleTemplateMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type DuplicateScheduleTemplateMutation = {
  duplicateScheduleTemplate: {
    id: string
    isDefault?: boolean | null
    workDays?: Array<{ label: string; active: boolean }> | null
    workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
    nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
  }
}

export type UpdateScheduleTemplateMutationVariables = Exact<{
  id: Scalars["String"]
  workDays?: InputMaybe<Array<ScheduleTemplateWorkDaysInput> | ScheduleTemplateWorkDaysInput>
  workHours?: InputMaybe<ScheduleTemplateWorkHoursInput>
  nonWorkDays?: InputMaybe<Array<ScheduleTemplateNonWorkDaysInput> | ScheduleTemplateNonWorkDaysInput>
  isDefault?: InputMaybe<Scalars["Boolean"]>
}>

export type UpdateScheduleTemplateMutation = {
  updateScheduleTemplate: {
    id: string
    isDefault?: boolean | null
    workDays?: Array<{ label: string; active: boolean }> | null
    workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
    nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
  }
}

export type DeleteNonWorkDayMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type DeleteNonWorkDayMutation = {
  deleteNonWorkDay: {
    id: string
    isDefault?: boolean | null
    workDays?: Array<{ label: string; active: boolean }> | null
    workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
    nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
  }
}

export type OrgScheduleAndBreaksQueryVariables = Exact<{ [key: string]: never }>

export type OrgScheduleAndBreaksQuery = {
  scheduleTemplates: Array<{
    id: string
    isDefault?: boolean | null
    workDays?: Array<{ label: string; active: boolean }> | null
    workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
    nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
  }>
  scheduledBreaks: Array<{
    id: string
    breakTaskId: string
    durationInMinutes: number
    isActive: boolean
    localizedStartTime: string
    name: string
    breakTask: { id: string; name: string }
  }>
}

export type ScheduleWithBreaksQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars["String"]>
  taskId?: InputMaybe<Scalars["String"]>
}>

export type ScheduleWithBreaksQuery = {
  scheduleTemplates: Array<{
    id: string
    isDefault?: boolean | null
    workDays?: Array<{ label: string; active: boolean }> | null
    workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
    nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
  }>
  scheduledBreaks: Array<{
    id: string
    breakTaskId: string
    durationInMinutes: number
    isActive: boolean
    localizedStartTime: string
    name: string
    breakTask: { id: string; name: string }
  }>
}

export type CreateScheduledBreakMutationVariables = Exact<{
  breakTaskId: Scalars["String"]
  durationInMinutes: Scalars["Int"]
  localizedStartTime: Scalars["String"]
  name: Scalars["String"]
  projectId?: InputMaybe<Scalars["String"]>
}>

export type CreateScheduledBreakMutation = {
  createScheduledBreak: {
    id: string
    durationInMinutes: number
    isActive: boolean
    localizedStartTime: string
    name: string
    breakTask: { id: string; name: string }
  }
}

export type EditScheduledBreakMutationVariables = Exact<{
  id: Scalars["String"]
  breakTaskId?: InputMaybe<Scalars["String"]>
  durationInMinutes?: InputMaybe<Scalars["Int"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  localizedStartTime?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
}>

export type EditScheduledBreakMutation = {
  editScheduledBreak: {
    id: string
    breakTaskId: string
    durationInMinutes: number
    isActive: boolean
    localizedStartTime: string
    name: string
    breakTask: { id: string; name: string }
  }
}

export type DeleteScheduledBreakMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type DeleteScheduledBreakMutation = { deleteScheduledBreak: { id: string } }

export type TaskCreateMutationVariables = Exact<{
  groupId?: InputMaybe<Scalars["String"]>
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId: Scalars["String"]
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  estimatedHours?: InputMaybe<Scalars["Float"]>
  metadata?: InputMaybe<Array<MetadataNoteInput> | MetadataNoteInput>
  name: Scalars["String"]
  startDate?: InputMaybe<Scalars["DateTime"]>
  unitGoals?: InputMaybe<Array<UnitGoalInput> | UnitGoalInput>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
  schedule?: InputMaybe<ScheduleTemplateInput>
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput> | ScheduledBreakInput>
}>

export type TaskCreateMutation = {
  insertOneTask: {
    id: string
    groupId?: string | null
    parentTaskId?: string | null
    projectId: string
    dateCreated: Date
    description?: string | null
    endDate?: Date | null
    estimatedHours?: number | null
    isDefault: boolean
    metadata?: any | null
    name: string
    startDate?: Date | null
    sumOfDeliveredUnits: number
    unitGoalsSumProgress: number
    unitGoalsSumTargetQuantity: number
    workersCompCode?: {
      id: string
      name: string
      ncciCode: string
      ncciDescription: string
      override: boolean
      rate: number
    } | null
    unitGoals: Array<{ id: string }>
  }
}

export type MarkTaskAsCompleteMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type MarkTaskAsCompleteMutation = {
  markTaskCompletion?: {
    id: string
    name: string
    projectId: string
    parentTaskId?: string | null
    dateCreated: Date
    endDate?: Date | null
    startDate?: Date | null
    archived: boolean
    description?: string | null
    estimatedHours?: number | null
    hasReportableUnit: boolean
    isComplete?: Date | null
    isDefault: boolean
    metadata?: any | null
    groupId?: string | null
    workersCompCode?: {
      id: string
      name: string
      ncciCode: string
      ncciDescription: string
      override: boolean
      rate: number
    } | null
    project: {
      id: string
      code?: string | null
      name: string
      dateCreated: Date
      organizationId: string
      unassignedTaskId?: string | null
      isArchived?: Date | null
      isDefault: boolean
      isComplete?: Date | null
      startDate?: Date | null
      endDate?: Date | null
      description?: string | null
      imageUrl?: string | null
      customerId?: string | null
      tasks: Array<{ id: string }>
    }
  } | null
}

export type MarkTaskAsIncompleteMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type MarkTaskAsIncompleteMutation = {
  markTaskCompletion?: {
    id: string
    name: string
    projectId: string
    parentTaskId?: string | null
    dateCreated: Date
    endDate?: Date | null
    startDate?: Date | null
    archived: boolean
    description?: string | null
    estimatedHours?: number | null
    hasReportableUnit: boolean
    isComplete?: Date | null
    isDefault: boolean
    metadata?: any | null
    groupId?: string | null
    workersCompCode?: {
      id: string
      name: string
      ncciCode: string
      ncciDescription: string
      override: boolean
      rate: number
    } | null
    project: {
      id: string
      code?: string | null
      name: string
      dateCreated: Date
      organizationId: string
      unassignedTaskId?: string | null
      isArchived?: Date | null
      isDefault: boolean
      isComplete?: Date | null
      startDate?: Date | null
      endDate?: Date | null
      description?: string | null
      imageUrl?: string | null
      customerId?: string | null
      tasks: Array<{ id: string }>
    }
  } | null
}

export type TaskEditMutationVariables = Exact<{
  id: Scalars["String"]
  groupId?: InputMaybe<Scalars["String"]>
  parentTaskId?: InputMaybe<Scalars["String"]>
  projectId: Scalars["String"]
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["DateTime"]>
  estimatedHours?: InputMaybe<Scalars["Float"]>
  isSubtask?: InputMaybe<Scalars["Boolean"]>
  metadata?: InputMaybe<Array<MetadataNoteInput> | MetadataNoteInput>
  name: Scalars["String"]
  startDate?: InputMaybe<Scalars["DateTime"]>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
  schedule?: InputMaybe<ScheduleTemplateInput>
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput> | ScheduledBreakInput>
}>

export type TaskEditMutation = {
  updateOneTask: {
    id: string
    name: string
    projectId: string
    parentTaskId?: string | null
    dateCreated: Date
    endDate?: Date | null
    startDate?: Date | null
    archived: boolean
    description?: string | null
    estimatedHours?: number | null
    hasReportableUnit: boolean
    isComplete?: Date | null
    isDefault: boolean
    metadata?: any | null
    workersCompCode?: {
      id: string
      name: string
      ncciCode: string
      ncciDescription: string
      override: boolean
      rate: number
    } | null
    parentTask?: {
      id: string
      name: string
      projectId: string
      parentTaskId?: string | null
      dateCreated: Date
      endDate?: Date | null
      startDate?: Date | null
      archived: boolean
      description?: string | null
      estimatedHours?: number | null
      hasReportableUnit: boolean
      isComplete?: Date | null
      isDefault: boolean
      metadata?: any | null
      workersCompCode?: {
        id: string
        name: string
        ncciCode: string
        ncciDescription: string
        override: boolean
        rate: number
      } | null
    } | null
  }
}

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type DeleteTaskMutation = { deleteOneTask?: { id: string; name: string } | null }

export type ReportTaskProgressMutationVariables = Exact<{
  projectId: Scalars["String"]
  taskId: Scalars["String"]
  fileIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>
  note?: InputMaybe<Scalars["String"]>
  unitGoalProgress?: InputMaybe<Array<UnitGoalProgressInput> | UnitGoalProgressInput>
}>

export type ReportTaskProgressMutation = { reportTaskProgress?: { id: string } | null }

export type CreateManyTaskReportsMutationVariables = Exact<{
  projectId: Scalars["String"]
  taskReports: Array<TaskReportInput> | TaskReportInput
}>

export type CreateManyTaskReportsMutation = {
  createManyTaskReports?: Array<{
    id: string
    name: string
    projectId: string
    parentTaskId?: string | null
    unitGoals: Array<{ id: string; targetQuantity?: number | null; deliverableUnitId: string }>
  }> | null
}

export type CreateTaskGroupMutationVariables = Exact<{
  description?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  notes?: InputMaybe<Scalars["String"]>
  projectId: Scalars["String"]
}>

export type CreateTaskGroupMutation = {
  insertOneTaskGroup: {
    id: string
    name: string
    description?: string | null
    notes?: string | null
    assetCount: number
    completedTaskCount: number
    organizationId: string
    unitProgress: number
    unitTargetGoal: number
    startDate?: Date | null
    endDate?: Date | null
    taskCount: number
    userCount: number
    updatedAt: Date
    createdAt: Date
    tasks: Array<{ id: string; name: string }>
  }
}

export type UpdateTaskGroupMutationVariables = Exact<{
  id: Scalars["String"]
  name?: InputMaybe<Scalars["String"]>
  notes?: InputMaybe<Scalars["String"]>
  projectId?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
}>

export type UpdateTaskGroupMutation = {
  updateOneTaskGroup: {
    id: string
    name: string
    description?: string | null
    notes?: string | null
    assetCount: number
    completedTaskCount: number
    organizationId: string
    unitProgress: number
    unitTargetGoal: number
    startDate?: Date | null
    endDate?: Date | null
    taskCount: number
    userCount: number
    updatedAt: Date
    createdAt: Date
    tasks: Array<{ id: string; name: string }>
  }
}

export type ClockInMutationVariables = Exact<{
  taskId: Scalars["String"]
  userId: Scalars["String"]
  startEvidence?: InputMaybe<StartEvidenceInput>
}>

export type ClockInMutation = {
  clockIn: {
    id: string
    durationInSeconds: number
    endAt?: Date | null
    evidence: any
    startAt: Date
    taskId: string
    userId: string
    user: { id: string; isClockedIn: boolean; secondsClockedSinceOrgMidnight: number }
  }
}

export type BulkClockInMutationVariables = Exact<{
  candidates: Array<BulkClockInCandidate> | BulkClockInCandidate
  startAt?: InputMaybe<Scalars["DateTime"]>
}>

export type BulkClockInMutation = {
  bulkClockIn: { success: number; errors: Array<{ userId: string; reason: TimeEntryErrorReason }> }
}

export type ClockOutUserMutationVariables = Exact<{
  userId: Scalars["String"]
  endEvidence?: InputMaybe<EndEvidenceInput>
}>

export type ClockOutUserMutation = {
  clockOutUser: {
    id: string
    durationInSeconds: number
    endAt?: Date | null
    evidence: any
    startAt: Date
    taskId: string
    userId: string
    user: { id: string; isClockedIn: boolean; secondsClockedSinceOrgMidnight: number }
  }
}

export type ClockOutMutationVariables = Exact<{
  id: Scalars["String"]
  endEvidence?: InputMaybe<EndEvidenceInput>
}>

export type ClockOutMutation = {
  clockOut: {
    id: string
    durationInSeconds: number
    endAt?: Date | null
    evidence: any
    startAt: Date
    taskId: string
    userId: string
    user: { id: string; isClockedIn: boolean; secondsClockedSinceOrgMidnight: number }
  }
}

export type BulkClockOutMutationVariables = Exact<{
  candidates: Array<BulkClockOutCandidate> | BulkClockOutCandidate
  endAt?: InputMaybe<Scalars["DateTime"]>
}>

export type BulkClockOutMutation = {
  bulkClockOut: { success: number; errors: Array<{ userId: string; reason: TimeEntryErrorReason }> }
}

export type TimeEntryCreateMutationVariables = Exact<{
  taskId: Scalars["String"]
  userId: Scalars["String"]
  startAt: Scalars["DateTime"]
  endAt: Scalars["DateTime"]
}>

export type TimeEntryCreateMutation = {
  insertOneTimeEntry: {
    id: string
    userId: string
    taskId: string
    startAt: Date
    endAt?: Date | null
    durationInSeconds: number
    evidence: any
    organization: { id: string }
    task: { id: string }
    user: { id: string }
  }
}

export type TimeEntryEditMutationVariables = Exact<{
  id: Scalars["String"]
  startAt: Scalars["DateTime"]
  endAt?: InputMaybe<Scalars["DateTime"]>
  taskId: Scalars["String"]
}>

export type TimeEntryEditMutation = {
  updateOneTimeEntry: {
    id: string
    userId: string
    taskId: string
    startAt: Date
    endAt?: Date | null
    durationInSeconds: number
    evidence: any
    user: { id: string }
  }
}

export type DeleteTimeEntryMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type DeleteTimeEntryMutation = { deleteOneTimeEntry: { id: string } }

export type CreateUnitGoalMutationVariables = Exact<{
  taskId: Scalars["String"]
  goal: UnitGoalInput
}>

export type CreateUnitGoalMutation = {
  createUnitGoal?: {
    id: string
    targetQuantity?: number | null
    deliverableUnitId: string
    deliverableUnit: { id: string; description: string; unitOfMeasure: string; referenceNumber?: string | null }
  } | null
}

export type EditUnitGoalMutationVariables = Exact<{
  id: Scalars["String"]
  goal: UnitGoalInput
}>

export type EditUnitGoalMutation = {
  editUnitGoal?: { id: string; targetQuantity?: number | null; deliverableUnitId: string } | null
}

export type DeleteUnitGoalMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type DeleteUnitGoalMutation = { deleteUnitGoal?: { id: string } | null }

export type UserEditMutationVariables = Exact<{
  id: Scalars["String"]
  firstName?: InputMaybe<Scalars["String"]>
  lastName?: InputMaybe<Scalars["String"]>
  jobTitle?: InputMaybe<Scalars["String"]>
  phoneNumber?: InputMaybe<Scalars["String"]>
  phoneNumberExt?: InputMaybe<Scalars["String"]>
  image?: InputMaybe<Scalars["String"]>
  task?: InputMaybe<Scalars["String"]>
  roles?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>
  workersCompCodeId?: InputMaybe<Scalars["String"]>
  companyUserId?: InputMaybe<Scalars["String"]>
  payType?: InputMaybe<PayType>
  payRate?: InputMaybe<Scalars["String"]>
}>

export type UserEditMutation = {
  updateOneUser: {
    id: string
    organizationId: string
    projectId: string
    taskId?: string | null
    email: string
    phoneNumber: string
    phoneNumberExt?: string | null
    imageUrl?: string | null
    firstName: string
    lastName: string
    jobTitle: string
    archived: boolean
    payRate?: string | null
    payType?: PayType | null
    roles: Array<{
      id: string
      name?: string | null
      order?: number | null
      description?: string | null
      permissions: any
      widgets: any
    }>
    task: { id: string; name: string }
    workersCompCode?: { id: string; name: string; ncciCode: string } | null
  }
}

export type ResendUserInvitesMutationVariables = Exact<{
  userIds: Array<Scalars["String"]> | Scalars["String"]
}>

export type ResendUserInvitesMutation = { resendUserInvites?: boolean | null }

export type UserDeleteMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type UserDeleteMutation = {
  deleteOneUser: {
    id: string
    organizationId: string
    projectId: string
    taskId?: string | null
    email: string
    phoneNumber: string
    phoneNumberExt?: string | null
    imageUrl?: string | null
    firstName: string
    lastName: string
    jobTitle: string
    archived: boolean
    roles: Array<{
      id: string
      name?: string | null
      order?: number | null
      description?: string | null
      permissions: any
      widgets: any
    }>
  }
}

export type UserRestoreMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type UserRestoreMutation = {
  restoreOneUser: {
    id: string
    organizationId: string
    projectId: string
    taskId?: string | null
    email: string
    phoneNumber: string
    phoneNumberExt?: string | null
    imageUrl?: string | null
    firstName: string
    lastName: string
    jobTitle: string
    archived: boolean
    roles: Array<{
      id: string
      name?: string | null
      order?: number | null
      description?: string | null
      permissions: any
      widgets: any
    }>
  }
}

export type ReassignUserMutationVariables = Exact<{
  taskId: Scalars["String"]
  userId: Scalars["String"]
  projectId: Scalars["String"]
}>

export type ReassignUserMutation = {
  reassignUser: {
    id: string
    organizationId: string
    projectId: string
    taskId?: string | null
    email: string
    phoneNumber: string
    phoneNumberExt?: string | null
    imageUrl?: string | null
    firstName: string
    lastName: string
    jobTitle: string
    archived: boolean
    currentProjectId: string
    currentTaskId: string
    isClockedIn: boolean
    secondsClockedSinceOrgMidnight: number
    latestTimeEntry?: {
      id: string
      userId: string
      taskId: string
      startAt: Date
      endAt?: Date | null
      durationInSeconds: number
      evidence: any
    } | null
    currentProject: { id: string; name: string }
    currentTask: { id: string; name: string }
    project: { id: string; name: string }
    task: { id: string; name: string }
  }
}

export type ReassignUsersMutationVariables = Exact<{
  assignments: Array<UserAssignmentAssignment> | UserAssignmentAssignment
}>

export type ReassignUsersMutation = {
  reassignUsers: Array<{
    id: string
    organizationId: string
    projectId: string
    taskId?: string | null
    email: string
    phoneNumber: string
    phoneNumberExt?: string | null
    imageUrl?: string | null
    firstName: string
    lastName: string
    jobTitle: string
    archived: boolean
    currentProjectId: string
    currentTaskId: string
    isClockedIn: boolean
    secondsClockedSinceOrgMidnight: number
    latestTimeEntry?: {
      id: string
      userId: string
      taskId: string
      startAt: Date
      endAt?: Date | null
      durationInSeconds: number
      evidence: any
    } | null
    currentProject: { id: string; name: string }
    currentTask: { id: string; name: string }
    project: { id: string; name: string }
    task: { id: string; name: string }
  }>
}

export type CreateUserMutationVariables = Exact<{
  user: NewUser
  sendInvite: Scalars["Boolean"]
}>

export type CreateUserMutation = {
  createUser?: {
    id: string
    organizationId: string
    projectId: string
    taskId?: string | null
    email: string
    phoneNumber: string
    phoneNumberExt?: string | null
    imageUrl?: string | null
    firstName: string
    lastName: string
    jobTitle: string
    archived: boolean
    payRate?: string | null
    payType?: PayType | null
    roles: Array<{ id: string; name?: string | null }>
    workersCompCode?: { id: string; name: string; ncciCode: string } | null
  } | null
}

export type SetUserParentMutationVariables = Exact<{
  childId: Scalars["String"]
  parentId: Scalars["String"]
}>

export type SetUserParentMutation = { assignChildToParent: { parentId: string; childId: string } }

export type DeleteParentChildRelationshipMutationVariables = Exact<{
  parentId: Scalars["String"]
  childId: Scalars["String"]
}>

export type DeleteParentChildRelationshipMutation = {
  deleteParentChildRelationship: { parentId: string; childId: string }
}

export type UpdatePasswordMutationVariables = Exact<{
  currentPassword: Scalars["String"]
  newPassword: Scalars["String"]
}>

export type UpdatePasswordMutation = { updatePassword: boolean }

export type DeleteUserDeviceSessionMutationVariables = Exact<{
  deviceSessionId: Scalars["String"]
}>

export type DeleteUserDeviceSessionMutation = { deleteUserDeviceSession: boolean }

export type MasqueradeUserMutationVariables = Exact<{
  subjectUserId: Scalars["String"]
}>

export type MasqueradeUserMutation = { masqueradeUser: { accessToken: string } }

export type EndAllMasqueradeSessionsForUserMutationVariables = Exact<{
  subjectUserId: Scalars["String"]
}>

export type EndAllMasqueradeSessionsForUserMutation = { endAllMasqueradeSessionsForUser: boolean }

export type CreateUserAssignmentMutationVariables = Exact<{
  projectId: Scalars["String"]
  taskId?: InputMaybe<Scalars["String"]>
  userId: Scalars["String"]
}>

export type CreateUserAssignmentMutation = {
  createUserAssignment?: {
    id: string
    projectId: string
    taskId?: string | null
    userId?: string | null
    isCurrentAssignment?: boolean | null
    active: boolean
    createdAt: Date
  } | null
}

export type UpdateUserAssignmentMutationVariables = Exact<{
  id: Scalars["String"]
  projectId: Scalars["String"]
  taskId?: InputMaybe<Scalars["String"]>
}>

export type UpdateUserAssignmentMutation = {
  updateUserAssignment?: { id: string; projectId: string; taskId?: string | null; userId?: string | null } | null
}

export type DeleteUserAssignmentMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type DeleteUserAssignmentMutation = {
  deleteUserAssignment: { id: string; projectId: string; taskId?: string | null; userId?: string | null }
}

export type BulkUpdateUserAssignmentsMutationVariables = Exact<{
  assignmentsToCreate?: InputMaybe<Array<UserAssignmentCreateInput> | UserAssignmentCreateInput>
  assignmentsToDelete?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>
}>

export type BulkUpdateUserAssignmentsMutation = { bulkUpdateUserAssignments?: { count: number } | null }

export type CreateWorkersCompCodeMutationVariables = Exact<{
  name: Scalars["String"]
  ncciCode: Scalars["String"]
  ncciDescription: Scalars["String"]
  override: Scalars["Boolean"]
  rate: Scalars["Float"]
}>

export type CreateWorkersCompCodeMutation = {
  createWorkersCompCode?: {
    id: string
    name: string
    ncciCode: string
    ncciDescription: string
    override: boolean
    rate: number
  } | null
}

export type ProjectProgressQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type ProjectProgressQuery = {
  tasks: Array<{
    id: string
    isComplete?: Date | null
    estimatedHours?: number | null
    isDefault: boolean
    timeEntriesSumDurationInSeconds: number
    unitGoalsSumProgress: number
    unitGoalsSumTargetQuantity: number
  }>
}

export type TaskAndSubtaskProgressSummaryHookQueryVariables = Exact<{
  parentTaskId: Scalars["String"]
}>

export type TaskAndSubtaskProgressSummaryHookQuery = {
  task: {
    id: string
    isComplete?: Date | null
    estimatedHours?: number | null
    timeEntriesSumDurationInSeconds: number
    unitGoalsSumProgress: number
    unitGoalsSumTargetQuantity: number
  }
  subtasks: Array<{
    id: string
    isComplete?: Date | null
    estimatedHours?: number | null
    timeEntriesSumDurationInSeconds: number
    unitGoalsSumProgress: number
    unitGoalsSumTargetQuantity: number
  }>
}

export type OrganizationWithAdminsAndRolesQueryVariables = Exact<{
  organizationId: Scalars["String"]
}>

export type OrganizationWithAdminsAndRolesQuery = {
  organization: {
    id: string
    archived: boolean
    dateCreated: Date
    imageUrl?: string | null
    name?: string | null
    timezone: string
    stripeCustomerId?: string | null
    billingPastDueAt?: Date | null
    featureToggleSegments: Array<string>
    admins: Array<{
      id: string
      archived: boolean
      firstName: string
      imageUrl?: string | null
      isClockedIn: boolean
      jobTitle: string
      lastName: string
      isBlackthornEmployee: boolean
      latestTimeEntry?: { id: string; startAt: Date } | null
    }>
    roles: Array<{
      id: string
      name?: string | null
      order?: number | null
      description?: string | null
      permissions: any
      widgets: any
    }>
  }
}

export type OrganizationsIndexPageQueryVariables = Exact<{
  archived: Scalars["Boolean"]
}>

export type OrganizationsIndexPageQuery = {
  organizations: Array<{
    id: string
    dateCreated: Date
    name?: string | null
    archived: boolean
    stripeCustomerId?: string | null
    lastStripeInvoice?: { id: string; status?: string | null; created?: Date | null } | null
    roles: Array<{ id: string; name?: string | null; permissions: any }>
    admins: Array<{ id: string; firstName: string; lastName: string }>
  }>
}

export type AssetDetailsPageQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type AssetDetailsPageQuery = {
  asset: {
    id: string
    assetChildCount: number
    assetGroupId?: string | null
    assignableType: AssetAssignableType
    assignableId: string
    companyAssetNumber?: string | null
    deletedAt?: Date | null
    groupQuantity?: number | null
    imageUrl?: string | null
    isAssetGroup: boolean
    name: string
    category: AssetCategory
    ownershipType: string
    active: boolean
    activeChangedAt: Date
    assetGroupMemberCount: number
    purchaseDetails?: { price?: number | null; date?: string | null } | null
    rentalAgreement?: {
      endOn?: string | null
      startOn?: string | null
      rate?: { daily: number; weekly: number; monthly: number } | null
    } | null
    manufacturer?: {
      id?: string | null
      make?: string | null
      name?: string | null
      year?: number | null
      model?: string | null
    } | null
    vendorContact?: { name?: string | null; email?: string | null; phone?: string | null } | null
    inventoryRequirements?: { intervalInSeconds?: number | null; photoRequired?: boolean | null } | null
    inspectionTemplatesAssignments: Array<{
      id: string
      assetId: string
      intervalInSeconds?: number | null
      lastReportedAt?: Date | null
      startDate?: Date | null
      inspectionTemplate: { id: string; name: string }
    }>
  }
}

export type AssetAssetsQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type AssetAssetsQuery = {
  asset: {
    id: string
    name: string
    childAssets?: Array<{
      id: string
      active: boolean
      assetGroupId?: string | null
      assignableId: string
      assignableType: AssetAssignableType
      companyAssetNumber?: string | null
      groupQuantity?: number | null
      imageUrl?: string | null
      isAssetGroup: boolean
      name: string
      ownershipType: string
      status: AssetStatus
    }> | null
  }
}

export type AssetGroupDocumentQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type AssetGroupDocumentQuery = {
  asset: {
    id: string
    isAssetGroup: boolean
    name: string
    childAssets?: Array<{
      id: string
      active: boolean
      assetChildCount: number
      assetGroupId?: string | null
      assignableId: string
      assignableType: AssetAssignableType
      companyAssetNumber?: string | null
      groupQuantity?: number | null
      imageUrl?: string | null
      isAssetGroup: boolean
      name: string
      ownershipType: string
      status: AssetStatus
    }> | null
    childAssetGroups?: Array<{
      assetGroupId: string
      assignableId: string
      assignableType: AssetAssignableType
      count: number
      status: AssetStatus
      groupParent: {
        id: string
        active: boolean
        assetChildCount: number
        assetGroupId?: string | null
        assignableId: string
        assignableType: AssetAssignableType
        companyAssetNumber?: string | null
        imageUrl?: string | null
        isAssetGroup: boolean
        name: string
        ownershipType: string
        status: AssetStatus
      }
    }> | null
  }
}

export type AssetHistoryQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type AssetHistoryQuery = {
  asset: {
    id: string
    active: boolean
    groupQuantity?: number | null
    name: string
    status: AssetStatus
    assetChildCount: number
    assignableId: string
    assignableType: AssetAssignableType
    isAssetGroup: boolean
    ownershipType: string
    inferredOwner?: { id: string } | null
    inventoryRequirements?: { photoRequired?: boolean | null; intervalInSeconds?: number | null } | null
    inspectionTemplatesAssignments: Array<{ id: string; inspectionTemplate: { id: string; name: string } }>
  }
  assetGroups: Array<{
    assetGroupId: string
    assignableId: string
    assignableType: AssetAssignableType
    assignedUserId?: string | null
    count: number
    status: AssetStatus
  }>
  assetReports: Array<{
    id: string
    assetId: string
    notes?: string | null
    createdAt: Date
    quantityReported?: number | null
    type: AssetReportType
    reporter: {
      id: string
      archived: boolean
      firstName: string
      imageUrl?: string | null
      jobTitle: string
      lastName: string
      isClockedIn: boolean
    }
    statusChange?: { active: boolean; status: AssetStatus; missingCount?: number | null } | null
    inspectionReport?: Array<{ id?: string | null; photoUrls?: Array<string> | null }> | null
    inspectionReportAggregations?: { trueCount: number; falseCount: number } | null
    inventoryReport?: { fileIds?: Array<string> | null; note?: string | null; photoUrls?: Array<string> | null } | null
  }>
}

export type AssetReportsQueryVariables = Exact<{
  assetId: Scalars["String"]
}>

export type AssetReportsQuery = {
  asset: {
    id: string
    active: boolean
    assetChildCount: number
    assetGroupId?: string | null
    assignableId: string
    assignableType: AssetAssignableType
    companyAssetNumber?: string | null
    groupQuantity?: number | null
    imageUrl?: string | null
    isAssetGroup: boolean
    name: string
    ownershipType: string
    status: AssetStatus
    inventoryRequirements?: { intervalInSeconds?: number | null; photoRequired?: boolean | null } | null
    inspectionTemplatesAssignments: Array<{ id: string; inspectionTemplate: { id: string; name: string } }>
  }
  assetGroups: Array<{
    assetGroupId: string
    assignableId: string
    assignableType: AssetAssignableType
    assignedUserId?: string | null
    count: number
    status: AssetStatus
  }>
}

export type AssetsQueryVariables = Exact<{ [key: string]: never }>

export type AssetsQuery = { assets_2: { edges: Array<{ node: { id: string } } | null> } }

export type CreateUserNotificationMutationVariables = Exact<{
  modelType: UserNotificationModelType
  type: UserNotificationType
  assetId?: InputMaybe<Scalars["String"]>
  userId?: InputMaybe<Scalars["String"]>
}>

export type CreateUserNotificationMutation = {
  createUserNotification?: {
    id: string
    type: UserNotificationType
    modelType: UserNotificationModelType
    asset?: { id: string; name: string } | null
    user?: { id: string; firstName: string; lastName: string } | null
  } | null
}

export type MyOrganizationRolesQueryVariables = Exact<{ [key: string]: never }>

export type MyOrganizationRolesQuery = {
  myOrganization: {
    roles: Array<{
      id: string
      name?: string | null
      order?: number | null
      description?: string | null
      permissions: any
      widgets: any
    }>
  }
}

export type MyOrganizationAdminsQueryVariables = Exact<{ [key: string]: never }>

export type MyOrganizationAdminsQuery = {
  myOrganization: {
    id: string
    dateCreated: Date
    imageUrl?: string | null
    name?: string | null
    admins: Array<{
      id: string
      archived: boolean
      firstName: string
      imageUrl?: string | null
      isClockedIn: boolean
      jobTitle: string
      lastName: string
      isBlackthornEmployee: boolean
      latestTimeEntry?: { id: string; startAt: Date } | null
    }>
    roles: Array<{
      id: string
      name?: string | null
      order?: number | null
      description?: string | null
      permissions: any
      widgets: any
    }>
  }
}

export type OrganizationDetailsQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationDetailsQuery = {
  myOrganization: {
    id: string
    archived: boolean
    dateCreated: Date
    imageUrl?: string | null
    name?: string | null
    timezone: string
  }
}

export type OrganizationRolesPageQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationRolesPageQuery = {
  myOrganization: {
    id: string
    dateCreated: Date
    imageUrl?: string | null
    name?: string | null
    roles: Array<{ id: string; name?: string | null; permissions: any }>
  }
}

export type MyOrganizationQueryVariables = Exact<{ [key: string]: never }>

export type MyOrganizationQuery = {
  myOrganization: {
    id: string
    dateCreated: Date
    imageUrl?: string | null
    name?: string | null
    requireTimeEntryEvidence: boolean
    notificationSettings: any
  }
}

export type GetTaskDetailsQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type GetTaskDetailsQuery = {
  task: {
    id: string
    name: string
    projectId: string
    parentTaskId?: string | null
    archived: boolean
    assetCount: number
    isComplete?: Date | null
    isDefault: boolean
    userCount: number
    project: { id: string; name: string; isArchived?: Date | null; isComplete?: Date | null }
  }
}

export type GetTaskGroupDetailsQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type GetTaskGroupDetailsQuery = {
  taskGroup?: { id: string; completedTaskCount: number; name: string; projectId: string; taskCount: number } | null
}

export type CompleteTaskPageQueryVariables = Exact<{
  projectId: Scalars["String"]
  taskId: Scalars["String"]
}>

export type CompleteTaskPageQuery = {
  task: { id: string; name: string; isDefault: boolean }
  project?: { id: string; name: string; unassignedTaskId?: string | null } | null
}

export type TaskDetailsQueryVariables = Exact<{
  taskId: Scalars["String"]
}>

export type TaskDetailsQuery = {
  task: {
    id: string
    groupId?: string | null
    organizationId: string
    projectId: string
    archived: boolean
    dateCreated: Date
    description?: string | null
    endDate?: Date | null
    estimatedHours?: number | null
    completedHours: number
    hasReportableUnit: boolean
    isComplete?: Date | null
    isDefault: boolean
    metadata?: any | null
    name: string
    startDate?: Date | null
    group?: { id: string; name: string } | null
    workersCompCode?: { id: string; name: string; ncciCode: string } | null
    project: { id: string; name: string }
    scheduleTemplate?: {
      id: string
      isDefault?: boolean | null
      workDays?: Array<{ label: string; active: boolean }> | null
      workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
      nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
    } | null
    scheduledBreaks?: Array<{
      id: string
      durationInMinutes: number
      isActive: boolean
      localizedStartTime: string
      name: string
      breakTaskId: string
    }> | null
    unitGoals: Array<{
      id: string
      deliverableUnitId: string
      targetQuantity?: number | null
      isPrimary: boolean
      deliverableUnit: { id: string; description: string; referenceNumber?: string | null; unitOfMeasure: string }
      unitGoalProgress: Array<{ id: string; progress: number }>
    }>
  }
}

export type GetTaskGroupQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type GetTaskGroupQuery = {
  taskGroup?: {
    id: string
    name: string
    notes?: string | null
    description?: string | null
    createdAt: Date
    completedTaskCount: number
    assetCount: number
    organizationId: string
    unitProgress: number
    unitTargetGoal: number
    startDate?: Date | null
    endDate?: Date | null
    taskCount: number
    updatedAt: Date
    userCount: number
    projectId: string
  } | null
}

export type TaskSummaryPageQueryVariables = Exact<{
  taskId: Scalars["String"]
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
}>

export type TaskSummaryPageQuery = {
  task: {
    id: string
    estimatedHours?: number | null
    name: string
    startDate?: Date | null
    timeEntriesSumDurationInSeconds: number
    project: { id: string; name: string }
  }
}

export type TaskTeamPageQueryVariables = Exact<{
  taskId: Scalars["String"]
}>

export type TaskTeamPageQuery = {
  task: {
    id: string
    hasReportableUnit: boolean
    isComplete?: Date | null
    isDefault: boolean
    name: string
    projectId: string
    project: { id: string; isArchived?: Date | null }
    subtasks: Array<{ id: string; name: string }>
  }
}

export type ProjectLayoutQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type ProjectLayoutQuery = {
  myOrganization: { id: string; imageUrl?: string | null; name?: string | null }
  project?: {
    id: string
    code?: string | null
    description?: string | null
    endDate?: Date | null
    isArchived?: Date | null
    isComplete?: Date | null
    isDefault: boolean
    imageUrl?: string | null
    metadata?: any | null
    name: string
    startDate?: Date | null
    userCount: number
    scheduleTemplate?: {
      id: string
      isDefault?: boolean | null
      workDays?: Array<{ label: string; active: boolean }> | null
      workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
      nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
    } | null
    scheduledBreaks?: Array<{
      id: string
      durationInMinutes: number
      isActive: boolean
      localizedStartTime: string
      name: string
      breakTaskId: string
      breakTask: { id: string; name: string }
    }> | null
  } | null
}

export type ArchiveProjectPageQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type ArchiveProjectPageQuery = {
  project?: {
    id: string
    name: string
    userCount: number
    users: Array<{
      id: string
      currentProjectId: string
      currentTaskId: string
      firstName: string
      jobTitle: string
      lastName: string
      projectId: string
      taskId?: string | null
      isClockedIn: boolean
    }>
    defaultTask: { id: string; name: string; isDefault: boolean; projectId: string }
  } | null
}

export type ProjectAssetsPageQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type ProjectAssetsPageQuery = { project?: { id: string; name: string } | null }

export type ArchiveProjectDetailsSectionQueryQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type ArchiveProjectDetailsSectionQueryQuery = {
  project?: { id: string; isArchived?: Date | null; userCount: number } | null
}

export type ActivateProjectMutationVariables = Exact<{
  id: Scalars["String"]
}>

export type ActivateProjectMutation = { activateProject: { id: string; isArchived?: Date | null } }

export type ProjectQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type ProjectQuery = {
  project?: {
    id: string
    tasks: Array<{ id: string; estimatedHours?: number | null; startDate?: Date | null; endDate?: Date | null }>
    taskGroups: Array<{ id: string; estimatedHours: number; startDate?: Date | null; endDate?: Date | null }>
  } | null
}

export type ProjectDetailsQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type ProjectDetailsQuery = {
  project?: {
    id: string
    code?: string | null
    description?: string | null
    endDate?: Date | null
    imageUrl?: string | null
    isDefault: boolean
    locationNotes?: string | null
    metadata?: any | null
    name: string
    startDate?: Date | null
    address?: { formattedAddress?: string | null } | null
    location?: { lat: number; latitude: number; lng: number; longitude: number; radius?: number | null } | null
    customer?: { id: string; name: string; customerId?: string | null; imageUrl?: string | null } | null
    scheduleTemplate?: {
      id: string
      isDefault?: boolean | null
      workDays?: Array<{ label: string; active: boolean }> | null
      workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
      nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
    } | null
    scheduledBreaks?: Array<{
      id: string
      durationInMinutes: number
      isActive: boolean
      localizedStartTime: string
      name: string
      breakTaskId: string
      breakTask: { id: string; name: string }
    }> | null
    assignees: Array<{
      id: string
      isCurrentAssignment?: boolean | null
      projectId: string
      taskId?: string | null
      userId?: string | null
      task?: { id: string; name: string } | null
      user: {
        id: string
        firstName: string
        imageUrl?: string | null
        isClockedIn: boolean
        jobTitle: string
        lastName: string
      }
    }>
    workersCompCode?: { id: string; name: string; ncciCode: string } | null
  } | null
}

export type ProjectScheduleGanttQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type ProjectScheduleGanttQuery = {
  project?: {
    id: string
    name: string
    tasks: Array<{
      id: string
      endDate?: Date | null
      estimatedHours?: number | null
      isComplete?: Date | null
      name: string
      startDate?: Date | null
      sortOrder?: number | null
      unitGoalsSumProgress: number
      unitGoalsSumTargetQuantity: number
    }>
    taskGroups: Array<{
      id: string
      endDate?: Date | null
      estimatedHours: number
      isComplete: boolean
      name: string
      startDate?: Date | null
      sortOrder?: number | null
      unitProgress: number
      unitTargetGoal: number
      tasks: Array<{
        id: string
        endDate?: Date | null
        estimatedHours?: number | null
        groupId?: string | null
        isComplete?: Date | null
        name: string
        startDate?: Date | null
        sortOrder?: number | null
        unitGoalsSumProgress: number
        unitGoalsSumTargetQuantity: number
      }>
    }>
  } | null
}

export type ProjectSummaryPageWrapperQueryVariables = Exact<{
  projectId: Scalars["String"]
  rangeStart?: InputMaybe<Scalars["DateTime"]>
  rangeEnd?: InputMaybe<Scalars["DateTime"]>
}>

export type ProjectSummaryPageWrapperQuery = {
  myOrganization: { id: string; timezone: string }
  project?: {
    id: string
    estimatedHours: number
    name: string
    startDate?: Date | null
    assetsCount: number
    userCount: number
    previousSeconds: number
    totalSeconds: number
    currentSeconds: number
  } | null
}

export type ProjectTeamPageQueryVariables = Exact<{
  projectId: Scalars["String"]
}>

export type ProjectTeamPageQuery = { project?: { id: string; name: string } | null }

export type OrgScheduleDetailsQueryVariables = Exact<{ [key: string]: never }>

export type OrgScheduleDetailsQuery = {
  scheduleTemplates: Array<{
    id: string
    isDefault?: boolean | null
    workDays?: Array<{ label: string; active: boolean }> | null
    workHours?: { hours?: number | null; startTime?: string | null; endTime?: string | null } | null
    nonWorkDays?: Array<{ id: string; name: string; dateRange: Array<Date>; active: boolean }> | null
  }>
  scheduledBreaks: Array<{
    id: string
    breakTaskId: string
    durationInMinutes: number
    isActive: boolean
    localizedStartTime: string
    name: string
    breakTask: { id: string; name: string }
  }>
}

export type GetUserAccountDetailsQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type GetUserAccountDetailsQuery = {
  user: {
    imageUrl?: string | null
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    phoneNumberExt?: string | null
    passwordUpdatedAt?: Date | null
    securityActivity: Array<{ createdAt: Date; event: string; ip?: string | null; userAgent?: string | null }>
    deviceSessions: Array<{
      id: string
      userId: string
      ip?: string | null
      userAgent?: string | null
      isSingleUse?: boolean | null
      createdAt: Date
      updatedAt: Date
      requestInfo: {
        country?: string | null
        region?: string | null
        city?: string | null
        browser?: string | null
        os?: string | null
        platform?: string | null
        isMobile?: boolean | null
        isDesktop?: boolean | null
        isTablet?: boolean | null
        isBot?: boolean | null
        isCurl?: boolean | null
      }
    }>
  }
}

export type TeamMemberPageQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type TeamMemberPageQuery = {
  user: {
    id: string
    firstName: string
    imageUrl?: string | null
    jobTitle: string
    lastName: string
    archived: boolean
    isClockedIn: boolean
  }
}

export type TeamDetailsPageGetUserQueryVariables = Exact<{
  id: Scalars["String"]
  fetchPay: Scalars["Boolean"]
}>

export type TeamDetailsPageGetUserQuery = {
  user: {
    id: string
    currentProjectId: string
    currentTaskId: string
    organizationId: string
    projectId: string
    taskId?: string | null
    companyUserId?: string | null
    archived: boolean
    email: string
    firstName: string
    imageUrl?: string | null
    isClockedIn: boolean
    jobTitle: string
    lastName: string
    phoneNumber: string
    phoneNumberExt?: string | null
    secondsClockedSinceOrgMidnight: number
    payRate?: string | null
    payType?: PayType | null
    currentProject: { id: string; name: string }
    currentTask: { id: string; name: string }
    assignments: Array<{
      id: string
      isCurrentAssignment?: boolean | null
      projectId: string
      taskId?: string | null
      userId?: string | null
      project: { id: string; code?: string | null; imageUrl?: string | null; name: string }
      task?: { id: string; name: string } | null
    }>
    workersCompCode?: { id: string; name: string; ncciCode: string } | null
    childAncestorNodes: Array<{ userId: string; depth: number }>
    parents: Array<{ id: string; firstName: string; lastName: string; jobTitle: string }>
    roles: Array<{ id: string; description?: string | null; name?: string | null }>
  }
}

export type UserOfflineEventsQueryVariables = Exact<{
  userId: Scalars["String"]
}>

export type UserOfflineEventsQuery = {
  user: {
    id: string
    firstName: string
    lastName: string
    offlineEvents: Array<{
      id: string
      reconciledAt?: Date | null
      receivedAt: Date
      clientCreatedAt: Date
      clientCanceledAt?: Date | null
      issues?: string | null
      type: OfflineEventType
      clockInData?: {
        taskId: string
        task: { id: string; name: string }
        user: { id: string; fullName: string }
      } | null
      clockOutData?: {
        task?: { id: string; name: string } | null
        user?: { id: string; fullName: string } | null
      } | null
    }>
  }
}

export type UserTimeCardsPageQueryVariables = Exact<{
  userId: Scalars["String"]
}>

export type UserTimeCardsPageQuery = {
  user: {
    id: string
    firstName: string
    lastName: string
    currentProjectId: string
    archived: boolean
    currentTaskId: string
    project: { id: string; name: string }
    task: { id: string; name: string }
    timeEntries?: Array<{
      id: string
      durationInSeconds: number
      endAt?: Date | null
      signInPhotoUrl?: string | null
      signOutPhotoUrl?: string | null
      projectId: string
      taskId: string
      startAt: Date
      project: { id: string; name: string; isDefault: boolean }
      task: {
        id: string
        parentTaskId?: string | null
        name: string
        visibilityLevel: string
        parentTask?: { id: string; name: string } | null
      }
    }> | null
  }
}

export type NotificationsProviderQueryVariables = Exact<{
  take?: InputMaybe<Scalars["Int"]>
}>

export type NotificationsProviderQuery = {
  myNotifications: Array<{
    id: string
    modelType: UserNotificationModelType
    type: UserNotificationType
    markedReadAt?: Date | null
    createdAt: Date
    asset?: { id: string; name: string } | null
    user?: { id: string; firstName: string; lastName: string } | null
  }>
}

export type OrgSettingsProviderQueryVariables = Exact<{ [key: string]: never }>

export type OrgSettingsProviderQuery = {
  myOrganization: {
    id: string
    requireTimeEntryEvidence: boolean
    unitsOfMeasure: Array<{ id?: number | null; name: string; symbol: string }>
  }
}

export type PermissionsExplorerGetOrganizationQueryVariables = Exact<{ [key: string]: never }>

export type PermissionsExplorerGetOrganizationQuery = {
  myOrganization: { id: string; roles: Array<{ id: string; name?: string | null; permissions: any; widgets: any }> }
}

export const AssetBaseFragmentDoc = gql`
  fragment AssetBase on Asset {
    id
    active
    activeChangedAt
    assetChildCount
    assetGroupId
    assignmentsCount
    assignableId
    assignedUserId
    assignableType
    companyAssetNumber
    deletedAt
    groupQuantity
    imageUrl
    isAssetGroup
    lastInventoriedAt
    name
    organizationId
    ownershipType
    status
  }
`
export const AssetAdditionalFragmentDoc = gql`
  fragment AssetAdditional on Asset {
    purchaseDetails {
      price
      date
    }
    rentalAgreement {
      rate {
        daily
        weekly
        monthly
      }
      endOn
      startOn
    }
    manufacturer {
      id
      make
      name
      year
      model
    }
    vendorContact {
      name
      email
      phone
    }
  }
`
export const AssetInventoryFragmentDoc = gql`
  fragment AssetInventory on Asset {
    inventoryRequirements {
      intervalInSeconds
      photoRequired
    }
  }
`
export const AssetAssignedUserFragmentDoc = gql`
  fragment AssetAssignedUser on Asset {
    assignedUser {
      id
      firstName
      lastName
      imageUrl
      jobTitle
    }
  }
`
export const AssetReportBaseFragmentDoc = gql`
  fragment AssetReportBase on AssetReport {
    id
    assetId
    reporterId
    notes
    type
  }
`
export const AssetReportDatesFragmentDoc = gql`
  fragment AssetReportDates on AssetReport {
    createdAt
    updatedAt
  }
`
export const AssetReportInventoryFragmentDoc = gql`
  fragment AssetReportInventory on AssetReport {
    inventoryReport {
      photoUrls
      note
    }
  }
`
export const AssetStatusFragmentDoc = gql`
  fragment AssetStatus on AssetReport {
    statusChange {
      active
      status
    }
  }
`
export const AssetReportReporterFragmentDoc = gql`
  fragment AssetReportReporter on AssetReport {
    reporter {
      id
      firstName
      lastName
      fullName
    }
  }
`
export const CustomerBaseFragmentDoc = gql`
  fragment CustomerBase on Customer {
    id
    name
    image
    customerId
  }
`
export const CustomerFullFragmentDoc = gql`
  fragment CustomerFull on Customer {
    id
    name
    image
    imageUrl
    customerId
    phoneNumber
    phoneNumberExt
    email
    notes
    addressLine1
    addressLine2
    city
    state
    zip
    country
  }
`
export const DeliverableUnitBaseFragmentDoc = gql`
  fragment DeliverableUnitBase on DeliverableUnit {
    id
    description
    unitOfMeasure
    referenceNumber
  }
`
export const OrganizationBaseFragmentDoc = gql`
  fragment OrganizationBase on Organization {
    id
    name
    imageUrl
    dateCreated
    unassignedProjectId
    timezone
    archived
    requireTimeEntryEvidence
    overtimeType
  }
`
export const ProjectBaseFragmentDoc = gql`
  fragment ProjectBase on Project {
    id
    code
    name
    dateCreated
    organizationId
    unassignedTaskId
    isArchived
    isDefault
    isComplete
    startDate
    endDate
    description
    imageUrl
    customerId
  }
`
export const ProjectAdditionalFragmentDoc = gql`
  fragment ProjectAdditional on Project {
    location {
      lat
      lng
      radius
    }
    address {
      formattedAddress
      addressComponents {
        longName
        shortName
        types
      }
    }
    locationNotes
    googlePlace
    userCount
    metadata
    workersCompCode {
      id
      name
      ncciCode
    }
  }
`
export const RoleBaseFragmentDoc = gql`
  fragment RoleBase on Role {
    id
    name
    order
    description
    permissions
    widgets
  }
`
export const TaskBaseFragmentDoc = gql`
  fragment TaskBase on Task {
    id
    name
    projectId
    parentTaskId
  }
`
export const TaskAdditionalFragmentDoc = gql`
  fragment TaskAdditional on Task {
    dateCreated
    endDate
    startDate
    archived
    description
    estimatedHours
    hasReportableUnit
    isComplete
    isDefault
    metadata
    workersCompCode {
      id
      name
      ncciCode
      ncciDescription
      override
      rate
    }
  }
`
export const TaskSumsFragmentDoc = gql`
  fragment TaskSums on Task {
    timeEntriesSumDurationInSeconds
    unitGoalsSumTargetQuantity
    unitGoalsSumProgress
  }
`
export const TaskProgressEventBaseFragmentDoc = gql`
  fragment TaskProgressEventBase on TaskProgressEvent {
    id
    reporterId
    note
    imageUrls
  }
`
export const TaskProgressEventDatesFragmentDoc = gql`
  fragment TaskProgressEventDates on TaskProgressEvent {
    createdAt
    updatedAt
  }
`
export const TimeEntryBaseFragmentDoc = gql`
  fragment TimeEntryBase on TimeEntry {
    id
    userId
    taskId
    startAt
    endAt
    durationInSeconds
    evidence
  }
`
export const TimeEntryPhotoUrlsFragmentDoc = gql`
  fragment TimeEntryPhotoUrls on TimeEntry {
    signInPhotoUrl
    signOutPhotoUrl
  }
`
export const UnitGoalBaseFragmentDoc = gql`
  fragment UnitGoalBase on UnitGoal {
    id
    targetQuantity
    deliverableUnitId
  }
`
export const UnitGoalProgressBaseFragmentDoc = gql`
  fragment UnitGoalProgressBase on UnitGoalProgress {
    id
    unitGoalId
    progress
    createdAt
    updatedAt
  }
`
export const UserBaseFragmentDoc = gql`
  fragment UserBase on User {
    id
    organizationId
    projectId
    taskId
    email
    phoneNumber
    phoneNumberExt
    imageUrl
    firstName
    lastName
    jobTitle
    archived
  }
`
export const UserSimpleFragmentDoc = gql`
  fragment UserSimple on User {
    id
    projectId
    taskId
    firstName
    lastName
    jobTitle
    archived
  }
`
export const UserParentsFragmentDoc = gql`
  fragment UserParents on User {
    parents {
      id
      firstName
      lastName
      jobTitle
      email
    }
  }
`
export const UserChildrenFragmentDoc = gql`
  fragment UserChildren on User {
    children {
      id
      firstName
      lastName
      jobTitle
      email
    }
  }
`
export const AssetInspectionReportModalDocument = gql`
  query AssetInspectionReportModal($id: String!) {
    assetReportTemplate(id: $id) {
      id
      assetsCount
      createdAt
      deletedAt
      fields {
        id
        label
        photoRequired
        photoLabel
        rule
        failedStatus
        required
        type
      }
      name
      reusable
      universal
    }
  }
`

export function useAssetInspectionReportModalQuery(
  options: Omit<Urql.UseQueryArgs<AssetInspectionReportModalQueryVariables>, "query">
) {
  return Urql.useQuery<AssetInspectionReportModalQuery, AssetInspectionReportModalQueryVariables>({
    query: AssetInspectionReportModalDocument,
    ...options,
  })
}
export const ConfirmClockInModalBodyQueryDocument = gql`
  query ConfirmClockInModalBodyQuery($projectId: String!, $taskId: String!) {
    project(id: $projectId) {
      id
      name
    }
    task(id: $taskId) {
      id
      name
      parentTask {
        id
        name
      }
    }
  }
`

export function useConfirmClockInModalBodyQueryQuery(
  options: Omit<Urql.UseQueryArgs<ConfirmClockInModalBodyQueryQueryVariables>, "query">
) {
  return Urql.useQuery<ConfirmClockInModalBodyQueryQuery, ConfirmClockInModalBodyQueryQueryVariables>({
    query: ConfirmClockInModalBodyQueryDocument,
    ...options,
  })
}
export const CreateOrUpdateUserAssignmentFormDocument = gql`
  query CreateOrUpdateUserAssignmentForm($after: String, $first: Int) {
    activeProjects {
      id
      name
      tasks {
        id
        name
      }
    }
    users(after: $after, first: $first) {
      edges {
        node {
          id
          firstName
          lastName
          jobTitle
          currentProjectId
          currentTaskId
          projectId
          taskId
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export function useCreateOrUpdateUserAssignmentFormQuery(
  options?: Omit<Urql.UseQueryArgs<CreateOrUpdateUserAssignmentFormQueryVariables>, "query">
) {
  return Urql.useQuery<CreateOrUpdateUserAssignmentFormQuery, CreateOrUpdateUserAssignmentFormQueryVariables>({
    query: CreateOrUpdateUserAssignmentFormDocument,
    ...options,
  })
}
export const DeliverableUnitsListingTableDocument = gql`
  query DeliverableUnitsListingTable {
    deliverableUnits {
      id
      deletedAt
      description
      referenceNumber
      unitOfMeasure
      color
    }
  }
`

export function useDeliverableUnitsListingTableQuery(
  options?: Omit<Urql.UseQueryArgs<DeliverableUnitsListingTableQueryVariables>, "query">
) {
  return Urql.useQuery<DeliverableUnitsListingTableQuery, DeliverableUnitsListingTableQueryVariables>({
    query: DeliverableUnitsListingTableDocument,
    ...options,
  })
}
export const AssetWithAssigneeRowGetUserDocument = gql`
  query AssetWithAssigneeRowGetUser($id: String!) {
    user(id: $id) {
      firstName
      lastName
      jobTitle
    }
  }
`

export function useAssetWithAssigneeRowGetUserQuery(
  options: Omit<Urql.UseQueryArgs<AssetWithAssigneeRowGetUserQueryVariables>, "query">
) {
  return Urql.useQuery<AssetWithAssigneeRowGetUserQuery, AssetWithAssigneeRowGetUserQueryVariables>({
    query: AssetWithAssigneeRowGetUserDocument,
    ...options,
  })
}
export const UserWithTaskRowQueryDocument = gql`
  query UserWithTaskRowQuery($taskId: String!, $projectId: String!) {
    task(id: $taskId) {
      id
      name
    }
    project(id: $projectId) {
      id
      name
    }
  }
`

export function useUserWithTaskRowQueryQuery(
  options: Omit<Urql.UseQueryArgs<UserWithTaskRowQueryQueryVariables>, "query">
) {
  return Urql.useQuery<UserWithTaskRowQueryQuery, UserWithTaskRowQueryQueryVariables>({
    query: UserWithTaskRowQueryDocument,
    ...options,
  })
}
export const ProjectAndTasksMultiSelectDocument = gql`
  query ProjectAndTasksMultiSelect {
    projectsByStatus(status: active) {
      id
      name
      isDefault
      tasks {
        id
        name
      }
    }
  }
`

export function useProjectAndTasksMultiSelectQuery(
  options?: Omit<Urql.UseQueryArgs<ProjectAndTasksMultiSelectQueryVariables>, "query">
) {
  return Urql.useQuery<ProjectAndTasksMultiSelectQuery, ProjectAndTasksMultiSelectQueryVariables>({
    query: ProjectAndTasksMultiSelectDocument,
    ...options,
  })
}
export const TeamMembersMultiSelectListOrgProjectsDocument = gql`
  query TeamMembersMultiSelectListOrgProjects($status: ProjectStatus!) {
    projectsByStatus(status: $status) {
      id
      name
    }
  }
`

export function useTeamMembersMultiSelectListOrgProjectsQuery(
  options: Omit<Urql.UseQueryArgs<TeamMembersMultiSelectListOrgProjectsQueryVariables>, "query">
) {
  return Urql.useQuery<TeamMembersMultiSelectListOrgProjectsQuery, TeamMembersMultiSelectListOrgProjectsQueryVariables>(
    { query: TeamMembersMultiSelectListOrgProjectsDocument, ...options }
  )
}
export const UserSelectDocument = gql`
  query UserSelect($filter: UserFilter!, $first: Int, $after: String) {
    users(filter: $filter, first: $first, after: $after, sort: { by: projectName, direction: asc }) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          currentProjectId
          currentTaskId
          firstName
          lastName
          jobTitle
          imageUrl
          projectId
          task {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
    }
  }
`

export function useUserSelectQuery(options: Omit<Urql.UseQueryArgs<UserSelectQueryVariables>, "query">) {
  return Urql.useQuery<UserSelectQuery, UserSelectQueryVariables>({ query: UserSelectDocument, ...options })
}
export const WorkersCompCodeSelectDocument = gql`
  query WorkersCompCodeSelect {
    workersCompCodes {
      id
      name
      ncciCode
    }
  }
`

export function useWorkersCompCodeSelectQuery(
  options?: Omit<Urql.UseQueryArgs<WorkersCompCodeSelectQueryVariables>, "query">
) {
  return Urql.useQuery<WorkersCompCodeSelectQuery, WorkersCompCodeSelectQueryVariables>({
    query: WorkersCompCodeSelectDocument,
    ...options,
  })
}
export const LogoQueryDocument = gql`
  query LogoQuery {
    myOrganization {
      id
      name
      imageUrl
    }
  }
`

export function useLogoQueryQuery(options?: Omit<Urql.UseQueryArgs<LogoQueryQueryVariables>, "query">) {
  return Urql.useQuery<LogoQueryQuery, LogoQueryQueryVariables>({ query: LogoQueryDocument, ...options })
}
export const GetDeliverableUnitsQueryDocument = gql`
  query GetDeliverableUnitsQuery {
    deliverableUnits {
      id
      description
      unitOfMeasure
    }
  }
`

export function useGetDeliverableUnitsQueryQuery(
  options?: Omit<Urql.UseQueryArgs<GetDeliverableUnitsQueryQueryVariables>, "query">
) {
  return Urql.useQuery<GetDeliverableUnitsQueryQuery, GetDeliverableUnitsQueryQueryVariables>({
    query: GetDeliverableUnitsQueryDocument,
    ...options,
  })
}
export const AssetHistoryReportDocument = gql`
  query AssetHistoryReport($reportId: String!) {
    assetReport(reportId: $reportId) {
      id
      createdAt
      inspectionReport {
        id
        fileIds
        input
        label
        photoUrls
        photoRequired
        photoLabel
        type
      }
      inventoryReport {
        fileIds
        note
        photoUrls
      }
      notes
      statusChange {
        status
      }
      transferReport {
        from {
          assignableId
          assignableType
          task {
            id
            name
            project {
              id
              name
            }
          }
          user {
            id
            firstName
            imageUrl
            jobTitle
            lastName
            isClockedIn
          }
          asset {
            id
          }
        }
        to {
          assignableId
          assignableType
          task {
            id
            name
            project {
              id
              name
            }
          }
          user {
            id
            firstName
            imageUrl
            jobTitle
            lastName
            isClockedIn
          }
          asset {
            id
          }
        }
      }
      updatedAt
    }
  }
`

export function useAssetHistoryReportQuery(
  options: Omit<Urql.UseQueryArgs<AssetHistoryReportQueryVariables>, "query">
) {
  return Urql.useQuery<AssetHistoryReportQuery, AssetHistoryReportQueryVariables>({
    query: AssetHistoryReportDocument,
    ...options,
  })
}
export const AssetQuantityTableDocument = gql`
  query AssetQuantityTable($assetId: String!) {
    asset(id: $assetId) {
      id
      status
      assetChildCount
      assetGroupId
      assignableId
      companyAssetNumber
      compositeKey
      groupQuantity
      imageUrl
      isAssetGroup
      name
      ownershipType
      lastInventoriedAt
      inventoryRequirements {
        photoRequired
        intervalInSeconds
      }
    }
    assetGroups(assetGroupId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      assignedAssetId
      assignedTaskId
      assignedUserId
      count
      status
      assignedAsset {
        id
        assetChildCount
        assetGroupId
        assignableId
        companyAssetNumber
        compositeKey
        groupQuantity
        imageUrl
        isAssetGroup
        name
        status
        ownershipType
      }
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedUser {
        id
        firstName
        imageUrl
        jobTitle
        lastName
      }
      compositeKey
      groupParent {
        id
        active
        assetChildCount
        imageUrl
        isAssetGroup
        name
        companyAssetNumber
        compositeKey
        ownershipType
      }
    }
  }
`

export function useAssetQuantityTableQuery(
  options: Omit<Urql.UseQueryArgs<AssetQuantityTableQueryVariables>, "query">
) {
  return Urql.useQuery<AssetQuantityTableQuery, AssetQuantityTableQueryVariables>({
    query: AssetQuantityTableDocument,
    ...options,
  })
}
export const AssetQuickActionsDocument = gql`
  query AssetQuickActions($assetId: String!) {
    asset(id: $assetId) {
      id
      name
      active
      assetChildCount
      assetGroupId
      assignableId
      assignableType
      compositeKey
      companyAssetNumber
      isAssetGroup
      lastInventoriedAt
      ownershipType
      status
      inferredOwner {
        id
      }
      inferredProjectId
      inventoryRequirements {
        photoRequired
        intervalInSeconds
      }
      assignedUser {
        id
        firstName
        imageUrl
        isClockedIn
        jobTitle
        lastName
        currentProject {
          id
          name
        }
        currentTask {
          id
          name
        }
      }
      assignedAsset {
        id
        assignableId
        companyAssetNumber
        compositeKey
        name
      }
      assignedTask {
        id
        name
        project {
          id
          name
          imageUrl
          isArchived
          isComplete
        }
      }
      inspectionTemplatesAssignments {
        id
        intervalInSeconds
        lastReportedAt
        startDate
        inspectionTemplate {
          id
          name
        }
      }
    }
    assetGroups(assetGroupId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      assignedUserId
      count
      status
    }
  }
`

export function useAssetQuickActionsQuery(options: Omit<Urql.UseQueryArgs<AssetQuickActionsQueryVariables>, "query">) {
  return Urql.useQuery<AssetQuickActionsQuery, AssetQuickActionsQueryVariables>({
    query: AssetQuickActionsDocument,
    ...options,
  })
}
export const AssetQuickAddQueryDocument = gql`
  query AssetQuickAddQuery {
    assets(deleted: false) {
      id
      active
      assetChildCount
      assignableId
      assignableType
      assignedAssetId
      assignedUserId
      companyAssetNumber
      deletedAt
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
      manufacturer {
        id
      }
    }
    usersList(status: "active") {
      id
      currentProjectId
      currentTaskId
      firstName
      imageUrl
      jobTitle
      lastName
      projectId
      taskId
    }
    projectsByStatus(status: active) {
      id
      code
      name
      isArchived
      isComplete
      imageUrl
    }
  }
`

export function useAssetQuickAddQueryQuery(
  options?: Omit<Urql.UseQueryArgs<AssetQuickAddQueryQueryVariables>, "query">
) {
  return Urql.useQuery<AssetQuickAddQueryQuery, AssetQuickAddQueryQueryVariables>({
    query: AssetQuickAddQueryDocument,
    ...options,
  })
}
export const AssetQuickAddAssignableTaskDocument = gql`
  query AssetQuickAddAssignableTask($projectId: String!) {
    tasks(projectId: $projectId, status: active) {
      id
      name
    }
  }
`

export function useAssetQuickAddAssignableTaskQuery(
  options: Omit<Urql.UseQueryArgs<AssetQuickAddAssignableTaskQueryVariables>, "query">
) {
  return Urql.useQuery<AssetQuickAddAssignableTaskQuery, AssetQuickAddAssignableTaskQueryVariables>({
    query: AssetQuickAddAssignableTaskDocument,
    ...options,
  })
}
export const AssetReassignmentRowDocument = gql`
  query AssetReassignmentRow($assetId: String!) {
    asset(id: $assetId) {
      id
      isAssetGroup
      compositeKey
      childAssets {
        id
        assignableId
        assignableType
        assignedUserId
        active
        assetChildCount
        compositeKey
        imageUrl
        isAssetGroup
        name
        ownershipType
        status
      }
      childAssetGroups {
        assetGroupId
        assignableId
        assignableType
        status
        compositeKey
        count
        groupParent {
          id
          name
          imageUrl
          ownershipType
        }
      }
    }
  }
`

export function useAssetReassignmentRowQuery(
  options: Omit<Urql.UseQueryArgs<AssetReassignmentRowQueryVariables>, "query">
) {
  return Urql.useQuery<AssetReassignmentRowQuery, AssetReassignmentRowQueryVariables>({
    query: AssetReassignmentRowDocument,
    ...options,
  })
}
export const AssignedUserDisplayBadgeDocument = gql`
  query AssignedUserDisplayBadge($id: String!) {
    user(id: $id) {
      id
      firstName
      imageUrl
      jobTitle
      lastName
      archived
      isClockedIn
    }
  }
`

export function useAssignedUserDisplayBadgeQuery(
  options: Omit<Urql.UseQueryArgs<AssignedUserDisplayBadgeQueryVariables>, "query">
) {
  return Urql.useQuery<AssignedUserDisplayBadgeQuery, AssignedUserDisplayBadgeQueryVariables>({
    query: AssignedUserDisplayBadgeDocument,
    ...options,
  })
}
export const CreateOrEditAssetFormQueryDocument = gql`
  query CreateOrEditAssetFormQuery {
    assets(deleted: false) {
      id
      active
      assetChildCount
      assignableId
      assignableType
      assignedAssetId
      assignedUserId
      companyAssetNumber
      deletedAt
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
      manufacturer {
        id
      }
    }
    usersList(status: "active") {
      id
      currentProjectId
      currentTaskId
      firstName
      imageUrl
      jobTitle
      lastName
      projectId
      taskId
    }
    projectsByStatus(status: active) {
      id
      code
      name
      isArchived
      isComplete
      imageUrl
    }
  }
`

export function useCreateOrEditAssetFormQueryQuery(
  options?: Omit<Urql.UseQueryArgs<CreateOrEditAssetFormQueryQueryVariables>, "query">
) {
  return Urql.useQuery<CreateOrEditAssetFormQueryQuery, CreateOrEditAssetFormQueryQueryVariables>({
    query: CreateOrEditAssetFormQueryDocument,
    ...options,
  })
}
export const AssignableTaskQueryDocument = gql`
  query AssignableTaskQuery($projectId: String!) {
    taskList(projectId: $projectId, archived: false) {
      taskId
      taskGroupId
      name
    }
  }
`

export function useAssignableTaskQueryQuery(
  options: Omit<Urql.UseQueryArgs<AssignableTaskQueryQueryVariables>, "query">
) {
  return Urql.useQuery<AssignableTaskQueryQuery, AssignableTaskQueryQueryVariables>({
    query: AssignableTaskQueryDocument,
    ...options,
  })
}
export const AssetInspectionFormDocument = gql`
  query AssetInspectionForm {
    reusableAssetReportTemplates {
      id
      name
      universal
    }
  }
`

export function useAssetInspectionFormQuery(
  options?: Omit<Urql.UseQueryArgs<AssetInspectionFormQueryVariables>, "query">
) {
  return Urql.useQuery<AssetInspectionFormQuery, AssetInspectionFormQueryVariables>({
    query: AssetInspectionFormDocument,
    ...options,
  })
}
export const AssetDetailsPanelDocument = gql`
  query AssetDetailsPanel($assetId: String!) {
    assets(assetId: $assetId) {
      id
      active
      assetChildCount
      assetGroupId
      assignableId
      assignableType
      assignedAsset {
        id
        name
        imageUrl
      }
      childAssetGroups {
        assetGroupId
        assignableId
        assignableType
        compositeKey
        count
        groupParent {
          id
          assetChildCount
          assetGroupId
          assignableId
          assignableType
          companyAssetNumber
          imageUrl
          isAssetGroup
          name
          ownershipType
          status
        }
        status
      }
      assignedAssetId
      assignedTaskId
      assignedUserId
      companyAssetNumber
      compositeKey
      deletedAt
      groupQuantity
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
      inferredOwner {
        id
        currentProject {
          id
          name
        }
      }
    }
  }
`

export function useAssetDetailsPanelQuery(options: Omit<Urql.UseQueryArgs<AssetDetailsPanelQueryVariables>, "query">) {
  return Urql.useQuery<AssetDetailsPanelQuery, AssetDetailsPanelQueryVariables>({
    query: AssetDetailsPanelDocument,
    ...options,
  })
}
export const GroupsByAssetGroupIdDocument = gql`
  query GroupsByAssetGroupId($assetId: String!) {
    groupsByAssetGroupId: assetGroups(assetGroupId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      compositeKey
      status
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedAsset {
        id
        compositeKey
        name
        imageUrl
        assignedUser {
          id
          currentProject {
            id
            name
          }
        }
      }
      groupParent {
        id
        assetGroupId
        imageUrl
        name
        ownershipType
        isAssetGroup
      }
      count
      compositeKey
    }
  }
`

export function useGroupsByAssetGroupIdQuery(
  options: Omit<Urql.UseQueryArgs<GroupsByAssetGroupIdQueryVariables>, "query">
) {
  return Urql.useQuery<GroupsByAssetGroupIdQuery, GroupsByAssetGroupIdQueryVariables>({
    query: GroupsByAssetGroupIdDocument,
    ...options,
  })
}
export const GroupsByAssetIdDocument = gql`
  query GroupsByAssetId($assetId: String!) {
    assetGroups(assetId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      compositeKey
      status
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedAsset {
        id
        compositeKey
        name
        imageUrl
        assignedUser {
          id
          currentProject {
            id
            name
          }
        }
      }
      groupParent {
        id
        assetGroupId
        imageUrl
        name
        ownershipType
        isAssetGroup
      }
      count
      compositeKey
    }
  }
`

export function useGroupsByAssetIdQuery(options: Omit<Urql.UseQueryArgs<GroupsByAssetIdQueryVariables>, "query">) {
  return Urql.useQuery<GroupsByAssetIdQuery, GroupsByAssetIdQueryVariables>({
    query: GroupsByAssetIdDocument,
    ...options,
  })
}
export const AssetListDataGridDocument = gql`
  query AssetListDataGrid(
    $filter: AssetFilter!
    $sort: AssetSort!
    $after: String
    $visibilityFilter: ListVisibilityFilter
  ) {
    assets_2(filter: $filter, sort: $sort, after: $after, visibilityFilter: $visibilityFilter) {
      totalCount
      pageInfo {
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
          category
          assetChildCount
          assetGroupId
          assignableId
          assignableType
          assignmentsCount
          assignedAsset {
            id
            name
            compositeKey
          }
          assignedTask {
            id
            name
            projectId
            project {
              id
              name
            }
          }
          assignedTaskId
          assignedUser {
            id
            firstName
            lastName
            currentProject {
              id
              name
            }
            currentTask {
              id
              name
            }
          }
          assignedUserId
          companyAssetNumber
          compositeKey
          deletedAt
          groupQuantity
          imageUrl
          inferredOwner {
            id
            currentProject {
              id
              name
            }
            currentTask {
              id
              name
            }
          }
          inferredProjectId
          isAssetGroup
          status
          ownershipType
        }
      }
    }
  }
`

export function useAssetListDataGridQuery(options: Omit<Urql.UseQueryArgs<AssetListDataGridQueryVariables>, "query">) {
  return Urql.useQuery<AssetListDataGridQuery, AssetListDataGridQueryVariables>({
    query: AssetListDataGridDocument,
    ...options,
  })
}
export const AssignedAssetsQueryDocument = gql`
  query AssignedAssetsQuery($filter: AssignedAssetFilter!, $sort: AssetSort!) {
    assignedAssets(filter: $filter, sort: $sort) {
      id
      active
      category
      assetChildCount
      assetGroupId
      assignableId
      assignableType
      assignmentsCount
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedTaskId
      assignedUser {
        id
        archived
        firstName
        lastName
        currentProject {
          id
          name
        }
        currentTask {
          id
          name
        }
      }
      assignedUserId
      companyAssetNumber
      compositeKey
      deletedAt
      groupParentAsset {
        id
        name
        companyAssetNumber
      }
      imageUrl
      inferredOwner {
        id
        currentProject {
          id
          name
        }
        currentTask {
          id
          name
        }
      }
      name
      status
      ownershipType
    }
  }
`

export function useAssignedAssetsQueryQuery(
  options: Omit<Urql.UseQueryArgs<AssignedAssetsQueryQueryVariables>, "query">
) {
  return Urql.useQuery<AssignedAssetsQueryQuery, AssignedAssetsQueryQueryVariables>({
    query: AssignedAssetsQueryDocument,
    ...options,
  })
}
export const GetAllAssetsDocument = gql`
  query GetAllAssets {
    assets(deleted: false) {
      id
      name
      imageUrl
      companyAssetNumber
      assignableId
    }
  }
`

export function useGetAllAssetsQuery(options?: Omit<Urql.UseQueryArgs<GetAllAssetsQueryVariables>, "query">) {
  return Urql.useQuery<GetAllAssetsQuery, GetAllAssetsQueryVariables>({ query: GetAllAssetsDocument, ...options })
}
export const GetAllTasksDocument = gql`
  query GetAllTasks {
    projectsByStatus(status: active) {
      id
      name
      tasks {
        id
        name
      }
    }
  }
`

export function useGetAllTasksQuery(options?: Omit<Urql.UseQueryArgs<GetAllTasksQueryVariables>, "query">) {
  return Urql.useQuery<GetAllTasksQuery, GetAllTasksQueryVariables>({ query: GetAllTasksDocument, ...options })
}
export const QuantizedAssetQueryDocument = gql`
  query QuantizedAssetQuery($assetId: String!) {
    asset(id: $assetId) {
      id
      assetChildCount
      assetGroupId
      assignableId
      companyAssetNumber
      groupQuantity
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }
    assetGroups(assetGroupId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      assignedAssetId
      assignedTaskId
      assignedUserId
      count
      compositeKey
      status
      assignedAsset {
        id
        assetChildCount
        assetGroupId
        assignableId
        companyAssetNumber
        groupQuantity
        imageUrl
        isAssetGroup
        name
        ownershipType
      }
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedUser {
        id
        firstName
        fullName
        imageUrl
        jobTitle
        lastName
        currentProject {
          id
          name
        }
      }
      groupParent {
        id
        active
        assetChildCount
        imageUrl
        isAssetGroup
        name
        companyAssetNumber
        ownershipType
        status
      }
    }
  }
`

export function useQuantizedAssetQueryQuery(
  options: Omit<Urql.UseQueryArgs<QuantizedAssetQueryQueryVariables>, "query">
) {
  return Urql.useQuery<QuantizedAssetQueryQuery, QuantizedAssetQueryQueryVariables>({
    query: QuantizedAssetQueryDocument,
    ...options,
  })
}
export const ArchivedAssetGroupsDocument = gql`
  query ArchivedAssetGroups($assetId: String!) {
    archivedAssetGroups(assetId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      assignedAssetId
      assignedTaskId
      assignedUserId
      count
      compositeKey
      status
      assignedAsset {
        id
        assetChildCount
        assetGroupId
        assignableId
        companyAssetNumber
        groupQuantity
        imageUrl
        isAssetGroup
        name
        ownershipType
      }
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedUser {
        id
        firstName
        fullName
        imageUrl
        jobTitle
        lastName
        currentProject {
          id
          name
        }
      }
      groupParent {
        id
        active
        assetChildCount
        imageUrl
        isAssetGroup
        name
        companyAssetNumber
        ownershipType
        status
      }
    }
  }
`

export function useArchivedAssetGroupsQuery(
  options: Omit<Urql.UseQueryArgs<ArchivedAssetGroupsQueryVariables>, "query">
) {
  return Urql.useQuery<ArchivedAssetGroupsQuery, ArchivedAssetGroupsQueryVariables>({
    query: ArchivedAssetGroupsDocument,
    ...options,
  })
}
export const AssetDocumentsDocument = gql`
  query AssetDocuments($assetId: String!) {
    asset(id: $assetId) {
      id
      files {
        id
        fileName
        key
        contentType
        objectVersion
        assetId
        status
        expiresAt
        createdAt
        documentUrl
      }
    }
  }
`

export function useAssetDocumentsQuery(options: Omit<Urql.UseQueryArgs<AssetDocumentsQueryVariables>, "query">) {
  return Urql.useQuery<AssetDocumentsQuery, AssetDocumentsQueryVariables>({ query: AssetDocumentsDocument, ...options })
}
export const TransferredAssetDetailsDocument = gql`
  query TransferredAssetDetails($assetId: String!) {
    asset(id: $assetId) {
      id
      name
      assetGroupId
      companyAssetNumber
      isAssetGroup
      assetChildCount
      ownershipType
      groupQuantity
    }
  }
`

export function useTransferredAssetDetailsQuery(
  options: Omit<Urql.UseQueryArgs<TransferredAssetDetailsQueryVariables>, "query">
) {
  return Urql.useQuery<TransferredAssetDetailsQuery, TransferredAssetDetailsQueryVariables>({
    query: TransferredAssetDetailsDocument,
    ...options,
  })
}
export const CustomerListQueryDocument = gql`
  query CustomerListQuery {
    customers {
      id
      name
      imageUrl
      phoneNumber
      email
      customerId
      activeProjectCount
    }
  }
`

export function useCustomerListQueryQuery(options?: Omit<Urql.UseQueryArgs<CustomerListQueryQueryVariables>, "query">) {
  return Urql.useQuery<CustomerListQueryQuery, CustomerListQueryQueryVariables>({
    query: CustomerListQueryDocument,
    ...options,
  })
}
export const AssetDrawerDocumentDocument = gql`
  query assetDrawerDocument($id: String!) {
    asset(id: $id) {
      id
      name
      inferredProjectId
      inferredOwner {
        id
      }
    }
  }
`

export function useAssetDrawerDocumentQuery(
  options: Omit<Urql.UseQueryArgs<AssetDrawerDocumentQueryVariables>, "query">
) {
  return Urql.useQuery<AssetDrawerDocumentQuery, AssetDrawerDocumentQueryVariables>({
    query: AssetDrawerDocumentDocument,
    ...options,
  })
}
export const AssetReportTemplateDocument = gql`
  query AssetReportTemplate($id: String!) {
    assetReportTemplate(id: $id) {
      id
      assetsCount
      createdAt
      deletedAt
      fields {
        id
        label
        photoRequired
        photoLabel
        rule
        failedStatus
        required
        type
      }
      name
      reusable
      universal
    }
  }
`

export function useAssetReportTemplateQuery(
  options: Omit<Urql.UseQueryArgs<AssetReportTemplateQueryVariables>, "query">
) {
  return Urql.useQuery<AssetReportTemplateQuery, AssetReportTemplateQueryVariables>({
    query: AssetReportTemplateDocument,
    ...options,
  })
}
export const CustomerEditDrawerDocument = gql`
  query CustomerEditDrawer($id: String!) {
    customer(id: $id) {
      id
      name
      image
      imageUrl
      customerId
      phoneNumber
      phoneNumberExt
      email
      notes
      addressLine1
      addressLine2
      city
      state
      zip
      country
    }
  }
`

export function useCustomerEditDrawerQuery(
  options: Omit<Urql.UseQueryArgs<CustomerEditDrawerQueryVariables>, "query">
) {
  return Urql.useQuery<CustomerEditDrawerQuery, CustomerEditDrawerQueryVariables>({
    query: CustomerEditDrawerDocument,
    ...options,
  })
}
export const NestedAssetInspectionTemplatesDocument = gql`
  query NestedAssetInspectionTemplates($assetId: String!) {
    nestedAssets(id: $assetId) {
      id
      name
      inspectionTemplatesAssignments {
        id
        inspectionTemplate {
          id
          assetsCount
          createdAt
          fields {
            id
            label
            photoRequired
            photoLabel
            rule
            failedStatus
            required
            type
          }
        }
      }
    }
  }
`

export function useNestedAssetInspectionTemplatesQuery(
  options: Omit<Urql.UseQueryArgs<NestedAssetInspectionTemplatesQueryVariables>, "query">
) {
  return Urql.useQuery<NestedAssetInspectionTemplatesQuery, NestedAssetInspectionTemplatesQueryVariables>({
    query: NestedAssetInspectionTemplatesDocument,
    ...options,
  })
}
export const NestedAssetInventoryDocument = gql`
  query NestedAssetInventory($id: String!) {
    nestedAssetsForInventory(id: $id) {
      id
      assetChildCount
      assetGroupId
      companyAssetNumber
      depth
      imageUrl
      isAssetGroup
      lastInventoriedAt
      name
      ownershipType
      status
      inventoryRequirements {
        photoRequired
        intervalInSeconds
      }
    }
  }
`

export function useNestedAssetInventoryQuery(
  options: Omit<Urql.UseQueryArgs<NestedAssetInventoryQueryVariables>, "query">
) {
  return Urql.useQuery<NestedAssetInventoryQuery, NestedAssetInventoryQueryVariables>({
    query: NestedAssetInventoryDocument,
    ...options,
  })
}
export const AssetTransferQueryDocument = gql`
  query AssetTransferQuery($assetIds: [String!], $assetGroupIds: [String!]) {
    assets(deleted: false, assetIds: $assetIds) {
      id
      active
      assetChildCount
      assignableId
      assignableType
      assignedAssetId
      assignedUserId
      companyAssetNumber
      compositeKey
      deletedAt
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }
    assetsForAssetGroups: assets(assetGroupIds: $assetGroupIds) {
      id
      assetGroupId
      assignableId
      assignableType
      compositeKey
      status
    }
  }
`

export function useAssetTransferQueryQuery(
  options?: Omit<Urql.UseQueryArgs<AssetTransferQueryQueryVariables>, "query">
) {
  return Urql.useQuery<AssetTransferQueryQuery, AssetTransferQueryQueryVariables>({
    query: AssetTransferQueryDocument,
    ...options,
  })
}
export const ReassignAssetsSelectUsersTableDocument = gql`
  query ReassignAssetsSelectUsersTable {
    usersList(status: "active") {
      id
      currentProjectId
      currentTaskId
      firstName
      imageUrl
      jobTitle
      lastName
      projectId
      taskId
      isClockedIn
      project {
        id
        name
      }
      task {
        id
        name
      }
    }
  }
`

export function useReassignAssetsSelectUsersTableQuery(
  options?: Omit<Urql.UseQueryArgs<ReassignAssetsSelectUsersTableQueryVariables>, "query">
) {
  return Urql.useQuery<ReassignAssetsSelectUsersTableQuery, ReassignAssetsSelectUsersTableQueryVariables>({
    query: ReassignAssetsSelectUsersTableDocument,
    ...options,
  })
}
export const SelectAssetTableDocument = gql`
  query SelectAssetTable {
    assets(deleted: false) {
      id
      active
      assetChildCount
      assignableId
      assignableType
      assignedAssetId
      assignedUserId
      companyAssetNumber
      deletedAt
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }
  }
`

export function useSelectAssetTableQuery(options?: Omit<Urql.UseQueryArgs<SelectAssetTableQueryVariables>, "query">) {
  return Urql.useQuery<SelectAssetTableQuery, SelectAssetTableQueryVariables>({
    query: SelectAssetTableDocument,
    ...options,
  })
}
export const AssetProjectsDocument = gql`
  query AssetProjects {
    projectsByStatus(status: active) {
      id
      code
      name
      isArchived
      isComplete
      imageUrl
      tasks {
        id
        name
        groupId
        projectId
        sortOrder
      }
      taskGroups {
        id
        name
        projectId
        sortOrder
      }
    }
  }
`

export function useAssetProjectsQuery(options?: Omit<Urql.UseQueryArgs<AssetProjectsQueryVariables>, "query">) {
  return Urql.useQuery<AssetProjectsQuery, AssetProjectsQueryVariables>({ query: AssetProjectsDocument, ...options })
}
export const TaskDrawerQueryDocument = gql`
  query TaskDrawerQuery($id: String!) {
    task(id: $id) {
      id
      groupId
      group {
        id
        name
      }
      organizationId
      projectId
      archived
      dateCreated
      description
      endDate
      estimatedHours
      completedHours
      hasReportableUnit
      isComplete
      isDefault
      metadata
      name
      startDate
      workersCompCode {
        id
        name
        ncciCode
      }
      assignees {
        id
        isCurrentAssignment
        projectId
        taskId
        userId
        project {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
      project {
        id
        name
      }
      unitGoals {
        id
        deliverableUnitId
        targetQuantity
        isPrimary
        deliverableUnit {
          id
          description
          referenceNumber
          unitOfMeasure
        }
        unitGoalProgress {
          id
          progress
        }
      }
    }
  }
`

export function useTaskDrawerQueryQuery(options: Omit<Urql.UseQueryArgs<TaskDrawerQueryQueryVariables>, "query">) {
  return Urql.useQuery<TaskDrawerQueryQuery, TaskDrawerQueryQueryVariables>({
    query: TaskDrawerQueryDocument,
    ...options,
  })
}
export const TaskEditDrawerDocument = gql`
  query TaskEditDrawer($id: String!) {
    task(id: $id) {
      id
      name
      description
      groupId
      group {
        id
        name
      }
      projectId
      startDate
      endDate
      estimatedHours
      isDefault
      metadata
      project {
        id
        name
        tasksCount
        taskGroups {
          id
          name
          completedTaskCount
          taskCount
        }
      }
      scheduleTemplate {
        id
        isDefault
        workDays {
          label
          active
        }
        workHours {
          hours
          startTime
          endTime
        }
        nonWorkDays {
          id
          name
          dateRange
          active
        }
      }
      scheduledBreaks {
        id
        durationInMinutes
        isActive
        localizedStartTime
        name
        breakTaskId
        breakTask {
          id
          name
        }
      }
      unitGoals {
        id
        targetQuantity
        isPrimary
        deliverableUnitId
        deliverableUnit {
          id
          description
          referenceNumber
          unitOfMeasure
        }
        unitGoalProgress {
          id
        }
      }
    }
  }
`

export function useTaskEditDrawerQuery(options: Omit<Urql.UseQueryArgs<TaskEditDrawerQueryVariables>, "query">) {
  return Urql.useQuery<TaskEditDrawerQuery, TaskEditDrawerQueryVariables>({ query: TaskEditDrawerDocument, ...options })
}
export const TaskGroupEditDrawerDocument = gql`
  query TaskGroupEditDrawer($id: String!) {
    taskGroup(id: $id) {
      id
      completedTaskCount
      description
      name
      notes
      projectId
      taskCount
    }
  }
`

export function useTaskGroupEditDrawerQuery(
  options: Omit<Urql.UseQueryArgs<TaskGroupEditDrawerQueryVariables>, "query">
) {
  return Urql.useQuery<TaskGroupEditDrawerQuery, TaskGroupEditDrawerQueryVariables>({
    query: TaskGroupEditDrawerDocument,
    ...options,
  })
}
export const TaskReorderDrawerTaskGroupDocument = gql`
  query TaskReorderDrawerTaskGroup($groupId: String!) {
    tasksForGroupId(groupId: $groupId) {
      id
      name
      sortOrder
    }
  }
`

export function useTaskReorderDrawerTaskGroupQuery(
  options: Omit<Urql.UseQueryArgs<TaskReorderDrawerTaskGroupQueryVariables>, "query">
) {
  return Urql.useQuery<TaskReorderDrawerTaskGroupQuery, TaskReorderDrawerTaskGroupQueryVariables>({
    query: TaskReorderDrawerTaskGroupDocument,
    ...options,
  })
}
export const TaskReorderDrawerTasksDocument = gql`
  query TaskReorderDrawerTasks($projectId: String!) {
    taskList(projectId: $projectId, archived: false) {
      taskId
      groupId
      isComplete
      isDefault
      name
      sortOrder
      taskGroupId
    }
  }
`

export function useTaskReorderDrawerTasksQuery(
  options: Omit<Urql.UseQueryArgs<TaskReorderDrawerTasksQueryVariables>, "query">
) {
  return Urql.useQuery<TaskReorderDrawerTasksQuery, TaskReorderDrawerTasksQueryVariables>({
    query: TaskReorderDrawerTasksDocument,
    ...options,
  })
}
export const BulkUpdateTaskSortOrderDocument = gql`
  mutation BulkUpdateTaskSortOrder($updates: [BulkUpdateTaskSortOrderInput!]!) {
    bulkUpdateTaskSortOrder(updates: $updates) {
      id
      sortOrder
    }
  }
`

export function useBulkUpdateTaskSortOrderMutation() {
  return Urql.useMutation<BulkUpdateTaskSortOrderMutation, BulkUpdateTaskSortOrderMutationVariables>(
    BulkUpdateTaskSortOrderDocument
  )
}
export const UserDrawerDocumentDocument = gql`
  query UserDrawerDocument($id: String!) {
    user(id: $id) {
      id
      organizationId
      currentProjectId
    }
  }
`

export function useUserDrawerDocumentQuery(
  options: Omit<Urql.UseQueryArgs<UserDrawerDocumentQueryVariables>, "query">
) {
  return Urql.useQuery<UserDrawerDocumentQuery, UserDrawerDocumentQueryVariables>({
    query: UserDrawerDocumentDocument,
    ...options,
  })
}
export const UserInjuryReportsDocument = gql`
  query UserInjuryReports($id: String!) {
    user(id: $id) {
      id
      injuryReports {
        id
        createdAt
        notes
        reporter {
          id
          fullName
        }
        user {
          id
          fullName
        }
      }
    }
  }
`

export function useUserInjuryReportsQuery(options: Omit<Urql.UseQueryArgs<UserInjuryReportsQueryVariables>, "query">) {
  return Urql.useQuery<UserInjuryReportsQuery, UserInjuryReportsQueryVariables>({
    query: UserInjuryReportsDocument,
    ...options,
  })
}
export const ProjectInjuryReportsDocument = gql`
  query ProjectInjuryReports($id: String!) {
    project(id: $id) {
      id
      injuryReports {
        id
        createdAt
        notes
        reporter {
          id
          fullName
        }
        user {
          id
          fullName
        }
      }
    }
  }
`

export function useProjectInjuryReportsQuery(
  options: Omit<Urql.UseQueryArgs<ProjectInjuryReportsQueryVariables>, "query">
) {
  return Urql.useQuery<ProjectInjuryReportsQuery, ProjectInjuryReportsQueryVariables>({
    query: ProjectInjuryReportsDocument,
    ...options,
  })
}
export const TaskInjuryReportsDocument = gql`
  query TaskInjuryReports($id: String!) {
    task(id: $id) {
      id
      injuryReports {
        id
        createdAt
        notes
        reporter {
          id
          fullName
        }
        user {
          id
          fullName
        }
      }
    }
  }
`

export function useTaskInjuryReportsQuery(options: Omit<Urql.UseQueryArgs<TaskInjuryReportsQueryVariables>, "query">) {
  return Urql.useQuery<TaskInjuryReportsQuery, TaskInjuryReportsQueryVariables>({
    query: TaskInjuryReportsDocument,
    ...options,
  })
}
export const InspectionFailsQueryDocument = gql`
  query InspectionFailsQuery($assetId: String!) {
    assetReports(assetId: $assetId) {
      id
      createdAt
      updatedAt
      inspectionReport {
        id
        input
        label
        photoLabel
        type
      }
    }
  }
`

export function useInspectionFailsQueryQuery(
  options: Omit<Urql.UseQueryArgs<InspectionFailsQueryQueryVariables>, "query">
) {
  return Urql.useQuery<InspectionFailsQueryQuery, InspectionFailsQueryQueryVariables>({
    query: InspectionFailsQueryDocument,
    ...options,
  })
}
export const OrganizationBillingComponentDocument = gql`
  query OrganizationBillingComponent($id: String!, $rangeEnd: DateTime!, $rangeStart: DateTime!) {
    organization(id: $id) {
      id
      dateCreated
      activeUsersForDateRangeCount(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      billingPastDueAt
      stripeInvoices {
        id
        amountRemaining
        created
        livemode
        status
        total
      }
    }
  }
`

export function useOrganizationBillingComponentQuery(
  options: Omit<Urql.UseQueryArgs<OrganizationBillingComponentQueryVariables>, "query">
) {
  return Urql.useQuery<OrganizationBillingComponentQuery, OrganizationBillingComponentQueryVariables>({
    query: OrganizationBillingComponentDocument,
    ...options,
  })
}
export const OrganizationSettingsDocument = gql`
  query OrganizationSettings {
    scheduledBreaks(includeOrgBreaks: true) {
      id
      breakTask {
        id
        name
      }
      durationInMinutes
      isActive
      localizedStartTime
      name
    }
  }
`

export function useOrganizationSettingsQuery(
  options?: Omit<Urql.UseQueryArgs<OrganizationSettingsQueryVariables>, "query">
) {
  return Urql.useQuery<OrganizationSettingsQuery, OrganizationSettingsQueryVariables>({
    query: OrganizationSettingsDocument,
    ...options,
  })
}
export const OrgAssetReportTemplatesDocument = gql`
  query OrgAssetReportTemplates {
    reusableAssetReportTemplates {
      id
      name
      organizationId
      reusable
      universal
      assetsCount
    }
  }
`

export function useOrgAssetReportTemplatesQuery(
  options?: Omit<Urql.UseQueryArgs<OrgAssetReportTemplatesQueryVariables>, "query">
) {
  return Urql.useQuery<OrgAssetReportTemplatesQuery, OrgAssetReportTemplatesQueryVariables>({
    query: OrgAssetReportTemplatesDocument,
    ...options,
  })
}
export const MyOrganizationSettingsDocument = gql`
  query MyOrganizationSettings {
    myOrganization {
      id
      dateCreated
      imageUrl
      name
      requireTimeEntryEvidence
      overtimeType
      notificationSettings
    }
  }
`

export function useMyOrganizationSettingsQuery(
  options?: Omit<Urql.UseQueryArgs<MyOrganizationSettingsQueryVariables>, "query">
) {
  return Urql.useQuery<MyOrganizationSettingsQuery, MyOrganizationSettingsQueryVariables>({
    query: MyOrganizationSettingsDocument,
    ...options,
  })
}
export const OrganizationDeliverableUnitsDocument = gql`
  query OrganizationDeliverableUnits {
    myOrganization {
      id
      dateCreated
      name
      unitsOfMeasure {
        id
        name
        symbol
      }
    }
  }
`

export function useOrganizationDeliverableUnitsQuery(
  options?: Omit<Urql.UseQueryArgs<OrganizationDeliverableUnitsQueryVariables>, "query">
) {
  return Urql.useQuery<OrganizationDeliverableUnitsQuery, OrganizationDeliverableUnitsQueryVariables>({
    query: OrganizationDeliverableUnitsDocument,
    ...options,
  })
}
export const NonWorkDaysDocument = gql`
  query NonWorkDays {
    scheduleTemplates(isDefault: true) {
      id
      nonWorkDays {
        id
        name
        dateRange
        active
      }
    }
  }
`

export function useNonWorkDaysQuery(options?: Omit<Urql.UseQueryArgs<NonWorkDaysQueryVariables>, "query">) {
  return Urql.useQuery<NonWorkDaysQuery, NonWorkDaysQueryVariables>({ query: NonWorkDaysDocument, ...options })
}
export const OrgScheduleDocument = gql`
  query OrgSchedule {
    scheduleTemplates(isDefault: true) {
      id
      isDefault
      workDays {
        label
        active
      }
      workHours {
        hours
        startTime
        endTime
      }
      nonWorkDays {
        id
        name
        dateRange
        active
      }
    }
    scheduledBreaks(includeOrgBreaks: true) {
      id
      breakTaskId
      breakTask {
        id
        name
      }
      durationInMinutes
      isActive
      localizedStartTime
      name
    }
  }
`

export function useOrgScheduleQuery(options?: Omit<Urql.UseQueryArgs<OrgScheduleQueryVariables>, "query">) {
  return Urql.useQuery<OrgScheduleQuery, OrgScheduleQueryVariables>({ query: OrgScheduleDocument, ...options })
}
export const OrganizationDeliverableWorkersCompCodesDocument = gql`
  query OrganizationDeliverableWorkersCompCodes {
    myOrganization {
      id
      dateCreated
      name
    }
    workersCompCodes {
      id
      name
      ncciCode
      ncciDescription
      override
      rate
    }
  }
`

export function useOrganizationDeliverableWorkersCompCodesQuery(
  options?: Omit<Urql.UseQueryArgs<OrganizationDeliverableWorkersCompCodesQueryVariables>, "query">
) {
  return Urql.useQuery<
    OrganizationDeliverableWorkersCompCodesQuery,
    OrganizationDeliverableWorkersCompCodesQueryVariables
  >({ query: OrganizationDeliverableWorkersCompCodesDocument, ...options })
}
export const ProjectDocumentsDocument = gql`
  query ProjectDocuments($projectId: String!) {
    project(id: $projectId) {
      id
      name
      documents {
        id
        fileName
        key
        contentType
        objectVersion
        projectId
        status
        expiresAt
        createdAt
        documentUrl
      }
    }
  }
`

export function useProjectDocumentsQuery(options: Omit<Urql.UseQueryArgs<ProjectDocumentsQueryVariables>, "query">) {
  return Urql.useQuery<ProjectDocumentsQuery, ProjectDocumentsQueryVariables>({
    query: ProjectDocumentsDocument,
    ...options,
  })
}
export const ActivityUnitGoalDocument = gql`
  query ActivityUnitGoal($unitGoalId: String!) {
    unitGoal(id: $unitGoalId) {
      id
      deliverableUnit {
        id
        description
      }
    }
  }
`

export function useActivityUnitGoalQuery(options: Omit<Urql.UseQueryArgs<ActivityUnitGoalQueryVariables>, "query">) {
  return Urql.useQuery<ActivityUnitGoalQuery, ActivityUnitGoalQueryVariables>({
    query: ActivityUnitGoalDocument,
    ...options,
  })
}
export const EditHistoryEventModalDocument = gql`
  query EditHistoryEventModal($projectId: String!) {
    project(id: $projectId) {
      id
      editHistoryEvents {
        id
        data
        type
        modelId
        modelType
        createdAt
        project {
          id
          name
        }
        task {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
    }
    deliverableUnits {
      id
      description
    }
  }
`

export function useEditHistoryEventModalQuery(
  options: Omit<Urql.UseQueryArgs<EditHistoryEventModalQueryVariables>, "query">
) {
  return Urql.useQuery<EditHistoryEventModalQuery, EditHistoryEventModalQueryVariables>({
    query: EditHistoryEventModalDocument,
    ...options,
  })
}
export const ActiveProjectBannerDocument = gql`
  query ActiveProjectBanner($projectId: String!) {
    project(id: $projectId) {
      id
      activeTasksCount
      assetsCount
      isComplete
      isDefault
      name
      tasksCount
      unassignedTaskId
      unassignedTask {
        id
        name
        userCount
      }
    }
  }
`

export function useActiveProjectBannerQuery(
  options: Omit<Urql.UseQueryArgs<ActiveProjectBannerQueryVariables>, "query">
) {
  return Urql.useQuery<ActiveProjectBannerQuery, ActiveProjectBannerQueryVariables>({
    query: ActiveProjectBannerDocument,
    ...options,
  })
}
export const ProjectListDocument = gql`
  query ProjectList($filter: ProjectFilter!, $sort: ProjectSort!, $visibilityFilter: ListVisibilityFilter) {
    myOrganization {
      id
      imageUrl
      name
    }
    projects(filter: $filter, sort: $sort, visibilityFilter: $visibilityFilter) {
      id
      name
      code
      imageUrl
      isArchived
      isComplete
      isDefault
      organizationId
      dateCreated
      startDate
      endDate
      userCount
      assetsCount
      hasReportableUnit
      metadata
    }
  }
`

export function useProjectListQuery(options: Omit<Urql.UseQueryArgs<ProjectListQueryVariables>, "query">) {
  return Urql.useQuery<ProjectListQuery, ProjectListQueryVariables>({ query: ProjectListDocument, ...options })
}
export const ProjectQuickActionsDocument = gql`
  query ProjectQuickActions($projectId: String!) {
    project(id: $projectId) {
      id
      editHistoryEventsCount
      address {
        formattedAddress
      }
      location {
        lat
        lng
      }
    }
  }
`

export function useProjectQuickActionsQuery(
  options: Omit<Urql.UseQueryArgs<ProjectQuickActionsQueryVariables>, "query">
) {
  return Urql.useQuery<ProjectQuickActionsQuery, ProjectQuickActionsQueryVariables>({
    query: ProjectQuickActionsDocument,
    ...options,
  })
}
export const TaskScheduleDetailsQueryDocument = gql`
  query TaskScheduleDetailsQuery($id: String!) {
    task(id: $id) {
      id
      group {
        id
        name
      }
      scheduleTemplate {
        id
        isDefault
        workDays {
          label
          active
        }
        workHours {
          hours
          startTime
          endTime
        }
        nonWorkDays {
          id
          name
          dateRange
          active
        }
      }
      scheduledBreaks {
        id
        durationInMinutes
        isActive
        localizedStartTime
        name
        breakTaskId
        breakTask {
          id
          name
        }
      }
      startDate
      endDate
      estimatedHours
    }
  }
`

export function useTaskScheduleDetailsQueryQuery(
  options: Omit<Urql.UseQueryArgs<TaskScheduleDetailsQueryQueryVariables>, "query">
) {
  return Urql.useQuery<TaskScheduleDetailsQueryQuery, TaskScheduleDetailsQueryQueryVariables>({
    query: TaskScheduleDetailsQueryDocument,
    ...options,
  })
}
export const TaskExtraAssignmentTableDocument = gql`
  query TaskExtraAssignmentTable($taskId: String!) {
    task(id: $taskId) {
      id
      isComplete
      name
      projectId
      assignees {
        id
        isCurrentAssignment
        projectId
        taskId
        userId
        user {
          id
          firstName
          imageUrl
          isClockedIn
          jobTitle
          lastName
        }
      }
    }
  }
`

export function useTaskExtraAssignmentTableQuery(
  options: Omit<Urql.UseQueryArgs<TaskExtraAssignmentTableQueryVariables>, "query">
) {
  return Urql.useQuery<TaskExtraAssignmentTableQuery, TaskExtraAssignmentTableQueryVariables>({
    query: TaskExtraAssignmentTableDocument,
    ...options,
  })
}
export const GetDetailsForTaskGroupDocument = gql`
  query GetDetailsForTaskGroup($groupId: String!, $projectId: String!) {
    taskGroup(id: $groupId) {
      id
      name
      notes
      description
      completedTaskCount
      assetCount
      organizationId
      unitProgress
      unitTargetGoal
      isComplete
      taskCount
      updatedAt
      userCount
      projectId
    }
    tasksForGroupId(groupId: $groupId) {
      id
      groupId
      name
      projectId
      assetCount
      hasReportableUnit
      completedHours
      isComplete
      isDefault
      isParentTask
      subtaskCount
      userCount
    }
    project(id: $projectId) {
      id
      name
      isComplete
      isArchived
    }
  }
`

export function useGetDetailsForTaskGroupQuery(
  options: Omit<Urql.UseQueryArgs<GetDetailsForTaskGroupQueryVariables>, "query">
) {
  return Urql.useQuery<GetDetailsForTaskGroupQuery, GetDetailsForTaskGroupQueryVariables>({
    query: GetDetailsForTaskGroupDocument,
    ...options,
  })
}
export const ConvertTaskToSubTaskFormDocument = gql`
  query ConvertTaskToSubTaskForm($projectId: String!) {
    tasks(projectId: $projectId) {
      id
      isDefault
      isComplete
      name
      parentTaskId
      project {
        id
        isArchived
        name
      }
    }
  }
`

export function useConvertTaskToSubTaskFormQuery(
  options: Omit<Urql.UseQueryArgs<ConvertTaskToSubTaskFormQueryVariables>, "query">
) {
  return Urql.useQuery<ConvertTaskToSubTaskFormQuery, ConvertTaskToSubTaskFormQueryVariables>({
    query: ConvertTaskToSubTaskFormDocument,
    ...options,
  })
}
export const TaskGroupHoursDocument = gql`
  query TaskGroupHours($groupId: String!) {
    taskGroup(id: $groupId) {
      id
      startDate
      endDate
      unitProgress
      unitTargetGoal
      completedHours
      estimatedHours
      taskCount
      completedTaskCount
    }
  }
`

export function useTaskGroupHoursQuery(options: Omit<Urql.UseQueryArgs<TaskGroupHoursQueryVariables>, "query">) {
  return Urql.useQuery<TaskGroupHoursQuery, TaskGroupHoursQueryVariables>({ query: TaskGroupHoursDocument, ...options })
}
export const TaskUnitProgressDocument = gql`
  query TaskUnitProgress($taskId: String!) {
    task(id: $taskId) {
      id
      unitGoals {
        id
        isPrimary
        targetQuantity
        totalProgress
        deliverableUnit {
          id
          description
        }
      }
    }
  }
`

export function useTaskUnitProgressQuery(options: Omit<Urql.UseQueryArgs<TaskUnitProgressQueryVariables>, "query">) {
  return Urql.useQuery<TaskUnitProgressQuery, TaskUnitProgressQueryVariables>({
    query: TaskUnitProgressDocument,
    ...options,
  })
}
export const TaskAssetsListDocument = gql`
  query TaskAssetsList($filter: AssignedAssetFilter!, $sort: AssetSort!) {
    assignedAssets(filter: $filter, sort: $sort) {
      id
      active
      assetChildCount
      assetGroupId
      assignableId
      assignableType
      companyAssetNumber
      imageUrl
      isAssetGroup
      name
      ownershipType
      groupParentAsset {
        id
        name
        companyAssetNumber
      }
    }
  }
`

export function useTaskAssetsListQuery(options: Omit<Urql.UseQueryArgs<TaskAssetsListQueryVariables>, "query">) {
  return Urql.useQuery<TaskAssetsListQuery, TaskAssetsListQueryVariables>({ query: TaskAssetsListDocument, ...options })
}
export const ProjectTasksListDocument = gql`
  query ProjectTasksList($projectId: String!, $searchText: String, $archived: Boolean!) {
    project(id: $projectId) {
      id
      unassignedTaskId
      name
      isArchived
      isComplete
    }
    taskList(projectId: $projectId, searchText: $searchText, archived: $archived) {
      taskId
      taskGroupId
      groupId
      sortOrder
      completedHours
      createdAt
      endDate
      estimatedHours
      isComplete
      isDefault
      name
      startDate
      unitProgress
      unitTargetGoal
      assetCount
      completedTaskCount
      taskCount
      userCount
    }
  }
`

export function useProjectTasksListQuery(options: Omit<Urql.UseQueryArgs<ProjectTasksListQueryVariables>, "query">) {
  return Urql.useQuery<ProjectTasksListQuery, ProjectTasksListQueryVariables>({
    query: ProjectTasksListDocument,
    ...options,
  })
}
export const UpdateTaskSortOrderDocument = gql`
  mutation UpdateTaskSortOrder($type: String!, $taskId: String!, $sortOrder: Int!) {
    updateTaskSortOrder(type: $type, taskId: $taskId, sortOrder: $sortOrder) {
      id
      sortOrder
    }
  }
`

export function useUpdateTaskSortOrderMutation() {
  return Urql.useMutation<UpdateTaskSortOrderMutation, UpdateTaskSortOrderMutationVariables>(
    UpdateTaskSortOrderDocument
  )
}
export const TaskTeamListDocument = gql`
  query TaskTeamList($filter: UserFilter!) {
    users(filter: $filter, first: 200) {
      edges {
        node {
          id
          firstName
          lastName
          jobTitle
          imageUrl
          isClockedIn
          currentProjectId
        }
      }
    }
  }
`

export function useTaskTeamListQuery(options: Omit<Urql.UseQueryArgs<TaskTeamListQueryVariables>, "query">) {
  return Urql.useQuery<TaskTeamListQuery, TaskTeamListQueryVariables>({ query: TaskTeamListDocument, ...options })
}
export const UsersFormDocument = gql`
  query UsersForm($myProjectId: String!) {
    projectsByStatus(status: active) {
      id
      name
      unassignedTaskId
      tasks {
        id
        name
        isComplete
        projectId
      }
    }
    project(id: $myProjectId) {
      id
      unassignedTaskId
    }
  }
`

export function useUsersFormQuery(options: Omit<Urql.UseQueryArgs<UsersFormQueryVariables>, "query">) {
  return Urql.useQuery<UsersFormQuery, UsersFormQueryVariables>({ query: UsersFormDocument, ...options })
}
export const AssignUserFormDocument = gql`
  query AssignUserForm($projectId: String!) {
    project(id: $projectId) {
      id
      isArchived
      tasks {
        id
        isComplete
        isDefault
        name
        subtasks {
          id
          isComplete
          isDefault
          name
        }
      }
    }
  }
`

export function useAssignUserFormQuery(options: Omit<Urql.UseQueryArgs<AssignUserFormQueryVariables>, "query">) {
  return Urql.useQuery<AssignUserFormQuery, AssignUserFormQueryVariables>({ query: AssignUserFormDocument, ...options })
}
export const TeamAssignmentsDocument = gql`
  query TeamAssignments($filter: UserFilter!, $projectId: String!) {
    users(filter: $filter, first: 200) {
      edges {
        node {
          id
          archived
          currentProjectId
          currentTaskId
          firstName
          imageUrl
          isClockedIn
          jobTitle
          lastName
          organizationId
          latestTimeEntry {
            id
            endAt
            startAt
            evidence
            taskId
            userId
            durationInSeconds
          }
          projectId
          project {
            id
            name
          }
          secondsClockedSinceOrgMidnight
          taskId
          task {
            id
            name
          }
        }
      }
    }
    project(id: $projectId) {
      id
      isComplete
      tasks {
        id
        projectId
        subtasks {
          id
        }
      }
    }
  }
`

export function useTeamAssignmentsQuery(options: Omit<Urql.UseQueryArgs<TeamAssignmentsQueryVariables>, "query">) {
  return Urql.useQuery<TeamAssignmentsQuery, TeamAssignmentsQueryVariables>({
    query: TeamAssignmentsDocument,
    ...options,
  })
}
export const CreateOrEditScheduledBreakDocument = gql`
  query CreateOrEditScheduledBreak {
    breakTasks {
      id
      name
      isUnpaid
    }
  }
`

export function useCreateOrEditScheduledBreakQuery(
  options?: Omit<Urql.UseQueryArgs<CreateOrEditScheduledBreakQueryVariables>, "query">
) {
  return Urql.useQuery<CreateOrEditScheduledBreakQuery, CreateOrEditScheduledBreakQueryVariables>({
    query: CreateOrEditScheduledBreakDocument,
    ...options,
  })
}
export const TaskOrProjectSummaryImageSectionDocument = gql`
  query TaskOrProjectSummaryImageSection(
    $projectId: String!
    $taskId: String
    $rangeEnd: DateTime!
    $rangeStart: DateTime!
    $filter: TaskProgressEventFilter
  ) {
    taskProgressEvents(
      projectId: $projectId
      taskId: $taskId
      rangeEnd: $rangeEnd
      rangeStart: $rangeStart
      filter: $filter
    ) {
      id
      imageUrls
      createdAt
      reporter {
        id
        fullName
      }
      task {
        id
        name
      }
    }
  }
`

export function useTaskOrProjectSummaryImageSectionQuery(
  options: Omit<Urql.UseQueryArgs<TaskOrProjectSummaryImageSectionQueryVariables>, "query">
) {
  return Urql.useQuery<TaskOrProjectSummaryImageSectionQuery, TaskOrProjectSummaryImageSectionQueryVariables>({
    query: TaskOrProjectSummaryImageSectionDocument,
    ...options,
  })
}
export const TaskOrProjectSummaryNotesSectionDocument = gql`
  query TaskOrProjectSummaryNotesSection(
    $projectId: String!
    $taskId: String
    $rangeEnd: DateTime!
    $rangeStart: DateTime!
    $filter: TaskProgressEventFilter
  ) {
    taskProgressEvents(
      projectId: $projectId
      taskId: $taskId
      rangeEnd: $rangeEnd
      rangeStart: $rangeStart
      filter: $filter
    ) {
      id
      note
      createdAt
      reporter {
        id
        fullName
      }
      task {
        id
        name
      }
    }
  }
`

export function useTaskOrProjectSummaryNotesSectionQuery(
  options: Omit<Urql.UseQueryArgs<TaskOrProjectSummaryNotesSectionQueryVariables>, "query">
) {
  return Urql.useQuery<TaskOrProjectSummaryNotesSectionQuery, TaskOrProjectSummaryNotesSectionQueryVariables>({
    query: TaskOrProjectSummaryNotesSectionDocument,
    ...options,
  })
}
export const TaskOrProjectTeamTableDocument = gql`
  query TaskOrProjectTeamTable($projectId: String!, $taskId: String, $rangeEnd: DateTime!, $rangeStart: DateTime!) {
    usersForSummarySection(taskId: $taskId, projectId: $projectId, rangeEnd: $rangeEnd, rangeStart: $rangeStart) {
      id
      currentProjectId
      firstName
      imageUrl
      jobTitle
      lastName
      isClockedIn
      secondsClockedInDateRangeOnProjectOrTask(rangeEnd: $rangeEnd, rangeStart: $rangeStart, taskId: $taskId)
      reportedUnitsInDateRangeByProjectOrTask(rangeEnd: $rangeEnd, rangeStart: $rangeStart, taskId: $taskId) {
        id
        unitGoalProgressReports {
          id
          progress
        }
      }
    }
  }
`

export function useTaskOrProjectTeamTableQuery(
  options: Omit<Urql.UseQueryArgs<TaskOrProjectTeamTableQueryVariables>, "query">
) {
  return Urql.useQuery<TaskOrProjectTeamTableQuery, TaskOrProjectTeamTableQueryVariables>({
    query: TaskOrProjectTeamTableDocument,
    ...options,
  })
}
export const TimeLineProgressEventsDocument = gql`
  query TimeLineProgressEvents($taskId: String, $projectId: String!, $rangeStart: DateTime, $rangeEnd: DateTime) {
    taskProgressEvents(taskId: $taskId, projectId: $projectId, rangeEnd: $rangeEnd, rangeStart: $rangeStart) {
      id
      imageUrls
      note
      task {
        id
        name
      }
      reporter {
        id
        firstName
        lastName
        jobTitle
        imageUrl
      }
      updatedAt
      unitGoalProgressReports {
        id
        progress
        unitGoalId
      }
    }
    unitGoals(taskId: $taskId, projectId: $projectId) {
      id
      deliverableUnit {
        id
        description
        unitOfMeasure
      }
    }
  }
`

export function useTimeLineProgressEventsQuery(
  options: Omit<Urql.UseQueryArgs<TimeLineProgressEventsQueryVariables>, "query">
) {
  return Urql.useQuery<TimeLineProgressEventsQuery, TimeLineProgressEventsQueryVariables>({
    query: TimeLineProgressEventsDocument,
    ...options,
  })
}
export const ProjectUnitsTableProgressDocument = gql`
  query ProjectUnitsTableProgress($rangeStart: DateTime!, $rangeEnd: DateTime!, $entityId: String!) {
    project(id: $entityId) {
      id
      name
      startDate
      lastExport
      unitGoals {
        id
        isPrimary
        targetQuantity
        totalProgress
        previousProgress(rangeStart: $rangeStart)
        progressInDateRange(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
        task {
          id
          name
          estimatedHours
          timeEntriesSumDurationInSeconds(rangeStart: $rangeStart)
        }
        deliverableUnit {
          id
          description
          unitOfMeasure
          color
        }
      }
    }
  }
`

export function useProjectUnitsTableProgressQuery(
  options: Omit<Urql.UseQueryArgs<ProjectUnitsTableProgressQueryVariables>, "query">
) {
  return Urql.useQuery<ProjectUnitsTableProgressQuery, ProjectUnitsTableProgressQueryVariables>({
    query: ProjectUnitsTableProgressDocument,
    ...options,
  })
}
export const TaskUnitsTableProgressDocument = gql`
  query TaskUnitsTableProgress($rangeStart: DateTime!, $rangeEnd: DateTime!, $entityId: String!) {
    task(id: $entityId) {
      id
      name
      startDate
      lastExport
      unitGoals {
        id
        isPrimary
        targetQuantity
        totalProgress
        previousProgress(rangeStart: $rangeStart)
        progressInDateRange(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
        deliverableUnit {
          id
          description
          unitOfMeasure
          color
        }
      }
      estimatedHours
      timeEntriesSumDurationInSeconds
    }
  }
`

export function useTaskUnitsTableProgressQuery(
  options: Omit<Urql.UseQueryArgs<TaskUnitsTableProgressQueryVariables>, "query">
) {
  return Urql.useQuery<TaskUnitsTableProgressQuery, TaskUnitsTableProgressQueryVariables>({
    query: TaskUnitsTableProgressDocument,
    ...options,
  })
}
export const ProjectSummaryAssetTableDocument = gql`
  query ProjectSummaryAssetTable($projectId: String!, $rangeEnd: DateTime!, $rangeStart: DateTime!) {
    project(id: $projectId) {
      id
      assets(rangeEnd: $rangeEnd, rangeStart: $rangeStart) {
        id
        name
        assetChildCount
        assetGroupId
        companyAssetNumber
        imageUrl
        ownershipType
        isAssetGroup
        assignedTask {
          id
          name
          projectId
        }
      }
    }
  }
`

export function useProjectSummaryAssetTableQuery(
  options: Omit<Urql.UseQueryArgs<ProjectSummaryAssetTableQueryVariables>, "query">
) {
  return Urql.useQuery<ProjectSummaryAssetTableQuery, ProjectSummaryAssetTableQueryVariables>({
    query: ProjectSummaryAssetTableDocument,
    ...options,
  })
}
export const ProjectSummaryTaskTableDocument = gql`
  query ProjectSummaryTaskTable($projectId: String!, $rangeEnd: DateTime!, $rangeStart: DateTime!) {
    project(id: $projectId) {
      id
      tasks(includeCompleted: true, includeSubtasks: false) {
        id
        completedHours(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        estimatedHours
        isComplete
        productionRate(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        name
        assetCount(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        usersWhoClockedOrReportedCount(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        primaryUnitGoals {
          id
          targetQuantity
          progressInDateRange(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
          deliverableUnit {
            id
            description
            unitOfMeasure
          }
        }
      }
      taskGroups {
        id
        completedHours
        isComplete
        name
        assetCount(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        productionRate(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        userCount(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        taskCount
      }
    }
  }
`

export function useProjectSummaryTaskTableQuery(
  options: Omit<Urql.UseQueryArgs<ProjectSummaryTaskTableQueryVariables>, "query">
) {
  return Urql.useQuery<ProjectSummaryTaskTableQuery, ProjectSummaryTaskTableQueryVariables>({
    query: ProjectSummaryTaskTableDocument,
    ...options,
  })
}
export const TimeEntryActivityDocument = gql`
  query TimeEntryActivity($rangeStart: DateTime, $rangeEnd: DateTime, $projectId: String!, $userId: String!) {
    user(id: $userId) {
      id
      timeEntries(rangeEnd: $rangeEnd, rangeStart: $rangeStart, projectId: $projectId) {
        id
        projectId
        taskId
        durationInSeconds
        endAt
        startAt
        task {
          id
          name
          isComplete
        }
        project {
          id
          name
        }
      }
    }
  }
`

export function useTimeEntryActivityQuery(options: Omit<Urql.UseQueryArgs<TimeEntryActivityQueryVariables>, "query">) {
  return Urql.useQuery<TimeEntryActivityQuery, TimeEntryActivityQueryVariables>({
    query: TimeEntryActivityDocument,
    ...options,
  })
}
export const ReportedUnitsInDateRangeDocument = gql`
  query ReportedUnitsInDateRange($rangeStart: DateTime, $rangeEnd: DateTime, $projectId: String!, $userId: String!) {
    user(id: $userId) {
      reportedUnitsInDateRangeByProjectOrTask(projectId: $projectId, rangeStart: $rangeStart, rangeEnd: $rangeEnd) {
        id
        taskId
        task {
          name
          id
          isComplete
        }
        createdAt
        imageUrls
        note
        unitGoalProgressReports {
          id
          progress
          unitGoal {
            id
            deliverableUnit {
              id
              description
              unitOfMeasure
            }
          }
        }
      }
    }
  }
`

export function useReportedUnitsInDateRangeQuery(
  options: Omit<Urql.UseQueryArgs<ReportedUnitsInDateRangeQueryVariables>, "query">
) {
  return Urql.useQuery<ReportedUnitsInDateRangeQuery, ReportedUnitsInDateRangeQueryVariables>({
    query: ReportedUnitsInDateRangeDocument,
    ...options,
  })
}
export const ProjectUnitsGraphProgressDocument = gql`
  query ProjectUnitsGraphProgress(
    $dateRangeType: String!
    $rangeStart: DateTime!
    $rangeEnd: DateTime!
    $entityId: String!
  ) {
    project(id: $entityId) {
      id
      hoursDataByDateRangeType(dateRangeType: $dateRangeType, rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      startDate
      endDate
      taskProgressEventsWithUnitReports(rangeEnd: $rangeEnd, rangeStart: $rangeStart) {
        id
        createdAt
        unitGoalProgressReports {
          id
          progress
          unitGoal {
            id
            deliverableUnit {
              description
              color
            }
          }
        }
      }
    }
  }
`

export function useProjectUnitsGraphProgressQuery(
  options: Omit<Urql.UseQueryArgs<ProjectUnitsGraphProgressQueryVariables>, "query">
) {
  return Urql.useQuery<ProjectUnitsGraphProgressQuery, ProjectUnitsGraphProgressQueryVariables>({
    query: ProjectUnitsGraphProgressDocument,
    ...options,
  })
}
export const TaskUnitsGraphProgressDocument = gql`
  query TaskUnitsGraphProgress(
    $dateRangeType: String!
    $rangeStart: DateTime!
    $rangeEnd: DateTime!
    $entityId: String!
  ) {
    task(id: $entityId) {
      id
      hoursDataByDateRangeType(dateRangeType: $dateRangeType, rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      startDate
      endDate
      taskProgressEventsWithUnitReports(rangeEnd: $rangeEnd, rangeStart: $rangeStart) {
        id
        createdAt
        unitGoalProgressReports {
          id
          progress
          unitGoal {
            id
            deliverableUnit {
              description
              color
            }
          }
        }
      }
    }
  }
`

export function useTaskUnitsGraphProgressQuery(
  options: Omit<Urql.UseQueryArgs<TaskUnitsGraphProgressQueryVariables>, "query">
) {
  return Urql.useQuery<TaskUnitsGraphProgressQuery, TaskUnitsGraphProgressQueryVariables>({
    query: TaskUnitsGraphProgressDocument,
    ...options,
  })
}
export const AddOrEditGoalModalDocument = gql`
  query AddOrEditGoalModal {
    deliverableUnits {
      id
      description
      unitOfMeasure
    }
  }
`

export function useAddOrEditGoalModalQuery(
  options?: Omit<Urql.UseQueryArgs<AddOrEditGoalModalQueryVariables>, "query">
) {
  return Urql.useQuery<AddOrEditGoalModalQuery, AddOrEditGoalModalQueryVariables>({
    query: AddOrEditGoalModalDocument,
    ...options,
  })
}
export const TaskCompletionAssetReassignmentTargetsDocument = gql`
  query TaskCompletionAssetReassignmentTargets {
    usersList(status: "active") {
      id
      firstName
      lastName
    }
    projectsByStatus(status: active) {
      id
      name
      tasks {
        id
        name
      }
    }
    allAssets: assets {
      id
      name
    }
  }
`

export function useTaskCompletionAssetReassignmentTargetsQuery(
  options?: Omit<Urql.UseQueryArgs<TaskCompletionAssetReassignmentTargetsQueryVariables>, "query">
) {
  return Urql.useQuery<
    TaskCompletionAssetReassignmentTargetsQuery,
    TaskCompletionAssetReassignmentTargetsQueryVariables
  >({ query: TaskCompletionAssetReassignmentTargetsDocument, ...options })
}
export const CompleteTaskReassignAssetsDocument = gql`
  query CompleteTaskReassignAssets($taskId: String!) {
    taskAssets: assets(taskId: $taskId) {
      id
      name
      assignableId
      imageUrl
      companyAssetNumber
    }
  }
`

export function useCompleteTaskReassignAssetsQuery(
  options: Omit<Urql.UseQueryArgs<CompleteTaskReassignAssetsQueryVariables>, "query">
) {
  return Urql.useQuery<CompleteTaskReassignAssetsQuery, CompleteTaskReassignAssetsQueryVariables>({
    query: CompleteTaskReassignAssetsDocument,
    ...options,
  })
}
export const CompleteTaskReassignUsersDocument = gql`
  query CompleteTaskReassignUsers($taskId: String!) {
    usersList(status: "active", taskId: $taskId) {
      id
      currentProjectId
      currentTaskId
      firstName
      imageUrl
      isClockedIn
      jobTitle
      lastName
      projectId
      taskId
    }
    projectsByStatus(status: active) {
      id
      name
      imageUrl
      tasks {
        id
        name
      }
    }
  }
`

export function useCompleteTaskReassignUsersQuery(
  options: Omit<Urql.UseQueryArgs<CompleteTaskReassignUsersQueryVariables>, "query">
) {
  return Urql.useQuery<CompleteTaskReassignUsersQuery, CompleteTaskReassignUsersQueryVariables>({
    query: CompleteTaskReassignUsersDocument,
    ...options,
  })
}
export const GetTasksGroupsAndUnitsDocument = gql`
  query GetTasksGroupsAndUnits($projectId: String!) {
    tasks(projectId: $projectId) {
      id
      name
      projectId
      archived
      dateCreated
      startDate
      endDate
      estimatedHours
      isDefault
      isComplete
      description
      hasReportableUnit
    }
    taskGroups(projectId: $projectId) {
      assetCount
      completedTaskCount
      createdAt
      description
      id
      name
      notes
      organizationId
      unitProgress
      unitTargetGoal
      taskCount
      updatedAt
      userCount
    }
    deliverableUnits {
      id
      description
      unitOfMeasure
      referenceNumber
      createdAt
      deletedAt
    }
  }
`

export function useGetTasksGroupsAndUnitsQuery(
  options: Omit<Urql.UseQueryArgs<GetTasksGroupsAndUnitsQueryVariables>, "query">
) {
  return Urql.useQuery<GetTasksGroupsAndUnitsQuery, GetTasksGroupsAndUnitsQueryVariables>({
    query: GetTasksGroupsAndUnitsDocument,
    ...options,
  })
}
export const TaskQuickActionUserCountDocument = gql`
  query TaskQuickActionUserCount($taskId: String!) {
    task(id: $taskId) {
      id
      isComplete
      clockedInUserCount
      clockedOutUserCount
      project {
        id
        address {
          formattedAddress
        }
        location {
          lat
          lng
        }
      }
      userCount
      users {
        id
        isClockedIn
        projectId
      }
    }
  }
`

export function useTaskQuickActionUserCountQuery(
  options: Omit<Urql.UseQueryArgs<TaskQuickActionUserCountQueryVariables>, "query">
) {
  return Urql.useQuery<TaskQuickActionUserCountQuery, TaskQuickActionUserCountQueryVariables>({
    query: TaskQuickActionUserCountDocument,
    ...options,
  })
}
export const BulkClockInUsersDocument = gql`
  query BulkClockInUsers($first: Int, $filter: UserFilter!) {
    users(first: $first, filter: $filter) {
      totalCount
      edges {
        node {
          id
          firstName
          lastName
          jobTitle
          imageUrl
          organizationId
          currentTaskId
          currentProjectId
          latestTimeEntry {
            id
            taskId
            endAt
            startAt
            evidence
          }
        }
      }
    }
  }
`

export function useBulkClockInUsersQuery(options: Omit<Urql.UseQueryArgs<BulkClockInUsersQueryVariables>, "query">) {
  return Urql.useQuery<BulkClockInUsersQuery, BulkClockInUsersQueryVariables>({
    query: BulkClockInUsersDocument,
    ...options,
  })
}
export const BulkClockOutUsersDocument = gql`
  query BulkClockOutUsers($first: Int, $filter: UserFilter!) {
    users(first: $first, filter: $filter) {
      totalCount
      edges {
        node {
          id
          firstName
          lastName
          jobTitle
          imageUrl
          latestTimeEntry {
            id
            taskId
            endAt
            startAt
            evidence
          }
        }
      }
    }
  }
`

export function useBulkClockOutUsersQuery(options: Omit<Urql.UseQueryArgs<BulkClockOutUsersQueryVariables>, "query">) {
  return Urql.useQuery<BulkClockOutUsersQuery, BulkClockOutUsersQueryVariables>({
    query: BulkClockOutUsersDocument,
    ...options,
  })
}
export const ParentAssignmentAndControlQueryDocument = gql`
  query ParentAssignmentAndControlQuery {
    users {
      edges {
        node {
          id
          currentProjectId
          currentTaskId
          firstName
          jobTitle
          lastName
          projectId
          taskId
          childAncestorNodes {
            userId
            depth
          }
          parents {
            id
            firstName
            lastName
            jobTitle
          }
        }
      }
    }
  }
`

export function useParentAssignmentAndControlQueryQuery(
  options?: Omit<Urql.UseQueryArgs<ParentAssignmentAndControlQueryQueryVariables>, "query">
) {
  return Urql.useQuery<ParentAssignmentAndControlQueryQuery, ParentAssignmentAndControlQueryQueryVariables>({
    query: ParentAssignmentAndControlQueryDocument,
    ...options,
  })
}
export const TeamListDocument = gql`
  query TeamList(
    $first: Int
    $after: String
    $filter: UserFilter!
    $sort: UserSort!
    $visibilityFilter: ListVisibilityFilter
  ) {
    users(first: $first, after: $after, filter: $filter, sort: $sort, visibilityFilter: $visibilityFilter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          organizationId
          currentProjectId
          currentTaskId
          projectId
          taskId
          invitationPending
          archived
          firstName
          imageUrl
          jobTitle
          lastName
          isClockedIn
          secondsClockedSinceOrgMidnight
          task {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
    }
  }
`

export function useTeamListQuery(options: Omit<Urql.UseQueryArgs<TeamListQueryVariables>, "query">) {
  return Urql.useQuery<TeamListQuery, TeamListQueryVariables>({ query: TeamListDocument, ...options })
}
export const CreateUserFormDocument = gql`
  query CreateUserForm {
    myOrganization {
      id
      roles {
        id
        name
        description
      }
    }
    getJobTitles
  }
`

export function useCreateUserFormQuery(options?: Omit<Urql.UseQueryArgs<CreateUserFormQueryVariables>, "query">) {
  return Urql.useQuery<CreateUserFormQuery, CreateUserFormQueryVariables>({ query: CreateUserFormDocument, ...options })
}
export const UserDocumentsDocument = gql`
  query UserDocuments($userId: String!) {
    user(id: $userId) {
      id
      myFiles {
        id
        fileName
        key
        contentType
        objectVersion
        userId
        status
        expiresAt
        createdAt
        documentUrl
      }
    }
  }
`

export function useUserDocumentsQuery(options: Omit<Urql.UseQueryArgs<UserDocumentsQueryVariables>, "query">) {
  return Urql.useQuery<UserDocumentsQuery, UserDocumentsQueryVariables>({ query: UserDocumentsDocument, ...options })
}
export const EditUserFormQueryDocument = gql`
  query EditUserFormQuery($id: String!, $fetchPay: Boolean!) {
    myOrganization {
      id
      roles {
        id
        name
        description
      }
    }
    getJobTitles
    user(id: $id) {
      id
      currentTaskId
      currentProjectId
      workersCompCodeId
      email
      firstName
      imageUrl
      jobTitle
      lastName
      phoneNumber
      phoneNumberExt
      companyUserId
      roles {
        id
        name
      }
      task {
        id
        name
      }
      payRate @include(if: $fetchPay)
      payType @include(if: $fetchPay)
    }
    usersList(status: "active") {
      id
      companyUserId
    }
  }
`

export function useEditUserFormQueryQuery(options: Omit<Urql.UseQueryArgs<EditUserFormQueryQueryVariables>, "query">) {
  return Urql.useQuery<EditUserFormQueryQuery, EditUserFormQueryQueryVariables>({
    query: EditUserFormQueryDocument,
    ...options,
  })
}
export const ReassignUserTaskFormDocument = gql`
  mutation ReassignUserTaskForm($assignments: [UserAssignmentAssignment!]!) {
    reassignUsers(assignments: $assignments) {
      id
      imageUrl
      currentProjectId
      currentTaskId
      projectId
      project {
        id
        name
      }
      taskId
      task {
        id
        name
      }
    }
  }
`

export function useReassignUserTaskFormMutation() {
  return Urql.useMutation<ReassignUserTaskFormMutation, ReassignUserTaskFormMutationVariables>(
    ReassignUserTaskFormDocument
  )
}
export const UserTimeCardsDocument = gql`
  query UserTimeCards($userId: String!) {
    user(id: $userId) {
      id
      firstName
      lastName
      currentProjectId
      archived
      project {
        id
        name
      }
      currentTaskId
      task {
        id
        name
      }
      timeEntries {
        id
        durationInSeconds
        endAt
        signInPhotoUrl
        signOutPhotoUrl
        projectId
        taskId
        startAt
        project {
          id
          isDefault
          name
        }
        task {
          id
          parentTaskId
          visibilityLevel
          name
          parentTask {
            id
            name
          }
        }
      }
    }
  }
`

export function useUserTimeCardsQuery(options: Omit<Urql.UseQueryArgs<UserTimeCardsQueryVariables>, "query">) {
  return Urql.useQuery<UserTimeCardsQuery, UserTimeCardsQueryVariables>({ query: UserTimeCardsDocument, ...options })
}
export const GetTasksForProjectReportingDocument = gql`
  query GetTasksForProjectReporting($projectId: String!) {
    tasks(projectId: $projectId, status: active) {
      id
      name
      isDefault
      group {
        id
        name
      }
      unitGoals {
        id
        targetQuantity
        totalProgress
        isPrimary
        deliverableUnit {
          id
          description
          unitOfMeasure
        }
      }
    }
  }
`

export function useGetTasksForProjectReportingQuery(
  options: Omit<Urql.UseQueryArgs<GetTasksForProjectReportingQueryVariables>, "query">
) {
  return Urql.useQuery<GetTasksForProjectReportingQuery, GetTasksForProjectReportingQueryVariables>({
    query: GetTasksForProjectReportingDocument,
    ...options,
  })
}
export const GetOrgRolesDocument = gql`
  query getOrgRoles {
    getJobTitles
  }
`

export function useGetOrgRolesQuery(options?: Omit<Urql.UseQueryArgs<GetOrgRolesQueryVariables>, "query">) {
  return Urql.useQuery<GetOrgRolesQuery, GetOrgRolesQueryVariables>({ query: GetOrgRolesDocument, ...options })
}
export const GetOrgScheduleForProjectDocument = gql`
  query GetOrgScheduleForProject {
    scheduleTemplates(isDefault: true) {
      id
      isDefault
      workDays {
        label
        active
      }
      workHours {
        hours
        startTime
        endTime
      }
      nonWorkDays {
        id
        name
        dateRange
        active
      }
    }
  }
`

export function useGetOrgScheduleForProjectQuery(
  options?: Omit<Urql.UseQueryArgs<GetOrgScheduleForProjectQueryVariables>, "query">
) {
  return Urql.useQuery<GetOrgScheduleForProjectQuery, GetOrgScheduleForProjectQueryVariables>({
    query: GetOrgScheduleForProjectDocument,
    ...options,
  })
}
export const DeliverableUnitsDocument = gql`
  query DeliverableUnits {
    deliverableUnits {
      id
      description
      unitOfMeasure
    }
  }
`

export function useDeliverableUnitsQuery(options?: Omit<Urql.UseQueryArgs<DeliverableUnitsQueryVariables>, "query">) {
  return Urql.useQuery<DeliverableUnitsQuery, DeliverableUnitsQueryVariables>({
    query: DeliverableUnitsDocument,
    ...options,
  })
}
export const TransferAssetModalGetProjectTasksDocument = gql`
  query TransferAssetModalGetProjectTasks($projectId: String!) {
    project(id: $projectId) {
      id
      tasks {
        id
        name
      }
    }
    assets(projectId: $projectId, includeGroupedAssets: false) {
      id
      assignableId
      assignableType
      assignedAssetId
      assignedTaskId
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedUserId
      assignedUser {
        id
        firstName
        imageUrl
        jobTitle
        lastName
      }
      active
      assetChildCount
      companyAssetNumber
      compositeKey
      deletedAt
      groupQuantity
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }
    assetGroups(projectId: $projectId) {
      assetGroupId
      assignableId
      assignableType
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      compositeKey
      count
      groupParent {
        id
        name
        imageUrl
        ownershipType
      }
      status
    }
  }
`

export function useTransferAssetModalGetProjectTasksQuery(
  options: Omit<Urql.UseQueryArgs<TransferAssetModalGetProjectTasksQueryVariables>, "query">
) {
  return Urql.useQuery<TransferAssetModalGetProjectTasksQuery, TransferAssetModalGetProjectTasksQueryVariables>({
    query: TransferAssetModalGetProjectTasksDocument,
    ...options,
  })
}
export const TransferAssetToProjectUserDocument = gql`
  query TransferAssetToProjectUser($projectId: String!) {
    project(id: $projectId) {
      id
      users {
        id
        currentProjectId
        currentTaskId
        firstName
        imageUrl
        jobTitle
        lastName
        projectId
        taskId
      }
    }
    allAssets: assets(deleted: false) {
      id
      name
      imageUrl
      companyAssetNumber
      assignableId
    }
    projectAssets: assets(projectId: $projectId) {
      id
    }
  }
`

export function useTransferAssetToProjectUserQuery(
  options: Omit<Urql.UseQueryArgs<TransferAssetToProjectUserQueryVariables>, "query">
) {
  return Urql.useQuery<TransferAssetToProjectUserQuery, TransferAssetToProjectUserQueryVariables>({
    query: TransferAssetToProjectUserDocument,
    ...options,
  })
}
export const TransferAssetToTaskUserQueryDocument = gql`
  query TransferAssetToTaskUserQuery($taskId: String!, $filter: UserFilter!) {
    allAssets: assets {
      id
      name
      imageUrl
      companyAssetNumber
      assignableId
    }
    taskAssets: assets(taskId: $taskId) {
      id
    }
    users(filter: $filter) {
      edges {
        node {
          id
          currentProjectId
          currentTaskId
          firstName
          lastName
          jobTitle
          taskId
          projectId
        }
      }
    }
  }
`

export function useTransferAssetToTaskUserQueryQuery(
  options: Omit<Urql.UseQueryArgs<TransferAssetToTaskUserQueryQueryVariables>, "query">
) {
  return Urql.useQuery<TransferAssetToTaskUserQueryQuery, TransferAssetToTaskUserQueryQueryVariables>({
    query: TransferAssetToTaskUserQueryDocument,
    ...options,
  })
}
export const TransferAssetToUserQueryDocument = gql`
  query TransferAssetToUserQuery {
    assets(deleted: false) {
      id
      active
      assetChildCount
      assignableId
      assignableType
      assignedAssetId
      assignedUserId
      companyAssetNumber
      deletedAt
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }
    users {
      edges {
        node {
          id
          currentProjectId
          currentTaskId
          firstName
          imageUrl
          jobTitle
          lastName
          projectId
          project {
            id
            name
          }
          task {
            id
            name
          }
        }
      }
    }
  }
`

export function useTransferAssetToUserQueryQuery(
  options?: Omit<Urql.UseQueryArgs<TransferAssetToUserQueryQueryVariables>, "query">
) {
  return Urql.useQuery<TransferAssetToUserQueryQuery, TransferAssetToUserQueryQueryVariables>({
    query: TransferAssetToUserQueryDocument,
    ...options,
  })
}
export const UnitInputDocument = gql`
  query UnitInput {
    deliverableUnits {
      id
      description
      unitOfMeasure
    }
  }
`

export function useUnitInputQuery(options?: Omit<Urql.UseQueryArgs<UnitInputQueryVariables>, "query">) {
  return Urql.useQuery<UnitInputQuery, UnitInputQueryVariables>({ query: UnitInputDocument, ...options })
}
export const GetTaskForReportingDocument = gql`
  query GetTaskForReporting($taskId: String!) {
    task(id: $taskId) {
      id
      name
      isDefault
      projectId
      group {
        id
        name
      }
      unitGoals {
        id
        isPrimary
        targetQuantity
        totalProgress
        deliverableUnit {
          id
          description
          unitOfMeasure
        }
      }
    }
  }
`

export function useGetTaskForReportingQuery(
  options: Omit<Urql.UseQueryArgs<GetTaskForReportingQueryVariables>, "query">
) {
  return Urql.useQuery<GetTaskForReportingQuery, GetTaskForReportingQueryVariables>({
    query: GetTaskForReportingDocument,
    ...options,
  })
}
export const ActiveProjectsWidget2Document = gql`
  query ActiveProjectsWidget2 {
    myOrganization {
      id
      activeProjectsCount
      imageUrl
      name
    }
    projectsByStatus(status: active, limit: 3, includeDefault: false) {
      id
      assetsCount
      code
      endDate
      hasReportableUnit
      imageUrl
      isArchived
      isComplete
      isDefault
      name
      startDate
      userCount
    }
  }
`

export function useActiveProjectsWidget2Query(
  options?: Omit<Urql.UseQueryArgs<ActiveProjectsWidget2QueryVariables>, "query">
) {
  return Urql.useQuery<ActiveProjectsWidget2Query, ActiveProjectsWidget2QueryVariables>({
    query: ActiveProjectsWidget2Document,
    ...options,
  })
}
export const CurrentAssignmentDocument = gql`
  query CurrentAssignment {
    currentUser {
      id
      currentProject {
        id
        isArchived
        isComplete
        imageUrl
        location {
          lat
          lng
          radius
        }
        name
        code
      }
      currentProjectId
      currentTask {
        id
        name
        projectId
      }
      currentTaskId
      firstName
      imageUrl
      jobTitle
      lastName
      projectId
      taskId
      organization {
        id
        imageUrl
        name
      }
    }
  }
`

export function useCurrentAssignmentQuery(options?: Omit<Urql.UseQueryArgs<CurrentAssignmentQueryVariables>, "query">) {
  return Urql.useQuery<CurrentAssignmentQuery, CurrentAssignmentQueryVariables>({
    query: CurrentAssignmentDocument,
    ...options,
  })
}
export const TimeClockWidgetDocument = gql`
  query TimeClockWidget {
    currentUser {
      id
      projectId
      taskId
      archived
      firstName
      isClockedIn
      isOnBreak
      lastName
      secondsClockedSinceOrgMidnight
      secondsClockedThisWeek
      latestTimeEntry {
        id
        endAt
        startAt
      }
    }
  }
`

export function useTimeClockWidgetQuery(options?: Omit<Urql.UseQueryArgs<TimeClockWidgetQueryVariables>, "query">) {
  return Urql.useQuery<TimeClockWidgetQuery, TimeClockWidgetQueryVariables>({
    query: TimeClockWidgetDocument,
    ...options,
  })
}
export const AssetsLinkWidgetQueryDocument = gql`
  query AssetsLinkWidgetQuery {
    assets(deleted: false) {
      id
      name
    }
  }
`

export function useAssetsLinkWidgetQueryQuery(
  options?: Omit<Urql.UseQueryArgs<AssetsLinkWidgetQueryQueryVariables>, "query">
) {
  return Urql.useQuery<AssetsLinkWidgetQueryQuery, AssetsLinkWidgetQueryQueryVariables>({
    query: AssetsLinkWidgetQueryDocument,
    ...options,
  })
}
export const ProjectsLinkWidgetDocument = gql`
  query ProjectsLinkWidget {
    myOrganization {
      id
      activeProjectsCount
    }
  }
`

export function useProjectsLinkWidgetQuery(
  options?: Omit<Urql.UseQueryArgs<ProjectsLinkWidgetQueryVariables>, "query">
) {
  return Urql.useQuery<ProjectsLinkWidgetQuery, ProjectsLinkWidgetQueryVariables>({
    query: ProjectsLinkWidgetDocument,
    ...options,
  })
}
export const GetMyOrgUserCountDocument = gql`
  query GetMyOrgUserCount {
    myOrganization {
      id
      userCount
    }
  }
`

export function useGetMyOrgUserCountQuery(options?: Omit<Urql.UseQueryArgs<GetMyOrgUserCountQueryVariables>, "query">) {
  return Urql.useQuery<GetMyOrgUserCountQuery, GetMyOrgUserCountQueryVariables>({
    query: GetMyOrgUserCountDocument,
    ...options,
  })
}
export const UserAssetsListDocument = gql`
  query UserAssetsList($userId: String!) {
    assets(deleted: false, userId: $userId, includeGroupedAssets: false) {
      id
      active
      assetChildCount
      assetGroupId
      assignableId
      assignableType
      companyAssetNumber
      compositeKey
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }
    assetGroups(userId: $userId) {
      assetGroupId
      assignableId
      assignableType
      compositeKey
      count
      status
      groupParent {
        id
        name
        imageUrl
        ownershipType
      }
    }
  }
`

export function useUserAssetsListQuery(options: Omit<Urql.UseQueryArgs<UserAssetsListQueryVariables>, "query">) {
  return Urql.useQuery<UserAssetsListQuery, UserAssetsListQueryVariables>({ query: UserAssetsListDocument, ...options })
}
export const ProjectProductionRateWidgetDocument = gql`
  query ProjectProductionRateWidget($projectId: String!, $rangeStart: DateTime!, $rangeEnd: DateTime!) {
    project(id: $projectId) {
      id
      productionRate(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
      deliveredPrimaryGoalUnits(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
      estimatedHours
      primaryGoalTargetQuantity
    }
  }
`

export function useProjectProductionRateWidgetQuery(
  options: Omit<Urql.UseQueryArgs<ProjectProductionRateWidgetQueryVariables>, "query">
) {
  return Urql.useQuery<ProjectProductionRateWidgetQuery, ProjectProductionRateWidgetQueryVariables>({
    query: ProjectProductionRateWidgetDocument,
    ...options,
  })
}
export const SummaryWidgetsTaskWidgetGroupDocument = gql`
  query SummaryWidgetsTaskWidgetGroup($taskId: String!, $rangeEnd: DateTime!, $rangeStart: DateTime!) {
    task(id: $taskId) {
      id
      projectId
      name
      estimatedHours
      completedHours
      previousUnits: sumOfDeliveredUnits(rangeEnd: $rangeStart)
      previousClockedSeconds: timeEntriesSumDurationInSeconds(rangeEnd: $rangeStart)
      totalUnitsInRange: sumOfDeliveredUnits(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      sumOfDeliveredUnits
      totalClockedSeconds: timeEntriesSumDurationInSeconds(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      usersWhoClockedOrReportedCount(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      unitGoals {
        id
        targetQuantity
      }
    }
  }
`

export function useSummaryWidgetsTaskWidgetGroupQuery(
  options: Omit<Urql.UseQueryArgs<SummaryWidgetsTaskWidgetGroupQueryVariables>, "query">
) {
  return Urql.useQuery<SummaryWidgetsTaskWidgetGroupQuery, SummaryWidgetsTaskWidgetGroupQueryVariables>({
    query: SummaryWidgetsTaskWidgetGroupDocument,
    ...options,
  })
}
export const TimeCardExportFormDocument = gql`
  query TimeCardExportForm {
    usersList {
      id
      firstName
      lastName
    }
  }
`

export function useTimeCardExportFormQuery(
  options?: Omit<Urql.UseQueryArgs<TimeCardExportFormQueryVariables>, "query">
) {
  return Urql.useQuery<TimeCardExportFormQuery, TimeCardExportFormQueryVariables>({
    query: TimeCardExportFormDocument,
    ...options,
  })
}
export const ActiveProjectsCountWidgetDocument = gql`
  query ActiveProjectsCountWidget {
    myOrganization {
      id
      activeProjectsCount
    }
  }
`

export function useActiveProjectsCountWidgetQuery(
  options?: Omit<Urql.UseQueryArgs<ActiveProjectsCountWidgetQueryVariables>, "query">
) {
  return Urql.useQuery<ActiveProjectsCountWidgetQuery, ActiveProjectsCountWidgetQueryVariables>({
    query: ActiveProjectsCountWidgetDocument,
    ...options,
  })
}
export const ClockedInCountWidgetDocument = gql`
  query ClockedInCountWidget {
    myOrganization {
      id
      clockedInUserCount
      userCount
    }
  }
`

export function useClockedInCountWidgetQuery(
  options?: Omit<Urql.UseQueryArgs<ClockedInCountWidgetQueryVariables>, "query">
) {
  return Urql.useQuery<ClockedInCountWidgetQuery, ClockedInCountWidgetQueryVariables>({
    query: ClockedInCountWidgetDocument,
    ...options,
  })
}
export const WeeklyHoursClockedWidgetDocument = gql`
  query WeeklyHoursClockedWidget {
    myOrganization {
      id
      secondsClockedForCurrentWeek
    }
  }
`

export function useWeeklyHoursClockedWidgetQuery(
  options?: Omit<Urql.UseQueryArgs<WeeklyHoursClockedWidgetQueryVariables>, "query">
) {
  return Urql.useQuery<WeeklyHoursClockedWidgetQuery, WeeklyHoursClockedWidgetQueryVariables>({
    query: WeeklyHoursClockedWidgetDocument,
    ...options,
  })
}
export const AssetEditDocument = gql`
  mutation AssetEdit(
    $id: String!
    $active: Boolean
    $companyAssetNumber: String
    $inventoryRequirements: AssetInventoryRequirementsInput
    $manufacturer: AssetManufacturerInput
    $name: String
    $ownershipType: String
    $photoId: String
    $purchaseDetails: AssetPurchaseDetailsInput
    $rentalAgreement: AssetRentalAgreementInput
    $reportTemplateIds: [String!]
    $status: AssetStatus
    $vendorContact: AssetVendorContactInput
    $inspectionRequirements: [AssetInspectionRequirementsInput!]
    $category: AssetCategory
  ) {
    updateOneAsset(
      id: $id
      active: $active
      companyAssetNumber: $companyAssetNumber
      inventoryRequirements: $inventoryRequirements
      manufacturer: $manufacturer
      name: $name
      ownershipType: $ownershipType
      photoId: $photoId
      purchaseDetails: $purchaseDetails
      rentalAgreement: $rentalAgreement
      reportTemplateIds: $reportTemplateIds
      status: $status
      vendorContact: $vendorContact
      inspectionRequirements: $inspectionRequirements
      category: $category
    ) {
      id
      active
      activeChangedAt
      assetChildCount
      assetGroupId
      assignableId
      assignableType
      assignedUserId
      companyAssetNumber
      deletedAt
      groupQuantity
      imageUrl
      isAssetGroup
      lastInventoriedAt
      name
      organizationId
      ownershipType
      status
      category
      inventoryRequirements {
        intervalInSeconds
        photoRequired
      }
      manufacturer {
        id
        make
        name
        year
        model
      }
      purchaseDetails {
        price
        date
      }
      rentalAgreement {
        rate {
          daily
          weekly
          monthly
        }
        endOn
        startOn
      }
      vendorContact {
        name
        email
        phone
      }
      inspectionTemplatesAssignments {
        id
        assetId
        intervalInSeconds
        lastReportedAt
        startDate
        inspectionTemplate {
          id
          name
        }
      }
    }
  }
`

export function useAssetEditMutation() {
  return Urql.useMutation<AssetEditMutation, AssetEditMutationVariables>(AssetEditDocument)
}
export const AssetCreateDocument = gql`
  mutation AssetCreate(
    $name: String!
    $assignableId: String!
    $assignableType: AssetAssignableType!
    $companyAssetNumber: String
    $ownershipType: String!
    $photoId: String
    $isAssetGroup: Boolean
    $groupQuantity: Int
    $purchaseDetails: AssetPurchaseDetailsInput
    $rentalAgreement: AssetRentalAgreementInput
    $manufacturer: AssetManufacturerInput
    $vendorContact: AssetVendorContactInput
    $inspectionRequirements: [AssetInspectionRequirementsInput!]
    $inventoryRequirements: AssetInventoryRequirementsInput
    $category: AssetCategory
  ) {
    insertOneAsset(
      name: $name
      assignableId: $assignableId
      assignableType: $assignableType
      companyAssetNumber: $companyAssetNumber
      ownershipType: $ownershipType
      photoId: $photoId
      isAssetGroup: $isAssetGroup
      groupQuantity: $groupQuantity
      purchaseDetails: $purchaseDetails
      rentalAgreement: $rentalAgreement
      manufacturer: $manufacturer
      vendorContact: $vendorContact
      inspectionRequirements: $inspectionRequirements
      inventoryRequirements: $inventoryRequirements
      category: $category
    ) {
      id
      ...AssetBase
      ...AssetAdditional
      ...AssetInventory
      inspectionTemplatesAssignments {
        id
        assetId
        intervalInSeconds
        lastReportedAt
        startDate
      }
      category
    }
  }
  ${AssetBaseFragmentDoc}
  ${AssetAdditionalFragmentDoc}
  ${AssetInventoryFragmentDoc}
`

export function useAssetCreateMutation() {
  return Urql.useMutation<AssetCreateMutation, AssetCreateMutationVariables>(AssetCreateDocument)
}
export const TransferAssetsDocument = gql`
  mutation TransferAssets(
    $assetIds: [String!]!
    $assignableId: String!
    $assignableType: AssetAssignableType!
    $projectIdIfTask: String
    $assetGroupReassignments: [AssetGroupReassignmentSpecification!]
  ) {
    transferAssets(
      assetIds: $assetIds
      assignableId: $assignableId
      assignableType: $assignableType
      projectIdIfTask: $projectIdIfTask
      assetGroupReassignments: $assetGroupReassignments
    ) {
      ...AssetBase
      ...AssetAdditional
      assetGroupId
      isAssetGroup
    }
  }
  ${AssetBaseFragmentDoc}
  ${AssetAdditionalFragmentDoc}
`

export function useTransferAssetsMutation() {
  return Urql.useMutation<TransferAssetsMutation, TransferAssetsMutationVariables>(TransferAssetsDocument)
}
export const DeleteAssetDocument = gql`
  mutation DeleteAsset($id: String!, $status: AssetStatus!) {
    deleteOneAsset(id: $id, status: $status) {
      id
      ...AssetBase
      ...AssetAdditional
    }
  }
  ${AssetBaseFragmentDoc}
  ${AssetAdditionalFragmentDoc}
`

export function useDeleteAssetMutation() {
  return Urql.useMutation<DeleteAssetMutation, DeleteAssetMutationVariables>(DeleteAssetDocument)
}
export const RestoreAssetDocument = gql`
  mutation RestoreAsset($id: String!) {
    restoreOneAsset(id: $id) {
      id
      ...AssetBase
      ...AssetAdditional
    }
  }
  ${AssetBaseFragmentDoc}
  ${AssetAdditionalFragmentDoc}
`

export function useRestoreAssetMutation() {
  return Urql.useMutation<RestoreAssetMutation, RestoreAssetMutationVariables>(RestoreAssetDocument)
}
export const AddQuantitiesToGroupDocument = gql`
  mutation AddQuantitiesToGroup(
    $assetGroupId: String!
    $assignableId: String!
    $assignableType: AssetAssignableType!
    $quantityToAdd: Int!
  ) {
    addQuantitiesToGroup(
      assetGroupId: $assetGroupId
      assignableId: $assignableId
      assignableType: $assignableType
      quantityToAdd: $quantityToAdd
    ) {
      id
      assetGroupId
      assetChildCount
      assetGroupMemberCount
      name
      status
    }
  }
`

export function useAddQuantitiesToGroupMutation() {
  return Urql.useMutation<AddQuantitiesToGroupMutation, AddQuantitiesToGroupMutationVariables>(
    AddQuantitiesToGroupDocument
  )
}
export const ReturnQuantityToInventoryDocument = gql`
  mutation ReturnQuantityToInventory(
    $assetGroupId: String!
    $assignableId: String!
    $assignableType: AssetAssignableType!
    $status: AssetStatus!
    $quantityToReturn: Int!
  ) {
    returnQuantityToInventory(
      assetGroupId: $assetGroupId
      assignableId: $assignableId
      assignableType: $assignableType
      status: $status
      quantityToReturn: $quantityToReturn
    ) {
      id
      assetGroupId
      assetChildCount
      assetGroupMemberCount
      name
      status
    }
  }
`

export function useReturnQuantityToInventoryMutation() {
  return Urql.useMutation<ReturnQuantityToInventoryMutation, ReturnQuantityToInventoryMutationVariables>(
    ReturnQuantityToInventoryDocument
  )
}
export const ArchiveQuantitiesDocument = gql`
  mutation ArchiveQuantities(
    $assetGroupId: String!
    $assignableId: String!
    $assignableType: AssetAssignableType!
    $status: AssetStatus!
    $quantityToArchive: Int!
  ) {
    archiveQuantities(
      assetGroupId: $assetGroupId
      assignableId: $assignableId
      assignableType: $assignableType
      status: $status
      quantityToArchive: $quantityToArchive
    ) {
      id
      assetGroupId
      assetChildCount
      assetGroupMemberCount
      name
      status
      deletedAt
    }
  }
`

export function useArchiveQuantitiesMutation() {
  return Urql.useMutation<ArchiveQuantitiesMutation, ArchiveQuantitiesMutationVariables>(ArchiveQuantitiesDocument)
}
export const UnarchiveQuantitiesDocument = gql`
  mutation UnarchiveQuantities(
    $assetGroupId: String!
    $assignableId: String!
    $assignableType: AssetAssignableType!
    $status: AssetStatus!
    $quantityToUnarchive: Int!
  ) {
    unarchiveQuantities(
      assetGroupId: $assetGroupId
      assignableId: $assignableId
      assignableType: $assignableType
      status: $status
      quantityToUnarchive: $quantityToUnarchive
    ) {
      id
      assetGroupId
      assetChildCount
      assetGroupMemberCount
      name
      status
      deletedAt
    }
  }
`

export function useUnarchiveQuantitiesMutation() {
  return Urql.useMutation<UnarchiveQuantitiesMutation, UnarchiveQuantitiesMutationVariables>(
    UnarchiveQuantitiesDocument
  )
}
export const CreateAssetReportTemplateDocument = gql`
  mutation CreateAssetReportTemplate(
    $name: String!
    $reusable: Boolean!
    $universal: Boolean
    $fields: [AssetReportTemplateFieldInput!]!
  ) {
    insertReportTemplate(name: $name, reusable: $reusable, universal: $universal, fields: $fields) {
      id
      reusable
      fields {
        id
        label
        photoRequired
        required
        type
      }
    }
  }
`

export function useCreateAssetReportTemplateMutation() {
  return Urql.useMutation<CreateAssetReportTemplateMutation, CreateAssetReportTemplateMutationVariables>(
    CreateAssetReportTemplateDocument
  )
}
export const UpdateAssetReportTemplateDocument = gql`
  mutation UpdateAssetReportTemplate(
    $id: String!
    $name: String
    $reusable: Boolean
    $fields: [AssetReportTemplateFieldInput!]
  ) {
    updateReportTemplate(id: $id, name: $name, reusable: $reusable, fields: $fields) {
      id
      assetsCount
      createdAt
      deletedAt
      fields {
        id
        label
        photoRequired
        photoLabel
        rule
        failedStatus
        required
        type
      }
      name
      reusable
      universal
    }
  }
`

export function useUpdateAssetReportTemplateMutation() {
  return Urql.useMutation<UpdateAssetReportTemplateMutation, UpdateAssetReportTemplateMutationVariables>(
    UpdateAssetReportTemplateDocument
  )
}
export const DuplicateReportTemplateDocument = gql`
  mutation DuplicateReportTemplate($id: String!) {
    duplicateReportTemplate(id: $id) {
      id
      assetsCount
      createdAt
      deletedAt
      fields {
        id
        label
        photoRequired
        photoLabel
        rule
        failedStatus
        required
        type
      }
      name
      reusable
      universal
    }
  }
`

export function useDuplicateReportTemplateMutation() {
  return Urql.useMutation<DuplicateReportTemplateMutation, DuplicateReportTemplateMutationVariables>(
    DuplicateReportTemplateDocument
  )
}
export const DeleteReportTemplateDocument = gql`
  mutation DeleteReportTemplate($id: String!) {
    deleteReportTemplate(id: $id) {
      id
      deletedAt
    }
  }
`

export function useDeleteReportTemplateMutation() {
  return Urql.useMutation<DeleteReportTemplateMutation, DeleteReportTemplateMutationVariables>(
    DeleteReportTemplateDocument
  )
}
export const InsertManyAssetReportsDocument = gql`
  mutation InsertManyAssetReports($reports: [AssetReportInput!]!) {
    insertManyAssetReports(reports: $reports) {
      ...AssetReportBase
      ...AssetReportDates
      ...AssetReportInventory
      ...AssetStatus
      ...AssetReportReporter
    }
  }
  ${AssetReportBaseFragmentDoc}
  ${AssetReportDatesFragmentDoc}
  ${AssetReportInventoryFragmentDoc}
  ${AssetStatusFragmentDoc}
  ${AssetReportReporterFragmentDoc}
`

export function useInsertManyAssetReportsMutation() {
  return Urql.useMutation<InsertManyAssetReportsMutation, InsertManyAssetReportsMutationVariables>(
    InsertManyAssetReportsDocument
  )
}
export const ContractsForCustomerDocument = gql`
  query ContractsForCustomer($customerId: String!) {
    contracts(customerId: $customerId) {
      id
      name
      number
      description
    }
  }
`

export function useContractsForCustomerQuery(
  options: Omit<Urql.UseQueryArgs<ContractsForCustomerQueryVariables>, "query">
) {
  return Urql.useQuery<ContractsForCustomerQuery, ContractsForCustomerQueryVariables>({
    query: ContractsForCustomerDocument,
    ...options,
  })
}
export const ContractDocument = gql`
  query Contract($id: String!) {
    contract(id: $id) {
      id
      name
      number
      description
      deliverableUnits {
        id
        customerDescription
        rate
        deliverableUnit {
          id
          description
          unitOfMeasure
        }
      }
    }
  }
`

export function useContractQuery(options: Omit<Urql.UseQueryArgs<ContractQueryVariables>, "query">) {
  return Urql.useQuery<ContractQuery, ContractQueryVariables>({ query: ContractDocument, ...options })
}
export const CreateOneContractDocument = gql`
  mutation CreateOneContract(
    $name: String!
    $number: String
    $description: String
    $customerId: String!
    $deliverableUnits: [ContractDeliverableUnitInput!]!
  ) {
    createOneContract(
      name: $name
      number: $number
      description: $description
      customerId: $customerId
      deliverableUnits: $deliverableUnits
    ) {
      id
      name
      number
      description
      customerId
      deliverableUnits {
        id
        customerDescription
        deliverableUnitId
        rate
      }
    }
  }
`

export function useCreateOneContractMutation() {
  return Urql.useMutation<CreateOneContractMutation, CreateOneContractMutationVariables>(CreateOneContractDocument)
}
export const UpdateOneContractDocument = gql`
  mutation UpdateOneContract($id: String!, $name: String, $number: String, $description: String) {
    updateOneContract(id: $id, name: $name, number: $number, description: $description) {
      id
      name
      number
      description
    }
  }
`

export function useUpdateOneContractMutation() {
  return Urql.useMutation<UpdateOneContractMutation, UpdateOneContractMutationVariables>(UpdateOneContractDocument)
}
export const DeleteOneContractDocument = gql`
  mutation DeleteOneContract($id: String!) {
    deleteOneContract(id: $id) {
      id
    }
  }
`

export function useDeleteOneContractMutation() {
  return Urql.useMutation<DeleteOneContractMutation, DeleteOneContractMutationVariables>(DeleteOneContractDocument)
}
export const CustomerDocument = gql`
  query Customer($id: String!) {
    customer(id: $id) {
      ...CustomerFull
    }
  }
  ${CustomerFullFragmentDoc}
`

export function useCustomerQuery(options: Omit<Urql.UseQueryArgs<CustomerQueryVariables>, "query">) {
  return Urql.useQuery<CustomerQuery, CustomerQueryVariables>({ query: CustomerDocument, ...options })
}
export const CreateCustomerDocument = gql`
  mutation CreateCustomer(
    $name: String!
    $image: String
    $customerId: String
    $phoneNumber: String
    $phoneNumberExt: String
    $email: String
    $notes: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $state: String
    $zip: String
    $country: String
  ) {
    createCustomer(
      name: $name
      image: $image
      customerId: $customerId
      phoneNumber: $phoneNumber
      phoneNumberExt: $phoneNumberExt
      email: $email
      notes: $notes
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      state: $state
      zip: $zip
      country: $country
    ) {
      ...CustomerFull
    }
  }
  ${CustomerFullFragmentDoc}
`

export function useCreateCustomerMutation() {
  return Urql.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument)
}
export const CustomerEditDocument = gql`
  mutation CustomerEdit(
    $id: String!
    $name: String
    $image: String
    $customerId: String
    $phoneNumber: String
    $phoneNumberExt: String
    $email: String
    $notes: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $state: String
    $zip: String
    $country: String
  ) {
    updateCustomer(
      id: $id
      name: $name
      image: $image
      customerId: $customerId
      phoneNumber: $phoneNumber
      phoneNumberExt: $phoneNumberExt
      email: $email
      notes: $notes
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      state: $state
      zip: $zip
      country: $country
    ) {
      ...CustomerFull
    }
  }
  ${CustomerFullFragmentDoc}
`

export function useCustomerEditMutation() {
  return Urql.useMutation<CustomerEditMutation, CustomerEditMutationVariables>(CustomerEditDocument)
}
export const CustomerDeleteDocument = gql`
  mutation CustomerDelete($id: String!) {
    deleteCustomer(id: $id) {
      ...CustomerBase
    }
  }
  ${CustomerBaseFragmentDoc}
`

export function useCustomerDeleteMutation() {
  return Urql.useMutation<CustomerDeleteMutation, CustomerDeleteMutationVariables>(CustomerDeleteDocument)
}
export const CreateDeliverableUnitDocument = gql`
  mutation CreateDeliverableUnit(
    $description: String!
    $unitOfMeasure: String!
    $referenceNumber: String
    $color: String!
  ) {
    createDeliverableUnit(
      description: $description
      unitOfMeasure: $unitOfMeasure
      referenceNumber: $referenceNumber
      color: $color
    ) {
      ...DeliverableUnitBase
    }
  }
  ${DeliverableUnitBaseFragmentDoc}
`

export function useCreateDeliverableUnitMutation() {
  return Urql.useMutation<CreateDeliverableUnitMutation, CreateDeliverableUnitMutationVariables>(
    CreateDeliverableUnitDocument
  )
}
export const EditDeliverableUnitDocument = gql`
  mutation EditDeliverableUnit(
    $id: String!
    $description: String!
    $unitOfMeasure: String!
    $referenceNumber: String
    $color: String!
  ) {
    editDeliverableUnit(
      id: $id
      description: $description
      color: $color
      unitOfMeasure: $unitOfMeasure
      referenceNumber: $referenceNumber
    ) {
      ...DeliverableUnitBase
    }
  }
  ${DeliverableUnitBaseFragmentDoc}
`

export function useEditDeliverableUnitMutation() {
  return Urql.useMutation<EditDeliverableUnitMutation, EditDeliverableUnitMutationVariables>(
    EditDeliverableUnitDocument
  )
}
export const DeleteDeliverableUnitDocument = gql`
  mutation DeleteDeliverableUnit($id: String!) {
    deleteDeliverableUnit(id: $id) {
      id
      deletedAt
    }
  }
`

export function useDeleteDeliverableUnitMutation() {
  return Urql.useMutation<DeleteDeliverableUnitMutation, DeleteDeliverableUnitMutationVariables>(
    DeleteDeliverableUnitDocument
  )
}
export const DeliverableUnitsBaseDocument = gql`
  query DeliverableUnitsBase {
    deliverableUnits {
      id
      description
      unitOfMeasure
      referenceNumber
      color
    }
  }
`

export function useDeliverableUnitsBaseQuery(
  options?: Omit<Urql.UseQueryArgs<DeliverableUnitsBaseQueryVariables>, "query">
) {
  return Urql.useQuery<DeliverableUnitsBaseQuery, DeliverableUnitsBaseQueryVariables>({
    query: DeliverableUnitsBaseDocument,
    ...options,
  })
}
export const UpdateOneFileDocument = gql`
  mutation UpdateOneFile($id: String!, $fileName: String, $status: String, $expiresAt: DateTime) {
    updateOneFile(id: $id, fileName: $fileName, status: $status, expiresAt: $expiresAt) {
      id
      fileName
      status
      expiresAt
    }
  }
`

export function useUpdateOneFileMutation() {
  return Urql.useMutation<UpdateOneFileMutation, UpdateOneFileMutationVariables>(UpdateOneFileDocument)
}
export const DeleteOneFileDocument = gql`
  mutation DeleteOneFile($id: String!) {
    deleteOneFile(id: $id) {
      id
    }
  }
`

export function useDeleteOneFileMutation() {
  return Urql.useMutation<DeleteOneFileMutation, DeleteOneFileMutationVariables>(DeleteOneFileDocument)
}
export const CreateInjuryReportDocument = gql`
  mutation CreateInjuryReport($userId: String!, $notes: String) {
    createInjuryReport(userId: $userId, notes: $notes) {
      id
      userId
      reporterId
      notes
      createdAt
    }
  }
`

export function useCreateInjuryReportMutation() {
  return Urql.useMutation<CreateInjuryReportMutation, CreateInjuryReportMutationVariables>(CreateInjuryReportDocument)
}
export const UpdateInjuryReportDocument = gql`
  mutation UpdateInjuryReport($id: String!, $userId: String, $notes: String) {
    updateInjuryReport(id: $id, userId: $userId, notes: $notes) {
      id
      userId
      reporterId
      notes
    }
  }
`

export function useUpdateInjuryReportMutation() {
  return Urql.useMutation<UpdateInjuryReportMutation, UpdateInjuryReportMutationVariables>(UpdateInjuryReportDocument)
}
export const UpdateLastExportDocument = gql`
  mutation UpdateLastExport($entityId: String, $entityType: String) {
    updateLastExport(entityId: $entityId, entityType: $entityType) {
      userId
      userName
      exportedAt
    }
  }
`

export function useUpdateLastExportMutation() {
  return Urql.useMutation<UpdateLastExportMutation, UpdateLastExportMutationVariables>(UpdateLastExportDocument)
}
export const MarkAllNotificationsReadDocument = gql`
  mutation markAllNotificationsRead {
    markAllNotificationsRead {
      id
      markedReadAt
    }
  }
`

export function useMarkAllNotificationsReadMutation() {
  return Urql.useMutation<MarkAllNotificationsReadMutation, MarkAllNotificationsReadMutationVariables>(
    MarkAllNotificationsReadDocument
  )
}
export const MarkNotificationsReadByIdDocument = gql`
  mutation markNotificationsReadById($ids: [String!]!) {
    markNotificationsReadById(ids: $ids) {
      id
      markedReadAt
    }
  }
`

export function useMarkNotificationsReadByIdMutation() {
  return Urql.useMutation<MarkNotificationsReadByIdMutation, MarkNotificationsReadByIdMutationVariables>(
    MarkNotificationsReadByIdDocument
  )
}
export const EditOrganizationDocument = gql`
  mutation EditOrganization(
    $id: String!
    $image: String
    $name: String
    $timezone: String
    $requireTimeEntryEvidence: Boolean
    $overtimeType: OvertimeTypeEnum
    $notificationSettings: JSON
    $featureToggleSegments: [FeatureToggleSegments!]
    $stripeCustomerId: String
  ) {
    editOrganization(
      id: $id
      image: $image
      name: $name
      timezone: $timezone
      requireTimeEntryEvidence: $requireTimeEntryEvidence
      overtimeType: $overtimeType
      notificationSettings: $notificationSettings
      featureToggleSegments: $featureToggleSegments
      stripeCustomerId: $stripeCustomerId
    ) {
      ...OrganizationBase
    }
  }
  ${OrganizationBaseFragmentDoc}
`

export function useEditOrganizationMutation() {
  return Urql.useMutation<EditOrganizationMutation, EditOrganizationMutationVariables>(EditOrganizationDocument)
}
export const ArchiveOrganizationDocument = gql`
  mutation ArchiveOrganization($id: String!) {
    archiveOrganization(id: $id) {
      ...OrganizationBase
    }
  }
  ${OrganizationBaseFragmentDoc}
`

export function useArchiveOrganizationMutation() {
  return Urql.useMutation<ArchiveOrganizationMutation, ArchiveOrganizationMutationVariables>(
    ArchiveOrganizationDocument
  )
}
export const ActivateOrganizationDocument = gql`
  mutation ActivateOrganization($id: String!) {
    activateOrganization(id: $id) {
      ...OrganizationBase
    }
  }
  ${OrganizationBaseFragmentDoc}
`

export function useActivateOrganizationMutation() {
  return Urql.useMutation<ActivateOrganizationMutation, ActivateOrganizationMutationVariables>(
    ActivateOrganizationDocument
  )
}
export const MarkOrganizationPastDueDocument = gql`
  mutation markOrganizationPastDue($id: String!) {
    markOrganizationPastDue(id: $id) {
      ...OrganizationBase
      billingPastDueAt
    }
  }
  ${OrganizationBaseFragmentDoc}
`

export function useMarkOrganizationPastDueMutation() {
  return Urql.useMutation<MarkOrganizationPastDueMutation, MarkOrganizationPastDueMutationVariables>(
    MarkOrganizationPastDueDocument
  )
}
export const MarkOrganizationNotPastDueDocument = gql`
  mutation markOrganizationNotPastDue($id: String!) {
    markOrganizationNotPastDue(id: $id) {
      ...OrganizationBase
      billingPastDueAt
    }
  }
  ${OrganizationBaseFragmentDoc}
`

export function useMarkOrganizationNotPastDueMutation() {
  return Urql.useMutation<MarkOrganizationNotPastDueMutation, MarkOrganizationNotPastDueMutationVariables>(
    MarkOrganizationNotPastDueDocument
  )
}
export const CreateUnitOfMeasureDocument = gql`
  mutation CreateUnitOfMeasure($name: String!, $symbol: String!) {
    createUnitOfMeasure(name: $name, symbol: $symbol) {
      id
      name
      symbol
      organizationId
    }
  }
`

export function useCreateUnitOfMeasureMutation() {
  return Urql.useMutation<CreateUnitOfMeasureMutation, CreateUnitOfMeasureMutationVariables>(
    CreateUnitOfMeasureDocument
  )
}
export const EditUnitOfMeasureDocument = gql`
  mutation EditUnitOfMeasure($id: Int!, $name: String!, $symbol: String!) {
    editUnitOfMeasure(id: $id, name: $name, symbol: $symbol) {
      id
      name
      symbol
      organizationId
    }
  }
`

export function useEditUnitOfMeasureMutation() {
  return Urql.useMutation<EditUnitOfMeasureMutation, EditUnitOfMeasureMutationVariables>(EditUnitOfMeasureDocument)
}
export const DeleteUnitOfMeasureDocument = gql`
  mutation DeleteUnitOfMeasure($id: Int!) {
    deleteUnitOfMeasure(id: $id) {
      id
      organizationId
    }
  }
`

export function useDeleteUnitOfMeasureMutation() {
  return Urql.useMutation<DeleteUnitOfMeasureMutation, DeleteUnitOfMeasureMutationVariables>(
    DeleteUnitOfMeasureDocument
  )
}
export const ProjectCreateDocument = gql`
  mutation ProjectCreate(
    $code: String
    $description: String
    $endDate: DateTime
    $address: AddressInput
    $location: JSON
    $locationNotes: String
    $metadata: [MetadataNoteInput!]
    $name: String!
    $schedule: ScheduleTemplateInput
    $scheduledBreaks: [ScheduledBreakInput!]
    $startDate: DateTime
    $workersCompCodeId: String
    $taskGroups: [GroupInput!]
    $tasks: [TaskInput!]
    $customerId: String
  ) {
    insertOneProject(
      code: $code
      description: $description
      endDate: $endDate
      address: $address
      location: $location
      locationNotes: $locationNotes
      metadata: $metadata
      name: $name
      schedule: $schedule
      scheduledBreaks: $scheduledBreaks
      startDate: $startDate
      taskGroups: $taskGroups
      tasks: $tasks
      workersCompCodeId: $workersCompCodeId
      customerId: $customerId
    ) {
      ...ProjectBase
      ...ProjectAdditional
      tasks {
        ...TaskBase
        ...TaskAdditional
      }
    }
  }
  ${ProjectBaseFragmentDoc}
  ${ProjectAdditionalFragmentDoc}
  ${TaskBaseFragmentDoc}
  ${TaskAdditionalFragmentDoc}
`

export function useProjectCreateMutation() {
  return Urql.useMutation<ProjectCreateMutation, ProjectCreateMutationVariables>(ProjectCreateDocument)
}
export const DuplicateProjectDocument = gql`
  mutation DuplicateProject(
    $projectId: String!
    $projectName: String!
    $includeTasks: Boolean
    $includeReportingUnits: Boolean
    $includeAdditionalUnits: Boolean
  ) {
    duplicateProject(
      projectId: $projectId
      projectName: $projectName
      includeTasks: $includeTasks
      includeReportingUnits: $includeReportingUnits
      includeAdditionalUnits: $includeAdditionalUnits
    ) {
      ...ProjectBase
      ...ProjectAdditional
      tasks {
        ...TaskBase
        ...TaskAdditional
      }
    }
  }
  ${ProjectBaseFragmentDoc}
  ${ProjectAdditionalFragmentDoc}
  ${TaskBaseFragmentDoc}
  ${TaskAdditionalFragmentDoc}
`

export function useDuplicateProjectMutation() {
  return Urql.useMutation<DuplicateProjectMutation, DuplicateProjectMutationVariables>(DuplicateProjectDocument)
}
export const ProjectEditDocument = gql`
  mutation ProjectEdit(
    $id: String!
    $code: String
    $name: String
    $isArchived: DateTime
    $unassignedTaskId: String
    $startDate: DateTime
    $endDate: DateTime
    $description: String
    $image: String
    $address: AddressInput
    $location: JSON
    $locationNotes: String
    $googlePlace: JSON
    $metadata: [MetadataNoteInput!]
    $workersCompCodeId: String
    $schedule: ScheduleTemplateInput
    $scheduledBreaks: [ScheduledBreakInput!]
    $customerId: String
  ) {
    updateOneProject(
      id: $id
      code: $code
      name: $name
      isArchived: $isArchived
      unassignedTaskId: $unassignedTaskId
      startDate: $startDate
      endDate: $endDate
      description: $description
      image: $image
      address: $address
      location: $location
      locationNotes: $locationNotes
      googlePlace: $googlePlace
      metadata: $metadata
      workersCompCodeId: $workersCompCodeId
      schedule: $schedule
      scheduledBreaks: $scheduledBreaks
      customerId: $customerId
    ) {
      ...ProjectBase
      ...ProjectAdditional
      tasks {
        ...TaskBase
        ...TaskAdditional
      }
    }
  }
  ${ProjectBaseFragmentDoc}
  ${ProjectAdditionalFragmentDoc}
  ${TaskBaseFragmentDoc}
  ${TaskAdditionalFragmentDoc}
`

export function useProjectEditMutation() {
  return Urql.useMutation<ProjectEditMutation, ProjectEditMutationVariables>(ProjectEditDocument)
}
export const ArchiveProjectDocument = gql`
  mutation ArchiveProject($id: String!) {
    archiveProject(id: $id) {
      id
      isArchived
    }
  }
`

export function useArchiveProjectMutation() {
  return Urql.useMutation<ArchiveProjectMutation, ArchiveProjectMutationVariables>(ArchiveProjectDocument)
}
export const MarkProjectAsCompleteDocument = gql`
  mutation MarkProjectAsComplete($id: String!) {
    completeProject(id: $id) {
      isComplete
      ...ProjectBase
      ...ProjectAdditional
      tasks {
        ...TaskBase
        ...TaskAdditional
      }
    }
  }
  ${ProjectBaseFragmentDoc}
  ${ProjectAdditionalFragmentDoc}
  ${TaskBaseFragmentDoc}
  ${TaskAdditionalFragmentDoc}
`

export function useMarkProjectAsCompleteMutation() {
  return Urql.useMutation<MarkProjectAsCompleteMutation, MarkProjectAsCompleteMutationVariables>(
    MarkProjectAsCompleteDocument
  )
}
export const AddOrUpdateNonWorkDayDocument = gql`
  mutation addOrUpdateNonWorkDay($nonWorkDay: ScheduleTemplateNonWorkDaysInput!) {
    addOrUpdateNonWorkDay(nonWorkDay: $nonWorkDay) {
      id
      nonWorkDays {
        id
        name
        dateRange
        active
      }
    }
  }
`

export function useAddOrUpdateNonWorkDayMutation() {
  return Urql.useMutation<AddOrUpdateNonWorkDayMutation, AddOrUpdateNonWorkDayMutationVariables>(
    AddOrUpdateNonWorkDayDocument
  )
}
export const CreateScheduleTemplateDocument = gql`
  mutation CreateScheduleTemplate(
    $workDays: [ScheduleTemplateWorkDaysInput!]
    $workHours: ScheduleTemplateWorkHoursInput
    $nonWorkDays: [ScheduleTemplateNonWorkDaysInput!]
    $isDefault: Boolean
  ) {
    createScheduleTemplate(
      workDays: $workDays
      workHours: $workHours
      nonWorkDays: $nonWorkDays
      isDefault: $isDefault
    ) {
      id
      isDefault
      workDays {
        label
        active
      }
      workHours {
        hours
        startTime
        endTime
      }
      nonWorkDays {
        id
        name
        dateRange
        active
      }
    }
  }
`

export function useCreateScheduleTemplateMutation() {
  return Urql.useMutation<CreateScheduleTemplateMutation, CreateScheduleTemplateMutationVariables>(
    CreateScheduleTemplateDocument
  )
}
export const DuplicateScheduleTemplateDocument = gql`
  mutation DuplicateScheduleTemplate($id: String!) {
    duplicateScheduleTemplate(id: $id) {
      id
      isDefault
      workDays {
        label
        active
      }
      workHours {
        hours
        startTime
        endTime
      }
      nonWorkDays {
        id
        name
        dateRange
        active
      }
    }
  }
`

export function useDuplicateScheduleTemplateMutation() {
  return Urql.useMutation<DuplicateScheduleTemplateMutation, DuplicateScheduleTemplateMutationVariables>(
    DuplicateScheduleTemplateDocument
  )
}
export const UpdateScheduleTemplateDocument = gql`
  mutation UpdateScheduleTemplate(
    $id: String!
    $workDays: [ScheduleTemplateWorkDaysInput!]
    $workHours: ScheduleTemplateWorkHoursInput
    $nonWorkDays: [ScheduleTemplateNonWorkDaysInput!]
    $isDefault: Boolean
  ) {
    updateScheduleTemplate(
      id: $id
      workDays: $workDays
      workHours: $workHours
      nonWorkDays: $nonWorkDays
      isDefault: $isDefault
    ) {
      id
      isDefault
      workDays {
        label
        active
      }
      workHours {
        hours
        startTime
        endTime
      }
      nonWorkDays {
        id
        name
        dateRange
        active
      }
    }
  }
`

export function useUpdateScheduleTemplateMutation() {
  return Urql.useMutation<UpdateScheduleTemplateMutation, UpdateScheduleTemplateMutationVariables>(
    UpdateScheduleTemplateDocument
  )
}
export const DeleteNonWorkDayDocument = gql`
  mutation DeleteNonWorkDay($id: String!) {
    deleteNonWorkDay(id: $id) {
      id
      isDefault
      workDays {
        label
        active
      }
      workHours {
        hours
        startTime
        endTime
      }
      nonWorkDays {
        id
        name
        dateRange
        active
      }
    }
  }
`

export function useDeleteNonWorkDayMutation() {
  return Urql.useMutation<DeleteNonWorkDayMutation, DeleteNonWorkDayMutationVariables>(DeleteNonWorkDayDocument)
}
export const OrgScheduleAndBreaksDocument = gql`
  query OrgScheduleAndBreaks {
    scheduleTemplates(isDefault: true) {
      id
      isDefault
      workDays {
        label
        active
      }
      workHours {
        hours
        startTime
        endTime
      }
      nonWorkDays {
        id
        name
        dateRange
        active
      }
    }
    scheduledBreaks(includeOrgBreaks: true) {
      id
      breakTask {
        id
        name
      }
      breakTaskId
      durationInMinutes
      isActive
      localizedStartTime
      name
    }
  }
`

export function useOrgScheduleAndBreaksQuery(
  options?: Omit<Urql.UseQueryArgs<OrgScheduleAndBreaksQueryVariables>, "query">
) {
  return Urql.useQuery<OrgScheduleAndBreaksQuery, OrgScheduleAndBreaksQueryVariables>({
    query: OrgScheduleAndBreaksDocument,
    ...options,
  })
}
export const ScheduleWithBreaksDocument = gql`
  query ScheduleWithBreaks($projectId: String, $taskId: String) {
    scheduleTemplates(projectId: $projectId, taskId: $taskId) {
      id
      isDefault
      workDays {
        label
        active
      }
      workHours {
        hours
        startTime
        endTime
      }
      nonWorkDays {
        id
        name
        dateRange
        active
      }
    }
    scheduledBreaks(projectId: $projectId, taskId: $taskId) {
      id
      breakTask {
        id
        name
      }
      breakTaskId
      durationInMinutes
      isActive
      localizedStartTime
      name
    }
  }
`

export function useScheduleWithBreaksQuery(
  options?: Omit<Urql.UseQueryArgs<ScheduleWithBreaksQueryVariables>, "query">
) {
  return Urql.useQuery<ScheduleWithBreaksQuery, ScheduleWithBreaksQueryVariables>({
    query: ScheduleWithBreaksDocument,
    ...options,
  })
}
export const CreateScheduledBreakDocument = gql`
  mutation CreateScheduledBreak(
    $breakTaskId: String!
    $durationInMinutes: Int!
    $localizedStartTime: String!
    $name: String!
    $projectId: String
  ) {
    createScheduledBreak(
      breakTaskId: $breakTaskId
      durationInMinutes: $durationInMinutes
      localizedStartTime: $localizedStartTime
      name: $name
      projectId: $projectId
    ) {
      id
      durationInMinutes
      isActive
      localizedStartTime
      name
      breakTask {
        id
        name
      }
    }
  }
`

export function useCreateScheduledBreakMutation() {
  return Urql.useMutation<CreateScheduledBreakMutation, CreateScheduledBreakMutationVariables>(
    CreateScheduledBreakDocument
  )
}
export const EditScheduledBreakDocument = gql`
  mutation EditScheduledBreak(
    $id: String!
    $breakTaskId: String
    $durationInMinutes: Int
    $isActive: Boolean
    $localizedStartTime: String
    $name: String
  ) {
    editScheduledBreak(
      id: $id
      breakTaskId: $breakTaskId
      durationInMinutes: $durationInMinutes
      isActive: $isActive
      localizedStartTime: $localizedStartTime
      name: $name
    ) {
      id
      breakTaskId
      durationInMinutes
      isActive
      localizedStartTime
      name
      breakTask {
        id
        name
      }
    }
  }
`

export function useEditScheduledBreakMutation() {
  return Urql.useMutation<EditScheduledBreakMutation, EditScheduledBreakMutationVariables>(EditScheduledBreakDocument)
}
export const DeleteScheduledBreakDocument = gql`
  mutation DeleteScheduledBreak($id: String!) {
    deleteScheduledBreak(id: $id) {
      id
    }
  }
`

export function useDeleteScheduledBreakMutation() {
  return Urql.useMutation<DeleteScheduledBreakMutation, DeleteScheduledBreakMutationVariables>(
    DeleteScheduledBreakDocument
  )
}
export const TaskCreateDocument = gql`
  mutation TaskCreate(
    $groupId: String
    $parentTaskId: String
    $projectId: String!
    $description: String
    $endDate: DateTime
    $estimatedHours: Float
    $metadata: [MetadataNoteInput!]
    $name: String!
    $startDate: DateTime
    $unitGoals: [UnitGoalInput!]
    $workersCompCodeId: String
    $schedule: ScheduleTemplateInput
    $scheduledBreaks: [ScheduledBreakInput!]
  ) {
    insertOneTask(
      groupId: $groupId
      parentTaskId: $parentTaskId
      projectId: $projectId
      description: $description
      endDate: $endDate
      estimatedHours: $estimatedHours
      metadata: $metadata
      name: $name
      startDate: $startDate
      unitGoals: $unitGoals
      workersCompCodeId: $workersCompCodeId
      schedule: $schedule
      scheduledBreaks: $scheduledBreaks
    ) {
      id
      groupId
      parentTaskId
      projectId
      dateCreated
      description
      endDate
      estimatedHours
      isDefault
      metadata
      name
      startDate
      sumOfDeliveredUnits
      unitGoalsSumProgress
      unitGoalsSumTargetQuantity
      workersCompCode {
        id
        name
        ncciCode
        ncciDescription
        override
        rate
      }
      unitGoals {
        id
      }
    }
  }
`

export function useTaskCreateMutation() {
  return Urql.useMutation<TaskCreateMutation, TaskCreateMutationVariables>(TaskCreateDocument)
}
export const MarkTaskAsCompleteDocument = gql`
  mutation MarkTaskAsComplete($id: String!) {
    markTaskCompletion(id: $id, completed: true) {
      ...TaskBase
      ...TaskAdditional
      isComplete
      groupId
      project {
        ...ProjectBase
        tasks {
          id
        }
      }
    }
  }
  ${TaskBaseFragmentDoc}
  ${TaskAdditionalFragmentDoc}
  ${ProjectBaseFragmentDoc}
`

export function useMarkTaskAsCompleteMutation() {
  return Urql.useMutation<MarkTaskAsCompleteMutation, MarkTaskAsCompleteMutationVariables>(MarkTaskAsCompleteDocument)
}
export const MarkTaskAsIncompleteDocument = gql`
  mutation MarkTaskAsIncomplete($id: String!) {
    markTaskCompletion(id: $id, completed: false) {
      ...TaskBase
      ...TaskAdditional
      isComplete
      groupId
      project {
        ...ProjectBase
        tasks {
          id
        }
      }
    }
  }
  ${TaskBaseFragmentDoc}
  ${TaskAdditionalFragmentDoc}
  ${ProjectBaseFragmentDoc}
`

export function useMarkTaskAsIncompleteMutation() {
  return Urql.useMutation<MarkTaskAsIncompleteMutation, MarkTaskAsIncompleteMutationVariables>(
    MarkTaskAsIncompleteDocument
  )
}
export const TaskEditDocument = gql`
  mutation TaskEdit(
    $id: String!
    $groupId: String
    $parentTaskId: String
    $projectId: String!
    $description: String
    $endDate: DateTime
    $estimatedHours: Float
    $isSubtask: Boolean
    $metadata: [MetadataNoteInput!]
    $name: String!
    $startDate: DateTime
    $workersCompCodeId: String
    $schedule: ScheduleTemplateInput
    $scheduledBreaks: [ScheduledBreakInput!]
  ) {
    updateOneTask(
      id: $id
      groupId: $groupId
      parentTaskId: $parentTaskId
      projectId: $projectId
      description: $description
      endDate: $endDate
      estimatedHours: $estimatedHours
      isSubtask: $isSubtask
      metadata: $metadata
      name: $name
      startDate: $startDate
      workersCompCodeId: $workersCompCodeId
      schedule: $schedule
      scheduledBreaks: $scheduledBreaks
    ) {
      ...TaskBase
      ...TaskAdditional
      parentTask {
        ...TaskBase
        ...TaskAdditional
      }
    }
  }
  ${TaskBaseFragmentDoc}
  ${TaskAdditionalFragmentDoc}
`

export function useTaskEditMutation() {
  return Urql.useMutation<TaskEditMutation, TaskEditMutationVariables>(TaskEditDocument)
}
export const DeleteTaskDocument = gql`
  mutation DeleteTask($id: String!) {
    deleteOneTask(id: $id) {
      id
      name
    }
  }
`

export function useDeleteTaskMutation() {
  return Urql.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument)
}
export const ReportTaskProgressDocument = gql`
  mutation ReportTaskProgress(
    $projectId: String!
    $taskId: String!
    $fileIds: [String!]
    $note: String
    $unitGoalProgress: [UnitGoalProgressInput!]
  ) {
    reportTaskProgress(
      projectId: $projectId
      taskId: $taskId
      fileIds: $fileIds
      note: $note
      unitGoalProgress: $unitGoalProgress
    ) {
      id
    }
  }
`

export function useReportTaskProgressMutation() {
  return Urql.useMutation<ReportTaskProgressMutation, ReportTaskProgressMutationVariables>(ReportTaskProgressDocument)
}
export const CreateManyTaskReportsDocument = gql`
  mutation CreateManyTaskReports($projectId: String!, $taskReports: [TaskReportInput!]!) {
    createManyTaskReports(projectId: $projectId, taskReports: $taskReports) {
      ...TaskBase
      unitGoals {
        ...UnitGoalBase
      }
    }
  }
  ${TaskBaseFragmentDoc}
  ${UnitGoalBaseFragmentDoc}
`

export function useCreateManyTaskReportsMutation() {
  return Urql.useMutation<CreateManyTaskReportsMutation, CreateManyTaskReportsMutationVariables>(
    CreateManyTaskReportsDocument
  )
}
export const CreateTaskGroupDocument = gql`
  mutation CreateTaskGroup($description: String, $name: String!, $notes: String, $projectId: String!) {
    insertOneTaskGroup(description: $description, name: $name, notes: $notes, projectId: $projectId) {
      id
      name
      description
      notes
      assetCount
      completedTaskCount
      organizationId
      unitProgress
      unitTargetGoal
      startDate
      endDate
      taskCount
      tasks {
        id
        name
      }
      userCount
      updatedAt
      createdAt
    }
  }
`

export function useCreateTaskGroupMutation() {
  return Urql.useMutation<CreateTaskGroupMutation, CreateTaskGroupMutationVariables>(CreateTaskGroupDocument)
}
export const UpdateTaskGroupDocument = gql`
  mutation UpdateTaskGroup($id: String!, $name: String, $notes: String, $projectId: String, $description: String) {
    updateOneTaskGroup(id: $id, name: $name, notes: $notes, projectId: $projectId, description: $description) {
      id
      name
      description
      notes
      assetCount
      completedTaskCount
      organizationId
      unitProgress
      unitTargetGoal
      startDate
      endDate
      taskCount
      tasks {
        id
        name
      }
      userCount
      updatedAt
      createdAt
    }
  }
`

export function useUpdateTaskGroupMutation() {
  return Urql.useMutation<UpdateTaskGroupMutation, UpdateTaskGroupMutationVariables>(UpdateTaskGroupDocument)
}
export const ClockInDocument = gql`
  mutation ClockIn($taskId: String!, $userId: String!, $startEvidence: StartEvidenceInput) {
    clockIn(taskId: $taskId, userId: $userId, startEvidence: $startEvidence) {
      id
      durationInSeconds
      endAt
      evidence
      startAt
      taskId
      userId
      user {
        id
        isClockedIn
        secondsClockedSinceOrgMidnight
      }
    }
  }
`

export function useClockInMutation() {
  return Urql.useMutation<ClockInMutation, ClockInMutationVariables>(ClockInDocument)
}
export const BulkClockInDocument = gql`
  mutation BulkClockIn($candidates: [BulkClockInCandidate!]!, $startAt: DateTime) {
    bulkClockIn(candidates: $candidates, startAt: $startAt) {
      success
      errors {
        userId
        reason
      }
    }
  }
`

export function useBulkClockInMutation() {
  return Urql.useMutation<BulkClockInMutation, BulkClockInMutationVariables>(BulkClockInDocument)
}
export const ClockOutUserDocument = gql`
  mutation ClockOutUser($userId: String!, $endEvidence: EndEvidenceInput) {
    clockOutUser(userId: $userId, endEvidence: $endEvidence) {
      id
      durationInSeconds
      endAt
      evidence
      startAt
      taskId
      userId
      user {
        id
        isClockedIn
        secondsClockedSinceOrgMidnight
      }
    }
  }
`

export function useClockOutUserMutation() {
  return Urql.useMutation<ClockOutUserMutation, ClockOutUserMutationVariables>(ClockOutUserDocument)
}
export const ClockOutDocument = gql`
  mutation ClockOut($id: String!, $endEvidence: EndEvidenceInput) {
    clockOut(id: $id, endEvidence: $endEvidence) {
      id
      durationInSeconds
      endAt
      evidence
      startAt
      taskId
      userId
      user {
        id
        isClockedIn
        secondsClockedSinceOrgMidnight
      }
    }
  }
`

export function useClockOutMutation() {
  return Urql.useMutation<ClockOutMutation, ClockOutMutationVariables>(ClockOutDocument)
}
export const BulkClockOutDocument = gql`
  mutation BulkClockOut($candidates: [BulkClockOutCandidate!]!, $endAt: DateTime) {
    bulkClockOut(candidates: $candidates, endAt: $endAt) {
      success
      errors {
        userId
        reason
      }
    }
  }
`

export function useBulkClockOutMutation() {
  return Urql.useMutation<BulkClockOutMutation, BulkClockOutMutationVariables>(BulkClockOutDocument)
}
export const TimeEntryCreateDocument = gql`
  mutation TimeEntryCreate($taskId: String!, $userId: String!, $startAt: DateTime!, $endAt: DateTime!) {
    insertOneTimeEntry(userId: $userId, startAt: $startAt, endAt: $endAt, taskId: $taskId) {
      ...TimeEntryBase
      organization {
        id
      }
      task {
        id
      }
      user {
        id
      }
    }
  }
  ${TimeEntryBaseFragmentDoc}
`

export function useTimeEntryCreateMutation() {
  return Urql.useMutation<TimeEntryCreateMutation, TimeEntryCreateMutationVariables>(TimeEntryCreateDocument)
}
export const TimeEntryEditDocument = gql`
  mutation TimeEntryEdit($id: String!, $startAt: DateTime!, $endAt: DateTime, $taskId: String!) {
    updateOneTimeEntry(id: $id, startAt: $startAt, endAt: $endAt, taskId: $taskId) {
      ...TimeEntryBase
      user {
        id
      }
    }
  }
  ${TimeEntryBaseFragmentDoc}
`

export function useTimeEntryEditMutation() {
  return Urql.useMutation<TimeEntryEditMutation, TimeEntryEditMutationVariables>(TimeEntryEditDocument)
}
export const DeleteTimeEntryDocument = gql`
  mutation DeleteTimeEntry($id: String!) {
    deleteOneTimeEntry(id: $id) {
      id
    }
  }
`

export function useDeleteTimeEntryMutation() {
  return Urql.useMutation<DeleteTimeEntryMutation, DeleteTimeEntryMutationVariables>(DeleteTimeEntryDocument)
}
export const CreateUnitGoalDocument = gql`
  mutation CreateUnitGoal($taskId: String!, $goal: UnitGoalInput!) {
    createUnitGoal(taskId: $taskId, goal: $goal) {
      ...UnitGoalBase
      deliverableUnit {
        id
        ...DeliverableUnitBase
      }
    }
  }
  ${UnitGoalBaseFragmentDoc}
  ${DeliverableUnitBaseFragmentDoc}
`

export function useCreateUnitGoalMutation() {
  return Urql.useMutation<CreateUnitGoalMutation, CreateUnitGoalMutationVariables>(CreateUnitGoalDocument)
}
export const EditUnitGoalDocument = gql`
  mutation EditUnitGoal($id: String!, $goal: UnitGoalInput!) {
    editUnitGoal(id: $id, goal: $goal) {
      ...UnitGoalBase
    }
  }
  ${UnitGoalBaseFragmentDoc}
`

export function useEditUnitGoalMutation() {
  return Urql.useMutation<EditUnitGoalMutation, EditUnitGoalMutationVariables>(EditUnitGoalDocument)
}
export const DeleteUnitGoalDocument = gql`
  mutation DeleteUnitGoal($id: String!) {
    deleteUnitGoal(id: $id) {
      id
    }
  }
`

export function useDeleteUnitGoalMutation() {
  return Urql.useMutation<DeleteUnitGoalMutation, DeleteUnitGoalMutationVariables>(DeleteUnitGoalDocument)
}
export const UserEditDocument = gql`
  mutation UserEdit(
    $id: String!
    $firstName: String
    $lastName: String
    $jobTitle: String
    $phoneNumber: String
    $phoneNumberExt: String
    $image: String
    $task: String
    $roles: [String!]
    $workersCompCodeId: String
    $companyUserId: String
    $payType: PayType
    $payRate: String
  ) {
    updateOneUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      jobTitle: $jobTitle
      phoneNumber: $phoneNumber
      phoneNumberExt: $phoneNumberExt
      image: $image
      task: $task
      roles: $roles
      workersCompCodeId: $workersCompCodeId
      companyUserId: $companyUserId
      payType: $payType
      payRate: $payRate
    ) {
      ...UserBase
      payRate
      payType
      roles {
        ...RoleBase
      }
      task {
        id
        name
      }
      workersCompCode {
        id
        name
        ncciCode
      }
    }
  }
  ${UserBaseFragmentDoc}
  ${RoleBaseFragmentDoc}
`

export function useUserEditMutation() {
  return Urql.useMutation<UserEditMutation, UserEditMutationVariables>(UserEditDocument)
}
export const ResendUserInvitesDocument = gql`
  mutation ResendUserInvites($userIds: [String!]!) {
    resendUserInvites(userIds: $userIds)
  }
`

export function useResendUserInvitesMutation() {
  return Urql.useMutation<ResendUserInvitesMutation, ResendUserInvitesMutationVariables>(ResendUserInvitesDocument)
}
export const UserDeleteDocument = gql`
  mutation UserDelete($id: String!) {
    deleteOneUser(id: $id) {
      ...UserBase
      roles {
        ...RoleBase
      }
    }
  }
  ${UserBaseFragmentDoc}
  ${RoleBaseFragmentDoc}
`

export function useUserDeleteMutation() {
  return Urql.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(UserDeleteDocument)
}
export const UserRestoreDocument = gql`
  mutation UserRestore($id: String!) {
    restoreOneUser(id: $id) {
      ...UserBase
      roles {
        ...RoleBase
      }
    }
  }
  ${UserBaseFragmentDoc}
  ${RoleBaseFragmentDoc}
`

export function useUserRestoreMutation() {
  return Urql.useMutation<UserRestoreMutation, UserRestoreMutationVariables>(UserRestoreDocument)
}
export const ReassignUserDocument = gql`
  mutation ReassignUser($taskId: String!, $userId: String!, $projectId: String!) {
    reassignUser(taskId: $taskId, userId: $userId, projectId: $projectId) {
      id
      ...UserBase
      currentProjectId
      currentTaskId
      projectId
      taskId
      isClockedIn
      secondsClockedSinceOrgMidnight
      latestTimeEntry {
        ...TimeEntryBase
      }
      currentProject {
        id
        name
      }
      currentTask {
        id
        name
      }
      project {
        id
        name
      }
      task {
        id
        name
      }
    }
  }
  ${UserBaseFragmentDoc}
  ${TimeEntryBaseFragmentDoc}
`

export function useReassignUserMutation() {
  return Urql.useMutation<ReassignUserMutation, ReassignUserMutationVariables>(ReassignUserDocument)
}
export const ReassignUsersDocument = gql`
  mutation ReassignUsers($assignments: [UserAssignmentAssignment!]!) {
    reassignUsers(assignments: $assignments) {
      id
      organizationId
      projectId
      taskId
      email
      phoneNumber
      phoneNumberExt
      imageUrl
      firstName
      lastName
      jobTitle
      archived
      currentProjectId
      currentTaskId
      projectId
      taskId
      isClockedIn
      secondsClockedSinceOrgMidnight
      latestTimeEntry {
        id
        userId
        taskId
        startAt
        endAt
        durationInSeconds
        evidence
      }
      currentProject {
        id
        name
      }
      currentTask {
        id
        name
      }
      project {
        id
        name
      }
      task {
        id
        name
      }
    }
  }
`

export function useReassignUsersMutation() {
  return Urql.useMutation<ReassignUsersMutation, ReassignUsersMutationVariables>(ReassignUsersDocument)
}
export const CreateUserDocument = gql`
  mutation CreateUser($user: NewUser!, $sendInvite: Boolean!) {
    createUser(user: $user, sendInvite: $sendInvite) {
      ...UserBase
      payRate
      payType
      roles {
        id
        name
      }
      taskId
      workersCompCode {
        id
        name
        ncciCode
      }
    }
  }
  ${UserBaseFragmentDoc}
`

export function useCreateUserMutation() {
  return Urql.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument)
}
export const SetUserParentDocument = gql`
  mutation SetUserParent($childId: String!, $parentId: String!) {
    assignChildToParent(parentId: $parentId, childId: $childId) {
      parentId
      childId
    }
  }
`

export function useSetUserParentMutation() {
  return Urql.useMutation<SetUserParentMutation, SetUserParentMutationVariables>(SetUserParentDocument)
}
export const DeleteParentChildRelationshipDocument = gql`
  mutation DeleteParentChildRelationship($parentId: String!, $childId: String!) {
    deleteParentChildRelationship(parentId: $parentId, childId: $childId) {
      parentId
      childId
    }
  }
`

export function useDeleteParentChildRelationshipMutation() {
  return Urql.useMutation<DeleteParentChildRelationshipMutation, DeleteParentChildRelationshipMutationVariables>(
    DeleteParentChildRelationshipDocument
  )
}
export const UpdatePasswordDocument = gql`
  mutation UpdatePassword($currentPassword: String!, $newPassword: String!) {
    updatePassword(currentPassword: $currentPassword, newPassword: $newPassword)
  }
`

export function useUpdatePasswordMutation() {
  return Urql.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument)
}
export const DeleteUserDeviceSessionDocument = gql`
  mutation DeleteUserDeviceSession($deviceSessionId: String!) {
    deleteUserDeviceSession(deviceSessionId: $deviceSessionId)
  }
`

export function useDeleteUserDeviceSessionMutation() {
  return Urql.useMutation<DeleteUserDeviceSessionMutation, DeleteUserDeviceSessionMutationVariables>(
    DeleteUserDeviceSessionDocument
  )
}
export const MasqueradeUserDocument = gql`
  mutation MasqueradeUser($subjectUserId: String!) {
    masqueradeUser(subjectUserId: $subjectUserId) {
      accessToken
    }
  }
`

export function useMasqueradeUserMutation() {
  return Urql.useMutation<MasqueradeUserMutation, MasqueradeUserMutationVariables>(MasqueradeUserDocument)
}
export const EndAllMasqueradeSessionsForUserDocument = gql`
  mutation EndAllMasqueradeSessionsForUser($subjectUserId: String!) {
    endAllMasqueradeSessionsForUser(subjectUserId: $subjectUserId)
  }
`

export function useEndAllMasqueradeSessionsForUserMutation() {
  return Urql.useMutation<EndAllMasqueradeSessionsForUserMutation, EndAllMasqueradeSessionsForUserMutationVariables>(
    EndAllMasqueradeSessionsForUserDocument
  )
}
export const CreateUserAssignmentDocument = gql`
  mutation CreateUserAssignment($projectId: String!, $taskId: String, $userId: String!) {
    createUserAssignment(projectId: $projectId, taskId: $taskId, userId: $userId) {
      id
      projectId
      taskId
      userId
      isCurrentAssignment
      active
      createdAt
    }
  }
`

export function useCreateUserAssignmentMutation() {
  return Urql.useMutation<CreateUserAssignmentMutation, CreateUserAssignmentMutationVariables>(
    CreateUserAssignmentDocument
  )
}
export const UpdateUserAssignmentDocument = gql`
  mutation UpdateUserAssignment($id: String!, $projectId: String!, $taskId: String) {
    updateUserAssignment(id: $id, projectId: $projectId, taskId: $taskId) {
      id
      projectId
      taskId
      userId
    }
  }
`

export function useUpdateUserAssignmentMutation() {
  return Urql.useMutation<UpdateUserAssignmentMutation, UpdateUserAssignmentMutationVariables>(
    UpdateUserAssignmentDocument
  )
}
export const DeleteUserAssignmentDocument = gql`
  mutation DeleteUserAssignment($id: String!) {
    deleteUserAssignment(id: $id) {
      id
      projectId
      taskId
      userId
    }
  }
`

export function useDeleteUserAssignmentMutation() {
  return Urql.useMutation<DeleteUserAssignmentMutation, DeleteUserAssignmentMutationVariables>(
    DeleteUserAssignmentDocument
  )
}
export const BulkUpdateUserAssignmentsDocument = gql`
  mutation bulkUpdateUserAssignments(
    $assignmentsToCreate: [UserAssignmentCreateInput!]
    $assignmentsToDelete: [String!]
  ) {
    bulkUpdateUserAssignments(assignmentsToCreate: $assignmentsToCreate, assignmentsToDelete: $assignmentsToDelete) {
      count
    }
  }
`

export function useBulkUpdateUserAssignmentsMutation() {
  return Urql.useMutation<BulkUpdateUserAssignmentsMutation, BulkUpdateUserAssignmentsMutationVariables>(
    BulkUpdateUserAssignmentsDocument
  )
}
export const CreateWorkersCompCodeDocument = gql`
  mutation CreateWorkersCompCode(
    $name: String!
    $ncciCode: String!
    $ncciDescription: String!
    $override: Boolean!
    $rate: Float!
  ) {
    createWorkersCompCode(
      name: $name
      ncciCode: $ncciCode
      ncciDescription: $ncciDescription
      override: $override
      rate: $rate
    ) {
      id
      name
      ncciCode
      ncciDescription
      override
      rate
    }
  }
`

export function useCreateWorkersCompCodeMutation() {
  return Urql.useMutation<CreateWorkersCompCodeMutation, CreateWorkersCompCodeMutationVariables>(
    CreateWorkersCompCodeDocument
  )
}
export const ProjectProgressDocument = gql`
  query ProjectProgress($projectId: String!) {
    tasks(projectId: $projectId) {
      id
      isComplete
      estimatedHours
      isDefault
      timeEntriesSumDurationInSeconds
      unitGoalsSumProgress
      unitGoalsSumTargetQuantity
    }
  }
`

export function useProjectProgressQuery(options: Omit<Urql.UseQueryArgs<ProjectProgressQueryVariables>, "query">) {
  return Urql.useQuery<ProjectProgressQuery, ProjectProgressQueryVariables>({
    query: ProjectProgressDocument,
    ...options,
  })
}
export const TaskAndSubtaskProgressSummaryHookDocument = gql`
  query TaskAndSubtaskProgressSummaryHook($parentTaskId: String!) {
    task(id: $parentTaskId) {
      id
      isComplete
      estimatedHours
      timeEntriesSumDurationInSeconds
      unitGoalsSumProgress
      unitGoalsSumTargetQuantity
    }
    subtasks(parentTaskId: $parentTaskId) {
      id
      isComplete
      estimatedHours
      timeEntriesSumDurationInSeconds
      unitGoalsSumProgress
      unitGoalsSumTargetQuantity
    }
  }
`

export function useTaskAndSubtaskProgressSummaryHookQuery(
  options: Omit<Urql.UseQueryArgs<TaskAndSubtaskProgressSummaryHookQueryVariables>, "query">
) {
  return Urql.useQuery<TaskAndSubtaskProgressSummaryHookQuery, TaskAndSubtaskProgressSummaryHookQueryVariables>({
    query: TaskAndSubtaskProgressSummaryHookDocument,
    ...options,
  })
}
export const OrganizationWithAdminsAndRolesDocument = gql`
  query OrganizationWithAdminsAndRoles($organizationId: String!) {
    organization(id: $organizationId) {
      id
      archived
      dateCreated
      imageUrl
      name
      timezone
      stripeCustomerId
      billingPastDueAt
      admins {
        id
        archived
        firstName
        imageUrl
        isClockedIn
        jobTitle
        lastName
        isBlackthornEmployee
        latestTimeEntry {
          id
          startAt
        }
      }
      roles {
        id
        name
        order
        description
        permissions
        widgets
      }
      featureToggleSegments
    }
  }
`

export function useOrganizationWithAdminsAndRolesQuery(
  options: Omit<Urql.UseQueryArgs<OrganizationWithAdminsAndRolesQueryVariables>, "query">
) {
  return Urql.useQuery<OrganizationWithAdminsAndRolesQuery, OrganizationWithAdminsAndRolesQueryVariables>({
    query: OrganizationWithAdminsAndRolesDocument,
    ...options,
  })
}
export const OrganizationsIndexPageDocument = gql`
  query OrganizationsIndexPage($archived: Boolean!) {
    organizations(archived: $archived) {
      id
      dateCreated
      name
      archived
      stripeCustomerId
      lastStripeInvoice {
        id
        status
        created
      }
      roles {
        id
        name
        permissions
      }
      admins {
        id
        firstName
        lastName
      }
    }
  }
`

export function useOrganizationsIndexPageQuery(
  options: Omit<Urql.UseQueryArgs<OrganizationsIndexPageQueryVariables>, "query">
) {
  return Urql.useQuery<OrganizationsIndexPageQuery, OrganizationsIndexPageQueryVariables>({
    query: OrganizationsIndexPageDocument,
    ...options,
  })
}
export const AssetDetailsPageDocument = gql`
  query AssetDetailsPage($id: String!) {
    asset(id: $id) {
      id
      assetChildCount
      assetGroupId
      assignableType
      assignableId
      companyAssetNumber
      deletedAt
      groupQuantity
      imageUrl
      isAssetGroup
      name
      category
      ownershipType
      active
      activeChangedAt
      assetChildCount
      assetGroupMemberCount
      purchaseDetails {
        price
        date
      }
      rentalAgreement {
        rate {
          daily
          weekly
          monthly
        }
        endOn
        startOn
      }
      manufacturer {
        id
        make
        name
        year
        model
      }
      vendorContact {
        name
        email
        phone
      }
      inventoryRequirements {
        intervalInSeconds
        photoRequired
      }
      inspectionTemplatesAssignments {
        id
        assetId
        intervalInSeconds
        lastReportedAt
        startDate
        inspectionTemplate {
          id
          name
        }
      }
    }
  }
`

export function useAssetDetailsPageQuery(options: Omit<Urql.UseQueryArgs<AssetDetailsPageQueryVariables>, "query">) {
  return Urql.useQuery<AssetDetailsPageQuery, AssetDetailsPageQueryVariables>({
    query: AssetDetailsPageDocument,
    ...options,
  })
}
export const AssetAssetsDocument = gql`
  query AssetAssets($assetId: String!) {
    asset(id: $assetId) {
      id
      name
      childAssets {
        id
        active
        assetGroupId
        assignableId
        assignableType
        companyAssetNumber
        groupQuantity
        imageUrl
        isAssetGroup
        name
        ownershipType
        status
      }
    }
  }
`

export function useAssetAssetsQuery(options: Omit<Urql.UseQueryArgs<AssetAssetsQueryVariables>, "query">) {
  return Urql.useQuery<AssetAssetsQuery, AssetAssetsQueryVariables>({ query: AssetAssetsDocument, ...options })
}
export const AssetGroupDocumentDocument = gql`
  query assetGroupDocument($id: String!) {
    asset(id: $id) {
      id
      isAssetGroup
      name
      childAssets {
        id
        active
        assetChildCount
        assetGroupId
        assignableId
        assignableType
        companyAssetNumber
        groupQuantity
        imageUrl
        isAssetGroup
        name
        ownershipType
        status
      }
      childAssetGroups {
        assetGroupId
        assignableId
        assignableType
        count
        groupParent {
          id
          active
          assetChildCount
          assetGroupId
          assignableId
          assignableType
          companyAssetNumber
          imageUrl
          isAssetGroup
          name
          ownershipType
          status
        }
        status
      }
    }
  }
`

export function useAssetGroupDocumentQuery(
  options: Omit<Urql.UseQueryArgs<AssetGroupDocumentQueryVariables>, "query">
) {
  return Urql.useQuery<AssetGroupDocumentQuery, AssetGroupDocumentQueryVariables>({
    query: AssetGroupDocumentDocument,
    ...options,
  })
}
export const AssetHistoryDocument = gql`
  query AssetHistory($assetId: String!) {
    asset(id: $assetId) {
      id
      active
      groupQuantity
      name
      status
      assetChildCount
      assignableId
      assignableType
      isAssetGroup
      ownershipType
      inferredOwner {
        id
      }
      inventoryRequirements {
        photoRequired
        intervalInSeconds
      }
      inspectionTemplatesAssignments {
        id
        inspectionTemplate {
          id
          name
        }
      }
    }
    assetGroups(assetGroupId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      assignedUserId
      count
      status
    }
    assetReports(assetId: $assetId) {
      id
      assetId
      notes
      createdAt
      quantityReported
      reporter {
        id
        archived
        firstName
        imageUrl
        jobTitle
        lastName
        isClockedIn
      }
      statusChange {
        active
        status
        missingCount
      }
      type
      inspectionReport {
        id
        photoUrls
      }
      inspectionReportAggregations {
        trueCount
        falseCount
      }
      inventoryReport {
        fileIds
        note
        photoUrls
      }
    }
  }
`

export function useAssetHistoryQuery(options: Omit<Urql.UseQueryArgs<AssetHistoryQueryVariables>, "query">) {
  return Urql.useQuery<AssetHistoryQuery, AssetHistoryQueryVariables>({ query: AssetHistoryDocument, ...options })
}
export const AssetReportsDocument = gql`
  query AssetReports($assetId: String!) {
    asset(id: $assetId) {
      id
      active
      assetChildCount
      assetGroupId
      assignableId
      assignableType
      companyAssetNumber
      groupQuantity
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
      inventoryRequirements {
        intervalInSeconds
        photoRequired
      }
      inspectionTemplatesAssignments {
        id
        inspectionTemplate {
          id
          name
        }
      }
    }
    assetGroups(assetGroupId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      assignedUserId
      count
      status
      assetGroupId
    }
  }
`

export function useAssetReportsQuery(options: Omit<Urql.UseQueryArgs<AssetReportsQueryVariables>, "query">) {
  return Urql.useQuery<AssetReportsQuery, AssetReportsQueryVariables>({ query: AssetReportsDocument, ...options })
}
export const AssetsDocument = gql`
  query assets {
    assets_2 {
      edges {
        node {
          id
        }
      }
    }
  }
`

export function useAssetsQuery(options?: Omit<Urql.UseQueryArgs<AssetsQueryVariables>, "query">) {
  return Urql.useQuery<AssetsQuery, AssetsQueryVariables>({ query: AssetsDocument, ...options })
}
export const CreateUserNotificationDocument = gql`
  mutation createUserNotification(
    $modelType: UserNotificationModelType!
    $type: UserNotificationType!
    $assetId: String
    $userId: String
  ) {
    createUserNotification(modelType: $modelType, type: $type, assetId: $assetId, userId: $userId) {
      id
      type
      modelType
      asset {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`

export function useCreateUserNotificationMutation() {
  return Urql.useMutation<CreateUserNotificationMutation, CreateUserNotificationMutationVariables>(
    CreateUserNotificationDocument
  )
}
export const MyOrganizationRolesDocument = gql`
  query MyOrganizationRoles {
    myOrganization {
      roles {
        id
        name
        order
        description
        permissions
        widgets
      }
    }
  }
`

export function useMyOrganizationRolesQuery(
  options?: Omit<Urql.UseQueryArgs<MyOrganizationRolesQueryVariables>, "query">
) {
  return Urql.useQuery<MyOrganizationRolesQuery, MyOrganizationRolesQueryVariables>({
    query: MyOrganizationRolesDocument,
    ...options,
  })
}
export const MyOrganizationAdminsDocument = gql`
  query MyOrganizationAdmins {
    myOrganization {
      id
      dateCreated
      imageUrl
      name
      admins {
        id
        archived
        firstName
        imageUrl
        isClockedIn
        jobTitle
        lastName
        isBlackthornEmployee
        latestTimeEntry {
          id
          startAt
        }
      }
      roles {
        id
        name
        order
        description
        permissions
        widgets
      }
    }
  }
`

export function useMyOrganizationAdminsQuery(
  options?: Omit<Urql.UseQueryArgs<MyOrganizationAdminsQueryVariables>, "query">
) {
  return Urql.useQuery<MyOrganizationAdminsQuery, MyOrganizationAdminsQueryVariables>({
    query: MyOrganizationAdminsDocument,
    ...options,
  })
}
export const OrganizationDetailsDocument = gql`
  query OrganizationDetails {
    myOrganization {
      id
      archived
      dateCreated
      imageUrl
      name
      timezone
    }
  }
`

export function useOrganizationDetailsQuery(
  options?: Omit<Urql.UseQueryArgs<OrganizationDetailsQueryVariables>, "query">
) {
  return Urql.useQuery<OrganizationDetailsQuery, OrganizationDetailsQueryVariables>({
    query: OrganizationDetailsDocument,
    ...options,
  })
}
export const OrganizationRolesPageDocument = gql`
  query OrganizationRolesPage {
    myOrganization {
      id
      dateCreated
      imageUrl
      name
      roles {
        id
        name
        permissions
      }
    }
  }
`

export function useOrganizationRolesPageQuery(
  options?: Omit<Urql.UseQueryArgs<OrganizationRolesPageQueryVariables>, "query">
) {
  return Urql.useQuery<OrganizationRolesPageQuery, OrganizationRolesPageQueryVariables>({
    query: OrganizationRolesPageDocument,
    ...options,
  })
}
export const MyOrganizationDocument = gql`
  query MyOrganization {
    myOrganization {
      id
      dateCreated
      imageUrl
      name
      requireTimeEntryEvidence
      notificationSettings
    }
  }
`

export function useMyOrganizationQuery(options?: Omit<Urql.UseQueryArgs<MyOrganizationQueryVariables>, "query">) {
  return Urql.useQuery<MyOrganizationQuery, MyOrganizationQueryVariables>({ query: MyOrganizationDocument, ...options })
}
export const GetTaskDetailsDocument = gql`
  query GetTaskDetails($id: String!) {
    task(id: $id) {
      id
      name
      projectId
      parentTaskId
      archived
      assetCount
      isComplete
      isDefault
      userCount
      project {
        id
        name
        isArchived
        isComplete
      }
    }
  }
`

export function useGetTaskDetailsQuery(options: Omit<Urql.UseQueryArgs<GetTaskDetailsQueryVariables>, "query">) {
  return Urql.useQuery<GetTaskDetailsQuery, GetTaskDetailsQueryVariables>({ query: GetTaskDetailsDocument, ...options })
}
export const GetTaskGroupDetailsDocument = gql`
  query GetTaskGroupDetails($id: String!) {
    taskGroup(id: $id) {
      id
      completedTaskCount
      name
      projectId
      taskCount
    }
  }
`

export function useGetTaskGroupDetailsQuery(
  options: Omit<Urql.UseQueryArgs<GetTaskGroupDetailsQueryVariables>, "query">
) {
  return Urql.useQuery<GetTaskGroupDetailsQuery, GetTaskGroupDetailsQueryVariables>({
    query: GetTaskGroupDetailsDocument,
    ...options,
  })
}
export const CompleteTaskPageDocument = gql`
  query CompleteTaskPage($projectId: String!, $taskId: String!) {
    task(id: $taskId) {
      id
      name
      isDefault
    }
    project(id: $projectId) {
      id
      name
      unassignedTaskId
    }
  }
`

export function useCompleteTaskPageQuery(options: Omit<Urql.UseQueryArgs<CompleteTaskPageQueryVariables>, "query">) {
  return Urql.useQuery<CompleteTaskPageQuery, CompleteTaskPageQueryVariables>({
    query: CompleteTaskPageDocument,
    ...options,
  })
}
export const TaskDetailsDocument = gql`
  query TaskDetails($taskId: String!) {
    task(id: $taskId) {
      id
      groupId
      group {
        id
        name
      }
      organizationId
      projectId
      archived
      dateCreated
      description
      endDate
      estimatedHours
      completedHours
      hasReportableUnit
      isComplete
      isDefault
      metadata
      name
      startDate
      workersCompCode {
        id
        name
        ncciCode
      }
      project {
        id
        name
      }
      scheduleTemplate {
        id
        isDefault
        workDays {
          label
          active
        }
        workHours {
          hours
          startTime
          endTime
        }
        nonWorkDays {
          id
          name
          dateRange
          active
        }
      }
      scheduledBreaks {
        id
        durationInMinutes
        isActive
        localizedStartTime
        name
        breakTaskId
      }
      unitGoals {
        id
        deliverableUnitId
        targetQuantity
        isPrimary
        deliverableUnit {
          id
          description
          referenceNumber
          unitOfMeasure
        }
        unitGoalProgress {
          id
          progress
        }
      }
    }
  }
`

export function useTaskDetailsQuery(options: Omit<Urql.UseQueryArgs<TaskDetailsQueryVariables>, "query">) {
  return Urql.useQuery<TaskDetailsQuery, TaskDetailsQueryVariables>({ query: TaskDetailsDocument, ...options })
}
export const GetTaskGroupDocument = gql`
  query GetTaskGroup($id: String!) {
    taskGroup(id: $id) {
      id
      name
      notes
      description
      createdAt
      completedTaskCount
      assetCount
      organizationId
      unitProgress
      unitTargetGoal
      startDate
      endDate
      taskCount
      updatedAt
      userCount
      projectId
    }
  }
`

export function useGetTaskGroupQuery(options: Omit<Urql.UseQueryArgs<GetTaskGroupQueryVariables>, "query">) {
  return Urql.useQuery<GetTaskGroupQuery, GetTaskGroupQueryVariables>({ query: GetTaskGroupDocument, ...options })
}
export const TaskSummaryPageDocument = gql`
  query TaskSummaryPage($taskId: String!, $rangeEnd: DateTime) {
    task(id: $taskId) {
      id
      estimatedHours
      name
      startDate
      timeEntriesSumDurationInSeconds(rangeEnd: $rangeEnd)
      project {
        id
        name
      }
    }
  }
`

export function useTaskSummaryPageQuery(options: Omit<Urql.UseQueryArgs<TaskSummaryPageQueryVariables>, "query">) {
  return Urql.useQuery<TaskSummaryPageQuery, TaskSummaryPageQueryVariables>({
    query: TaskSummaryPageDocument,
    ...options,
  })
}
export const TaskTeamPageDocument = gql`
  query TaskTeamPage($taskId: String!) {
    task(id: $taskId) {
      id
      hasReportableUnit
      isComplete
      isDefault
      name
      projectId
      project {
        id
        isArchived
      }
      subtasks {
        id
        name
      }
    }
  }
`

export function useTaskTeamPageQuery(options: Omit<Urql.UseQueryArgs<TaskTeamPageQueryVariables>, "query">) {
  return Urql.useQuery<TaskTeamPageQuery, TaskTeamPageQueryVariables>({ query: TaskTeamPageDocument, ...options })
}
export const ProjectLayoutDocument = gql`
  query ProjectLayout($projectId: String!) {
    myOrganization {
      id
      imageUrl
      name
    }
    project(id: $projectId) {
      id
      code
      description
      endDate
      isArchived
      isComplete
      isDefault
      imageUrl
      metadata
      name
      scheduleTemplate {
        id
        isDefault
        workDays {
          label
          active
        }
        workHours {
          hours
          startTime
          endTime
        }
        nonWorkDays {
          id
          name
          dateRange
          active
        }
      }
      scheduledBreaks {
        id
        durationInMinutes
        isActive
        localizedStartTime
        name
        breakTaskId
        breakTask {
          id
          name
        }
      }
      startDate
      userCount
    }
  }
`

export function useProjectLayoutQuery(options: Omit<Urql.UseQueryArgs<ProjectLayoutQueryVariables>, "query">) {
  return Urql.useQuery<ProjectLayoutQuery, ProjectLayoutQueryVariables>({ query: ProjectLayoutDocument, ...options })
}
export const ArchiveProjectPageDocument = gql`
  query ArchiveProjectPage($projectId: String!) {
    project(id: $projectId) {
      id
      name
      userCount
      users {
        id
        currentProjectId
        currentTaskId
        firstName
        jobTitle
        lastName
        projectId
        taskId
        isClockedIn
      }
      defaultTask {
        id
        name
        isDefault
        projectId
      }
    }
  }
`

export function useArchiveProjectPageQuery(
  options: Omit<Urql.UseQueryArgs<ArchiveProjectPageQueryVariables>, "query">
) {
  return Urql.useQuery<ArchiveProjectPageQuery, ArchiveProjectPageQueryVariables>({
    query: ArchiveProjectPageDocument,
    ...options,
  })
}
export const ProjectAssetsPageDocument = gql`
  query ProjectAssetsPage($projectId: String!) {
    project(id: $projectId) {
      id
      name
    }
  }
`

export function useProjectAssetsPageQuery(options: Omit<Urql.UseQueryArgs<ProjectAssetsPageQueryVariables>, "query">) {
  return Urql.useQuery<ProjectAssetsPageQuery, ProjectAssetsPageQueryVariables>({
    query: ProjectAssetsPageDocument,
    ...options,
  })
}
export const ArchiveProjectDetailsSectionQueryDocument = gql`
  query ArchiveProjectDetailsSectionQuery($projectId: String!) {
    project(id: $projectId) {
      id
      isArchived
      userCount
    }
  }
`

export function useArchiveProjectDetailsSectionQueryQuery(
  options: Omit<Urql.UseQueryArgs<ArchiveProjectDetailsSectionQueryQueryVariables>, "query">
) {
  return Urql.useQuery<ArchiveProjectDetailsSectionQueryQuery, ArchiveProjectDetailsSectionQueryQueryVariables>({
    query: ArchiveProjectDetailsSectionQueryDocument,
    ...options,
  })
}
export const ActivateProjectDocument = gql`
  mutation ActivateProject($id: String!) {
    activateProject(id: $id) {
      id
      isArchived
    }
  }
`

export function useActivateProjectMutation() {
  return Urql.useMutation<ActivateProjectMutation, ActivateProjectMutationVariables>(ActivateProjectDocument)
}
export const ProjectDocument = gql`
  query project($projectId: String!) {
    project(id: $projectId) {
      id
      tasks {
        id
        estimatedHours
        startDate
        endDate
      }
      taskGroups {
        id
        estimatedHours
        startDate
        endDate
      }
    }
  }
`

export function useProjectQuery(options: Omit<Urql.UseQueryArgs<ProjectQueryVariables>, "query">) {
  return Urql.useQuery<ProjectQuery, ProjectQueryVariables>({ query: ProjectDocument, ...options })
}
export const ProjectDetailsDocument = gql`
  query ProjectDetails($projectId: String!) {
    project(id: $projectId) {
      id
      code
      description
      endDate
      imageUrl
      isDefault
      address {
        formattedAddress
      }
      location {
        lat
        latitude
        lng
        longitude
        radius
      }
      locationNotes
      metadata
      name
      startDate
      customer {
        id
        name
        customerId
        imageUrl
      }
      scheduleTemplate {
        id
        isDefault
        workDays {
          label
          active
        }
        workHours {
          hours
          startTime
          endTime
        }
        nonWorkDays {
          id
          name
          dateRange
          active
        }
      }
      scheduledBreaks {
        id
        durationInMinutes
        isActive
        localizedStartTime
        name
        breakTaskId
        breakTask {
          id
          name
        }
      }
      assignees {
        id
        isCurrentAssignment
        projectId
        taskId
        userId
        task {
          id
          name
        }
        user {
          id
          firstName
          imageUrl
          isClockedIn
          jobTitle
          lastName
        }
      }
      workersCompCode {
        id
        name
        ncciCode
      }
    }
  }
`

export function useProjectDetailsQuery(options: Omit<Urql.UseQueryArgs<ProjectDetailsQueryVariables>, "query">) {
  return Urql.useQuery<ProjectDetailsQuery, ProjectDetailsQueryVariables>({ query: ProjectDetailsDocument, ...options })
}
export const ProjectScheduleGanttDocument = gql`
  query ProjectScheduleGantt($projectId: String!) {
    project(id: $projectId) {
      id
      name
      tasks(includeSubtasks: false) {
        id
        endDate
        estimatedHours
        isComplete
        name
        startDate
        sortOrder
        unitGoalsSumProgress
        unitGoalsSumTargetQuantity
      }
      taskGroups {
        id
        endDate
        estimatedHours
        isComplete
        name
        startDate
        sortOrder
        tasks {
          id
          endDate
          estimatedHours
          groupId
          isComplete
          name
          startDate
          sortOrder
          unitGoalsSumProgress
          unitGoalsSumTargetQuantity
        }
        unitProgress
        unitTargetGoal
      }
    }
  }
`

export function useProjectScheduleGanttQuery(
  options: Omit<Urql.UseQueryArgs<ProjectScheduleGanttQueryVariables>, "query">
) {
  return Urql.useQuery<ProjectScheduleGanttQuery, ProjectScheduleGanttQueryVariables>({
    query: ProjectScheduleGanttDocument,
    ...options,
  })
}
export const ProjectSummaryPageWrapperDocument = gql`
  query ProjectSummaryPageWrapper($projectId: String!, $rangeStart: DateTime, $rangeEnd: DateTime) {
    myOrganization {
      id
      timezone
    }
    project(id: $projectId) {
      id
      estimatedHours
      name
      startDate
      assetsCount(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      previousSeconds: timeEntriesSumDurationInSeconds(rangeEnd: $rangeStart)
      totalSeconds: timeEntriesSumDurationInSeconds
      currentSeconds: timeEntriesSumDurationInSeconds(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      userCount
    }
  }
`

export function useProjectSummaryPageWrapperQuery(
  options: Omit<Urql.UseQueryArgs<ProjectSummaryPageWrapperQueryVariables>, "query">
) {
  return Urql.useQuery<ProjectSummaryPageWrapperQuery, ProjectSummaryPageWrapperQueryVariables>({
    query: ProjectSummaryPageWrapperDocument,
    ...options,
  })
}
export const ProjectTeamPageDocument = gql`
  query ProjectTeamPage($projectId: String!) {
    project(id: $projectId) {
      id
      name
    }
  }
`

export function useProjectTeamPageQuery(options: Omit<Urql.UseQueryArgs<ProjectTeamPageQueryVariables>, "query">) {
  return Urql.useQuery<ProjectTeamPageQuery, ProjectTeamPageQueryVariables>({
    query: ProjectTeamPageDocument,
    ...options,
  })
}
export const OrgScheduleDetailsDocument = gql`
  query OrgScheduleDetails {
    scheduleTemplates(isDefault: true) {
      id
      isDefault
      workDays {
        label
        active
      }
      workHours {
        hours
        startTime
        endTime
      }
      nonWorkDays {
        id
        name
        dateRange
        active
      }
    }
    scheduledBreaks(includeOrgBreaks: true) {
      id
      breakTask {
        id
        name
      }
      breakTaskId
      durationInMinutes
      isActive
      localizedStartTime
      name
    }
  }
`

export function useOrgScheduleDetailsQuery(
  options?: Omit<Urql.UseQueryArgs<OrgScheduleDetailsQueryVariables>, "query">
) {
  return Urql.useQuery<OrgScheduleDetailsQuery, OrgScheduleDetailsQueryVariables>({
    query: OrgScheduleDetailsDocument,
    ...options,
  })
}
export const GetUserAccountDetailsDocument = gql`
  query GetUserAccountDetails($id: String!) {
    user(id: $id) {
      imageUrl
      firstName
      lastName
      email
      phoneNumber
      phoneNumberExt
      passwordUpdatedAt
      securityActivity {
        createdAt
        event
        ip
        userAgent
      }
      deviceSessions {
        id
        userId
        ip
        userAgent
        isSingleUse
        requestInfo {
          country
          region
          city
          browser
          os
          platform
          isMobile
          isDesktop
          isTablet
          isBot
          isCurl
        }
        createdAt
        updatedAt
      }
    }
  }
`

export function useGetUserAccountDetailsQuery(
  options: Omit<Urql.UseQueryArgs<GetUserAccountDetailsQueryVariables>, "query">
) {
  return Urql.useQuery<GetUserAccountDetailsQuery, GetUserAccountDetailsQueryVariables>({
    query: GetUserAccountDetailsDocument,
    ...options,
  })
}
export const TeamMemberPageDocument = gql`
  query TeamMemberPage($id: String!) {
    user(id: $id) {
      id
      firstName
      imageUrl
      jobTitle
      lastName
      archived
      isClockedIn
    }
  }
`

export function useTeamMemberPageQuery(options: Omit<Urql.UseQueryArgs<TeamMemberPageQueryVariables>, "query">) {
  return Urql.useQuery<TeamMemberPageQuery, TeamMemberPageQueryVariables>({ query: TeamMemberPageDocument, ...options })
}
export const TeamDetailsPageGetUserDocument = gql`
  query TeamDetailsPageGetUser($id: String!, $fetchPay: Boolean!) {
    user(id: $id) {
      id
      currentProjectId
      currentTaskId
      organizationId
      projectId
      taskId
      companyUserId
      archived
      email
      firstName
      imageUrl
      isClockedIn
      jobTitle
      lastName
      phoneNumber
      phoneNumberExt
      secondsClockedSinceOrgMidnight
      payRate @include(if: $fetchPay)
      payType @include(if: $fetchPay)
      currentProject {
        id
        name
      }
      currentTask {
        id
        name
      }
      assignments {
        id
        isCurrentAssignment
        projectId
        taskId
        userId
        project {
          id
          code
          imageUrl
          name
        }
        task {
          id
          name
        }
        taskId
      }
      workersCompCode {
        id
        name
        ncciCode
      }
      childAncestorNodes {
        userId
        depth
      }
      parents {
        id
        firstName
        lastName
        jobTitle
      }
      roles {
        id
        description
        name
      }
    }
  }
`

export function useTeamDetailsPageGetUserQuery(
  options: Omit<Urql.UseQueryArgs<TeamDetailsPageGetUserQueryVariables>, "query">
) {
  return Urql.useQuery<TeamDetailsPageGetUserQuery, TeamDetailsPageGetUserQueryVariables>({
    query: TeamDetailsPageGetUserDocument,
    ...options,
  })
}
export const UserOfflineEventsDocument = gql`
  query UserOfflineEvents($userId: String!) {
    user(id: $userId) {
      id
      firstName
      lastName
      offlineEvents {
        id
        reconciledAt
        receivedAt
        clientCreatedAt
        clientCanceledAt
        issues
        clockInData {
          taskId
          task {
            id
            name
          }
          user {
            id
            fullName
          }
        }
        clockOutData {
          task {
            id
            name
          }
          user {
            id
            fullName
          }
        }
        type
      }
    }
  }
`

export function useUserOfflineEventsQuery(options: Omit<Urql.UseQueryArgs<UserOfflineEventsQueryVariables>, "query">) {
  return Urql.useQuery<UserOfflineEventsQuery, UserOfflineEventsQueryVariables>({
    query: UserOfflineEventsDocument,
    ...options,
  })
}
export const UserTimeCardsPageDocument = gql`
  query UserTimeCardsPage($userId: String!) {
    user(id: $userId) {
      id
      firstName
      lastName
      currentProjectId
      archived
      project {
        id
        name
      }
      currentTaskId
      task {
        id
        name
      }
      timeEntries {
        id
        durationInSeconds
        endAt
        signInPhotoUrl
        signOutPhotoUrl
        projectId
        taskId
        startAt
        project {
          id
          name
          isDefault
        }
        task {
          id
          parentTaskId
          name
          visibilityLevel
          parentTask {
            id
            name
          }
        }
      }
    }
  }
`

export function useUserTimeCardsPageQuery(options: Omit<Urql.UseQueryArgs<UserTimeCardsPageQueryVariables>, "query">) {
  return Urql.useQuery<UserTimeCardsPageQuery, UserTimeCardsPageQueryVariables>({
    query: UserTimeCardsPageDocument,
    ...options,
  })
}
export const NotificationsProviderDocument = gql`
  query NotificationsProvider($take: Int) {
    myNotifications(take: $take) {
      id
      modelType
      type
      markedReadAt
      createdAt
      asset {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`

export function useNotificationsProviderQuery(
  options?: Omit<Urql.UseQueryArgs<NotificationsProviderQueryVariables>, "query">
) {
  return Urql.useQuery<NotificationsProviderQuery, NotificationsProviderQueryVariables>({
    query: NotificationsProviderDocument,
    ...options,
  })
}
export const OrgSettingsProviderDocument = gql`
  query OrgSettingsProvider {
    myOrganization {
      id
      requireTimeEntryEvidence
      unitsOfMeasure {
        id
        name
        symbol
      }
    }
  }
`

export function useOrgSettingsProviderQuery(
  options?: Omit<Urql.UseQueryArgs<OrgSettingsProviderQueryVariables>, "query">
) {
  return Urql.useQuery<OrgSettingsProviderQuery, OrgSettingsProviderQueryVariables>({
    query: OrgSettingsProviderDocument,
    ...options,
  })
}
export const PermissionsExplorerGetOrganizationDocument = gql`
  query PermissionsExplorerGetOrganization {
    myOrganization {
      id
      roles {
        id
        name
        permissions
        widgets
      }
    }
  }
`

export function usePermissionsExplorerGetOrganizationQuery(
  options?: Omit<Urql.UseQueryArgs<PermissionsExplorerGetOrganizationQueryVariables>, "query">
) {
  return Urql.useQuery<PermissionsExplorerGetOrganizationQuery, PermissionsExplorerGetOrganizationQueryVariables>({
    query: PermissionsExplorerGetOrganizationDocument,
    ...options,
  })
}
