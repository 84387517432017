import type {} from "@mui/lab/themeAugmentation"
import { ComponentsOverrides, createTheme, Theme } from "@mui/material"
import { OverridesStyleRules } from "@mui/material/styles/overrides"
import type {} from "@mui/x-data-grid/themeAugmentation"
import type {} from "@mui/x-date-pickers-pro/themeAugmentation"
import { BiX } from "react-icons/bi"
import { colors } from "../helpers/colors"

const { gray, blue, red, green, orange } = colors
declare module "@mui/material/styles" {
  interface Palette {
    black: Palette["primary"]
    white: Palette["primary"]
  }

  interface PaletteOptions {
    black: PaletteOptions["primary"]
    white: PaletteOptions["primary"]
  }
}
declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    black: true
    white: true
  }
}

// Per the linked issue, and because the `root` styling does work for theming "Select"....
// https://github.com/mui/material-ui/issues/30225
type SelectStylesWithRoot = ComponentsOverrides<Theme>["MuiSelect"] & {
  root: OverridesStyleRules<"root", "MuiSelect", Theme>["root"]
}

export const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    primary: {
      light: blue[500],
      main: blue[600],
      dark: blue[700],
    },
    secondary: {
      light: gray[500],
      main: gray[600],
      dark: gray[700],
      contrastText: gray[600],
    },
    success: {
      light: green[500],
      main: green[600],
      dark: green[700],
    },
    warning: {
      light: orange[500],
      main: orange[600],
      dark: orange[700],
    },
    error: {
      light: red[500],
      main: red[600],
      dark: red[700],
    },
    black: {
      // Not really black, but there isn't really something like this in material palette terminology and gray
      // seems too much like what "secondary" actually is so that would be confusing IMO
      light: gray[700],
      main: gray[800],
      dark: gray[900],
    },
    white: {
      light: "#fff",
      main: "#fff",
      dark: "#fff",
    },
  },
  typography: {
    h3: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
    },
    h4: {
      fontSize: "20px",
      lineHeight: "32px",
      color: "gray[800]",
      fontWeight: 700,
      marginBottom: "16px",
    },
    h5: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      fontWeight: 700,
      color: gray[800],
      marginBottom: "1rem",
    },
    h6: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "1rem",
      "&:hover": { textDecoration: "underline" },
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:first-of-type": { borderRadius: "8px" },
          "&:last-of-type": { borderRadius: "8px" },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          fontWeight: 400,
          fontSize: "16px",
        },
        standardWarning: {
          backgroundColor: `${orange[50]} !important`,
          color: `${orange[600]} !important`,
          "& .MuiAlert-icon": {
            color: `${orange[600]} !important`,
            fontSize: "24px",
          },
        },
      },
    },
    MuiBackdrop: { styleOverrides: { root: { background: "rgba(15, 24, 40, 0.59)" } } },
    MuiButton: {
      styleOverrides: {
        root: {
          height: "40px",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: 400,
          letterSpacing: 0,
          boxShadow: "none",
          padding: "0.5rem 1rem",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
      // Tailwind global styles messes with the contained variant background colors. These are the manual overrides.
      // Most button styles do not need to be done this way. Try and default to the palette settings
      variants: [
        // Primary
        {
          props: { variant: "contained", color: "primary" },
          style: {
            backgroundColor: `${blue[600]} !important`,
            color: "white",
            "&:hover": {
              backgroundColor: `${blue[700]} !important`,
            },
          },
        },
        {
          props: { variant: "contained", color: "primary", disabled: true },
          style: {
            backgroundColor: `${blue[100]} !important`,
            color: `white !important`,
          },
        },
        // Secondary
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            backgroundColor: `${gray[100]} !important`,
            color: gray[800],
            "&:hover": {
              backgroundColor: `${gray[200]} !important`,
            },
          },
        },
        {
          props: { variant: "contained", color: "secondary", disabled: true },
          style: {
            backgroundColor: `${gray[50]} !important`,
            color: `${gray[300]} !important`,
          },
        },
        // Error
        {
          props: { variant: "contained", color: "error" },
          style: {
            backgroundColor: `${red[600]} !important`,
            color: "white",
            "&:hover": {
              backgroundColor: `${red[700]} !important`,
            },
            "&:disabled": {
              opacity: 0.2,
              color: "white",
            },
          },
        },
        // Success
        {
          props: { variant: "contained", color: "success" },
          style: {
            backgroundColor: `${green[600]} !important`,
            color: "white",
            "&:disabled": {
              opacity: 0.2,
              color: "white",
            },
            "&:hover": {
              backgroundColor: `${green[700]} !important`,
            },
          },
        },
        // Warning
        {
          props: { variant: "contained", color: "warning" },
          style: {
            backgroundColor: `${orange[600]} !important`,
            color: "white",
            "&:disabled": {
              opacity: 0.2,
              color: "white",
            },
            "&:hover": {
              backgroundColor: `${orange[700]} !important`,
            },
          },
        },
        // Black (Ok, it isn't really black but there isn't a name for a style like this in materia1)
        {
          props: { variant: "contained", color: "black" },
          style: {
            backgroundColor: `${gray[800]} !important`,
            color: "white",
            "&:disabled": {
              opacity: 0.2,
              color: "white",
            },
            "&:hover": {
              backgroundColor: `${gray[900]} !important`,
            },
          },
        },
        // White (Ok, it isn't really white but there isn't a name for a style like this in materia1 thats
        // better... we just need something that looks clean over a dark blue background so far... probs a
        // gross way to accomplish this but it'll work)
        {
          props: { variant: "contained", color: "white" },
          style: {
            backgroundColor: `white !important`,
            color: "primary",
            "&:disabled": {
              opacity: 0.2,
              color: "primary.main",
            },
            "&:hover": {
              backgroundColor: `${gray[100]} !important`,
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            borderColor: `${blue[600]}`,
            borderWidth: "2px",
            "&:hover": {
              borderColor: `${blue[700]}`,
              borderWidth: "2px",
            },
          },
        },
      ],
    },
    MuiChip: {
      defaultProps: { deleteIcon: <BiX size="16px" /> },
      variants: [
        {
          props: { variant: "filled", color: "primary" },
          style: { background: blue[50], color: blue[600], "&:hover": { backgroundColor: blue[100] } },
        },
      ],
      styleOverrides: {
        root: { backgroundColor: gray[100] },
        label: { fontSize: "16px", paddingLeft: "16px", paddingRight: "16px" },
        deleteIcon: { color: gray[800] },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        rowBuffer: 10,
      },
      styleOverrides: {
        root: {
          fontSize: "1rem",
          borderRadius: "8px",
          "& .MuiDataGrid-virtualScroller .MuiDataGrid-row": {
            borderTop: `thin solid ${gray[200]}`,
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: gray[25],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 600,
          },
          ".MuiDataGrid-columnHeaders": {
            border: "none",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .PrivateSwitchBase-input": {
            marginLeft: "9px",
            marginTop: "9px",
            width: "24px",
            height: "24px",
          },
          "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
            boxShadow: "none",
            paddingLeft: "6px",
          },
          "& .MuiInputBase-input.MuiInput-input, & .MuiInputBase-input:focus": {
            "--tw-ring-color": "none",
            "--tw-ring-shadow": "none",
            borderColor: "transparent",
          },
          "& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover": {
            backgroundColor: "transparent",
          },
          "& .MuiDataGrid-editInputCell, & .MuiDataGrid-cell--editing, & .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within":
            {
              outline: "none",
            },
        },
        columnHeader: {
          fontSize: "1rem",
          fontWeight: "600",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
        },
      },
    },
    MuiInputLabel: { styleOverrides: { root: { color: gray[500] } } },
    MuiMenu: { styleOverrides: { root: { "& .MuiBackdrop-invisible": { background: "transparent" } } } },
    MuiSelect: {
      styleOverrides: { root: { height: "40px" } } as SelectStylesWithRoot,
    },
    MuiSwitch: {
      defaultProps: {
        inputProps: {
          // This override was added because for some reason the clickable area on switches had a height of 1rem
          style: { height: "100%" },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: "24px",
          "& .MuiTabs-flexContainer": {
            gap: "24px",
          },
          "& .MuiTabs-indicator": {
            height: "4px",
            borderRadius: "13px",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: colors.gray[400],
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "24px",
          paddingLeft: 0,
          paddingRight: 0,
          minWidth: 0,

          "&.Mui-selected": {
            color: colors.gray[800],
          },
        },
      },
    },
    MuiTabPanel: { styleOverrides: { root: { padding: 0 } } },
    MuiTableCell: {
      styleOverrides: { body: { padding: "8px" }, root: { padding: "8px", borderColor: gray[200] } },
      variants: [
        {
          props: { variant: "head" },
          style: {
            fontWeight: "600",
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: { onWheel: (e) => e.target instanceof HTMLElement && e.target.blur(), size: "small" },
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": { borderColor: gray[400] },
            "& .MuiOutlinedInput-input": { boxShadow: "none" },
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": { color: colors.blue[600], backgroundColor: blue[50] },
          "&.Mui-selected:hover": { backgroundColor: blue[100] },
          "&:hover": { backgroundColor: blue[100] },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          height: "40px",
          borderColor: gray[400],
          marginLeft: 0,
          "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": { marginLeft: 0 },
          "&.Mui-selected": { backgroundColor: blue[50] },
          "&.Mui-selected:hover": { backgroundColor: blue[100] },
          "&:hover": { backgroundColor: blue[100] },
        },
      },
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          height: 15,
        },
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          display: "flex",
          "flex-direction": "column",
          "justify-content": "space-evenly",
          "align-items": "flex-start",
          flex: 6,
          padding: "12px 8px",
        },
      },
    },
    MuiTimelineOppositeContent: {
      styleOverrides: {
        root: {
          color: `${gray[600]}`,
          marginTop: "auto",
          marginBottom: "auto",
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          margin: "10px 0",
          backgroundColor: "#F1F5F9",
          color: "#1E293B",
          "box-shadow": "none",
        },
      },
    },
  },
  zIndex: {
    modal: 41,
    appBar: 42,
    drawer: 40,
  },
})
