import { FC } from "react"
import { classNames } from "../../helpers/classNames"

type Props = {
  className: string
}

export const SkeletonElement: FC<Props> = ({ className }) => (
  <div className={classNames("bg-gray-200 rounded-full", className)} />
)
