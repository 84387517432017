import React, { FC, InputHTMLAttributes } from "react"
import { IconType } from "react-icons"
import { overrideTailwindClasses } from "tailwind-override"
import { classNames } from "../../helpers/classNames"

type Props = {
  Icon?: IconType
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  type: InputHTMLAttributes<HTMLInputElement>["type"]
  value: string
}

export const Input: FC<InputHTMLAttributes<HTMLInputElement> & Props> = ({ className = "", Icon, ...props }) => {
  return (
    <div className="relative">
      <input
        style={{ boxShadow: "none" }}
        {...props}
        onWheel={(e) => e.currentTarget.blur()}
        className={overrideTailwindClasses(
          classNames("w-full rounded py-2 border-gray-400 placeholder:text-gray-400", Icon ? "pl-9" : "pl-3", className)
        )}
      />
      {Icon && (
        <div className="absolute inset-y-0 left-0 flex items-center ml-2 w-9 text-gray-400 placeholder:text-gray-400 placeholder:font-extralight">
          <Icon className="h-6 w-6" />
        </div>
      )}
    </div>
  )
}
